<div class="flex flex-row align-items-center justify-content-between" style="height: 36px;">
    <div class="flex-1">
        <div (click)="toggleAreaExpand()"
             class="bp-cursor-pointer root-header">
            <span>{{ scheduleArea.area }}</span>
            <i class="material-icons">{{ scheduleArea.expanded ? "expand_more" : "chevron_right" }}</i>
        </div>
    </div>
    <div class="flex-1 root-header flex flex-row align-items-center justify-content-end gap-10">
        <div>{{ getScheduleAreaTotal(scheduleArea) | currency:'GBP' }}</div>

        <button (click)="onDeleteAreaClick()"
                class="delete-stage btn btn-circle btn-small btn-secondary"
                [disabled]="scheduleService.disabled"
                ngbTooltip="Delete area">
            <i class="material-icons md-18">delete_forever</i>
        </button>
    </div>
</div>

<div *ngIf="expanded"
     class="flex flex-column m-l-20 gap-10">
    <bp-schedule-stage-for-filter-by-area-grouping
        *ngFor="let stage of scheduleService.filteredStages(scheduleArea['stages'], scheduleArea); let first = first;"
        [stage]="stage"
        [first]="first"
        [scheduleArea]="scheduleArea">
    </bp-schedule-stage-for-filter-by-area-grouping>
</div>
