import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IProjectComment } from 'app/shared/model/bp.model';

@Injectable({ providedIn: 'root' })
export class ProjectCommentsService {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {
    }

    query(projectId: number, scheduleTaskId?: number): Observable<HttpResponse<IProjectComment[]>> {
        return this.http.get<IProjectComment[]>(`${this.resourceUrl}/${projectId}/comments`, {
            params: { 'scheduleTaskId': scheduleTaskId },
            observe: 'response' });
    }

    delete(projectId: number, commentId: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${projectId}/comments/${commentId}`, { observe: 'response' });
    }

    create(projectId: number, comment: string, scheduleTaskId?: number): Observable<HttpResponse<IProjectComment>> {
        return this.http.post<IProjectComment>(`${this.resourceUrl}/${projectId}/comments`, comment, {
            params: { 'scheduleTaskId': scheduleTaskId },
            observe: 'response'
        });
    }
}
