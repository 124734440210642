import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ISpecification } from 'app/shared/model/specification.model';
import { IAccount } from 'app/shared/model/account.model';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { AccountService } from 'app/core/auth/account.service';
import { SpecDeleteDialogComponent } from 'app/entities/spec/delete/spec-delete-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bp-spec-list',
    templateUrl: './spec-list.component.html'
})
export class SpecListComponent implements OnInit {
    protected specs: ISpecification[];
    protected currentAccount: IAccount;

    constructor(
        private specService: SpecificationService,
        private accountService: AccountService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });
    }

    protected trackId(index: number, item: ISpecification): number {
        return item.id;
    }

    protected onDeleteClick(spec: ISpecification): void {
        const ngbModalRef = this.modalService.open(SpecDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.spec = spec;

        ngbModalRef.result.then(
            result => {
                this.loadAll();
            },
            reason => {
                //
            }
        );
    }

    private loadAll(): void {
        this.specService.query().subscribe(
            (res: HttpResponse<ISpecification[]>) => {
                this.specs = res.body;
            }
        );
    }
}
