import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IProject } from 'app/shared/model/project.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class QuoterExpandAreaGroupStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(project: IProject, area: IScheduleArea, value: boolean): void {
        this.webStorage.store(this.getLocalStorageKey(project, area), value);
    }

    retrieve(project: IProject, area: IScheduleArea): boolean {
        return this.webStorage.retrieve(this.getLocalStorageKey(project, area)) || false;
    }

    private getLocalStorageKey(project: IProject, area: IScheduleArea): string {
        return `quoter_${this.accountService.getAccountId()}_expandedAreaGroup_${project.id}_${area.id}'}`;
    }
}
