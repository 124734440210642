<div class="show-display-quoters-container">
    <div class="title">
        {{ MAX_SELECTED_COUNT }} choice maximum
    </div>

    <div class="values-container">
        <div class="display-quoters-option"
             *ngFor="let quoter of dashboardStore.quoters;">
            <input type="checkbox"
                   name="display-quoters_{{quoter.id}}"
                   class="with-gap size-20"
                   [disabled]="dashboardStore.inProcess"
                   [value]="quoter.selected"
                   (click)="select(quoter, $event)"
                   [checked]="quoter.selected">
            {{ quoter.company || quoter.email }}
        </div>
    </div>
</div>

