import { Route } from '@angular/router';
import { HomeownerComponent } from "app/account/homeowner/homeowner.component";

export const homeownerRoute: Route = {
    path: 'homeowner/dashboard/:token',
    component: HomeownerComponent,
    data: {
        authorities: [],
        pageTitle: 'Redirecting...'
    }
};
