import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class PaymentProviderGocardlessService {
    public resourceUrl = SERVER_API_URL + 'api/payment-provider/gocardless';

    constructor(private http: HttpClient) {}

    sendGocardlessPayerAuthorisationId(payerAuthorisationId: string): Observable<HttpResponse<void>> {
        return this.http.post<any>(`${this.resourceUrl}/${payerAuthorisationId}`, null, { observe: 'response' });
    }

    retrieveGocardlessToken(): Observable<string> {
        const domain = window.location.hostname; // 'dev.buildpartner.com.s3-website.eu-west-2.amazonaws.com'
        return this.http.get(`${this.resourceUrl}/token?domain=${domain}`, { responseType: 'text' });
    }

    createSubscription(): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/subscription`, null);
    }
}
