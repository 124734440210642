import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { map } from 'rxjs/operators';

export interface IAddressByPostCode {
    postcode: string;
    formattedAddress: string;
    addressComponents: {longName: string, shortName: string, types: string[]}[];
}

@Injectable({ providedIn: 'root' })
export class CommonService {
    public resourceUrl = SERVER_API_URL + 'api/common';

    constructor(private http: HttpClient) {
    }

    addressesByPostCode(postcode: string): Observable<IAddressByPostCode[]> {
        return this.http.get<IAddressByPostCode[]>(`${this.resourceUrl}/address/${postcode}`, { observe: 'response' })
            .pipe(map(res => {
            return res.body.map( item => {
                item.postcode = item.addressComponents.find(ac => ac.types[0] === 'POSTAL_CODE')?.longName || postcode;
                return item;
            });
        }));
    }
}
