import { Component, Input } from '@angular/core';
import { IScheduleTemplate } from 'app/shared/model/schedule-template.model';
import * as _ from 'lodash';

const PREDEFINED_ICON_FILE_NAMES = [
    'basement-extension',
    'dormer-loft-extension',
    'ground-floor-rear-extension',
    'ground-floor-side-extension',
    'first-floor-extension',
    'mansard-loft-extension',
    'hip-to-gable-loft-extension',
    'bathroom',
    'bedroom',
    'corridor',
    'en-suite',
    'extension',
    'external',
    'hallway',
    'hall',
    'kitchen',
    'landing',
    'living-room',
    'loft-conversion',
    'loft',
    'utility',
    'wc',
    'study',
    'snug',
    'ground-floor-shell',
    'top-floor-shell',
    'middle-floor-shell'
];

const DEFAULT_ICON_FILE_NAME = 'area_generic';

const EXTENSION = 'png';

@Component({
    selector: 'bp-schedule-template-card',
    templateUrl: './schedule-template-card.component.html',
    styleUrls: ['schedule-template-card.scss']
})
export class ScheduleTemplateCardComponent {
    @Input() template: IScheduleTemplate;

    get iconPath(): string {
        const iconPath = '/content/images/icons/areas';
        const filename = this.template.schedule.toLowerCase().replace(/\s/g, '-');

        let result = `${iconPath}/${DEFAULT_ICON_FILE_NAME}.${EXTENSION}`;

        if (_.indexOf(PREDEFINED_ICON_FILE_NAMES, filename) !== -1) {
            result = `${iconPath}/${filename}.${EXTENSION}`;
        } else {
            _.each(PREDEFINED_ICON_FILE_NAMES, (predFN: string) => {
                if (filename.includes(predFN)) {
                    result = `${iconPath}/${predFN}.${EXTENSION}`;
                    return;
                }
            });
        }

        return result;
    }

    onRemoveTemplateCountClick(event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.template.count > 0) {
            this.template.count--;
        }
    }

    onAddTemplateCountClick(event): void {
        event.preventDefault();
        event.stopPropagation();

        this.template.count++;
    }
}
