<div class="container-fluid">
    <div class="flex justify-content-between items-center flex-wrap gap-10">
        <div class="flex-1 flex flex-column gap-1">
            <div class="flex gap-20">
                <h1 class="title">
                    Your Quote
                </h1>

                <bp-project-status
                    [status]="costPlanService.project?.status">
                </bp-project-status>
            </div>
        </div>

        <div class="flex-1 flex flex-row justify-content-center">
            <bp-main-view-filter
                *ngIf="costPlanService.inited && costPlanService.scheduleAreaItems?.length && costPlanService.stageItems?.length"
                [disabled]="costPlanService?.inProcess"
                [disabledRoomMode]="true"
                (onChanged)="onMainViewFilterChangedFunc($event)"
                [initialFilterState]="costPlanService.filterState"
                [areaItems]="costPlanService.scheduleAreaItems"
                [stageItems]="costPlanService.stageItems">
            </bp-main-view-filter>
        </div>

        <div class="flex-1 flex justify-content-end">
            <button (click)="onViewQuoteClick()"
                    class="btn btn-secondary btn-border-radius btn-small pull-right"
                    type="button">
                View Quote
            </button>
        </div>
    </div>

    <div class="const-visualization-container flex justify-content-center m-t-40">
        <bp-project-details-cost-visualization
            [filterState]="costPlanService.filterState">
        </bp-project-details-cost-visualization>
    </div>
</div>
