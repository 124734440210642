<div class="step-container">
    <div class="step-header text-center">
        Which level of specification is your project?
    </div>

    <div class="bp-flex-fill-container">
        <div class="item-container">
            <div *ngFor="let specificationOption of data.specifications; trackBy: trackSpecificationById"
                 (click)="onSpecificationGroupClick(specificationOption)"
                 [ngClass]="{'selected': specificationOption.id === data.project.specification.id}"
                 class="text-center item">
                <div class="icon-and-label-container">
                    <div [style.background-image]="'url(' + (specificationOption.icon || iconDefault) + ')'"
                         class="bp-icon">
                    </div>

                    <div class="title m-t-20 d-none d-md-block">
                        {{specificationOption.title || specificationOption.name }}
                    </div>
                </div>

                <div class="description-container m-t-20 m-b-20">
                    <div class="title m-b-5 d-md-none">
                        {{specificationOption.title || specificationOption.name }}
                    </div>

                    <div class="description">
                        {{specificationOption.description || 'No Description'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
