<div class="modal-header">
    <div class="text-center"
         style="flex: 1">
        <h2>
            A Builder has been invited to Quote
        </h2>
    </div>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="description m-t-20">
        <p>
            If they're new to BuildPartner it might be worth following up with an email as sometimes the automated
            invite goes to spam.
        </p>
    </div>
</div>

<div class="modal-footer modal-footer-space-between">
    <div class="form-check m-b-30">
        <label class="form-check-label"
               for="do-not-show-again">
            Don't show again
            <input [formControl]="doNotShowAgain"
                   class="form-check-input"
                   id="do-not-show-again"
                   name="do-not-show-again"
                   type="checkbox">
            <span class="form-check-sign"><span class="check"></span></span>
        </label>
    </div>

    <button (click)="close()"
            tabindex="0"
            class="btn btn-primary btn-border-radius waves-effect"
            type="button">
        Close
    </button>
</div>

