import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SERVER_API_URL } from 'app/app.constants';
import { IKreoSpace } from 'app/shared/model/bp.model';

@Injectable({ providedIn: 'root' })
export class KreoApi {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {
    }

    /**
     * returns kreo data
     * @param projectId
     */
    getKreoSpace(projectId: number): Observable<IKreoSpace> {
        return this.http.get(`${this.resourceUrl}/${projectId}/kreo_data`, { observe: 'response' })
            .pipe(map((x) => {
                return x.body['Space'];
            }))
    }

    /**
     * Creates areas from kreo
     * @param projectId
     */
    createAreasFromKreo(projectId: number): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/${projectId}/attachments/kreo/areas`, { observe: 'response' });
    }
}
