import { Injectable } from '@angular/core';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { IScope, IScopesHeader } from 'app/shared/model/bp.model';

export interface IScopeFocusUpdateEvent {
    scopeHeader: IScopesHeader;
    scope: IScope | null;
}

@Injectable({ providedIn: 'root' })
export class ScopesStore {

    public focusUpdatedSubject = new Subject<IScopeFocusUpdateEvent>();
    public focusUpdated$ = this.focusUpdatedSubject.asObservable();
    private headersWithScopesSubject = new BehaviorSubject<IScopesHeader[]>([]);
    public headersWithScopes$ = this.headersWithScopesSubject.asObservable();

    constructor(private topMenuStateService: TopMenuStateService) {
    }

    public get headersWithScopes(): IScopesHeader[] {
        return this.headersWithScopesSubject.value;
    }

    public set headersWithScopes(value: IScopesHeader[]) {
        this.headersWithScopesSubject.next(value);
    }

    get projectId(): number {
        return this.topMenuStateService.project.id;
    }

}
