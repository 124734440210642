import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'bp-material-category-update',
    templateUrl: './material-category-update.component.html'
})
export class MaterialCategoryUpdateComponent implements OnInit {
    materialCategory: IMaterialCategory;
    isSaving: boolean;

    duplicatedMaterialCategoryList: IMaterialCategory[] = null;

    constructor(private materialCategoryService: MaterialCategoryService,
                private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ materialCategory }) => {
            this.materialCategory = materialCategory;
            this.checkIfExistWithTheSameName();
        });
    }

    previousState(): void {
        window.history.back();
    }

    save(): void {
        this.isSaving = true;

        if (this.materialCategory.id !== undefined) {
            this.subscribeToSaveResponse(this.materialCategoryService.update(this.materialCategory));
        } else {
            this.subscribeToSaveResponse(this.materialCategoryService.create(this.materialCategory));
        }
    }

    onNameChange(): void {
        this.checkIfExistWithTheSameName();
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IMaterialCategory>>): void {
        result.subscribe(
            (res: HttpResponse<IMaterialCategory>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess(): void {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError(): void {
        this.isSaving = false;
    }

    private checkIfExistWithTheSameName(): void {
        this.materialCategoryService
            .query({
                page: 0,
                size: 20,
                searchValue: this.materialCategory.name
            })
            .subscribe((res: HttpResponse<IMaterialCategory[]>) => {
                this.duplicatedMaterialCategoryList = _.filter(
                    res.body,
                    (mc: IMaterialCategory) =>
                        mc.name.toLowerCase() === this.materialCategory.name.toLowerCase() &&
                        (this.materialCategory.id == null || mc.id !== this.materialCategory.id)
                );
            });
    }
}
