import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as _ from 'lodash';

import { Subject, Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { finalize, take } from 'rxjs/operators';

export interface SearchBySelectInputData {
    searchBy: string;
    indexProperty: string;
    titleProperty: string;
    displayValue?: string;
    displayProperty?: string;
    calcLabelField?: Function;
    initItem?: any;
    initIndex?: any;
    initValue?: any;
    itemService: any;
    itemServiceMethod?: string;
    dropdownPosition?: 'top' | 'bottom' | 'auto';
    additionalQueryPathParams?: any;
    eventEmitter?: Subject<void>;
    clearEventEmitter?: Subject<void>;
    clearable?: boolean;
    placeholder?: string;
    placeholderInFocusedState?: string;
    onEnterClickHandler?: Function;
    suggestionsField?: string;
    pageSize?: number;
}


const DEFAULT_PLACEHOLDER = 'Type to search';

@Component({
    selector: 'bp-search-by-select',
    templateUrl: './search-by-select.component.html',
    styleUrls: ['search-by-select.scss']
})
export class SearchBySelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() disabled = false;
    @Input() data: SearchBySelectInputData;
    @Input() appendTo;
    @Input() multiply = false;
    @Input() removeUnderlines = false;
    @Input() additionalClasses = '';

    @Output() onSelectionChange = new EventEmitter();
    @Output() onSuggestionClick = new EventEmitter();

    eventSubscriber: Subscription;
    clearEventSubscriber: Subscription;

    inProcess = false;
    totalItemsCount = 0;
    items: any[] = [];
    suggestionItems: any[] = [];
    selectedIndex = null;
    page = 0;

    searchObject: {
        searchBy: string;
        searchValue: string;
    } = null;

    openingInProgress = false;

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    constructor(private ref: ChangeDetectorRef) {
    }

    get addNewPossible(): boolean {
        return (
            this.data?.onEnterClickHandler &&
            this.ngSelectComponent?.itemsList?.filteredItems?.length <= 0 &&
            this.ngSelectComponent?.searchTerm?.length > 0
        );
    }

    get placeholder(): string {
        return this.ngSelectComponent?.focused
            ? this.data?.placeholderInFocusedState || this.data?.placeholder || DEFAULT_PLACEHOLDER
            : this.data?.placeholder || DEFAULT_PLACEHOLDER;
    }

    ngOnInit(): void {
        if (this.data.eventEmitter) {
            this.eventSubscriber = this.data.eventEmitter.subscribe(() => {
                this.items = [];
                this.suggestionItems = [];
                this.initSelectedItemOnInit();
                this.prepareItems(this.items);
            });
        }

        if (this.data.clearEventEmitter) {
            this.clearEventSubscriber = this.data.clearEventEmitter.subscribe(() => {
                // Call to clear
                this.ngSelectComponent.handleClearClick();
            });
        }
    }

    ngOnDestroy(): void {
        this.eventSubscriber?.unsubscribe();
        this.clearEventSubscriber?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !_.isEqual(changes.data.currentValue, changes.data.previousValue)) {
            this.initSearchObject();

            if (!this.data.initValue) {
                this.selectedIndex =
                    this.data.initIndex != null
                        ? this.data.initIndex
                        : this.data.initItem
                            ? this.data.initItem[this.data.indexProperty]
                            : null;
            }

            this.initSelectedItemOnInit();
        }
    }

    setData(): void {
        const item = this.findSelectedItem();
        this.onSelectionChange.emit(item);
    }

    onAddNewTaskClick(): void {
        this.onEnterClick();
    }

    onOpen(): void {
        if ((this.items.length < 2 || this.multiply) && !this.openingInProgress) {
            this.openingInProgress = true;
            this.ngSelectComponent.close();
            this.page = 0;
            this.loadItems().then(
                () => {
                    this.ngSelectComponent.open();
                    this.openingInProgress = false;
                },
                () => {
                    this.openingInProgress = false;
                }
            );
        }
    }

    onTyped(event): void {
        if (!this.searchObject.searchBy || _.isEqual(this.searchObject.searchValue, event.term)) {
            return;
        }

        this.searchObject.searchValue = event.term;
        this.page = 0;
        this.loadItems();
    }

    onScrollToEnd(): void {
        if (this.items.length < this.totalItemsCount) {
            ++this.page;
            this.loadItems();
        }
    }

    onClear(): void {
        this.initSearchObject();
        this.onSelectionChange?.emit(null);
    }

    onScroll({ end }): void {
        if (this.inProcess || end <= this.items.length || this.items.length >= this.totalItemsCount) {
            return;
        }

        ++this.page;
        this.loadItems();
    }

    @HostListener('scroll', ['$event'])
    onScrollSuggestions(event: any): void {
        if (this.inProcess || this.suggestionItems.length >= this.totalItemsCount) {
            return;
        }

        this.loadSuggestionItems();
    }

    onEnterClick(): void {
        if (!this.addNewPossible) {
            return;
        }

        this.data.onEnterClickHandler(this.ngSelectComponent.searchTerm);
        this.ngSelectComponent.close();
        this.ngSelectComponent.blur();
    }

    onSuggestionItemClick(event, suggestionItem: any): void {
        this.onSuggestionClick.emit(suggestionItem);
        this.ngSelectComponent.close();
        this.ngSelectComponent.blur();
    }

    private initSearchObject(): void {
        this.searchObject = {
            searchBy: this.data.searchBy,
            searchValue: ''
        };
    }

    private orderItems(initialItems): any[] {
        return _.orderBy(initialItems, [(item: any) => (item[this.data.titleProperty] || '').toLowerCase()], ['asc']);
    }

    private calcLabelForItems(items): any[] {
        return _.map(items, (item: any) => {
            item['_calculatedLabelField'] = this.data.calcLabelField ? this.data.calcLabelField(item) : item[this.data.titleProperty] || '';
            return item;
        });
    }

    private validateIfModelItemInItems(): void {
        const foundIItem = this.findSelectedItem();

        if (!foundIItem && this.selectedIndex) {
            this.initSelectedItemOnInit();
        }
    }

    private initSelectedItemOnInit(): void {
        if (!this.multiply) {
            if (this.data.initIndex != null) {
                const item: any = { id: this.data.initIndex };
                item[this.data.titleProperty] = this.data.initValue;
                if (this.data.displayProperty && this.data.displayValue) {
                    item[this.data.displayProperty] = this.data.displayValue;
                    this.calcLabelForItems([item]);
                }
                this.items.unshift(item);
                this.selectedIndex = item.id;
            } else {
                this.selectedIndex = null;
            }
        } else {
            if (this.data.initIndex?.length) {
                const items: any = this.data.initIndex.map(i => {
                    return { id: i }
                });
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item[this.data.titleProperty] = this.data.initValue[i];
                    if (this.data.displayProperty && this.data.displayValue?.length) {
                        item[this.data.displayProperty] = this.data.displayValue[i];
                    }
                    this.items.unshift(item);
                }

                this.calcLabelForItems(items);

                this.selectedIndex = this.data.initIndex;
            } else {
                this.selectedIndex = [];
            }
        }
    }

    private prepareItems(items): void {
        this.validateIfModelItemInItems();

        let actualItems = items.filter(item => {
            if (this.data.suggestionsField?.length) {
                return !item[this.data.suggestionsField];
            } else {
                return true;
            }
        })
        actualItems = this.orderItems(actualItems);

        actualItems = this.orderItems(actualItems);
        this.items = this.calcLabelForItems(actualItems);

        let suggestionItems = items.filter(item => {
            if (this.data.suggestionsField?.length) {
                return item[this.data.suggestionsField];
            } else {
                return false;
            }
        })

        suggestionItems = this.orderItems(suggestionItems);
        this.suggestionItems = this.calcLabelForItems(suggestionItems);

        if (this.ngSelectComponent) {
            this.ngSelectComponent['options'] = this.items;
            this.ref.detectChanges();
        }
    }

    private async loadItems(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.page === 0) {
                this.items = [];
            }

            let queryObject = {
                page: this.page,
                size: this.data.pageSize || 10
            };

            if (this.searchObject) {
                queryObject = _.merge(queryObject, this.searchObject);
            }

            let bindQuery = null;

            if (this.data.additionalQueryPathParams) {
                bindQuery = (qo: any) => {
                    return this.data.itemService[this.data.itemServiceMethod ?? 'query'](this.data.additionalQueryPathParams, qo);
                };
            } else {
                bindQuery = (qo: any) => {
                    return this.data.itemService[this.data.itemServiceMethod ?? 'query'](qo);
                };
            }

            if (this.data.itemService) {
                this.inProcess = true;
                bindQuery(queryObject)
                    .pipe(
                        finalize(() => {
                            this.inProcess = false;
                        }),
                        take(1)
                    )
                    .subscribe(
                        (res: any) => {
                            this.totalItemsCount = res.headers.get('x-total-count');
                            this.items = _.uniqBy(this.items.concat(res.body), 'id');

                            this.prepareItems(this.items);
                            resolve();
                        },
                        () => {
                            reject();
                        }
                    );
            } else {
                resolve();
            }
        });
    }

    private async loadSuggestionItems(): Promise<void> {
        return new Promise((resolve, reject) => {
            let queryObject = {
                page: 0,
                size: this.totalItemsCount
            };

            if (this.searchObject) {
                queryObject = _.merge(queryObject, this.searchObject);
            }

            let bindQuery = null;

            if (this.data.additionalQueryPathParams) {
                bindQuery = (qo: any) => {
                    return this.data.itemService[this.data.itemServiceMethod ?? 'query'](this.data.additionalQueryPathParams, qo);
                };
            } else {
                bindQuery = (qo: any) => {
                    return this.data.itemService[this.data.itemServiceMethod ?? 'query'](qo);
                };
            }

            if (this.data.itemService) {
                this.inProcess = true;
                bindQuery(queryObject)
                    .pipe(
                        finalize(() => {
                            this.inProcess = false;
                        }),
                        take(1)
                    )
                    .subscribe(
                        (res: any) => {
                            this.totalItemsCount = res.headers.get('x-total-count');
                            this.suggestionItems = this.calcLabelForItems(res.body);
                            resolve();
                        },
                        () => {
                            reject();
                        }
                    );
            } else {
                resolve();
            }
        });
    }

    private findSelectedItem(): any[] {
        if (!this.multiply) {
            return _.find(this.items, (item: any) => {
                return item[this.data.indexProperty] === this.selectedIndex;
            });
        } else {
            return _.filter(this.items, (item: any) => {
                return this.selectedIndex.includes(item[this.data.indexProperty]);
            });
        }

    }
}
