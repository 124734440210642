import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class ImageUploadService {
    public resourceUrl = SERVER_API_URL + 'api/account';

    constructor(private http: HttpClient) {}

    uploadImage(image: File): Observable<string> {
        const formData = new FormData();
        formData.append('file', image);
        return this.http.post(`${this.resourceUrl}/logo_upload`, formData, { observe: 'body', responseType: 'text' });
    }

    removeCurrentImage(): Observable<void> {
        return this.http.delete<void>(`${this.resourceUrl}/logo`, { observe: 'body' });
    }
}
