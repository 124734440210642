import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IQuoterTaskCost } from 'app/shared/model/schedule-task.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';

@Injectable({ providedIn: 'root' })
export class QuoterDefaultPricesService {
    public resourceUrl = SERVER_API_URL + 'api/quoters';

    constructor(private http: HttpClient) {}

    queryTaskCost(
        quoterId: number,
        taskId: number,
        ratio: number,
        qty: number,
        primeId: number = null,
        scheduleTaskId: number | null = null,
        clientSupplied: boolean | null = null,
        clientMaterialPrice: number | null = null
    ): Observable<HttpResponse<IQuoterTaskCost>> {
        const req: any = { ratio, qty };
        if (primeId != null && primeId >= 0) {
            req.primeId = primeId;
        }
        if (scheduleTaskId != null) {
            req.scheduleTaskId = scheduleTaskId;
        }
        if (clientSupplied != null) {
            req.clientSupplied = clientSupplied;
        }
        if (clientMaterialPrice != null) {
            req.clientMaterialPrice = clientMaterialPrice;
        }
        const options = createRequestOption(req);
        return this.http.get<IQuoterTaskCost>(`${this.resourceUrl}/${quoterId}/task-cost/${taskId}`, {
            params: options,
            observe: 'response'
        });
    }

    queryTaskCostForTheProject(projectId: number, quoterId: number): Observable<HttpResponse<{ prop: IQuoterTaskCost }[]>> {
        return this.http.get<{ prop: IQuoterTaskCost }[]>(`${this.resourceUrl}/${quoterId}/${projectId}/task-cost`, {
            observe: 'response'
        });
    }
}
