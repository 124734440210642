import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IMaterial } from 'app/shared/model/material.model';
import { MaterialService } from 'app/shared/dataservices/material.service';
import { EntityMaterialService } from 'app/entities/material/material.service';

@Component({
    selector: 'bp-material-delete-dialog',
    templateUrl: './material-delete-dialog.component.html'
})
export class MaterialDeleteDialogComponent {
    material: IMaterial;

    constructor(
        private materialService: MaterialService,
        public activeModal: NgbActiveModal,
        private entityService: EntityMaterialService
    ) {
    }

    clear(): void {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number): void {
        this.materialService.delete(id).subscribe(
            response => {
                this.entityService.itemUpdatedSubject.next();
                this.activeModal.dismiss(true);
            }
        );
    }
}

@Component({
    selector: 'bp-material-delete-popup',
    template: ''
})
export class MaterialDeletePopupComponent implements OnInit, OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(({ material }) => {
            setTimeout(() => {
                this.ngbModalRef = this.modalService.open(MaterialDeleteDialogComponent as Component, {
                    size: 'lg',
                    backdrop: 'static'
                });
                this.ngbModalRef.componentInstance.material = material;

                const doOnResult = () => {
                    this.router.navigate([{ outlets: { popup: null } }], {
                        replaceUrl: true,
                        queryParamsHandling: 'merge'
                    });
                    this.ngbModalRef = null;
                };

                this.ngbModalRef.result.then(
                    result => {
                        doOnResult();
                    },
                    reason => {
                        doOnResult();
                    }
                );
            }, 0);
        });
    }

    ngOnDestroy() {
        this.ngbModalRef = null;
    }
}
