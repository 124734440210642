import { Component, Injectable, OnDestroy } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    AddMaterialModalComponent
} from 'app/shared/components/schedule/add-material-modal/add-material-modal.component';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IProject } from 'app/shared/model/project.model';

@Injectable({ providedIn: 'root' })
export class AddMaterialModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(project: IProject, scheduleTask: IScheduleTask, materialName: string, materialCategoryId: number, unit: string): NgbModalRef {
        this.ngbModalRef = this.modalService.open(AddMaterialModalComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;
        this.ngbModalRef.componentInstance.scheduleTask = scheduleTask;
        this.ngbModalRef.componentInstance.materialName = materialName;
        this.ngbModalRef.componentInstance.materialCategoryId = materialCategoryId;
        this.ngbModalRef.componentInstance.unit = unit;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
