export type SpecificMarginAction = 'labour-rates' | 'material-rates' | 'tasks';

export interface SpecificMarginActionInfo {
    id: SpecificMarginAction;
    label: string;
}

export const SPECIFIC_MARGIN_ACTIONS: SpecificMarginActionInfo[] = [
    { id: 'labour-rates', label: 'Labour' },
    { id: 'material-rates', label: 'Materials' },
    { id: 'tasks', label: 'Tasks' },
];
