import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { ValuationsPage } from 'app/flows/scheduler/valuations/valuations.page';
import {
    ValuationsTableComponent
} from 'app/flows/scheduler/valuations/components/valuations-table/valuations-table.component';

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule
    ],
    declarations: [
        ValuationsPage,
        ValuationsTableComponent
    ],
    providers: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class BpValuationsModule {
}
