import { HttpParams } from '@angular/common/http';

export const createRequestOption = (req?: any, defaults: { page?: number; size?: number; sort?: string } = {}): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (!req) {
        req = {
            page: defaults.page || 0,
            size: defaults.size || 3000,
            sort: defaults.sort || null
        };
    }
    if (req) {
        Object.keys(req).forEach(key => {
            if (req[key] != null) {
                options = options.set(key, req[key]);
            }
        });
    }

    return options;
};
