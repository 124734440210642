import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { taskRoute } from 'app/entities/task/task.route';
import { TaskListComponent } from 'app/entities/task/list/task-list.component';
import { TaskUpdateComponent } from 'app/entities/task/edit/task-update.component';
import { TaskDeleteDialogComponent } from 'app/entities/task/delete/task-delete-dialog.component';

const ENTITY_STATES = [...taskRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        TaskListComponent,
        TaskUpdateComponent,
        TaskDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpTaskModule {
}
