import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BuildUpCategoryUpdateComponent } from 'app/entities/build-up-category/edit/build-up-category-update.component';
import { BuildUpCategoryListComponent } from 'app/entities/build-up-category/list/build-up-category-list.component';
import {
    buildUpCategoryPopupRoute,
    buildUpCategoryRoute
} from 'app/entities/build-up-category/build-up-category.route';
import {
    BuildUpCategoryDeleteDialogComponent,
    BuildUpCategoryDeletePopupComponent
} from 'app/entities/build-up-category/delete/build-up-category-delete-dialog.component';

const ENTITY_STATES = [...buildUpCategoryRoute, ...buildUpCategoryPopupRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        BuildUpCategoryListComponent,
        BuildUpCategoryUpdateComponent,
        BuildUpCategoryDeletePopupComponent,
        BuildUpCategoryDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpBuildUpCategoryModule {
}
