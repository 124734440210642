<div (bpClickOutside)="editing = false">
    <div *ngIf="editing">
        <select (blur)="onBlurSelect()"
                [disabled]="disabled"
                [(ngModel)]="value"
                [name]="value"
                class="form-control">
            <option [ngValue]="null">
                {{placeholder}}
            </option>
            <option
                *ngFor="let option of values; trackBy: trackById"
                [ngValue]="option[idOption] === (value || {}[idField]) ? value : option[idOption]">
                {{option[labelField]}}
            </option>
        </select>
    </div>
    <div *ngIf="!editing">
        <a (click)="beginEdit(value)"
           (focus)="beginEdit(value)"
           href="#"
           tabindex="0">
            <span *ngIf="initialLabel()">{{ initialLabel() }}</span>
            <span *ngIf="!initialLabel()" class="grey-text">N/A</span>
        </a>
    </div>
</div>


