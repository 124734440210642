import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class PaymentProviderStripeService {
    public resourceUrl = SERVER_API_URL + 'api/payment-provider/stripe';

    constructor(private http: HttpClient) {}

    retrieveSessionId(projectId: number, total: number): Observable<string> {
        return this.http.get(`${this.resourceUrl}/session?projectId=${projectId}&total=${total}`, { responseType: 'text' });
    }

    createSubscription(): Observable<string> {
        return this.http.post(`${this.resourceUrl}/subscription`, null, { responseType: 'text' });
    }
}
