<div class="row schedule-task-row">
    <div class="col-sm-3 flex-line">
        <div class="task-name">
            {{ scheduleTask.task }}
        </div>
    </div>

    <div class="col-sm-2">
        <div class="column">
            <div class="form-line">
                <bp-select-input
                    *ngIf="materialCategory?.id && materialSelectInputData"
                    (onSelectionChange)="onMaterialSelectionChange($event)"
                    [data]="materialSelectInputData"
                    [removeUnderlines]="true"
                    class="select-material-category-id">
                </bp-select-input>
            </div>
        </div>
    </div>

    <div class="col-sm-2">
        <div class="column">
            <div class="form-line">
                <bp-select-input
                    tabindex="0"
                    (onSelectionChange)="onAreaSelectionChange($event)"
                    *ngIf="scheduleAreaSelectInputData"
                    [data]="scheduleAreaSelectInputData"
                    [placeholder]="'Select area'"
                    [removeUnderlines]="true"
                    class="select-area-id">
                </bp-select-input>
            </div>
        </div>
    </div>

    <div class="col-sm-2 flex align-items-center">
        <div class="column">
            <div class="form-line">
                {{ scheduleTask.stage }}
            </div>
        </div>
    </div>

    <div class="col-sm-2 row no-margin no-padding">
        <div class="col-sm-3 p-r-15">
            <div class="column">
                <div class="form-line">
                    <input [(ngModel)]="scheduleTask.unitValue"
                           [dropSpecialCharacters]="false"
                           [mask]="'0*.00'"
                           [ngClass]="{ 'error-state': scheduleTask['error'] && scheduleTask.unitValue == null }"
                           class="form-control af-input quantity-input"
                           id="unit-value-{{scheduleTask._uuid}}"
                           name="unit-value-{{scheduleTask._uuid}}"
                           required
                           type="text">
                    <span class="quantity-unit">
                       <span *ngIf="scheduleTask.unit === 'm2'">m<sup>2</sup></span>
                       <span
                           *ngIf="scheduleTask.unit !== 'm2'">{{ scheduleTask.unitValue > 1 ? scheduleTask.unitPlural : scheduleTask.unit }}</span>
                    </span>
                </div>
            </div>
        </div>
        <div
            *ngIf="!scheduleTask.clientSupplied && scheduleTask.primeMaterialId != null && scheduleTask.primeMaterialId >= 0"
            class="col-sm-9">
            <div class="flex-line has-units m-l-10">
                <input style="color: rgb(73, 80, 87);"
                       [(ngModel)]="scheduleTask.primeMaterialCost"
                       placeholder="Cost"
                       class="af-input material-cost-input width-limited"
                       id="material-cost-{{scheduleTask._uuid}}"
                       name="material-cost-{{scheduleTask._uuid}}"
                       type="number"/>
                <span class="pound-symbol">&pound;</span>
            </div>
        </div>
    </div>

    <div class="col-sm-1 p-l-5 text-left flex flex-row align-items-center justify-content-between">
            <div class="form-check clear" style="margin-top: 4px;">
                <label class="form-check-label"
                       for="client-supplied-{{scheduleTask._uuid}}">
                    <input [checked]="!scheduleTask.clientSupplied"
                           (change)="scheduleTask.clientSupplied = !scheduleTask.clientSupplied"
                           class="form-check-input"
                           id="client-supplied-{{scheduleTask._uuid}}"
                           name="client-supplied-{{scheduleTask._uuid}}"
                           type="checkbox">
                    <span class="form-check-sign"><span class="check"></span></span>
                </label>
          </div>

        <button class="delete-task btn btn-circle btn-small btn-secondary"
                (click)="onDeleteClick()"
                ngbTooltip="Delete task"
                type="button">
            <i class="material-icons md-18">delete_forever</i>
        </button>
    </div>
</div>
