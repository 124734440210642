import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { QuoterService } from 'app/shared/dataservices/quoter.service';
import { BpOnErrorService } from 'app/shared/services/bp-on-error.service';
import { finalize } from 'rxjs/operators';
import { ITaskLabour } from 'app/shared/model/task-labour.model';

@Component({
    selector: 'bp-quoter-task-labour-rates',
    templateUrl: './task-labour-rates.component.html',
    styleUrls: ['task-labour-rates.scss']
})
export class TaskLabourRatesComponent implements OnInit {
    taskLabours: ITaskLabour[];

    inProcessLoadingTaskLabours = false;
    inProcessDeleteTaskLabour = false;

    constructor(
        private quoterService: QuoterService,
        private bpOnErrorService: BpOnErrorService
    ) {
        this.taskLabours = [];
    }

    ngOnInit(): void {
        this.loadAll();
    }

    loadAll(): void {
        this.inProcessLoadingTaskLabours = true;

        this.quoterService
            .queryTaskLabours()
            .pipe(
                finalize(() => {
                    this.inProcessLoadingTaskLabours = false;
                })
            )
            .subscribe(
                (res: HttpResponse<ITaskLabour[]>) => this.paginate(res.body),
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    inProcess(): boolean {
        return this.inProcessLoadingTaskLabours || this.inProcessDeleteTaskLabour;
    }

    onDeleteTaskLabourClick(labour: ITaskLabour): void {
        this.inProcessDeleteTaskLabour = true;

        this.quoterService
            .deleteTaskLabour(labour.id)
            .pipe(
                finalize(() => {
                    this.inProcessDeleteTaskLabour = false;
                })
            )
            .subscribe(
                () => {
                    const index: number = this.taskLabours.indexOf(labour);
                    if (index !== -1) {
                        this.taskLabours.splice(index, 1);
                    }
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    private paginate(data: ITaskLabour[]): void {
        for (const taskLabour of data) {
            this.taskLabours.push(taskLabour);
        }
    }
}
