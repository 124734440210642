<div class="scope-header">
    <textarea
        #scopeHeaderInput
        bpTextareaAutoresize
        (blur)="applyChanges($event)"
        (keydown.enter)="applyChanges($event)"
        [formControl]="scopesHeaderControl"
        class="sc-input"
        placeholder="Enter new scope header"
        rows="1"
        type="text">
    </textarea>

    <button (confirm)="delete()"
            class="btn btn-circle btn-small btn-secondary"
            ngbTooltip="Delete scope header"
            [swal]="{
                    title: 'Are you sure you?',
                    text: 'Are you sure you want to delete this Scope Header and all it\'s scopes?',
                    icon: 'warning',
                    showCancelButton: true,
                    customClass: {
                       confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                       cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                    }
                }"
            type="button">
        <i class="material-icons">delete_forever</i>
    </button>
</div>
