import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRegion } from 'app/shared/model/region.model';
import { RegionApi } from 'app/shared/dataservices/region.api';

@Component({
    selector: 'bp-unit-update',
    templateUrl: './region-update.component.html'
})
export class RegionUpdateComponent implements OnInit {
    region: IRegion;
    isSaving: boolean;

    constructor(private regionservice: RegionApi, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ region }) => {
            this.region = region;
        });
    }

    previousState() {
        window.history.back();
    }

    save() {
        this.isSaving = true;
        if (this.region.id !== undefined) {
            console.log('1');
            this.subscribeToSaveResponse(this.regionservice.update(this.region));
        } else {
            console.log('2');
            this.subscribeToSaveResponse(this.regionservice.create(this.region));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IRegion>>) {
        result.subscribe(
            (res: HttpResponse<IRegion>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
