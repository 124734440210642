import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { tradeRoute } from 'app/entities/trade/trade.route';
import { TradeComponent } from 'app/entities/trade/trade.component';
import { TradeDetailComponent } from 'app/entities/trade/trade-detail.component';
import { TradeUpdateComponent } from 'app/entities/trade/trade-update.component';
import { TradeDeleteDialogComponent } from 'app/entities/trade/trade-delete-dialog.component';

const ENTITY_STATES = [...tradeRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        TradeComponent,
        TradeDetailComponent,
        TradeUpdateComponent,
        TradeDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpTradeModule {}
