<div class="quote container-fluid">
    <div class="flex flex-row justify-content-between align-items-start">
        <div class="flex flex-column gap-10">
            <div class="flex gap-15">
                <bp-main-view-filter
                    *ngIf="store.filterState"
                    (onChanged)="onMainViewFilterChangedFunc($event)"
                    [initialFilterState]="store.filterState"
                    [areaItems]="store.scheduleAreaItems"
                    [stageItems]="store.stageItems">
                </bp-main-view-filter>

                <button (click)="toggleExpand()"
                        [disabled]="inProcess()"
                        class="btn btn-secondary btn-border-radius-5 expand-collapse"
                        ngbTooltip="{{ store.expandedAll ? 'Collapse' : 'Expand'}}"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <ng-container *ngIf="store.expandedAll">
                            <i class="material-icons md-24">
                                fullscreen_exit
                            </i>
                            Expanded
                        </ng-container>

                        <ng-container *ngIf="!store.expandedAll">
                            <i class="material-icons md-24">
                                fullscreen
                            </i>
                            Collapsed
                        </ng-container>
                    </div>
                </button>

                <button [disabled]="inProcess()"
                        [popper]="bpShowSubStagesModeSelector"
                        [popperAppendTo]="'body'"
                        [popperTrigger]="'click'"
                        [popperPlacement]="'bottom'"
                        [popperPreventOverflow]="true"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        [popperApplyClass]="'bp-popper'"
                        class="btn btn-secondary btn-border-radius-5 toggle-show-sub-stages"
                        ngbTooltip="{{ 'Select show sub-stages mode'}}"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <i class="material-icons md-24">
                            {{ showSubstageModeInfo?.icon }}
                        </i>
                        {{ showSubstageModeInfo?.extendedLabel }}

                        <i class="material-icons cursor-pointer">
                            expand_more
                        </i>
                    </div>
                </button>
            </div>

            <div class="quote__search-container">
                <input [formControl]="searchControl"
                       (keydown.enter)="search()"
                       placeholder="Search"
                       type="text"/>

                <button (click)="clearSearch()"
                        *ngIf="searchControl.value?.length"
                        aria-hidden="true"
                        class="quote__search-container__clear-search"
                        type="button">&times;
                </button>

                <div class="quote__search-container__vr">
                </div>

                <fa-icon [icon]="['fas', 'search']" (click)="search()"></fa-icon>
            </div>
        </div>

        <div class="flex gap-15">
            <button [disabled]="inProcess()"
                    [popper]="bpProjectSpecificMarginSelector"
                    [popperAppendTo]="'body'"
                    [popperTrigger]="'click'"
                    [popperPlacement]="'bottom'"
                    [popperPreventOverflow]="true"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="true"
                    [popperApplyClass]="'bp-popper'"
                    class="btn btn-secondary btn-border-radius rates"
                    ngbTooltip="Rates"
                    [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                <div class="flex align-items-center gap-1">
                    <i class="material-icons md-24">
                        price_change
                    </i>
                    Rates

                    <i class="material-icons cursor-pointer">
                        expand_more
                    </i>
                </div>
            </button>

            <button [disabled]="inProcess()"
                    [popper]="bpExportSelector"
                    [popperAppendTo]="'body'"
                    [popperTrigger]="'click'"
                    [popperPlacement]="'bottom'"
                    [popperPreventOverflow]="true"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="true"
                    [popperApplyClass]="'bp-popper'"
                    class="btn btn-secondary btn-border-radius export"
                    ngbTooltip="Export"
                    [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                <div class="flex align-items-center gap-1">
                    <i class="material-icons md-24">
                        file_download
                    </i>
                    Export

                    <i class="material-icons cursor-pointer">
                        expand_more
                    </i>
                </div>
            </button>

            <button (click)="toggleHistoryView()"
                    [disabled]="inProcess()"
                    class="btn btn-secondary btn-border-radius expand-collapse"
                    ngbTooltip="{{ store.showHistoryView ? 'Hide variations' : 'Show variations'}}"
                    [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                <ng-container *ngIf="store.showHistoryView">
                    Hide variations
                </ng-container>

                <ng-container *ngIf="!store.showHistoryView">
                    Show variations
                </ng-container>
            </button>

            <button (click)="onSubmitButtonClick()"
                    *ngIf="!store.project.currentUserRelation.includes('ROLE_SCHEDULER')"
                    [disabled]="(store.project.status !== 'Schedule' && store.project.status !== 'Draft' && store.project.status !== 'Variations Made' && store.project.status !== 'Quoting') || inProcess()"
                    class="btn btn-primary btn-border-radius save"
                    data-toggle="tooltip"
                    title="Submit Quote"
                    type="button">
                {{ store.inProcessSubmittingQuote ? "Submitting..." : "Submit Quote"}}
            </button>
        </div>
    </div>

    <div *ngIf="store.inited"
         class="m-t-10">
        <bp-quote-stages>
        </bp-quote-stages>
    </div>

    <div *ngIf="!store.inited || inProcess()"
         class="text-center m-t-20">
        <strong>
            Please wait..
        </strong>
    </div>
</div>

<popper-content #bpExportSelector>
    <bp-export-selector
        (onChanged)="onExportTypeSelected($event)">
    </bp-export-selector>
</popper-content>

<popper-content #bpShowSubStagesModeSelector>
    <bp-show-substages-mode-selector
        [hideInUse]="true"
        [showSubStagesMode]="store.showSubStagesMode"
        (onChanged)="onShowSubStagesModeChanged($event)">
    </bp-show-substages-mode-selector>
</popper-content>

<popper-content #bpProjectSpecificMarginSelector>
    <bp-specific-margin-action-selector (onChanged)="onProjectSpecificMarginSelector($event)">
    </bp-specific-margin-action-selector>
</popper-content>
