<div class="modal-header">
    <h2>
        What kind of service do you require?
    </h2>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="addons-container">
        <div class="addons-container__addon"
             *ngFor="let addon of _addons"
             [ngClass]="{'addons-container__addon__selected': _selectedAddon === addon}"
             (click)="select(addon)">
            <img [alt]="addon.name"
                 height="150"
                 [src]="addon.imageUrl"/>

            <div class="addons-container__addon__title">
                {{ addon.name }}
            </div>

            <div class="addons-container__addon__price">
                {{ addon.cost | currency:'GBP' :'symbol' : '1.0-0' }}
            </div>

            <div class="addons-container__addon__subtitle"
                [innerHTML]="addon.subtitle">
            </div>

            <div class="addons-container__addon__description"
                 [innerHTML]="addon.description">
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button (click)="close()"
            tabindex="0"
            class="btn btn-secondary btn-border-radius waves-effect cancel"
            type="button">
        Cancel
    </button>

    <button (click)="ok()"
            [disabled]="_selectedAddon == null"
            tabindex="1"
            class="btn btn-border-radius waves-effect ok"
            type="button">
        Select Service
    </button>
</div>

