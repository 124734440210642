import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {

    constructor(private http: HttpClient,
                private $localStorage: LocalStorageService) {
    }

    getToken(): string {
        return this.$localStorage.retrieve('authenticationToken');
    }

    login(credentials): Observable<string> {
        const authenticateSuccess = (resp) => {
            const bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);
                this.storeAuthenticationToken(jwt);
                this.storeAdminProxy(resp?.body?.proxyAdmin || false)
                return jwt;
            }
        }

        const data = {
            username: credentials.username,
            password: credentials.password
        };

        return this.http.post(SERVER_API_URL + 'api/authenticate', data, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)));
    }

    loginForHomeowner(token: string): Observable<void> {
        const authenticateSuccess = (resp) => {
            const bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);
                this.storeAuthenticationToken(jwt);
                this.storeAdminProxy(resp?.body?.proxyAdmin || false)
                return jwt;
            }
        }

        return this.http.post(SERVER_API_URL + 'api/authenticate/temp-link', token, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)));
    }

    loginWithToken(jwt: string): Promise<string> {
        if (jwt) {
            this.storeAuthenticationToken(jwt);
            return Promise.resolve(jwt);
        } else {
            return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        }
    }

    storeAuthenticationToken(jwt: string): void {
        this.$localStorage.store('authenticationToken', jwt);
    }

    storeAdminProxy(adminProxy: boolean): void {
        this.$localStorage.store('proxyAdmin', adminProxy);
    }

    getProxyAdmin(): boolean {
        return this.$localStorage.retrieve('proxyAdmin') ?? false;
    }

    logout(): void {
        this.$localStorage.clear('authenticationToken');
        this.$localStorage.clear('proxyAdmin');
    }
}
