import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import {
    CssElementDeleteDialogComponent,
    CssElementDeletePopupComponent
} from 'app/entities/css-element/delete/css-element-delete-dialog.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { cssElementPopupRoute, cssElementRoute } from 'app/entities/css-element/css-element.route';
import { CssElementListComponent } from 'app/entities/css-element/list/css-element-list.component';
import { CssElementUpdateComponent } from 'app/entities/css-element/edit/css-element-update.component';

const ENTITY_STATES = [...cssElementRoute, ...cssElementPopupRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        CssElementListComponent,
        CssElementUpdateComponent,
        CssElementDeleteDialogComponent,
        CssElementDeletePopupComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpCssElementModule {
}
