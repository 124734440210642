<form name="add-group-of-task-form">
    <div class="modal-header">
        <h4 class="modal-title">
            Add Tasks
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <div class="row added-task-header-container"
             *ngIf="scheduleTasks.length > 0">
            <div class="col-sm-3 flex-line">
                <div class="task-name">
                    Task
                </div>
            </div>

            <div class="col-sm-2">
                Specification
            </div>

            <div class="col-sm-2">
                Area
            </div>

            <div class="col-sm-2">
                Stage
            </div>

            <div class="col-sm-2 row no-margin no-padding">
                <div class="col-sm-6 p-r-15">
                    Quantity
                </div>
                <div class="col-sm-6">
                    Total
                </div>
            </div>

            <div class="col-sm-1 text-left line-height-1"
                 style="margin-left: -30px;">
                Client<br>Supplied?
            </div>
        </div>

        <div class="added-task-container">
            <bp-edit-schedule-task-in-add-group
                class="added-task"
                *ngFor="let scheduleTask of scheduleTasks"
                [project]="project"
                [scheduleAreas]="scheduleAreas"
                [scheduleArea]="scheduleArea"
                [scheduleTask]="scheduleTask"
                (onDelete)="onDeleteScheduleTask($event)">
            </bp-edit-schedule-task-in-add-group>
        </div>

        <div class="search-container p-t-20">
            <input #taskSearchElement
                   [formControl]="searchControl"
                   (keydown.arrowDown)="onTaskSearchKeyDown($event)"
                   autocomplete="off"
                   class="af-input"
                   name="task-search-value"
                   placeholder="Search tasks"
                   type="text">

            <ul class="tasks-container m-t-20"
                #scrollTasksElement
                (scroll)="onScrollTasksList($event)"
                (keydown.space)="$event.preventDefault()">
                <li *ngFor="let task of tasks; trackBy: trackId"
                    (keydown.arrowUp)="onScrollTaskKeyUp($event)"
                    (keydown.arrowDown)="onScrollTaskKeyDown($event)"
                    (keydown.enter)="onScrollTaskEnter($event, task)"
                    (focus)="task.focused = true"
                    (blur)="task.focused = false"
                    tabindex="0"
                    class="task"
                    [ngClass]="{ 'focused': task.focused, 'selected': task._selectedCount > 0 }"
                    (click)="addScheduleTask(task)">
                    {{ task.displayName }}
                </li>

                <li *ngIf="inProcessTasksLoading"
                    class="loading">
                    Loading..
                </li>

                <li *ngIf="!inProcessTasksLoading && tasks && tasks.length === 0"
                    class="tasks-were-not-found">
                    <span *ngIf="!searchControl?.value?.length; else cannotFindTerm">No data to show</span>
                    <ng-template #cannotFindTerm>
                        <div>
                            Sorry, we couldn't find the term "{{ searchControl.value }}
                            " {{ scheduleArea ? 'in the "' + scheduleArea.area + '"' : '' }}, would you like to
                            <button class="btn btn-link" (click)="addNotFoundedTask()">Add this task</button>
                            ?
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="clear()"
                tabindex="0"
                class="btn btn-secondary btn-border-radius mr-2"
                data-dismiss="modal"
                type="button">
            Close
        </button>
        <button [disabled]="!addTaskButtonEnabled()"
                (click)="onAddTasksClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect add-tasks"
                type="button">
            Add Tasks
        </button>
    </div>
</form>
