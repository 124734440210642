import { Component, Input, OnInit } from '@angular/core';
import { IStage } from 'app/shared/model/stage.model';
import * as _ from 'lodash';
import { ExpandStagesStorageService } from 'app/flows/scheduler/schedule/services/expand-stages-storage.service';
import { IScheduleTaskQuoterTotalModificationEvent, } from 'app/shared/constants/events.constants';
import { IElement } from 'app/shared/model/element.model';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'bp-schedule-stage-for-filter-by-stage-grouping',
    templateUrl: './stage.component.html',
    styleUrls: [
        '../../../schedule-common.scss',
        '../../root-container/stage.scss',
        'stage.scss'
    ]
})
export class StageForFilterByStageGroupingComponent implements OnInit {
    @Input() stage: IStage;
    @Input() first: boolean;
    @Input() showGroupNode = true;

    protected expanded = false;

    constructor(
        public scheduleService: ScheduleService,
        private scheduleEventsService: ScheduleEventsService,
        private confirmModalService: ConfirmModalService,
        private expandStagesStorageService: ExpandStagesStorageService
    ) {
    }

    get stageElements(): IElement[] {
        switch (this.scheduleService.showSubStagesMode) {
            case 'display-all':
            case 'hide-all':
            case "finishes":
                return this.stage.elements;
            case 'show-in-use':
                return _.filter(this.stage.elements, (element: IElement) => {
                    return element.scheduleTasks && element.scheduleTasks.length > 0;
                });
        }
    }

    ngOnInit(): void {
        this.stage.elements = this.stage.elements || [];
        this.expanded = this.expandStagesStorageService.retrieve(this.scheduleService.project.id, this.stage.id);
        this.registerScheduleTaskQuoterTotalModificationEvent();

        this.scheduleService.expandAll$
            .pipe(
                skip(1),
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res;
            this.expandStagesStorageService.store(this.scheduleService.project.id, this.stage.id, res);
        })

        this.scheduleService.expandStage$
            .pipe(
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res.expand;
            this.expandStagesStorageService.store(this.scheduleService.project.id, this.stage.id, res.expand);
        })
    }

    protected toggleStageExpand(): void {
        if (this.expanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    protected onDeleteStageClick(): void {
        this.confirmModalService.open({
            header: `Delete ${this.stage.stage} Stage?`,
            textHtml: `<div class="strong m-b-10">
                            This will delete all its associated tasks and costs.
                        </div>
                        <div class="strong m-b-10">
                            Note: You can re-add tasks to this stage by clicking 'On' in the templates section or using the 'add task' function.
                        </div>`
        }).result.then((res) => {
            if (!res) {
                return;
            }

            this.scheduleService.deleteStage(null, this.stage);
        })
    }

    private expand(): void {
        this.expanded = true;
        this.expandStagesStorageService.store(this.scheduleService.project.id, this.stage.id, true);
    }

    private collapse(): void {
        this.expanded = false;
        this.expandStagesStorageService.store(this.scheduleService.project.id, this.stage.id, false);
    }

    private registerScheduleTaskQuoterTotalModificationEvent(): void {
        this.scheduleEventsService.scheduleTaskTotalModified$
            .pipe(
                untilDestroyed(this)
            ).subscribe((event: IScheduleTaskQuoterTotalModificationEvent) => {
            if (!event || event.stageId !== this.stage.id) {
                return;
            }

            let total = 0;
            this.stage.elements.forEach((element) => {
                element.scheduleTasks.forEach(scheduleTask => {
                    if (!scheduleTask.markedAsDeleted) {
                        total += scheduleTask.taskTotal.total;
                    }
                });
            });

            this.stage.total = total;
            this.scheduleEventsService.stageQuoterTotalModified();
        })
    }
}
