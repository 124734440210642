import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SelectInputData } from "app/shared/components/common/select-input/select-input.component";
import { ScheduleAreaApi } from "app/shared/dataservices/schedule-area.api";
import { IScheduleAreaScope } from "app/shared/model/tag.model";
import { ConfirmModalService } from "app/shared/components/common/confirm-modal/confirm-modal.service";
import { BpAlertService } from "app/shared/services/bp-alert.service";
import {
    EditScheduleAreaServiceService,
} from "app/flows/scheduler/schedule/components/edit-schedule-areas/edit-schedule-area-service.service";

const NUMBER_PATTERN = '^[0-9]+(\.[0-9]+)*$';
const ROUNDING_PRECISION = 2;

@Component({
    selector: 'bp-schedule-area-row',
    templateUrl: './schedule-area-row.component.html',
    styleUrls: ['schedule-area-row.scss']
})
export class ScheduleAreaRowComponent implements OnInit, OnDestroy {
    @Input() scheduleArea: IScheduleArea;

    protected ceilingAreaWasUpdated = false;

    protected scopeSelectInputData: SelectInputData;
    protected form: FormGroup | null = null;
    protected formSubs: Subscription = new Subscription();
    protected subs: Subscription = new Subscription();
    private scheduleAreaScopes: IScheduleAreaScope[] = [];

    constructor(
        protected service: EditScheduleAreaServiceService,
        private scheduleAreaApi: ScheduleAreaApi,
        private confirmModalService: ConfirmModalService,
        private alertService: BpAlertService
    ) {
    }

    protected get perimeter(): number {
        return _.round((this.scheduleArea.width + this.scheduleArea.depth) * 2, ROUNDING_PRECISION)
    }

    protected get wallArea(): number {
        return _.round(
            this.scheduleArea.perimeter * this.scheduleArea.height,
            ROUNDING_PRECISION
        );
    }

    protected get floorArea(): number {
        return _.round(this.scheduleArea.width * this.scheduleArea.depth, ROUNDING_PRECISION);
    }

    ngOnInit(): void {
        if (this.scheduleArea._updated) {
            this.subs.add(
                this.scheduleArea._updated.subscribe(() => {
                    this.updateForm();
                    this.service.updateSub.next({ saveToCache: true });
                })
            );
        }

        this.fillComponentAreaSearchBySelectInputData();

        this.form = new FormGroup({
            area: new FormControl(this.scheduleArea.area, [Validators.required]),
            width: new FormControl(this.scheduleArea.width, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            depth: new FormControl(this.scheduleArea.depth, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            height: new FormControl(this.scheduleArea.height, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            wallArea: new FormControl(this.scheduleArea.wallArea || this.wallArea, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            floorArea: new FormControl(this.scheduleArea.floorArea || this.floorArea, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            ceilingArea: new FormControl(this.scheduleArea.ceilingArea || this.scheduleArea.ceilingArea, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
            perimeter: new FormControl(this.scheduleArea.perimeter || this.perimeter, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
        });

        this.subscribeFormToUpdates();
    }

    ngOnDestroy(): void {
        this.formSubs?.unsubscribe();
        this.subs?.unsubscribe();
    }

    protected updateScheduleArea(): void {
        this.scheduleArea.area = this.form.controls.area.value.trim();
        this.scheduleArea.width = +this.form.controls.width.value;
        this.scheduleArea.depth = +this.form.controls.depth.value;
        this.scheduleArea.height = +this.form.controls.height.value;
        this.scheduleArea.wallArea = +this.form.controls.wallArea.value;
        this.scheduleArea.floorArea = +this.form.controls.floorArea.value;
        this.scheduleArea.ceilingArea = +this.form.controls.ceilingArea.value;
        this.scheduleArea.perimeter = +this.form.controls.perimeter.value;
        this.scheduleArea.doors = this.scheduleArea.doors || 1;
        this.scheduleArea.windows = this.scheduleArea.windows || 1;
        this.scheduleArea._invalid = this.form.invalid;
    }

    protected onDeleteScheduleAreaClick(): void {
        this.service.onRemoveScheduleArea(this.scheduleArea);
    }

    protected onCopyScheduleAreaClick(): void {
        this.service.onCopyScheduleArea(this.scheduleArea)
    }

    protected tryToCreateAreaIfNeeded(): void {
        if (this.form.controls.area.value?.length) {
            if (this.scheduleArea._empty) {
                this.updateScheduleArea();
                this.service.onStartToFillScheduleArea(this.scheduleArea);
            }
        }
    }

    protected preventDND(event: DragEvent): void {
        event.stopPropagation();
        event.preventDefault();
    }

    protected onScopeSelectionChange(event: any): void {
        this.confirmModalService.open(
            {
                header: 'Are you sure?',
                textHtml: `<div class="strong text-center">
                                This will override the tasks in this area and cannot be reversed.
                           </div>`,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'OK',
            }
        ).result.then(res => {
                if (res) {
                    const templateAreaId = this.scheduleAreaScopes.find(scope => scope.tag.id === event.id).id;
                    if (this.service.useCache) {
                        this.service.save(false, false).then(() => {
                            this.scheduleAreaApi.setScope(this.scheduleArea.id, templateAreaId, event.id).subscribe((res) => {
                                this.service.reload();
                                this.alertService.success('Scope was successfully updated');
                            })
                        })
                    } else {
                        this.scheduleAreaApi.setScope(this.scheduleArea.id, templateAreaId, event.id).subscribe((res) => {
                            this.service.reload();
                            this.alertService.success('Scope was successfully updated');
                        })
                    }
                } else {
                    event.preventDefault();
                }
            }
        );
    }

    private subscribeFormToUpdates(): void {
        this.formSubs.add(this.form.controls.area.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (this.scheduleArea._empty) {
                return;
            }
            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));

        this.formSubs.add(this.form.controls.width.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.recalculateOnChangeWidthOrHeightDepth();
            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));

        this.formSubs.add(this.form.controls.height.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.recalculateOnChangeWidthOrHeightDepth();
            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));

        this.formSubs.add(this.form.controls.depth.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.recalculateOnChangeWidthOrHeightDepth();
            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));

        this.formSubs.add(this.form.controls.wallArea.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));

        this.formSubs.add(this.form.controls.ceilingArea.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));

        this.formSubs.add(this.form.controls.floorArea.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            if (!this.ceilingAreaWasUpdated) {
                this.form.controls.ceilingArea.setValue(_.round(value, ROUNDING_PRECISION)), {
                    emitEvent: false,
                    onlySelf: true
                };
            }

            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));

        this.formSubs.add(this.form.controls.perimeter.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.form.controls.wallArea.setValue(_.round(this.form.controls.perimeter.value * this.form.controls.height.value, ROUNDING_PRECISION), {
                emitEvent: false,
                onlySelf: true
            });
            this.updateScheduleArea();
            this.service.updateSub.next({ saveToCache: true });
        }));
    }

    private updateForm(): void {
        this.formSubs?.unsubscribe();

        this.form.controls.area.setValue(this.scheduleArea.area);
        this.form.controls.width.setValue(this.scheduleArea.width);
        this.form.controls.depth.setValue(this.scheduleArea.depth);
        this.form.controls.height.setValue(this.scheduleArea.height);
        this.form.controls.wallArea.setValue(this.scheduleArea.wallArea);
        this.form.controls.floorArea.setValue(this.scheduleArea.floorArea);
        this.form.controls.ceilingArea.setValue(this.scheduleArea.ceilingArea);
        this.form.controls.perimeter.setValue(this.scheduleArea.perimeter);

        this.subscribeFormToUpdates();
    }

    private recalculateOnChangeWidthOrHeightDepth(): void {
        this.form.controls.ceilingArea.setValue(_.round(+this.form.controls.width.value * +this.form.controls.depth.value, ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
        this.form.controls.floorArea.setValue(_.round(+this.form.controls.width.value * +this.form.controls.depth.value, ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
        this.form.controls.perimeter.setValue(_.round(2 * (+this.form.controls.width.value + +this.form.controls.depth.value), ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
        this.form.controls.wallArea.setValue(_.round(+this.form.controls.perimeter.value * +this.form.controls.height.value, ROUNDING_PRECISION),
            { emitEvent: false, onlySelf: true });
    }

    private fillComponentAreaSearchBySelectInputData(): void {
        if (this.scheduleArea.id != null) {
            this.scheduleAreaApi.scopes(this.scheduleArea.id).subscribe((res) => {
                this.scheduleAreaScopes = res.body;

                if (res.body.length) {
                    const data = [];
                    res.body.forEach(scheduleAreaScope => {
                        data.push(scheduleAreaScope.tag);
                    })

                    this.scopeSelectInputData = {
                        indexProperty: 'id',
                        titleProperty: 'name',
                        initIndex: data[0].id,
                        initItem: data[0].name,
                        data: data,
                        dropdownPosition: 'bottom',
                        clearable: false,
                        searchable: false
                    };
                }
            })
        }
    }

}
