import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    ThanksForSubmittingYourProjectModalComponent
} from 'app/shared/components/projects/thanks-for-submitting-your-project-modal/thanks-for-submitting-your-project-modal.component';

@Injectable({ providedIn: 'root' })
export class ThanksForSubmittingYourProjectModalService implements OnDestroy {

    constructor(private modalService: NgbModal) {
    }

    ngOnDestroy(): void {
    }

    showModal(): Promise<boolean> {
        return this.modalService.open(ThanksForSubmittingYourProjectModalComponent as Component, {
            windowClass: 'bp-modal thanks-for-submitting-your-project',
            size: 'lg',
            backdrop: 'static'
        }).result.then((res: boolean) => {
            return res;
        });
    }
}
