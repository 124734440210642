import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { areaRoute } from 'app/entities/area/area.route';
import { AreaComponent } from 'app/entities/area/area.component';
import { AreaDetailComponent } from 'app/entities/area/area-detail.component';
import { AreaUpdateComponent } from 'app/entities/area/area-update.component';
import { AreaDeleteDialogComponent } from 'app/entities/area/area-delete-dialog.component';

const ENTITY_STATES = [...areaRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [AreaComponent, AreaDetailComponent, AreaUpdateComponent, AreaDeleteDialogComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpAreaModule {
}
