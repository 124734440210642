<div class="successfully-submitted-for-analysis">
    <div class="modal-header flex flex-column gap-15 align-items-center position-relative">
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>

        <div class="successfully-submitted-for-analysis__title">
            Success! Your floorplans<br>are now under analysis!
        </div>
    </div>

    <div class="modal-body flex flex-column align-items-center gap-20">
        <div class="flex flex-column gap-20">
            <div>
                Thanks for submitting your floorplans!
            </div>
            <div>
                Our team will be reviewing your floorplans shortly and will reach out to you to confirm your service/
                populate your Areas and dimensions.
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button (click)="onOkClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect w-full successfully-submitted-for-analysis__ok"
                type="button">
            Close
        </button>
    </div>
</div>
