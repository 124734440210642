import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bp-error',
    templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {
    errorMessage: string;
    error403: boolean;
    error404: boolean;

    routeDataSub = Subscription.EMPTY;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.routeDataSub = this.route.data.subscribe(routeData => {
            if (routeData.error403) {
                this.error403 = routeData.error403;
            }
            if (routeData.error404) {
                this.error404 = routeData.error404;
            }
            if (routeData.errorMessage) {
                this.errorMessage = routeData.errorMessage;
            }
        });
    }

    ngOnDestroy(): void {
        this.routeDataSub.unsubscribe();
    }
}
