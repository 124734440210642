import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'bp-valuations-submit-valuation',
    templateUrl: './submit-valuation.component.html',
    styleUrls: ['submit-valuation.scss']
})
export class SubmitValuationComponent implements OnInit, OnDestroy {

    constructor(
    ) {
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }
}
