<div class="select-payment-provider-container">
    <div class="modal-header">
        <h4 class="modal-title">
            Select payment method
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="account"
             class="m-b-20">
            <ng-select [items]="account.availableProviders"
                       [(ngModel)]="selectedPaymentProvider"
                       [disabled]="inProcess()"
                       (ngModelChange)="onChangeSelectedPaymentProvider()"
                       [placeholder]="'Select payment method'"
                       class="bp-select">
                <ng-template ng-label-tmp let-item="item">
                    {{item | paymentProviderLabel }}
                    <span class="label light-green" *ngIf="isSelectedPaymentProviderSetup">Setup</span> <span
                    class="label red" *ngIf="!isSelectedPaymentProviderSetup">Not setup</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{item | paymentProviderLabel }} <span class="label light-green"
                                                           *ngIf="isPaymentProviderSetup(item)">Set up</span>
                    <span
                        class="label red" *ngIf="!isPaymentProviderSetup(item)">Not setup</span>
                </ng-template>
            </ng-select>

            <div *ngIf="selectedPaymentProvider"
                 class="m-t-20">

                <div *ngIf="!isSelectedPaymentProviderSetup">
                    <span class="red-text">This payment provider is not setup.</span>
                    <button (click)="onSetupButtonClick()"
                            class="btn btn-default btn-small btn-border-radius waves-effect setup m-l-20"
                            type="button">
                        Setup
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div>
            <a [href]="PAYMENT_TERMS_URL"
               target="_blank"
               rel="noopener"
               class="link">
                Payment Terms
            </a>
        </div>
        <div>
            <button (click)="clear()"
                    tabindex="0"
                    class="btn btn-secondary btn-border-radius m-r-20"
                    data-dismiss="modal"
                    type="button">
                Close
            </button>
            <button (click)="onOkClick()"
                    [disabled]="!selectedPaymentProvider || !isSelectedPaymentProviderSetup || isWaitingForStripePaymentInProcess"
                    tabindex="0"
                    class="btn btn-primary btn-border-radius waves-effect ok"
                    type="button">
                {{ okButtonLabel() }}
            </button>
        </div>
    </div>
</div>

