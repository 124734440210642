<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-material-heading">
                    Create or edit a Material
                </h1>
                <div>
                    <div [hidden]="!material.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="material.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_material">
                            Material
                        </label>
                        <input [(ngModel)]="material.material"
                               class="form-control"
                               id="field_material"
                               name="material"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.material?.dirty && editForm.controls.material?.invalid)">
                            <small [hidden]="!editForm.controls.material?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_reference_url">
                            Reference Url
                        </label>
                        <input [(ngModel)]="material.referenceUrl"
                               class="form-control"
                               id="field_reference_url"
                               name="referenceUrl"
                               type="text"/>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_material_order">
                            Material Order
                        </label>
                        <input [(ngModel)]="material.materialOrder"
                               class="form-control"
                               id="field_material_order"
                               name="materialOrder"
                               type="number"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_unit">
                            Unit
                        </label>
                        <select [(ngModel)]="material.unit"
                                class="form-control"
                                id="field_unit"
                                name="unit">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let unitOption of units; trackBy: trackUnitById"
                                    [ngValue]="unitOption.id === material.unit?.id ? material.unit : unitOption">
                                {{unitOption.unit}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_material_category">
                            Material Category
                        </label>

                        <bp-search-by-select
                            (onSelectionChange)="onMaterialCategorySelectionChange($event)"
                            *ngIf="materialCategorySelectInputData"
                            [data]="materialCategorySelectInputData"
                            class="select-material-category-id"
                            id="field_material_category"
                            name="material_category">
                        </bp-search-by-select>
                    </div>
                    <div class="form-group">
                        <div *ngIf="material.specification">
                            <label class="form-control-label"
                                   for="field_specification">
                                Select Specification
                            </label>
                            <div class="row p-l-10"
                                 id="field_specification">
                                <div *ngFor="let specificationOption of specifications; trackBy: trackSpecificationById"
                                     class="flex-fill">
                                    <div class="form-check form-check-radio">
                                        <label>
                                            <input (click)="material.specification = specificationOption"
                                                   [checked]="specificationOption.id === material.specification.id"
                                                   [value]="specificationOption"
                                                   class="with-gap"
                                                   name="specificationGroup"
                                                   type="radio">
                                            <span>{{specificationOption.name}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <label class="form-check-label"
                                   for="default">
                                Default
                                <input [(ngModel)]="material.isDefault"
                                       class="form-check-input"
                                       id="default"
                                       name="default"
                                       type="checkbox">
                                <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label"
                                   for="finish">
                                Finish
                                <input [checked]="material.type === 'FINISH'"
                                       (change)="onMaterialFinishChange($event)"
                                       class="form-check-input"
                                       id="finish"
                                       name="finish"
                                       type="checkbox">
                                <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label"
                                   for="prime">
                                Prime
                                <input [checked]="material.isPrime"
                                       (change)="onMaterialPrimeChange($event)"
                                       class="form-check-input"
                                       id="prime"
                                       name="prime"
                                       type="checkbox">
                                <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="default_price">
                            Default Price
                        </label>

                        <input #cost="ngModel"
                               [(ngModel)]="material.defaultPrice"
                               class="form-control"
                               id="default_price"
                               name="default_price"
                               placeholder="Enter default price"
                               type="number"/>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <label class="form-check-label"
                                   for="archive">
                                Archive
                                <input [(ngModel)]="material.archive"
                                       class="form-check-input"
                                       id="archive"
                                       name="archive"
                                       type="checkbox">
                                <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
