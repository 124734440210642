import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';
import { lastValueFrom } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoginViaTokenFromGoogleService {

    constructor(private http: HttpClient,
                private authServerProvider: AuthServerProvider) {
    }

    public login(code: string): Promise<void> {
        const headers = {
            'content-type': 'text/plain'
        };

        return new Promise(resolve => {
            lastValueFrom(this.http.post(`${environment.server_api_url}api/authenticate/google/token`, code, {
                headers,
                responseType: 'text'
            }))
                .then((res: string) => {
                    lastValueFrom(this.http
                        .post(`${environment.server_api_url}api/authenticate/google`, res, {
                            headers,
                        }))
                        .then((res2: { id_token: string }) => {
                            this.authServerProvider.loginWithToken(res2.id_token);
                            resolve();
                        })
                })
        })

    }
}
