import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class ExpandCssElementsStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(projectId: number, cssElement: number, value: boolean): void {
        this.webStorage.store(this.getLocalStorageKey(projectId, cssElement), value);
    }

    retrieve(projectId: number, cssElement: number): boolean {
        return this.webStorage.retrieve(this.getLocalStorageKey(projectId, cssElement)) || false;
    }

    private getLocalStorageKey(projectId: number, cssElement: number): string {
        return `scheduler_${this.accountService.getAccountId()}_expandedCssElement_project_${projectId}_${cssElement}`;
    }
}
