<div class="thanks-for-submitting-your-project-modal">
    <div class="modal-header flex flex-column gap-15 align-items-center position-relative">
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>

        <div class="thanks-for-submitting-your-project-modal__title">
            Thanks for submitting your<br> project!
        </div>
    </div>

    <div class="modal-body flex flex-column align-items-center gap-20 m-t-20">
        <div class="flex flex-column gap-20">
            <div>
                Hi there!
            </div>
            <div>
                Our estimators are setting up your take-off.
            </div>
            <div>
                In the meantime, you can chat to us at any time via the chatbot (bottom right) or at
                <a class="link underline" [href]="'contact@buildpartner.com'">contact@buildpartner.com</a>
            </div>
        </div>
    </div>

    <div class="modal-footer flex flex-row justify-content-center">
        <button (click)="onOkClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect thanks-for-submitting-your-project-modal__ok"
                type="button">
            Close
        </button>
    </div>
</div>
