import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'app/shared/constants/links.constants';
import { MainBpBackgroundService } from 'app/shared/services/main-bp-background.service';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { ProfessionService } from 'app/shared/dataservices/profession.service';
import { IProfession } from 'app/shared/model/profession.model';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { EMAIL_ALREADY_USED_TYPE, LOGIN_ALREADY_USED_TYPE } from 'app/shared/constants/error.constants';
import { RegisterService } from 'app/shared/dataservices/register.service';
import { LoginService } from 'app/core/login/login.service';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { HEARD_ABOUT_US_PREDEFINED_VALUES, OTHER, REFERRAL } from 'app/shared/constants/heard-about-us.constants';
import { GtagService } from 'app/shared/services/gtag.service';

interface IHeardAboutUs {
    id: number;
    value: string;
}

interface IRegistrationAccount {
    authorities?: string[];
    email?: string;
    firstName?: string;
    lastName?: string;
    login?: string;
    imageUrl?: string;
    company?: string;
    companyNumber?: string;
    phoneNumber?: string;
    profession?: IProfession;
    heardAboutUs?: string;
    activationKey?: string;
    password?: string;
    langKey?: string;
    kwTracking?: string;
}


@Component({
    selector: 'bp-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['sign-up.scss']
})
export class BpSingUpComponent implements OnInit, AfterViewInit, OnDestroy {
    protected role: 'ROLE_SCHEDULER' | 'ROLE_QUOTER' = 'ROLE_SCHEDULER';

    protected OTHER = OTHER;
    protected REFERRAL = REFERRAL;

    protected confirmPassword: string;
    protected registerAccount: IRegistrationAccount;
    protected success: boolean;
    protected agreeLicense: boolean;

    protected TERMS_OF_USE_URL = TERMS_OF_USE_URL;
    protected PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;

    protected professionSelectInputData: SelectInputData;
    protected heardAboutUsSelectInputData: SelectInputData;

    protected selectedHeardAboutUs: IHeardAboutUs = null;

    protected teamInvitationToken: string;
    protected kwTracking?: string;

    constructor(
        private registerService: RegisterService,
        private elementRef: ElementRef,
        private alertService: BpAlertService,
        private router: Router,
        private mainBpBackgroundService: MainBpBackgroundService,
        private invitationService: InvitationService,
        private route: ActivatedRoute,
        private professionService: ProfessionService,
        private regionService: RegionApi,
        private loginService: LoginService,
        private gtagService: GtagService
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.teamInvitationToken = params['teamInvitationToken'];
            this.kwTracking = params['kw'];
        });

        this.mainBpBackgroundService.activate();

        this.registerAccount = {};
        this.agreeLicense = false;

        this.fillProfessionSelectInputData();
        this.fillHeardAboutUsSelectInputData();
    }

    ngOnDestroy(): void {
        this.mainBpBackgroundService.deactivate();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#first-name').focus();
        }, 0);
    }

    protected cancel(): void {
        this.router.navigate(['/login']);
    }

    protected register(): void {
        if (this.registerAccount.password !== this.confirmPassword) {
            this.alertService.error('The password and its confirmation do not match!');
        } else {
            this.registerAccount.login = 'unnamed__' + UUID.UUID();
            this.registerAccount.langKey = 'en';
            this.registerAccount.authorities = [this.role];

            switch (this.selectedHeardAboutUs?.value) {
                case REFERRAL: {
                    this.registerAccount.heardAboutUs = `${this.selectedHeardAboutUs.value} ${this.registerAccount.heardAboutUs ||
                    ''}`.trim();
                    break;
                }
                case OTHER: {
                    this.registerAccount.heardAboutUs = (this.registerAccount.heardAboutUs || OTHER).trim();
                    break;
                }
                default: {
                    this.registerAccount.heardAboutUs =
                        this.registerAccount.heardAboutUs ||
                        (this.selectedHeardAboutUs != null ? this.selectedHeardAboutUs.value : 'Unknown');
                    break;
                }
            }

            if (this?.teamInvitationToken?.length) {
                this.registerAccount.activationKey = this?.teamInvitationToken;
            }

            if (this?.kwTracking?.length) {
                this.registerAccount.kwTracking = this.kwTracking;
            }

            this.regionService.query().subscribe((regionsRes) => {
                this.registerService.save(this.registerAccount).subscribe(
                    (tokenRes: { id_token: string }) => {
                        if (this?.teamInvitationToken?.length) {
                            this.invitationService.acceptTeamMember(this.teamInvitationToken).subscribe((res: HttpResponse<any>) => {
                                this.success = true;
                                onLogin(tokenRes.id_token);
                            });
                        } else {
                            this.gtagService.sendConversionEvent();
                            this.success = true;
                            onLogin(tokenRes.id_token);
                        }
                    },
                    response => this.processError(response)
                );
            })

            const onLogin = (token: string) => {
                this.loginService.loginWithToken(token).then(() => {
                    this.router.navigate(['']);
                });
                this.alertService.success('Registration saved! Confirmation has been sent to your email.', 7 * 1000);
            }
        }
    }

    protected login(): void {
        this.router.navigate(['/login']);
    }

    protected onProfessionSelectionChange(event): void {
        this.registerAccount.profession = event;
    }

    protected onHeardAboutUsSelectionChange(event): void {
        this.selectedHeardAboutUs = event;
        delete this.registerAccount.heardAboutUs;
    }

    private fillProfessionSelectInputData(): void {
        this.professionService.query().subscribe((res: HttpResponse<IProfession[]>) => {
            this.professionSelectInputData = {
                data: res.body,
                indexProperty: 'id',
                titleProperty: 'name',
                dropdownPosition: 'bottom',
                searchable: false
            };
        });
    }

    private fillHeardAboutUsSelectInputData(): void {
        this.heardAboutUsSelectInputData = {
            data: HEARD_ABOUT_US_PREDEFINED_VALUES,
            indexProperty: 'id',
            titleProperty: 'value',
            dropdownPosition: 'bottom',
            searchable: false
        };
    }

    private processError(response: HttpErrorResponse): void {
        this.success = null;
        if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
            this.alertService.error('Login name already registered! Please choose another one.');
        } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
            this.alertService.error('Email is already in use! Please choose another one.');
        } else {
            this.alertService.error(response.error.title);
        }
    }
}
