import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IProject } from 'app/shared/model/project.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class ComparisonExpandStagesStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(project: IProject, stage: IComparisonStage, value: boolean): void {
        this.webStorage.store(this.getLocalStorageKey(project, stage), value);
    }

    retrieve(project: IProject, stage: IComparisonStage): boolean {
        return this.webStorage.retrieve(this.getLocalStorageKey(project, stage)) || false;
    }

    private getLocalStorageKey(project: IProject, stage: IComparisonStage): string {
        return `scheduler_${this.accountService.getAccountId()}_comparison_expandedStage_${project.id}_${stage.id}`;
    }
}
