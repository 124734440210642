import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IComparisonQoter } from 'app/shared/model/comparison.model';

@Component({
    selector: 'bp-select-quoters',
    templateUrl: './select-quoters.component.html',
    styleUrls: ['select-quoters.scss']
})
export class SelectQuotersComponent {
    @Input() quoters: IComparisonQoter[];
    @Output() onChanged = new EventEmitter();

    areaDropDown = false;

    onClickOutside() {
        this.closeAreaDropDown();
    }

    openDropdown() {
        if (!this.areaDropDown) {
            this.areaDropDown = true;
        }
    }

    onSelectionChange(event, quoter) {
        quoter.selected = event;
        this.onChanged.emit(this.quoters);
    }

    closeAreaDropDown() {
        this.areaDropDown = false;
    }
}
