import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TaskApi } from 'app/shared/dataservices/task.api';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { ITaskInfo } from 'app/shared/model/task.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AddGroupOfScheduleTasksRequest } from 'app/shared/constants/events.constants';
import { IProject } from 'app/shared/model/project.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    DefaultUnitValueUpdaterService
} from 'app/flows/scheduler/schedule/services/default-unit-value-updater.service';
import { v4 as uuid } from 'uuid';
import { SelectInputComponent } from 'app/shared/components/common/select-input/select-input.component';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { INewTaskRequestCreationDTO } from 'app/shared/model/new-task-request.model';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';

const MIN_COUNT_OF_LETTERS_TO_SEARCH = 1;
const PAGE_SIZE = 10;

@Component({
    selector: 'bp-add-group-of-schedule-tasks-modal',
    templateUrl: './add-group-of-schedule-tasks-modal.component.html',
    styleUrls: ['add-group-of-schedule-tasks-modal.scss']
})
export class AddGroupOfScheduleTasksModalComponent implements OnInit, OnDestroy, AfterViewInit {
    project: IProject;
    scheduleAreas: IScheduleArea[];
    scheduleArea: IScheduleArea | null;

    tasks: ITaskInfo[] = [];

    scheduleTasks: IScheduleTask[] = [];

    inProcessTasksLoading = false;

    lastPage = false;
    page = 0;

    searchControl = new FormControl();
    subSearch = Subscription.EMPTY;

    set initialScheduleArea(value: IScheduleArea) {
        this.scheduleArea = value;
    }

    set initialSearchValue(value: string) {
        this.searchControl.setValue(value);
        this.onTaskSearchValueChange(value);
    }

    @ViewChild(SelectInputComponent) selectInputElement;
    @ViewChild('taskSearchElement') taskSearchElement: ElementRef;
    @ViewChild('scrollTasksElement') scrollTasksElement: ElementRef;

    constructor(
        public activeModal: NgbActiveModal,
        private scheduleEventsService: ScheduleEventsService,
        private taskService: TaskApi,
        private defaultUnitValueUpdaterService: DefaultUnitValueUpdaterService
    ) {
    }

    ngOnInit(): void {
        this.subSearch = this.searchControl.valueChanges.pipe(debounceTime(700), distinctUntilChanged())
            .subscribe((term: string) => {
                this.onTaskSearchValueChange(term);
            });
    }

    ngOnDestroy(): void {
        this.subSearch.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.taskSearchElement.nativeElement.focus({
            preventScroll: true
        });
    }

    clear(): void {
        this.activeModal.dismiss('close');
    }

    onAddTasksClick(): void {
        this.scheduleTasks = _.map(this.scheduleTasks, (st: IScheduleTask) => {
            st.unitValue = +st.unitValue || 1;

            if (st.clientSupplied) {
                delete st.primeMaterialCost;
                st.primeMaterialCostAvailable = false;
            } else {
                st.primeMaterialCost = +st.primeMaterialCost;
                st.primeMaterialCostAvailable = true;
            }
            return st;
        });

        const request: AddGroupOfScheduleTasksRequest = {
            scheduleTasks: this.scheduleTasks
        };

        this.scheduleEventsService.addGroupOfScheduleTasks(request);

        /**
         reset can be removed but if to leave it -> it will be easy to restore previously functionality, when
         we reset form instead of closing it
         */
        this.reset();
        this.activeModal.dismiss('close');
    }

    onTaskSearchValueChange(event): void {
        if (event.length < MIN_COUNT_OF_LETTERS_TO_SEARCH) {
            return;
        }

        this.resetPaging();

        this.clearAllNotSelectedTasks();
        this.loadTasksPage();
    }

    addScheduleTask(task: ITaskInfo): void {
        task._selectedCount++;

        const newScheduleTask: IScheduleTask = {};

        newScheduleTask.projectId = this.project.id;
        newScheduleTask._uuid = uuid();
        newScheduleTask.taskId = task.id;
        newScheduleTask.task = task.task;
        newScheduleTask.stageId = task.stageId;
        newScheduleTask.stage = task.stage as string;
        newScheduleTask.displayName = task.task;
        newScheduleTask.componentAreaId = task.componentAreaId;
        newScheduleTask.unitId = task.unitId;
        newScheduleTask.unit = task.unit ? task.unit.toString() : null;
        newScheduleTask.unitPlural = task.unitPlural;
        newScheduleTask.materialCategories = task.materialCategories;
        newScheduleTask.elementId = task.elementId;

        // default values
        newScheduleTask.ratio = 1;
        newScheduleTask.clientSupplied = true;
        newScheduleTask.scheduleAreaId = this.scheduleAreas[0].id;

        newScheduleTask.primeMaterialId = null;

        this.defaultUnitValueUpdaterService.setDefaultUnitValue(newScheduleTask);
        this.scheduleTasks.push(newScheduleTask);
    }

    addTaskButtonEnabled(): boolean {
        return this.scheduleTasks.length !== 0;
    }

    onDeleteScheduleTask(scheduleTask: IScheduleTask): void {
        const index = this.scheduleTasks.indexOf(scheduleTask);
        if (index !== -1) {
            this.scheduleTasks.splice(index, 1);

            const task: ITaskInfo = _.find(this.tasks, { id: scheduleTask.taskId });
            if (task != null) {
                task._selectedCount--;
            }
        }
    }

    trackId(index: number, item: ITaskInfo): number {
        return item.id;
    }

    @HostListener('scroll', ['$event'])
    onScrollTasksList(event: any): void {
        this.tryToScrollTaskList(event);
    }

    onTaskSearchKeyDown(event: any): void {
        event.preventDefault();

        if (this.scrollTasksElement.nativeElement.children.length === 0) {
            return;
        }

        this.scrollTasksElement.nativeElement.children[0].focus();
    }

    onScrollTaskKeyUp(event: any): void {
        event.preventDefault();

        const element = event.target.previousElementSibling;
        if (element == null) {
            this.taskSearchElement.nativeElement.focus();
        } else {
            element.focus();
        }
    }

    onScrollTaskKeyDown(event: any): void {
        event.preventDefault();

        const element = event.target.nextElementSibling;
        if (element == null) {
            this.tryToScrollTaskList(event);
        } else {
            element.focus();
        }
    }

    onScrollTaskEnter(event: any, task: ITaskInfo): void {
        event.preventDefault();
        this.addScheduleTask(task);
    }

    private clearAllNotSelectedTasks(): void {
        this.tasks = _.filter(this.tasks, (task: ITaskInfo) => task._selectedCount > 0);
    }

    private loadTasksPage(): void {
        this.inProcessTasksLoading = true;

        this.taskService
            .queryForGroupAdding(null, {searchValue: this.searchControl?.value, page: this.page, pageSize: PAGE_SIZE})
            .pipe(
                finalize(() => {
                    this.inProcessTasksLoading = false;
                })
            )
            .subscribe((res: HttpResponse<ITaskInfo[]>) => {
                this.checkLastPage(res.headers);

                _.each(res.body, (task: ITaskInfo) => {
                    if (!_.find(this.tasks, { id: task.id })) {
                        task._selectedCount = 0;
                        this.tasks.push(task);
                    }
                });
            });
    }

    private tryToScrollTaskList(event): void {
        if (this.lastPage) {
            return;
        }

        /* visible height + pixel scrolled >= total height */
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.page += 1;
            this.loadTasksPage();
        }
    }

    private checkLastPage(headers: HttpHeaders): void {
        const totalItems = parseInt(headers.get('X-Total-Count'), 10);

        if ((this.page + 1) * PAGE_SIZE >= totalItems) {
            this.lastPage = true;
        }
    }

    private reset(): void {
        this.searchControl.setValue(null);
        this.tasks = [];
        this.scheduleTasks = [];

        this.resetPaging();
    }

    private resetPaging(): void {
        this.page = 0;
        this.lastPage = false;
    }

    addNotFoundedTask(): void {
        const searchTerm = this.searchControl.value;

        const newTaskRequest: INewTaskRequestCreationDTO = {
            name: searchTerm,
            projectId: this.project.id,
            unitValue: 1,
            scheduleareaId: this.scheduleArea?.id || this.scheduleAreas[0].id
        }

        this.activeModal.close({newTaskRequest});
    }
}
