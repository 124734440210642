<div class="modal-header">
    <div class="flex flex-column gap-20 justify-content-center align-items-center">
        <h2>
            We're sad to see you go!
        </h2>
    </div>
    <button (click)="onCancel()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form"
          class="flex flex-column gap-20">
        <div class="strong">
            To help us improve our platform, we would be grateful if you could tell us why:
        </div>

        <bp-select-input
            (onSelectionChange)="form.controls['reason'].setValue($event)"
            *ngIf="reasonSelectInputData"
            [data]="reasonSelectInputData"
            [placeholder]="'Please select reason *'"
            [removeUnderlines]="false"
            additionalClasses="fs-14 heght-auto"
            class="select-reason">
        </bp-select-input>

        <textarea
            class="af-input comment"
            [formControlName]="'comment'"
            name="comment"
            autocomplete="off"
            placeholder="Feel free to add some more detail">
    </textarea>
    </form>
</div>

<div class="modal-footer">
    <button (click)="confirm()"
            [disabled]="form.invalid"
            tabindex="1"
            class="btn btn-primary btn-border-radius waves-effect cancel"
            type="button">
        Confirm cancellation
    </button>
</div>

