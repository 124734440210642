import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { IAccount } from 'app/shared/model/account.model';
import { IStage } from 'app/shared/model/stage.model';
import { StageService } from 'app/shared/dataservices/stage.service';
import { AccountService } from 'app/core/auth/account.service';
import { StageDeleteDialogComponent } from 'app/entities/stage/stage-delete-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bp-stage',
    templateUrl: './stage.component.html'
})
export class StageComponent implements OnInit {
    stages: IStage[];
    currentAccount: IAccount;

    constructor(
        private stageService: StageService,
        private accountService: AccountService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });
    }

    protected trackId(index: number, item: IStage): number {
        return item.id;
    }

    protected onDeleteClick(stage: IStage): void {
        const ngbModalRef = this.modalService.open(StageDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.stage = stage;

        ngbModalRef.result.then(
            result => {
                this.loadAll();
            },
            reason => {
                //
            }
        );
    }

    private loadAll(): void {
        this.stageService.query().subscribe(
            (res: HttpResponse<IStage[]>) => {
                this.stages = res.body;
            }
        );
    }
}
