import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    IProjectAttachment,
    IProjectAttachmentAdditionProperties,
    ProjectAttachmentService,
    ProjectAttachmentType
} from 'app/shared/model/project-attachment.model';
import { map } from 'rxjs/operators';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class ProjectAttachmentsService {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {
    }

    /**
     * returns list of project attachment, in format: {Long id; String fileName; String originalName;}
     * @param projectId
     * @param type
     */
    query(projectId: number, type: ProjectAttachmentType = 'GENERAL'): Observable<HttpResponse<IProjectAttachment[]>> {
        return this.http.get<IProjectAttachment[]>(`${this.resourceUrl}/${projectId}/attachments?type=${type}`, { observe: 'response' })
            .pipe(map((x) => {
                x.body.forEach((a) => {
                    if (a.additionalProperties) {
                        try {
                            a.additionalProperties = JSON.parse('' + a.additionalProperties);
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                })
                return x;
            }))
    }

    /**
     * upload file to server (same as logo in user setting), return file name
     * @param projectId
     * @param document
     * @param type
     * @param sendNotification
     * @param uploadToKreo
     * @param service - not required
     @param additionalProperties - not required
     */
    upload(projectId: number,
           document: File,
           type: ProjectAttachmentType = 'GENERAL',
           sendNotification = false,
           uploadToKreo = false,
           service?: ProjectAttachmentService,
           additionalProperties: IProjectAttachmentAdditionProperties = {}): Observable<string> {
        let url = `${this.resourceUrl}/${projectId}/attachments/upload?type=${type}&sendNotification=${sendNotification}&uploadToKreo=${uploadToKreo}`;
        if (service) {
            url += `&service=${service}`
        }

        const formData = new FormData();
        if (document != null) {
            formData.append('file', document);
        }

        formData.append('additionalProperties', JSON.stringify(additionalProperties));

        return this.http.post(url, formData, {
            observe: 'body',
            responseType: 'text'
        });
    }

    /**
     * delete file from project
     * @param projectId
     * @param attachmentId
     */
    delete(projectId: number, attachmentId: number): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.resourceUrl}/${projectId}/attachments/${attachmentId}`, { observe: 'response' });
    }

    /**
     * will start downloading file, same as excel
     * @param projectId
     * @param attachmentId
     */
    download(projectId: number, attachmentId: number): Observable<string> {
        const options = {};
        options['observe'] = 'response';
        options['responseType'] = 'blob';

        return this.http.get<any>(`${this.resourceUrl}/${projectId}/attachments/${attachmentId}`, options).pipe(
            map((res: HttpResponse<string>) => {
                return res.body;
            })
        );
    }
}
