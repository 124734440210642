<div class="root">
    <div class="row">
        <div class="col-sm-6">
            <div (click)="toggleExpand()"
                 class="bp-cursor-pointer stage-header">
                <span>{{ stage.stage }}</span>
                <i class="material-icons">{{ stage.expanded ? "expand_more" : "chevron_right" }}</i>
            </div>
        </div>
        <div class="col-sm-6 no-margin no-padding-left text-right">
            <div [title]="service.getStageTotal(stage) | currency:'GBP'"
                 class="total">
                {{ service.getStageTotal(stage) | currency:'GBP' }}
            </div>
        </div>
    </div>

    <div *ngIf="stage.expanded">
        <div class="element-container">
            <bp-valuation-element
                *ngFor="let element of stage.elementDTOs;"
                [element]="element">
            </bp-valuation-element>
        </div>
    </div>
</div>
