import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IUnsubscribeRequest, IUnsubscriptionReason } from 'app/shared/model/bp.model';

@Injectable({ providedIn: 'root' })
export class UnsubscriptionService {
    public resourceUrl = SERVER_API_URL + 'api/unsubscriptions';

    constructor(private http: HttpClient) {
    }

    queryReasons(): Observable<HttpResponse<IUnsubscriptionReason[]>> {
        return this.http.get<IUnsubscriptionReason[]>(`${this.resourceUrl}/reasons`, { observe: 'response' });
    }

    unsubscribe(unsubscribeRequest: IUnsubscribeRequest): Observable<any> {
        return this.http.post(this.resourceUrl, unsubscribeRequest, { responseType: 'text' });
    }
}
