import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IProject, IProjectCache } from 'app/shared/model/project.model';
import { map } from 'rxjs/operators';
import moment from 'moment/moment';

@Injectable({ providedIn: 'root' })
export class ProjectCacheService {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {
    }

    update(project: IProject): Observable<HttpResponse<IProject>> {
        return this.http.post<IProject>(`${this.resourceUrl}/${project.id}/project-cache`, project, { observe: 'response' });
    }

    get(projectId: number): Observable<HttpResponse<IProjectCache>> {
        return this.http.get<IProjectCache>(`${this.resourceUrl}/${projectId}/project-cache`, { observe: 'response' })
            .pipe(map((res: HttpResponse<IProjectCache>) => this.convertDateFromServer(res)));
    }

    remove(projectId: number): Observable<HttpResponse<IProjectCache>> {
        return this.http.delete<IProjectCache>(`${this.resourceUrl}/${projectId}/project-cache`, { observe: 'response' });
    }

    private convertDateFromServer(res: HttpResponse<IProjectCache>): HttpResponse<IProjectCache> {
        if (res.body) {
            res.body.createdDate = res.body.createdDate != null ? moment(res.body.createdDate) : null;
            res.body.startDate = res.body.startDate != null ? moment(res.body.startDate) : null;
            res.body.endDate = res.body.endDate != null ? moment(res.body.endDate) : null;
            res.body.tenderDecision = res.body.tenderDecision != null ? moment(res.body.tenderDecision) : null;
            res.body.tenderDeadline = res.body.tenderDeadline != null ? moment(res.body.tenderDeadline) : null;
        }
        return res;
    }
}
