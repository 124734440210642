import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { professionRoute } from 'app/entities/profession/profession.route';
import { ProfessionListComponent } from 'app/entities/profession/list/profession-list.component';
import { ProfessionUpdateComponent } from 'app/entities/profession/edit/profession-update.component';
import { ProfessionDeleteDialogComponent, } from 'app/entities/profession/delete/profession-delete-dialog.component';

const ENTITY_STATES = [...professionRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [
        ProfessionListComponent,
        ProfessionUpdateComponent,
        ProfessionDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpProfessionModule {
}
