import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IScheduleAreaScope } from '../model/tag.model';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class UserTagApi {
    public resourceUrl = SERVER_API_URL + 'api/users/tags';

    constructor(private http: HttpClient) {
    }

    create(tag: IScheduleAreaScope): Observable<HttpResponse<IScheduleAreaScope>> {
        return this.http.post<IScheduleAreaScope>(this.resourceUrl, tag, { observe: 'response' });
    }

    update(tag: IScheduleAreaScope): Observable<HttpResponse<IScheduleAreaScope>> {
        return this.http.put<IScheduleAreaScope>(this.resourceUrl, tag, { observe: 'response' });
    }

    find(id: number): Observable<HttpResponse<IScheduleAreaScope>> {
        return this.http.get<IScheduleAreaScope>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(): Observable<HttpResponse<IScheduleAreaScope[]>> {
        return this.http.get<IScheduleAreaScope[]>(this.resourceUrl, { observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
