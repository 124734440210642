<form (ngSubmit)="confirmDelete(buildUpCategory.id)"
      name="deleteForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Confirm delete operation
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <p id="bp-delete-build-up-category-heading">
            Are you sure you want to delete this build up category?
        </p>
    </div>
    <div class="modal-footer">
        <button (click)="clear()"
                class="btn btn-default btn-border-radius waves-effect mr-1"
                data-dismiss="modal"
                type="button">
            Cancel
        </button>
        <button class="btn btn-danger btn-border-radius waves-effect"
                type="submit">
            Delete
        </button>
    </div>
</form>
