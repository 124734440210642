import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    HowToCreateProjectModalComponent
} from 'app/shared/components/projects/how-to-create-project-modal/how-to-create-project-modal.component';

@Injectable({ providedIn: 'root' })
export class HowToCreateProjectModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal) {
    }

    open(): NgbModalRef {
        this.ngbModalRef = this.modalService.open(HowToCreateProjectModalComponent as Component, {
            windowClass: 'bp-modal fit-content how-to-create-project-modal blue-modal',
            backdrop: 'static',
            centered: true
        });

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
