import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    TaskRequestModalComponent
} from 'app/flows/scheduler/schedule/components/task-request-modal/task-request-modal.component';
import { INewTaskRequest, INewTaskRequestCreationDTO } from 'app/shared/model/new-task-request.model';

@Injectable({ providedIn: 'root' })
export class TaskRequestModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(inputData: INewTaskRequest | INewTaskRequestCreationDTO): NgbModalRef {
        this.ngbModalRef = this.modalService.open(TaskRequestModalComponent as Component, {
            windowClass: 'bp-modal fill-width-80 task-request-modal',
            backdrop: 'static'
        });

        let data: INewTaskRequestCreationDTO;
        if (inputData.id == null) {
            data = inputData;
        } else {
            const x = inputData as INewTaskRequest;
            data = Object.assign({
                id: x.id,
                projectId: x.project.id,
                stageId: x.stage.id,
                elementId: x.element.id,
                name: x.name,
                specification: x.specification,
                unitValue: x.unitValue,
                scheduleareaId: x.schedulearea?.id,
                laborRate: x.laborRate,
                materialRate: x.materialRate,
                total: x.total,
            })
        }

        this.ngbModalRef.componentInstance.data = data;
        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
