import { Injectable } from '@angular/core';
import { IScheduleTaskUpd, ScheduleTaskUpd } from 'app/shared/model/schedule-task-upd.model';
import { IQuoterTaskCost, IScheduleTask } from 'app/shared/model/schedule-task.model';
import { lastValueFrom, Observable, Observer, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ScheduleUpdateService } from 'app/shared/dataservices/schedule-update.service';
import {
    FillTaskTotalForScheduleTaskService
} from 'app/flows/scheduler/schedule/services/fill-task-total-for-schedule-task.service';
import { IScheduleUpdateData } from 'app/shared/model/schedule-update-data.model';
import * as _ from 'lodash';
import { IScheduleTaskQuoterTotalModificationEvent } from 'app/shared/constants/events.constants';
import { map } from 'rxjs/operators';
import { NewTaskRequestApi } from 'app/shared/dataservices/new-task-request.api';
import { IUpdateTaskRequestDTO } from 'app/shared/model/new-task-request.model';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';

@Injectable({
    providedIn: 'root'
})
export class TaskUpdateHelperService {
    constructor(
        private fillTaskTotalForScheduleTaskService: FillTaskTotalForScheduleTaskService,
        private scheduleCacheService: ScheduleUpdateService,
        private scheduleEventsService: ScheduleEventsService,
        private newTaskRequestApi: NewTaskRequestApi
    ) {
    }

    public updateScheduleTasks(projectId: number,
                               scheduleTasks: IScheduleTask[],
                               allScheduleTasks: IScheduleTask[],
                               defaultQuoterId: number): Promise<boolean> {
        return new Promise((resolve) => {
            const tasksMarkedAsDeleted = _.filter(scheduleTasks, t => t.markedAsDeleted && t.id != null);
            const taskRequestsMarkedAsDeleted = _.filter(scheduleTasks, t => t.markedAsDeleted && t.newTaskRequestId != null && t.id == null);
            const tasksForSaving = _.filter(scheduleTasks, t => t.newTaskRequestId == null && !t.markedAsDeleted);
            const requestedTasksForSaving = _.filter(scheduleTasks, t => t.newTaskRequestId != null && !t.markedAsDeleted);

            const promises = [];

            if (tasksMarkedAsDeleted.length || tasksForSaving.length) {
                const tasks: ScheduleTaskUpd[] = _.map(tasksForSaving, st => this.getTaskForSaving(st));

                const scheduleCache: IScheduleUpdateData = {
                    projectId,
                    tasks,
                    deleteIds: _.map(tasksMarkedAsDeleted, 'id')
                };

                promises.push(lastValueFrom(this.scheduleCacheService.batchUpdate(scheduleCache).pipe(map((value) => {
                    const resBody = (value as HttpResponse<IScheduleTask[]>).body;
                    resBody.forEach((updST) => {
                        let scheduleTask = allScheduleTasks.find((st) => st.id === updST.id);
                        if (scheduleTask) {
                            scheduleTask = _.merge(scheduleTask, updST);
                            this.scheduleEventsService.scheduleTaskUpdatedOnBE(scheduleTask);

                            if (defaultQuoterId != null) {
                                this.fillTotals(scheduleTask, defaultQuoterId);
                            }
                        } else {
                            const createdScheduleTask = scheduleTasks.find((st) => st.id == null);
                            if (createdScheduleTask && updST.taskId === createdScheduleTask.taskId) {
                                createdScheduleTask.id = updST.id;
                                createdScheduleTask.primeMaterialName = updST.primeMaterialName;
                                this.scheduleEventsService.scheduleTaskUpdatedOnBE(createdScheduleTask);
                            }
                        }
                    })
                }))));
            }

            taskRequestsMarkedAsDeleted?.forEach((taskRequestMarkedAsDeleted) => {
                const promise = lastValueFrom(this.newTaskRequestApi.delete(taskRequestMarkedAsDeleted.newTaskRequestId).pipe(map((value) => {
                    this.scheduleEventsService.scheduleTaskTotalModified({
                        stageId: taskRequestMarkedAsDeleted.stageId
                    });
                })));

                promises.push(promise);
            })


            requestedTasksForSaving?.forEach((requestedTaskForSaving) => {
                const data: IUpdateTaskRequestDTO = {
                    name: requestedTaskForSaving.task,
                    unitValue: requestedTaskForSaving.unitValue,
                    scheduleareaId: requestedTaskForSaving.scheduleAreaId
                }

                const promise = lastValueFrom(this.newTaskRequestApi.update(requestedTaskForSaving.newTaskRequestId, data).pipe(
                    map((res) => {
                        requestedTaskForSaving.taskTotal = res.body.taskTotal;
                    })));

                promises.push(promise);
            })

            if (!promises.length) {
                resolve(true);
            } else {
                Promise.all(promises).then(() => {
                    resolve(true);
                });
            }
        });
    }

    fillTotals(scheduleTask: IScheduleTask, defaultQuoterId: number): Promise<IScheduleTask> {
        return this.fillTaskTotalForScheduleTaskService
            .fill(scheduleTask, defaultQuoterId).then((quoterTaskCost: IQuoterTaskCost) => {
                const fillDefaultPrice: IScheduleTaskQuoterTotalModificationEvent = {
                    stageId: scheduleTask.stageId
                };
                this.scheduleEventsService.scheduleTaskTotalModified(fillDefaultPrice);
                return scheduleTask;
            });
    };

    getTaskForSaving(task: IScheduleTask) {
        const t: IScheduleTaskUpd = {};
        t.id = task.id;
        t.taskId = task.taskId;
        t.task = task.task;
        t.displayName = task.task;
        t.ratio = +task.ratio;
        t.unitId = task.unitId;
        t.elementId = task.elementId;
        t.stageId = task.stageId;
        t.unitValue = task.unitValue;
        t.note = task.note;
        t.componentAreaId = task.componentAreaId;
        t.scheduleAreaId = task.scheduleAreaId || task.areaId; // for some case
        t.actualMaterialComponents = task.actualMaterialComponents;
        t.materialCategoryIds = task.materialCategories?.map((mc => mc.id));
        t.primeMaterialId = task.primeMaterialId;
        t.primeMaterialIds = task.primeMaterialId ? [task.primeMaterialId] : [];
        t.primeMaterialCost = task.primeMaterialCost;
        t.primeMaterialCostAvailable = task.primeMaterialCostAvailable;
        t.clientSupplied = !task.primeMaterialCostAvailable;
        t.materialUrlRef = task.materialUrlRef;

        if (task.clientSupplied != null) {
            t.clientSupplied = task.clientSupplied;
        }

        return t;
    }

    globalSaveSchedule(projectId: number, isMajor: boolean): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            this.scheduleCacheService.flush(projectId, isMajor).subscribe(
                () => {
                    observer.next(true);
                    observer.complete();
                },
                (res: HttpErrorResponse) => {
                    throwError(res);
                }
            );
        });
    }
}
