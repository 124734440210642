import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetFinishService } from './password-reset-finish.service';
import { MainBpBackgroundService } from 'app/shared/services/main-bp-background.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bp-password-reset-finish',
    templateUrl: './password-reset-finish.component.html',
    styleUrls: ['password-reset-finish.scss']
})
export class PasswordResetFinishComponent implements OnInit, AfterViewInit, OnDestroy {
    confirmPassword: string;
    doNotMatch: string;
    error: string;
    keyMissing: boolean;
    resetAccount: any;
    success: string;
    modalRef: NgbModalRef;
    key: string;

    routeQuerySub = Subscription.EMPTY;

    constructor(
        private passwordResetFinishService: PasswordResetFinishService,
        private route: ActivatedRoute,
        private router: Router,
        private mainBpBackgroundService: MainBpBackgroundService,
        private elementRef: ElementRef
    ) {}

    ngOnInit(): void {
        this.mainBpBackgroundService.activate();
        this.routeQuerySub = this.route.queryParams.subscribe(params => {
            this.key = params['key'];
        });
        this.resetAccount = {};
        this.keyMissing = !this.key;
    }

    ngOnDestroy(): void {
        this.routeQuerySub.unsubscribe();
        this.mainBpBackgroundService.deactivate();
    }

    ngAfterViewInit(): void {
        if (this.elementRef.nativeElement.querySelector('#password') != null) {
            setTimeout(() => {
                this.elementRef.nativeElement.querySelector('#password').focus();
            }, 0);
        }
    }

    finishReset(): void {
        this.doNotMatch = null;
        this.error = null;
        if (this.resetAccount.password !== this.confirmPassword) {
            this.doNotMatch = 'ERROR';
        } else {
            this.passwordResetFinishService.save({ key: this.key, newPassword: this.resetAccount.password }).subscribe(
                () => {
                    this.success = 'OK';
                },
                () => {
                    this.success = null;
                    this.error = 'ERROR';
                }
            );
        }
    }
}
