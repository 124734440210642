import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SPECIFIC_MARGIN_ACTIONS, SpecificMarginActionInfo } from 'app/shared/constants/specific-margin-actions';

@Component({
    selector: 'bp-specific-margin-action-selector',
    templateUrl: './specific-margin-action-selector.component.html',
    styleUrls: ['specific-margin-action-selector.scss']
})
export class SpecificMarginActionSelectorComponent implements OnInit {
    @Input() showTasksItem: boolean = false;
    @Output() onChanged = new EventEmitter<SpecificMarginActionInfo>();

    actions!: SpecificMarginActionInfo[];

    ngOnInit(): void {
        this.actions = SPECIFIC_MARGIN_ACTIONS.filter(action => {
            if (this.showTasksItem) {
                return true;
            }

            return action.id !== 'tasks';
        })
    }

    select(specificMarginActionInfo: SpecificMarginActionInfo): void {
        this.onChanged?.emit(specificMarginActionInfo);
    }
}
