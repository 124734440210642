<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-element-heading">
                    Create or edit a Element
                </h1>
                <div>
                    <div [hidden]="!element.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="element.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_stage">
                            Stage
                        </label>
                        <select [(ngModel)]="element.stage"
                                class="form-control"
                                id="field_stage"
                                name="stage">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let stageOption of stages; trackBy: trackStageById"
                                    [ngValue]="stageOption.id === element.stage?.id ? element.stage : stageOption">{{stageOption.stage}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_element">
                            Element
                        </label>
                        <input [(ngModel)]="element.element"
                               class="form-control"
                               id="field_element"
                               name="element"
                               required type="text"/>
                        <div [hidden]="!(editForm.controls.element?.dirty && editForm.controls.element?.invalid)">
                            <small [hidden]="!editForm.controls.element?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_elementOrder">
                            Element Order
                        </label>
                        <input [(ngModel)]="element.elementOrder"
                               class="form-control"
                               id="field_elementOrder"
                               name="elementOrder"
                               type="number"/>
                    </div>

                    <div class="form-group">
                        <div class="form-check">
                            <label class="form-check-label"
                                   for="archive">
                                Archive
                                <input [(ngModel)]="element.archive"
                                       class="form-check-input"
                                       id="archive"
                                       name="archive"
                                       type="checkbox">
                                <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
