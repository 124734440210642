import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IComparisonQoter } from 'app/shared/model/comparison.model';
import { IMainViewFilterState } from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { ExportType } from 'app/shared/constants/export-types';
import { PopperContent } from 'ngx-popper';
import { ComparisonService } from 'app/flows/scheduler/comparison/comparison.service';
import { ComparisonStore } from 'app/flows/scheduler/comparison/comparison.store';
import { Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

/**
 * Main Comparison Component
 */
@Component({
    selector: 'bp-comparison',
    templateUrl: './comparison.component.html',
    providers: [
        ComparisonStore,
        ComparisonService
    ],
    styleUrls: ['comparison.scss']
})
export class ComparisonComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    MENU_TOOLTIP_OPEN_DELAY = 100;

    routeData: Subscription;

    @ViewChild(PopperContent) bpExportSelector: PopperContent;

    constructor(
        private activatedRoute: ActivatedRoute,
        public comparisonService: ComparisonService,
        public comparisonStore: ComparisonStore
    ) {
        this.routeData = this.activatedRoute.data.subscribe(data => {
            this.comparisonStore.quoterIdsAsInitialFilter = data.quoterIds;
        });
    }

    ngOnInit(): void {
        this.comparisonStore.clear();
        this.comparisonService.reloadAll();
    }

    ngOnDestroy(): void {
        this.routeData?.unsubscribe();
    }

    onMainViewFilterChangedFunc(newFilterState: IMainViewFilterState): void {
        this.comparisonService.setMainViewFilter(newFilterState);
    }

    onSelectedQuotersChanged(quoters: IComparisonQoter[]): void {
        this.blockUI.start('Loading data..');
        this.comparisonService.setSelectedQuoters(quoters).finally(() => {
            this.blockUI.stop();
        });
    }

    onExportTypeSelected(exportType: ExportType): void {
        this.bpExportSelector.hide();
        this.comparisonService.export(exportType);
    }
}
