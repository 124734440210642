<div class="projects-container"
     *ngIf="projects"
     (scroll)="handleScroll($event)">
    <bp-add-project-card
        (onAddClick)="onAddNewProjectClick()"
        *ngIf="showAddNewProjectCard">
    </bp-add-project-card>

    <div *ngFor="let project of projects; trackBy: trackId;"
         class="project-container">
        <bp-project-card
            [project]="project"
            [isAdmin]="isAdmin"
            (onUpdate)="reset()">
        </bp-project-card>

        <bp-project-card-xs
            [project]="project"
            [isAdmin]="isAdmin"
            (onUpdate)="reset()">
        </bp-project-card-xs>
    </div>
</div>

<div class="add-new-project-mobile"
     *ngIf="showAddNewProjectCard">
    <button class="btn add-new-project"
            (click)="onAddNewProjectClick()"
            type="button">
        Create New Project
    </button>
</div>
