import { Route } from '@angular/router';
import { SystemUpgradesComponent } from 'app/account/system-upgrades/system-upgrades.component';

export const systemUpgradesRoute: Route = {
    path: 'system-upgrades',
    component: SystemUpgradesComponent,
    data: {
        authorities: [],
        pageTitle: 'Build Partner - System upgrades'
    }
};
