import { IMaterialComponent } from './material-component.model';
import { IBuilderMaterial } from './builder-material.model';
import { IActualMaterialComponent } from './actual-material-component.model';
import { IUnit } from './unit.model';
import { IMaterialCategory } from './material-category.model';
import { ISpecification } from 'app/shared/model/specification.model';

export interface IMaterial {
    id?: number;
    material?: string;
    archive?: boolean;
    isDefault?: boolean;
    isPrime?: boolean;
    materialComponents?: IMaterialComponent[];
    builderMaterials?: IBuilderMaterial[];
    actualMaterialComponents?: IActualMaterialComponent[];
    unit?: IUnit;
    specification?: ISpecification;
    materialCategory?: IMaterialCategory;
    defaultPrice?: number;
    materialOrder?: number;
    referenceUrl?: string;
    lastPriceModifiedBy?: string;
    lastPriceModifiedDate?: Date;
    createdDate?: Date;
    reviewed?: boolean;
    type?: MaterialType;
}
export type MaterialType = 'COMMON' | 'FINISH';
export class Material implements IMaterial {
    constructor(
        public id?: number,
        public material?: string,
        public archive?: boolean,
        public isDefault?: boolean,
        public materialComponents?: IMaterialComponent[],
        public builderMaterials?: IBuilderMaterial[],
        public actualMaterialComponents?: IActualMaterialComponent[],
        public specification?: ISpecification,
        public unit?: IUnit,
        public materialCategory?: IMaterialCategory,
        public defaultPrice?: number,
        public materialOrder?: number,
        public referenceUrl?: string,
        public type?: MaterialType
    ) {
        this.archive = this.archive || false;
        this.isDefault = this.isDefault || false;
    }
}
