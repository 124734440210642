import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUnit } from 'app/shared/model/unit.model';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { IRegion } from 'app/shared/model/region.model';

@Component({
    selector: 'bp-unit-delete-dialog',
    templateUrl: './region-delete-dialog.component.html'
})
export class RegionDeleteDialogComponent {
    unit: IUnit;
    region: IRegion;

    constructor(private regionService: RegionApi,
                public activeModal: NgbActiveModal) {}

    clear(): void {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number): void {
        this.regionService.delete(id).subscribe(response => {
            this.activeModal.close(true);
        });
    }
}
