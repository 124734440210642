import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISchedule } from '../model/schedule.model';
import { IScheduleTemplate } from 'app/shared/model/schedule-template.model';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';

type EntityResponseType = HttpResponse<ISchedule>;
type EntityArrayResponseType = HttpResponse<ISchedule[]>;

const DEFAULT_COUNT_VALUE = 0;

@Injectable({ providedIn: 'root' })
export class ScheduleService {
    public resourceUrl = SERVER_API_URL + 'api/schedules';

    constructor(private http: HttpClient) {}

    create(schedule: ISchedule): Observable<EntityResponseType> {
        return this.http.post<ISchedule>(this.resourceUrl, schedule, { observe: 'response' });
    }

    update(schedule: ISchedule): Observable<EntityResponseType> {
        return this.http.put<ISchedule>(this.resourceUrl, schedule, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<ISchedule>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<ISchedule[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    queryScheduleTemplates(req?: any): Observable<HttpResponse<IScheduleTemplate[]>> {
        const options = createRequestOption(req);
        return this.http
            .get<IScheduleTemplate[]>(`${this.resourceUrl}/templates`, {
                params: options,
                observe: 'response'
            })
            .pipe(
                map((res: HttpResponse<IScheduleTemplate[]>) => {
                    _.each(res.body, (template: IScheduleTemplate) => {
                        template.count = template.count || DEFAULT_COUNT_VALUE;
                    });

                    return res;
                })
            );
    }
}
