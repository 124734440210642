import { Route } from '@angular/router';
import { CreateQuoterFromInvitationComponent } from './create-quoter-from-invitation.component';

export const createQuoterFromInvitationRoute: Route = {
    path: 'create-quoter-from-invitation',
    component: CreateQuoterFromInvitationComponent,
    data: {
        authorities: [],
        pageTitle: 'Create quoter from invitation'
    }
};
