import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStage } from 'app/shared/model/stage.model';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { IComponentArea } from 'app/shared/model/component-area.model';
import { IUnit } from 'app/shared/model/unit.model';
import { UnitService } from 'app/shared/dataservices/unit.service';

@Component({
    selector: 'bp-component-area-update',
    templateUrl: './component-area-update.component.html'
})
export class ComponentAreaUpdateComponent implements OnInit {
    componentArea: IComponentArea;
    isSaving: boolean;

    units: IUnit[];

    constructor(
        private componentAreaService: ComponentAreaService,
        private unitService: UnitService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ componentArea }) => {
            this.componentArea = componentArea;
        });
        this.unitService.query().subscribe(
            (res: HttpResponse<IUnit[]>) => {
                this.units = res.body;
            }
        );
    }

    previousState(): void {
        window.history.back();
    }

    save(): void {
        this.isSaving = true;
        if (this.componentArea.id !== undefined) {
            this.subscribeToSaveResponse(this.componentAreaService.update(this.componentArea));
        } else {
            this.subscribeToSaveResponse(this.componentAreaService.create(this.componentArea));
        }
    }

    trackStageById(index: number, item: IStage): number {
        return item.id;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IComponentArea>>) {
        result.subscribe(
            (res: HttpResponse<IComponentArea>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    trackUnitById(index: number, item: IUnit) {
        return item.id;
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
