<script src="cost-plan.service.ts"></script>
<div *ngIf="costPlanService.nativeComparison === undefined && !costPlanService.inProcessLoadingStatData"
     class="text-center">
    No data to show
</div>

<div *ngIf="costPlanService.nativeComparison === null"
     class="text-center">
    Please wait..
</div>

<div *ngIf="costPlanService.inited && costPlanService.nativeComparison && costPlanService.valueColumns">
    <div class="overview-container flex flex-wrap gap-20 justify-content-between">
        <div class="flex flex-column text-center pie-chart-container d-md-none"
             *ngIf="costPlanService.costPlanMode === 'pie-chart-view' && costPlanService.getChartData()">
            <bp-cost-plan-chart #costPlanChartComponent
                                [data]="costPlanService.getChartData()">
            </bp-cost-plan-chart>

            <div class="flex align-self-center m-t-20 gap-20 position-relative"
                 style="width: 80%;"
                 *ngIf="showMtSteps">
                <div class="position-absolute m-t--30">Spec</div>
                <bp-multi-toggler
                    [steps]="mtSteps"
                    [currentStepId]="getCurrentSpecStepId()"
                    (changed)="onSpecTogglerChanged($event)"
                    class="w-full">
                </bp-multi-toggler>
            </div>
        </div>

        <div class="flex-1">
            <bp-cost-plan-table
                class="w-full">
            </bp-cost-plan-table>
        </div>

        <div class="flex flex-column text-center pie-chart-container d-none d-md-block"
             *ngIf="costPlanService.costPlanMode === 'pie-chart-view' && costPlanService.getChartData()">
            <bp-cost-plan-chart #costPlanChartComponentMobile
                                [data]="costPlanService.getChartData()">
            </bp-cost-plan-chart>

            <div class="flex m-t-50 gap-20 w-full position-relative"
                 *ngIf="showMtSteps">
                <div class="position-absolute m-l--50"
                     style="margin-top: -7px">
                    Spec
                </div>
                <bp-multi-toggler
                    [steps]="mtSteps"
                    [currentStepId]="getCurrentSpecStepId()"
                    (changed)="onSpecTogglerChanged($event)"
                    class="w-full">
                </bp-multi-toggler>
            </div>
        </div>
    </div>
</div>

