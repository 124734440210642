<div class="modal-header">
    <h2>
        Comments
    </h2>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="flex flex-column gap-20 comments position-relative"
         *ngIf="comments$ | async as comments;">
        <div *ngFor="let comment of comments; trackBy: trackById; let odd = odd; let last = last"
             class="comments__comment"
             [ngClass]="{'not-last': !last}">
            <div class="flex flex-row justify-content-between gap-20 f-12-400-gray">
                <div>
                    {{ comment.user.email }}
                </div>
                <div class="flex gap-10 align-items-center">
                    <div>
                        {{ comment.timestamp | date:'dd/MM/yy HH:mm'  }}
                    </div>
                    <button (confirm)="remove(comment)"
                            [disabled]="comment.user.id !== accountId"
                            title="Remove comment"
                            class="icon-button-18 icon-button-primary comments__remove"
                            type="button"
                            [swal]="{
                                        title: 'Are you sure?',
                                        text: 'You will not be able to recover this comment!',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        customClass: {
                                           confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                           cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                        }
                                    }">
                        <i class="material-icons md-18">
                            delete_forever
                        </i>
                    </button>
                </div>
            </div>

            <div class="comments__comment__text"
                 [ngClass]="{'odd' : odd, 'even': !odd}">{{ comment.comment }}
            </div>
        </div>
    </div>

    <div class="m-t-20">
        <textarea
            [formControl]="newCommentControl"
            autocomplete="off"
            placeholder="Write your comment here...">
         </textarea>
    </div>
</div>

<div class="modal-footer m-t-20">
    <button (click)="close()"
            tabindex="0"
            class="btn btn-secondary btn-border-radius waves-effect book-demo"
            type="button">
        Cancel
    </button>

    <button (click)="addComment()"
            [disabled]="newCommentControl.invalid"
            tabindex="1"
            class="btn btn-primary btn-border-radius waves-effect add-comment"
            type="button">
        Comment
    </button>
</div>

