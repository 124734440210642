<div class="step-container">
    <div class="flex justify-content-between align-items-end gap-20 flex-wrap">
        <div class="flex items-center justify-content-end sort-by-container d-none d-md-block">
            <div class="w-250 flex flex-column gap-1">
                <bp-select-input
                    *ngIf="sortSelectInputData"
                    (onSelectionChange)="onChangeSort($event)"
                    [data]="sortSelectInputData"
                    [removeUnderlines]="false"
                    class="sort-select">
                </bp-select-input>
            </div>
        </div>

        <div class="flex-1 flex items-center justify-content-center">
            <div class="step-header text-center"
                 *ngIf="!td.tag.parentTag.title.toLowerCase().endsWith('ing')">
                Which {{ td.tag.title }} are in your {{ td.tag.parentTag.title }}?
            </div>

            <div class="step-header text-center"
                 *ngIf="td.tag.parentTag.title.toLowerCase().endsWith('ing')">
                Which {{ td.tag.title }} are you {{ td.tag.parentTag.title }}?
            </div>
        </div>

        <div class="flex justify-content-center d-none d-md-flex">
            <div class="w-250">
                <input [formControl]="searchControl"
                       id="template-search-query"
                       name="template-search-query"
                       class="template-search-query"
                       placeholder="Search"
                       type="text"/>
            </div>
        </div>
    </div>

    <div class="text-center m-t-10">
        {{ td.tag.parentTag.description }}
    </div>

    <div class="w-full m-t-10 d-md-none flex gap-2">
        <input [formControl]="searchControl"
               id="template-search-query-mobile"
               name="template-search-query-mobile"
               class="template-search-query"
               placeholder="Search"
               type="text"/>

        <bp-select-input
            style="width: 100px"
            *ngIf="sortSelectInputData"
            (onSelectionChange)="onChangeSort($event)"
            [data]="sortSelectInputData"
            [removeUnderlines]="false"
            [replaceArrow]="true"
            class="sort-select">
        </bp-select-input>
    </div>

    <bp-template-list
        class="templates-container"
        [templates]="filteredTemplates"
        [disabled]="inProcess()">
    </bp-template-list>
</div>
