import { BuilderMaterial, IBuilderMaterial, IEndpointMaterial } from 'app/shared/model/builder-material.model';
import * as _ from 'lodash';
import { IMaterialsWrapper } from 'app/shared/dataservices/quoter.service';

export interface IMaterialRatesDataModel {
    margin: number;
    handlingCharge: number;
    materials: IBuilderMaterial[];

    updateMargin(value: number): void;

    updateHandlingCharge(value: number): void;

    fill(materialsInput: IEndpointMaterial[], fillOnlyExisted?: boolean): void;

    isMarginValid(): boolean;

    applyMarginToAll(): void;

    endpointData(): IMaterialsWrapper;

    clearMaterials(): void;
}

export class MaterialRatesDataModel implements IMaterialRatesDataModel {
    private _margin: number;
    private _handlingCharge: number;
    private _materials: IBuilderMaterial[];

    constructor() {
        this._margin = 0;
        this._handlingCharge = 0;
        this._materials = [];
    }

    get margin(): number {
        return this._margin;
    }

    get handlingCharge(): number {
        return this._handlingCharge;
    }

    set handlingCharge(value: number) {
        this._handlingCharge = value;
    }

    set margin(value: number) {
        this._margin = value;
    }

    get materials(): IBuilderMaterial[] {
        return this._materials;
    }

    set materials(values: IBuilderMaterial[]) {
        this._materials = _.map(values, (value: IBuilderMaterial) => new BuilderMaterial(value));
    }

    updateMargin(value: number): void {
        this._margin = Math.round(value * 100);
    }

    updateHandlingCharge(value: number): void {
        this._handlingCharge = Math.round(value * 100);
    }

    fill(materialsInput: IEndpointMaterial[], fillOnlyExisted = false): void {
        _.each(materialsInput, (_material: IBuilderMaterial) => {
            const existedMaterial = _.find(this._materials, { id: _material.id });
            if (existedMaterial) {
                _.merge(existedMaterial, new BuilderMaterial(_material));
            } else if (!fillOnlyExisted) {
                this._materials.push(new BuilderMaterial(_material));
            }
        });
    }

    isMarginValid(): boolean {
        return this.margin != null && typeof this.margin === 'number' && this.margin >= 0 && this.margin <= 100;
    }

    endpointData(): IMaterialsWrapper {
        return {
            margin: this.margin / 100,
            handlingCharge: this.handlingCharge / 100,
            materials: _.map(this.materials, (material: IBuilderMaterial) => {
                return material.endpointData();
            })
        };
    }

    applyMarginToAll(): void {
        _.each(this.materials, (material: IBuilderMaterial) => {
            material.margin = this.margin;
        });
    }

    clearMaterials() {
        this._materials = [];
    }
}
