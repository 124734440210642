<div class="card">
    <div class="af-row-container">
        <div class="row">
            <div class="col-sm-8">
                <bp-main-view-filter
                    class="m-b-10"
                    (onChanged)="onMainViewFilterChangedFunc($event)"
                    [initialFilterState]="filterState"
                    [areaItems]="scheduleAreaItems"
                    [stageItems]="stageItems">
                </bp-main-view-filter>
            </div>
            <div class="col-sm-4 flex-container row-reverse">
                <h4 class="total">
                    {{ quoter.total || 0 | currency:'GBP' }}
                </h4>
            </div>
        </div>
    </div>

    <div *ngIf="filterState.groupBy === 'stage' || filterState.groupBy === 'stage_room'">
        <bp-individual-quote-stage
            *ngFor="let stage of comparison.stageDTOs"
            [project]="project"
            [stage]="stage"
            [quoter]="quoter"
            [showGroupNode]="filterState.groupBy === 'stage'">
        </bp-individual-quote-stage>

        <div class="m-l-20"
             *ngIf="!comparison.stageDTOs?.length">
            <span>No stages to show</span>
        </div>
    </div>

    <div *ngIf="filterState.groupBy === 'area' || filterState.groupBy === 'area_room'">
        <div *ngFor="let area of nativeScheduleAreas; let first = first;"
             [ngClass]="{'m-t-10': first}"
             class="af-row-container no-bottom-border">
            <ng-container *ngIf="area['comparison']">
                <div *ngIf="filterState.groupBy === 'area' && !area.expanded">
                    <div class="row">
                        <div class="col-sm-6 cursor-pointer">
                            <div (click)="onClosedAreaIconClick(area)">
                                <h3>
                                    {{ area.area }}
                                </h3>
                                <i class="material-icons md-18">chevron_right</i>
                            </div>
                        </div>
                        <div class="col-sm-6 text-right">
                            <h4>
                                {{ area['comparison'].quoters[0].total || 0 | currency:'GBP' }}
                            </h4>
                        </div>
                    </div>
                </div>

                <div *ngIf="filterState.groupBy === 'area_room' || area.expanded">
                    <div class="row"
                         *ngIf="filterState.groupBy === 'area'">
                        <div class="col-sm-6 cursor-pointer">
                            <div (click)="onOpenedAreaIconClick(area)">
                                <h3>
                                    {{ area.area }}
                                </h3>
                                <i class="material-icons md-18">expand_more</i>
                            </div>
                        </div>
                        <div class="col-sm-6 text-right">
                            <h3>
                                {{ area['comparison'].quoters[0].total || 0 | currency:'GBP' }}
                            </h3>
                        </div>
                    </div>

                    <div class="element-container"
                         [ngClass]="{'m-t-10': first && filterState.groupBy === 'area'}"
                         *ngFor="let stage of area['comparison']['stageDTOs'] || []; let first = first;">
                        <bp-individual-quote-element
                            *ngFor="let element of stage.elementDTOs"
                            [element]="element">
                        </bp-individual-quote-element>
                    </div>

                    <div class="m-l-20"
                         *ngIf="!(area['comparison']['stageDTOs'] || []).length">
                        <span>No stages to show</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
