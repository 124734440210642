import { Component, OnInit } from '@angular/core';
import { QuoterExpandAreaGroupStorageService } from 'app/flows/quoter/quote/services/expand-area-group-storage.service';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { QuoteStore } from "app/flows/quoter/quote/stores/quote.store";

@Component({
    selector: 'bp-quote-stages',
    templateUrl: './stages.component.html',
    styleUrls: ['stages.scss']
})
export class QuoteStagesComponent implements OnInit {
    constructor(private expandAreaGroupStorageService: QuoterExpandAreaGroupStorageService,
                protected store: QuoteStore) {
    }

    ngOnInit(): void {
        if (this.store.filterState.groupBy === 'area' || this.store.filterState.groupBy === 'area_room') {
            this.store.nativeScheduleAreas.forEach((area) => {
                area.expanded = this.expandAreaGroupStorageService.retrieve(this.store.project, area);

                if (area.expanded) {
                    this.expandArea(area);
                } else {
                    this.collapseArea(area);
                }
            })
        }
    }

    onClosedAreaIconClick(area: IScheduleArea): void {
        this.expandArea(area);
    }

    onOpenedAreaIconClick(area: IScheduleArea): void {
        this.collapseArea(area);
    }

    private expandArea(area: IScheduleArea): void {
        area.expanded = true;
        this.expandAreaGroupStorageService.store(this.store.project, area, true);
    }

    private collapseArea(area: IScheduleArea): void {
        area.expanded = false;
        this.expandAreaGroupStorageService.store(this.store.project, area, false);
    }
}
