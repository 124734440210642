import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import {
    materialCategoryPopupRoute,
    materialCategoryRoute
} from 'app/entities/material-category/material-category.route';
import { MaterialCategoryListComponent } from 'app/entities/material-category/list/material-category-list.component';
import {
    MaterialCategoryUpdateComponent
} from 'app/entities/material-category/edit/material-category-update.component';
import {
    MaterialCategoryDeleteDialogComponent,
    MaterialCategoryDeletePopupComponent
} from 'app/entities/material-category/delete/material-category-delete-dialog.component';

const ENTITY_STATES = [...materialCategoryRoute, ...materialCategoryPopupRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [
        MaterialCategoryListComponent,
        MaterialCategoryUpdateComponent,
        MaterialCategoryDeleteDialogComponent,
        MaterialCategoryDeletePopupComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpMaterialCategoryModule {
}
