import { Route } from '@angular/router';
import { QuoterInvitationDeclinerComponent } from './quoter-invitation-decliner.component';

export const quoterInvitationDeclinerRoute: Route = {
    path: 'quoter/invitation/decline',
    component: QuoterInvitationDeclinerComponent,
    data: {
        authorities: []
    }
};
