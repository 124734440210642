<div class="af-row-container">
    <div (click)="toggleExpand()"
         *ngIf="showGroupNode"
         class="row cursor-pointer">
        <div class="col-sm-6">
            <h3>
                {{ stage.stage }}
            </h3>
            <i class="material-icons">{{ stage.expanded ? "expand_more" : "chevron_right" }}</i>
        </div>
        <div class="col-sm-6 text-right">
            <h3>
                {{ stage.totals[0] || 0 | currency:'GBP' }}
            </h3>
        </div>
    </div>

    <div *ngIf="!showGroupNode || stage.expanded">
        <div class="element-container">
            <bp-individual-quote-element
                *ngFor="let element of stage.elementDTOs"
                [element]="element">
            </bp-individual-quote-element>
        </div>
    </div>
</div>
