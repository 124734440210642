import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IMainViewFilterState } from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import {
    CostPlanTableComponent
} from 'app/shared/components/projects/project-details-cost-visualization/components/cost-plan-table/cost-plan-table.component';
import { CostPlanService } from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IProject } from 'app/shared/model/project.model';
import {
    IOverviewProjectChartData
} from 'app/shared/components/projects/project-details-cost-visualization/overview-project-chart.service';
import { IMultiTogglerStep } from 'app/shared/components/common/multi-toggler/multi-toggler.component';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { HttpResponse } from '@angular/common/http';
import { ISpecification } from 'app/shared/model/specification.model';
import { ProjectSpecificationService } from 'app/shared/dataservices/project-specification.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { AccountService } from 'app/core/auth/account.service';
import { IInvitation } from 'app/shared/model/invitation.model';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import {
    UpdateInvitationVersionStatusCheckingService
} from 'app/shared/services/update-invitation-version-status-checking.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';

@Component({
    selector: 'bp-project-details-cost-visualization',
    templateUrl: './project-details-cost-visualization.component.html',
    styleUrls: ['project-details-cost-visualization.scss']
})
export class ProjectDetailsCostVisualizationComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    @Input() filterState: IMainViewFilterState;

    @ViewChild('costPlanChartComponent') costPlanChartComponent;
    @ViewChild('costPlanChartComponentMobile') costPlanChartComponentMobile;
    @ViewChild(CostPlanTableComponent) costPlanTableComponent;

    private onDestroy$ = new Subject<void>();

    mtSteps: IMultiTogglerStep[] | null = null;

    get showMtSteps(): boolean {
        return this.project.currentUserRelation.includes('ROLE_SCHEDULER') && this.mtSteps != null;
    }

    get project(): IProject {
        return this.costPlanService.project;
    }

    constructor(public costPlanService: CostPlanService,
                public accountService: AccountService,
                private specificationService: SpecificationService,
                private invitationService: InvitationService,
                private projectSpecificationService: ProjectSpecificationService,
                private confirmModalService: ConfirmModalService,
                private updateInvitationVersionStatusCheckingService: UpdateInvitationVersionStatusCheckingService,
                private alertService: BpAlertService) {
    }

    ngOnInit(): void {
        this.specificationService.query().subscribe((res: HttpResponse<ISpecification[]>) => {
            this.mtSteps = res.body.map(spec => {
                const res: IMultiTogglerStep = {
                    id: spec.id,
                    label: spec.title,
                    tooltip: spec.description
                }

                return res;
            });
        })

        this.costPlanService.initedState$.pipe(takeUntil(this.onDestroy$)).subscribe((inited: boolean) => {
            if (inited) {
                setTimeout(() => {
                    const chartData = this.costPlanService.getChartData();
                    if (chartData) {
                        this.costPlanChartComponent?.redraw(chartData);
                        this.costPlanChartComponentMobile?.redraw(chartData);
                    }

                    this.costPlanService.overviewProjectChartDataState$
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe((res: IOverviewProjectChartData) => {
                            if (res != null) {
                                const chartData = this.costPlanService.getChartData();
                                if (chartData) {
                                    this.costPlanChartComponent?.redraw(chartData);
                                    this.costPlanChartComponentMobile?.redraw(chartData);
                                }
                            }
                        });
                });
            }
        });

        this.refresh();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    inProcess(): boolean {
        return this.costPlanService.inProcess;
    }

    onSpecTogglerChanged(event: { step: IMultiTogglerStep, callback: Function }): void {
        this.confirmModalService.open(
            {
                header: 'Update Level of Specification?',
                textHtml: `<div class="strong m-b-10">
                            This will change all material items to your chosen specification.
                        </div>
                        <div class="strong m-b-10">
                            Beware, this will override any specification updates you’ve made on the schedule page.
                        </div>`,
                confirmButtonText: 'Update'
            }
        ).result.then(result => {
                event.callback(result);

                if (!result) {
                    return;
                }

                this.blockUI.start('Please wait..');

                this.projectSpecificationService.change(this.project.id, event.step.id).subscribe(() => {
                    this.invitationService
                        .query(this.project.id)
                        .subscribe((res: HttpResponse<IInvitation[]>) => {
                            const invitations = res.body.filter(invitation => invitation.isDefault || invitation.quoterId === this.project.defaultQuoter.id || invitation.processing);
                            const checkPromises = invitations.map(
                                (invitation: IInvitation) => this.updateInvitationVersionStatusCheckingService.addUpdateVersionStatusChecking(this.project, invitation));
                            Promise.all(checkPromises).then(() => {
                                this.refresh();
                                this.blockUI.stop();
                                this.alertService.success('Specification of materials was globally changed');
                            });

                        })
                })
            },
            reason => {
                event.callback(false);
            });
    }

    getCurrentSpecStepId(): number {
        return (this.mtSteps.find((mtStep) => (mtStep.id === this.project.specification?.id)) || this.mtSteps[0]).id;
    }

    private refresh(): void {
        this.costPlanService.init(this.project, this.filterState);
    }
}

