import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notAllowedValidator(input: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const forbidden = input.test(control.value);
    return forbidden ? {notAllowed: {value: control.value}} : null;
  };
}
