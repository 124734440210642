import { Component, Input, OnInit } from '@angular/core';
import { IStage } from 'app/shared/model/stage.model';
import * as _ from 'lodash';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExpandAreaGroupStorageService } from "app/flows/scheduler/schedule/services/expand-area-group-storage.service";
import { skip } from "rxjs/operators";
import { ConfirmModalService } from "app/shared/components/common/confirm-modal/confirm-modal.service";

@UntilDestroy()
@Component({
    selector: 'bp-schedule-area-for-filter-by-area-grouping',
    templateUrl: './area.component.html',
    styleUrls: [
        '../../root-container/stage.scss',
        '../../../schedule-common.scss',
        'area.scss'
    ]
})
export class AreaForFilterByAreaGroupingComponent implements OnInit {
    @Input() scheduleArea: IScheduleArea;

    protected expanded = false;

    constructor(
        public scheduleService: ScheduleService,
        private confirmModalService: ConfirmModalService,
        private expandAreaGroupStorageService: ExpandAreaGroupStorageService
    ) {
    }

    ngOnInit(): void {
        this.expanded = this.expandAreaGroupStorageService.retrieve(this.scheduleService.project.id, this.scheduleArea.id);

        this.scheduleService.expandAll$
            .pipe(
                skip(1),
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res;
            this.expandAreaGroupStorageService.store(this.scheduleService.project.id, this.scheduleArea.id, res);

            setTimeout(() => {
                this.scheduleService.filteredStages(this.scheduleArea['stages'], this.scheduleArea).forEach(stage => {
                    this.scheduleService.expandStage(stage.id, this.scheduleArea.id, res);
                })
            })
        })
    }

    protected toggleAreaExpand(): void {
        this.expanded ? this.collapseArea() : this.expandArea();
    }

    protected getScheduleAreaTotal(scheduleArea: IScheduleArea): number {
        return _.sumBy(scheduleArea['stages'], (stage: IStage) => stage._quoterTotals[scheduleArea.id]);
    }

    private expandArea(): void {
        this.expanded = true;
        this.expandAreaGroupStorageService.store(this.scheduleService.project.id, this.scheduleArea.id, true);
    }

    private collapseArea(): void {
        this.expanded = false;
        this.expandAreaGroupStorageService.store(this.scheduleService.project.id, this.scheduleArea.id, false);
    }

    protected onDeleteAreaClick(): void {
        this.confirmModalService.open({
            header: `Delete ${this.scheduleArea.area} Area?`,
            textHtml: `<div class="strong m-b-10">
                            This will delete all its associated tasks and costs.
                        </div>`
        }).result.then((res) => {
            if (!res) {
                return;
            }

           this.scheduleService.deleteArea(this.scheduleArea);
        })
    }
}
