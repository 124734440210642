import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class SubscriptionApi {
    public resourceUrl = SERVER_API_URL + 'api/subscription';

    constructor(private http: HttpClient) {
    }

    pause(userId: number, months: number): Observable<HttpResponse<void>> {
        return this.http.put<void>(`${this.resourceUrl}/pause`, {userId, months}, { observe: 'response' });
    }
}
