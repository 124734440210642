import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { OptInPayModalService } from 'app/flows/quoter/components/opt-in-pay-modal/opt-in-pay-modal.service';
import { CostPlanService } from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IMainViewFilterState } from "app/shared/components/common/main-view-filter/main-view-filter.component";

/**
 * Quoter Cost Plan
 */
@Component({
    selector: 'bp-quoter-cost-plan',
    templateUrl: './cost-plan.component.html',
    styleUrls: ['cost-plan.scss']
})
export class CostPlanComponent {

    constructor(
        public costPlanService: CostPlanService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private optInPayModalService: OptInPayModalService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.activatedRoute.data.subscribe(data => {
            this.costPlanService.project = data.project;
            if (this.costPlanService.project.isPaid) {
                this.costPlanService.filterState = this.mainFilterInitialStateStorageService.retrieve(this.costPlanService.project .id, 'project_overview_quote');
            } else {
                this.optInPayModalService.open(this.costPlanService.project);
            }
        });
    }

    onViewQuoteClick(): void {
        this.router.navigate(['../../quote', this.costPlanService.project .id], { relativeTo: this.activatedRoute });
    }

    onMainViewFilterChangedFunc(value: IMainViewFilterState): void {
        this.costPlanService.filterState = value;
        this.mainFilterInitialStateStorageService.store(this.costPlanService.project .id, 'project_overview_quote', value);
    }
}
