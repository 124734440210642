import { Injectable } from '@angular/core';
import { ValuationApi } from 'app/shared/dataservices/valuation.api';
import { lastValueFrom } from 'rxjs';
import { IValuationRequest } from 'app/shared/model/bp.model';
import { ValuationsStore } from 'app/flows/quoter/valuations/stores/valuations.store';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { NUMBER_PATTERN } from 'app/shared/constants/patterns';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ValuationsService {

    constructor(private valuationApi: ValuationApi,
                private projectApi: ProjectApi,
                private store: ValuationsStore) {
    }

    public async init(): Promise<void> {
        return lastValueFrom(this.valuationApi.queryValuation(this.store.project.id))
            .then((res) => {
                this.store.valuationResponse = res.body;
            })
    }

    public submitForValuation(): Promise<void> {
        let index = 0;
        const tasks: { id: number, complete: number }[] = [];
        this.store.nativeComparison?.stageDTOs.forEach((stage) => {
            stage.elementDTOs.forEach((element) => {
                element.taskDTOs.forEach((task) => {
                    // @ts-ignore
                    tasks.push({ id: task.rootId, complete: +this.store.createForm.controls.valuations.value.at(index++) });
                })
            })
        })
        const data: IValuationRequest = {
            projectDeposit: this.store.createForm.controls.deposit.value,
            projectRetention: this.store.createForm.controls.retention.value,
            valuationRetention: this.getLessRetention(),
            valuationDeposit: this.getLessDeposit(),
            tasks
        }
        return lastValueFrom(this.valuationApi.submitForValuation(this.store.project.id as number, data));
    }

    public fillCreateForm(): void {
        const valuationsControl = this.store.createForm.get('valuations') as FormArray;
        let index = 0;
        this.store.nativeComparison.stageDTOs.forEach((stageDTO) => {
            stageDTO.elementDTOs.forEach((elementDTO) => {
                elementDTO.taskDTOs.forEach((taskDTO) => {
                    taskDTO['_index'] = index++;
                    valuationsControl.push(
                        new FormControl(50, [
                                Validators.pattern(NUMBER_PATTERN),
                                Validators.required
                            ]
                        )
                    );
                })
            })
        })
    }

    public getRemaining(scheduleTask: IComparisonScheduleTask): number {
        return scheduleTask.totals[0] - this.getToInvoice(scheduleTask);
    }

    public getToInvoice(scheduleTask: IComparisonScheduleTask): number {
        const formControl = this.getValuationFormControl(scheduleTask);
        return scheduleTask.totals[0] / 100 * formControl.value;
    }

    public getValuationFormControl(scheduleTask: IComparisonScheduleTask): FormControl {
        return this.store.createForm.controls.valuations.at(scheduleTask['_index']);
    }

    public getLessDeposit(): number {
        const deposit = this.store.createForm.controls.deposit.value;
        let result = 0;
        this.store.nativeComparison?.stageDTOs.forEach((stage) => {
            stage.elementDTOs.forEach((element) => {
                element.taskDTOs.forEach((task) => {
                    result += this.getToInvoice(task);
                })
            })
        })
        return result / 100 * deposit;
    }

    public getLessRetention(): number {
        const retention = this.store.createForm.controls.retention.value;
        let result = 0;
        this.store.nativeComparison?.stageDTOs.forEach((stage) => {
            stage.elementDTOs.forEach((element) => {
                element.taskDTOs.forEach((task) => {
                    result += this.getToInvoice(task);
                })
            })
        })
        return result / 100 * retention;
    }

    public getStageTotal(stage: IComparisonStage): number {
        let result = 0;

        stage.elementDTOs.forEach((element) => {
            element.taskDTOs.forEach((task) => {
                result += this.getToInvoice(task);
            })
        })
        return result;
    }

    public getElementTotal(element: IComparisonElement): number {
        let result = 0;

        element.taskDTOs.forEach((task) => {
            result += this.getToInvoice(task);
        })

        return result;
    }

    public getTotal(): number {
        let result = 0;
        this.store.nativeComparison?.stageDTOs.forEach((stage) => {
            stage.elementDTOs.forEach((element) => {
                element.taskDTOs.forEach((task) => {
                    result += this.getToInvoice(task);
                })
            })
        })
        return result;
    }

    public getInvoiceTotal(): number {
        return this.getTotal() - (this.getLessDeposit() + this.getLessRetention());
    }

    public createInvoice(): Promise<void> {
        return lastValueFrom(this.projectApi.createInvoice(this.store.project.id).pipe(map(res => res.body)));
    }
}
