<ng-select #selectInput
           (change)="setData()"
           (open)="onOpenFunc()"
           (keydown.enter)="onEnterClick()"
           [(ngModel)]="selectedIndex"
           [clearable]="data.clearable != null ? data.clearable : false"
           [disabled]="disabled"
           [items]="items"
           [multiple]="false"
           [virtualScroll]="true"
           [searchable]="data.searchable != null ? data.clearable : true"
           [ngClass]="{'remove-underlines': removeUnderlines, 'no-bottom-border': noBottomBorder, 'hide-arrow': replaceArrow}"
           class="bp-select {{ additionalClasses }}"
           bindLabel="_calculatedLabelField"
           bindValue="{{data.indexProperty}}"
           dropdownPosition="{{data.dropdownPosition || 'top'}}"
           id="autocompleteSelect"
           notFoundText="'No items found'"
           appendTo="{{appendTo}}"
           placeholder="{{ placeholder || '-- start typing to find item --'}}">
    <ng-template ng-label-tmp let-item="item">
        <div style="max-width: 100%; overflow: hidden; word-wrap: normal; white-space: normal; line-height: 1;">
            <div class="flex gap-1">
                {{ item['_calculatedLabelField'] || item[data.titleProperty]}}
                <ng-container *ngIf="replaceArrow">
                    <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                </ng-container>
            </div>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
        <div style="max-width: 100%; overflow: hidden; word-wrap: normal; white-space: normal; line-height: 1;">
            <div class="flex gap-1">
                {{ item['_calculatedLabelField'] || item[data.titleProperty]}}
                <ng-container *ngIf="replaceArrow">
                    <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                </ng-container>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="addNewPossible">
        <ng-template ng-footer-tmp>
            <a (click)="onAddNewTaskClick()">
                + Add '{{ ngSelectComponent.searchTerm }}'
            </a>
        </ng-template>
    </ng-container>
</ng-select>
