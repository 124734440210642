import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { IValuationRequest, IValuationResponse } from "app/shared/model/bp.model";
import { SERVER_API_URL } from "app/app.constants";

@Injectable({ providedIn: 'root' })
export class ValuationApi {

    constructor(private http: HttpClient) {
    }

    queryValuation(projectId: number): Observable<HttpResponse<IValuationResponse>> {
        const url = `${SERVER_API_URL}api/projects/${projectId}/valuation`;
        return this.http.get<IValuationResponse>(url, { observe: 'response' });
    }

    submitForValuation(projectId: number, data: IValuationRequest): Observable<any> {
        const url = `${SERVER_API_URL}api/projects/${projectId}/valuation`;
        return this.http.post<any>(url, data, { observe: 'response' });
    }

}
