<div class="root">
    <table class="af-table align-right m-b-20">
        <tr class="tr-head"
            *ngIf="store.showSubStages">
            <th class="align-left"
                style="width: 35%">
                {{ element?.element }}
            </th>
            <th class="align-left"
                style="width: 15%">
                Specification
            </th>
            <th style="width: 10%">
                Qty
            </th>
            <th style="width: 8%">
                Labour<br>cost/unit
            </th>
            <th style="width: 8%">
                Materials<br>cost/unit
            </th>
            <th style="width: 8%">
                Total<br>cost/unit
            </th>
            <th style="width: 8%">
                Line<br>total
            </th>
            <th style="width: 8%">
                {{ element.totals[0] || 0 | currency:'GBP' }}
            </th>
        </tr>

        <ng-container *ngFor="let scheduleTask of element.taskDTOs"
                      style="display: contents">
            <tr [element]="element"
                [scheduleTask]="scheduleTask"
                [stage]="stage"
                [showHistoryView]="store.showHistoryView"
                [className]="scheduleTask.historyStatus == 'MODIFIED' ? 'NEW' : scheduleTask.historyStatus"
                bpQuoteScheduleTask>
            </tr>
            <tr *ngIf="scheduleTask.historyStatus === 'MODIFIED' && store.showHistoryView"
                [element]="element"
                [scheduleTask]="scheduleTask.previous"
                [stage]="stage"
                [showHistoryView]="store.showHistoryView"
                [className]="'REMOVED'"
                bpQuoteScheduleTask>
            </tr>
        </ng-container>
    </table>
</div>
