import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { ICssElement } from 'app/shared/model/bp.model';

export interface IMainFilterApplyingToComparisonResult {
    scheduleAreas: IScheduleArea[];
    comparison: IComparison;
    cssElementMap: Map<number, { cssElement: ICssElement, scheduleTasks: IComparisonScheduleTask[], totals: number[] }>;
}

@Injectable({ providedIn: 'root' })
export class ApplyMainFilterToComparisonService {
    constructor() {
    }

    apply(selectedQuoters: IComparisonQoter[],
          nativeComparison: IComparison,
          nativeScheduleAreas: IScheduleArea[],
          filterState: IMainViewFilterState,
          scheduleAreaItems: IMainViewFilterItemItem[],
          stageItems: IMainViewFilterItemItem[],
          cssElementItems: IMainViewFilterItemItem[]): IMainFilterApplyingToComparisonResult {

        const result: IMainFilterApplyingToComparisonResult = {
            scheduleAreas: [],
            comparison: null,
            cssElementMap: new Map<number, {
                cssElement: ICssElement,
                scheduleTasks: IComparisonScheduleTask[],
                totals: number[]
            }>()
        };

        let selectedScheduleAreaIds = filterState.areaIds == null ? scheduleAreaItems.map(i => i.id) : filterState.areaIds;
        let selectedStageIds = filterState.stageIds == null ? stageItems.map(i => i.id) : filterState.stageIds;
        let selectedCssElementIds = filterState.cssElementIds == null ? cssElementItems.map(i => i.id) : filterState.cssElementIds;

        if (filterState.groupBy === 'area_room') {
            selectedScheduleAreaIds = [filterState.areaRoomId];
        }

        if (filterState.groupBy === 'stage_room') {
            selectedStageIds = [filterState.stageRoomId];
        }

        if (filterState.groupBy === 'css-element_room') {
            selectedCssElementIds = [filterState.cssElementRoomId];
        }

        switch (filterState.groupBy) {
            case 'area':
            case 'area_room':
                const sa = _.cloneDeep(nativeScheduleAreas);

                result.scheduleAreas = _.filter(sa, area => {
                    if (!selectedScheduleAreaIds?.length) {
                        return false;
                    }

                    return selectedScheduleAreaIds.indexOf(area.id) !== -1;
                });

                for (let quoterIndex = 0; quoterIndex < selectedQuoters.length; quoterIndex++) {
                    const quoter = selectedQuoters[quoterIndex];
                    quoter.total = 0;

                    _.each(result.scheduleAreas, (area: IScheduleArea) => {
                        area['comparison']['stageDTOs'] = _.filter(area['comparison']['stageDTOs'], (stageDTO: IComparisonStage) => {
                            if (!selectedStageIds?.length) {
                                return false;
                            }

                            return selectedStageIds.indexOf(stageDTO.id) !== -1;
                        });
                    });

                    _.each(result.scheduleAreas, (area: IScheduleArea) => {
                        area['comparison'].quoters[quoterIndex].total = 0;

                        _.each(area['comparison']['stageDTOs'], (stageDTO: IComparisonStage) => {
                            stageDTO.totals[quoterIndex] = 0;

                            _.each(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                                _.each(elementDTO.taskDTOs, (taskDTO: IComparisonScheduleTask) => {
                                    const additionalTotal = taskDTO.totals[quoterIndex];

                                    area['comparison'].quoters[quoterIndex].total += additionalTotal;
                                    stageDTO.totals[quoterIndex] += additionalTotal;
                                    quoter.total += additionalTotal;
                                });
                            });
                        });
                    });
                }

                break;
            case 'stage':
            case 'stage_room':
                const comp = _.cloneDeep(nativeComparison);

                const stageComp: IComparison = {
                    quoters: comp.quoters,
                    stageDTOs: _.filter(comp.stageDTOs, stageDTO => {
                        if (!selectedScheduleAreaIds?.length || !selectedStageIds?.length) {
                            return false;
                        }

                        return selectedStageIds.indexOf(stageDTO.id) !== -1;
                    })
                };

                _.each(stageComp.stageDTOs, (stageDTO: IComparisonStage) => {
                    _.each(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                        elementDTO.taskDTOs = _.filter(elementDTO.taskDTOs, (taskDTO: IComparisonScheduleTask) => {
                            return _.indexOf(selectedScheduleAreaIds, taskDTO.scheduleAreaRootId) !== -1;
                        });
                    });
                });

                _.each(stageComp.stageDTOs, (stageDTO: IComparisonStage) => {
                    stageDTO.elementDTOs = _.filter(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                        return elementDTO.taskDTOs.length > 0;
                    });
                });

                stageComp.stageDTOs = _.filter(stageComp.stageDTOs, (stageDTO: IComparisonStage) => {
                    return stageDTO.elementDTOs.length > 0;
                });

                for (let quoterIndex = 0; quoterIndex < selectedQuoters.length; quoterIndex++) {
                    const quoter = selectedQuoters[quoterIndex];
                    quoter.total = 0;

                    _.each(stageComp.stageDTOs, stageDTO => {
                        stageDTO.totals[quoterIndex] = 0;

                        _.each(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                            _.each(elementDTO.taskDTOs, (taskDTO: IComparisonScheduleTask) => {
                                const additionalTotal = taskDTO.totals[quoterIndex];

                                stageDTO.totals[quoterIndex] += additionalTotal;
                                quoter.total += additionalTotal;
                            });
                        });
                    });
                }

                result.comparison = stageComp;
                break;

            case 'css-element':
            case 'css-element_room':
                const comp2 = _.cloneDeep(nativeComparison);

                const allScheduleTaskDTOs: IComparisonScheduleTask[] = [];

                _.each(comp2.stageDTOs, (stage: IComparisonStage) => {
                    stage.elementDTOs?.forEach((element) => {
                        allScheduleTaskDTOs.push(...element.taskDTOs || []);
                    })
                });

                result.cssElementMap = this.createCssElementMap(allScheduleTaskDTOs, selectedCssElementIds, cssElementItems);

                for (let i = 0; i < selectedQuoters.length; i++) {
                    selectedQuoters[i].total = 0;
                    result.cssElementMap.forEach(o => {
                        selectedQuoters[i].total += o.totals[i];
                    })
                }
                break;
        }

        return result;
    }

    private createCssElementMap(allScheduleTaskDTOs: IComparisonScheduleTask[] = [],
                             selectedCssElementIds: number[],
                                cssElementItems: IMainViewFilterItemItem[]): Map<number, {
        cssElement: ICssElement,
        scheduleTasks: IComparisonScheduleTask[],
        totals: number[]
    }> {
        const res: Map<number, {
            cssElement?: ICssElement,
            scheduleTasks?: IComparisonScheduleTask[],
            totals: number[]
        }> = new Map<number, {
            cssElement: ICssElement,
            scheduleTasks: IScheduleTask[],
            totals: []
        }>;

        allScheduleTaskDTOs.forEach(scheduleTask => {
            if (scheduleTask.cssElementId != null
                && selectedCssElementIds.indexOf(scheduleTask.cssElementId) !== -1) {
                if (!res.get(scheduleTask.cssElementId)) {
                    const cssElement: ICssElement = { id: scheduleTask.cssElementId, name: scheduleTask.cssElement };
                    res.set(scheduleTask.cssElementId, { cssElement: cssElement, scheduleTasks: [], totals: [] });
                }

                const scheduleTasks = res.get(scheduleTask.cssElementId)?.scheduleTasks || [];
                scheduleTasks.push(scheduleTask);
                res.get(scheduleTask.cssElementId).cssElement.total = scheduleTasks.reduce((partialSum, st) => partialSum + st['totals'][0], 0);
            }
        });

        const unspecifiedScheduleTasks = allScheduleTaskDTOs.filter(scheduleTask => scheduleTask.cssElement == null);

        if (unspecifiedScheduleTasks.length) {
            res.set(-1, {
                cssElement: {
                    id: -1,
                    name: 'Unspecified',
                    order: Number.MAX_SAFE_INTEGER,
                    archive: false,
                    total: 0
                },
                scheduleTasks: unspecifiedScheduleTasks,
                totals: []
            });
        }

        res.forEach(o => {
            o.totals = this.getTotals(o.scheduleTasks);
            if (o.totals.length === 1) {
                o.cssElement.total = o.totals[0];
            }
        })

        return new Map([...res as Map<number, {
            cssElement: ICssElement,
            scheduleTasks: IComparisonScheduleTask[],
            totals: number[]
        }>].sort((a, b) => {
            const orderA = a[1].cssElement.order || cssElementItems.find(i => i.id === a[0])?.order;
            const orderB = b[1].cssElement.order || cssElementItems.find(i => i.id === b[0])?.order;

            if (orderA == orderB) {
                return 0;
            }
            return orderA > orderB ? 1 : -1;
        }));
    }

    private getTotals(scheduleTasks: IComparisonScheduleTask[]): number[] {
        if (!scheduleTasks.length) {
            return [];
        }
        const result = [];
        scheduleTasks.forEach(scheduleTask => {
            for (let i = 0; i < scheduleTask.totals.length; i++) {
                result[i] = result[i] || 0;
                result[i] += scheduleTask.totals[i];
            }
        })
        return result;
    }

}
