import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ShowSubStagesMode } from 'app/shared/constants/show-substages-modes';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class ShowSubStagesModeStorageService {
    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(projectId: number, value: ShowSubStagesMode): void {
        this.webStorage.store(this.getLocalStorageKey(projectId), value);
    }

    retrieve(projectId: number): ShowSubStagesMode {
        const value: ShowSubStagesMode = this.webStorage.retrieve(this.getLocalStorageKey(projectId));
        return value != null ? value : 'hide-all';
    }

    private getLocalStorageKey(projectId: number): string {
        return `scheduler_${this.accountService.getAccountId()}_showSubStages_${projectId}`;
    }
}
