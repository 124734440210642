import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IProjectTimeline } from 'app/shared/model/project-time-line.model';

@Injectable({ providedIn: 'root' })
export class ProjectTimelineService {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {
    }

    query(projectId: number): Observable<HttpResponse<IProjectTimeline>> {
        return this.http.get<IProjectTimeline>(`${this.resourceUrl}/${projectId}/time-line`, { observe: 'response' });
    }

    setTradespeople(projectId: number, value: number): Observable<HttpResponse<void>> {
        return this.http.put<void>(`${this.resourceUrl}/${projectId}/tradespeople`, { tradespeople: value }, { observe: 'response' });
    }

}
