export const REFERRAL = 'Referral';
export const SOCIAL_MEDIA_FACEBOOK = 'Social Media (Facebook)';
export const SOCIAL_MEDIA_INSTAGRAM = 'Social Media (Instagram)';
export const SOCIAL_MEDIA_LINKEDIN = 'Social Media (Linkedin)';
export const SEARCH_ENGINE = 'Search Engine';
export const OTHER = 'Other';

export const HEARD_ABOUT_US_PREDEFINED_VALUES: { id: number; value: string }[] = [
    {
        id: 1,
        value: REFERRAL
    },
    {
        id: 2,
        value: SOCIAL_MEDIA_FACEBOOK
    },
    {
        id: 3,
        value: SOCIAL_MEDIA_INSTAGRAM
    },
    {
        id: 4,
        value: SOCIAL_MEDIA_LINKEDIN
    },
    {
        id: 5,
        value: SEARCH_ENGINE
    },
    {
        id: 6,
        value: OTHER
    }
];
