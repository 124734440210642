import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ScopesStore } from 'app/flows/scheduler/scopes/stores/scopes.store';
import { ScopesService } from 'app/flows/scheduler/scopes/services/scopes.service';

/**
 * Scopes
 */
@Component({
    selector: 'bp-scopes-page',
    templateUrl: './scopes.page.html',
    styleUrls: ['scopes.scss']
})
export class ScopesPage implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        public scopesStore: ScopesStore,
        public scopesService: ScopesService
    ) {

    }

    ngOnInit(): void {
        this.blockUI.start('Loading data..');
        this.scopesService.init().finally(() => {
            this.blockUI.stop();
        })
    }

    ngOnDestroy(): void {
    }

}
