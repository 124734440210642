import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import {
    BuildUpDeleteDialogComponent,
    BuildUpDeletePopupComponent
} from 'app/entities/build-up/delete/build-up-delete-dialog.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { buildUpPopupRoute, buildUpRoute } from 'app/entities/build-up/build-up.route';
import { BuildUpListComponent } from 'app/entities/build-up/list/build-up-list.component';
import { BuildUpUpdateComponent } from 'app/entities/build-up/edit/build-up-update.component';

const ENTITY_STATES = [...buildUpRoute, ...buildUpPopupRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        BuildUpListComponent,
        BuildUpUpdateComponent,
        BuildUpDeleteDialogComponent,
        BuildUpDeletePopupComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpBuildUpModule {
}
