import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class CurrentMenuItemStorageService {

    constructor(private webStorage: LocalStorageService,
                private accountService: AccountService) {
    }

    store(projectId: number, value: string): void {
        this.webStorage.store(this.getLocalStorageKey(projectId), value);
    }

    retrieve(projectId: number): string {
        return this.webStorage.retrieve(this.getLocalStorageKey(projectId));
    }

    private getLocalStorageKey(projectId: number): string {
        return `account_${this.accountService.getAccountId()}_current-menu-item_${projectId}`;
    }
}
