import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';
import { IComparison } from 'app/shared/model/comparison.model';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';

export interface IApplyMainFilterForQuoterInputData {
    filteredScheduleAreas: IScheduleArea[];
    comparison: IComparison;
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class ApplyMainFilterForQuoterService {
    public apply(
        nativeScheduleAreas: IScheduleArea[],
        nativeComparison: IComparison,
        filterState: IMainViewFilterState,
        scheduleAreaItems: IMainViewFilterItemItem[],
        stageItems: IMainViewFilterItemItem[],
        dataToUpdate: IApplyMainFilterForQuoterInputData
    ): void {
        dataToUpdate.total = 0;

        switch (filterState.groupBy) {
            case 'area':
            case 'area_room':
                this.processForArea(nativeScheduleAreas, filterState, scheduleAreaItems, stageItems, dataToUpdate);
                break;
            case 'stage':
            case 'stage_room':
                this.processForStage(nativeComparison, filterState, scheduleAreaItems, stageItems, dataToUpdate);
                break;
        }
    }

    private processForArea(
        nativeScheduleAreas: IScheduleArea[],
        filterState: IMainViewFilterState,
        scheduleAreaItems: IMainViewFilterItemItem[],
        stageItems: IMainViewFilterItemItem[],
        dataToUpdate: IApplyMainFilterForQuoterInputData
    ): void {
        let selectedScheduleAreaIds = filterState.areaIds == null ? scheduleAreaItems.map(i => i.id) : filterState.areaIds;
        let selectedStageIds = filterState.stageIds == null ? stageItems.map(i => i.id) : filterState.stageIds;

        if (filterState.groupBy === 'area_room') {
            selectedScheduleAreaIds = [filterState.areaRoomId];
        }

        const sa = _.cloneDeep(nativeScheduleAreas);

        dataToUpdate.filteredScheduleAreas = _.filter(sa, area => {
            if (!selectedScheduleAreaIds?.length) {
                return false;
            }

            return selectedScheduleAreaIds.indexOf(area.id) !== -1;
        });

        _.each(dataToUpdate.filteredScheduleAreas, (area: IScheduleArea) => {
            area['comparison']['stageDTOs'] = _.filter(area['comparison']['stageDTOs'], (stageDTO: IComparisonStage) => {
                if (!selectedStageIds.length) {
                    return false;
                }

                return selectedStageIds.indexOf(stageDTO.id) !== -1;
            });

            area['comparison'].quoters[0].total = 0;
            area['comparison']['total'] = 0;

            _.each(area['comparison']['stageDTOs'], (stageDTO: IComparisonStage) => {
                stageDTO.totals[0] = 0;

                _.each(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                    _.each(elementDTO.taskDTOs, (taskDTO: IComparisonScheduleTask) => {
                        const additionalTotal = taskDTO.totals[0];

                        area['comparison'].quoters[0].total += additionalTotal;
                        area['comparison']['total'] += additionalTotal;
                        stageDTO.totals[0] += additionalTotal;
                        dataToUpdate.total += additionalTotal;
                    });
                });
            });
        });
    }

    private processForStage(
        nativeComparison: IComparison,
        filterState: IMainViewFilterState,
        scheduleAreaItems: IMainViewFilterItemItem[],
        stageItems: IMainViewFilterItemItem[],
        dataToUpdate: IApplyMainFilterForQuoterInputData
    ) {
        let selectedScheduleAreaIds = filterState.areaIds == null ? scheduleAreaItems.map(i => i.id) : filterState.areaIds;
        let selectedStageIds = filterState.stageIds == null ? stageItems.map(i => i.id) : filterState.stageIds;

        if (filterState.groupBy === 'stage_room') {
            selectedStageIds = [filterState.stageRoomId];
        }

        const comp = _.cloneDeep(nativeComparison);

        const stageComp: IComparison = {
            quoters: comp.quoters,
            stageDTOs: _.filter(comp.stageDTOs, stageDTO => {
                if (!selectedStageIds?.length) {
                    return false;
                }

                return selectedStageIds.indexOf(stageDTO.id) !== -1;
            })
        };

        _.each(stageComp.stageDTOs, (stageDTO: IComparisonStage) => {
            _.each(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                elementDTO.taskDTOs = _.filter(elementDTO.taskDTOs, (taskDTO: IComparisonScheduleTask) => {
                    return selectedScheduleAreaIds.length === 0 || _.indexOf(selectedScheduleAreaIds, taskDTO.scheduleAreaRootId) !== -1;
                });
            });

            stageDTO.elementDTOs = _.filter(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                return elementDTO.taskDTOs.length > 0;
            });
        });

        stageComp.stageDTOs = _.filter(stageComp.stageDTOs, (stageDTO: IComparisonStage) => {
            return stageDTO.elementDTOs.length > 0;
        });

        dataToUpdate.total = 0;

        _.each(stageComp.stageDTOs, stageDTO => {
            stageDTO.totals[0] = 0;

            _.each(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                _.each(elementDTO.taskDTOs, (taskDTO: IComparisonScheduleTask) => {
                    const additionalTotal = taskDTO.totals[0];

                    stageDTO.totals[0] += additionalTotal;
                    dataToUpdate.total += additionalTotal;
                });
            });
        });

        dataToUpdate.comparison = stageComp;
    }
}
