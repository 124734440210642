import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';

@Injectable({ providedIn: 'root' })
export class IsSchedulerProjectReadOnlyService {
    constructor() {}

    public isReadOnly(project: IProject): boolean {
        return !(project.isOwner || project.privilege === 'EDIT' || project.privilege === 'OWNER');
    }
}
