import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { PaymentProviderStripeService } from 'app/shared/dataservices/payment-provider-stripe.service';
import { PUBLIC_STRIPE_API_KEY } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class SetupStripeService {
    constructor(private paymentProviderStripeService: PaymentProviderStripeService) {}

    setup(projectId: number, total: number): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            this.paymentProviderStripeService.retrieveSessionId(projectId, total).subscribe(
                (sessionId: string) => {
                    window.open(
                        `stripe-redirect-to-checkout.html?sessionId=${sessionId}&publicStripeApiKey=${PUBLIC_STRIPE_API_KEY}`,
                        '_blank'
                    );
                    observer.next(sessionId);
                    observer.complete();
                },
                (error: any) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
}
