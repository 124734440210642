import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfessionListComponent } from 'app/entities/profession/list/profession-list.component';
import { ProfessionUpdateComponent } from 'app/entities/profession/edit/profession-update.component';
import { IProfession, Profession } from 'app/shared/model/profession.model';
import { ProfessionService } from 'app/shared/dataservices/profession.service';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class ProfessionResolve implements Resolve<IProfession> {
    constructor(private service: ProfessionService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profession> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<Profession>) => response.ok),
                map((profession: HttpResponse<Profession>) => profession.body)
            );
        }
        return of(new Profession());
    }
}

export const professionRoute: Routes = [
    {
        path: 'profession',
        component: ProfessionListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Professions'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'profession/new',
        component: ProfessionUpdateComponent,
        resolve: {
            profession: ProfessionResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Professions'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'profession/:id/edit',
        component: ProfessionUpdateComponent,
        resolve: {
            profession: ProfessionResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Professions'
        },
        canActivate: [UserRouteAccessService]
    }
];
