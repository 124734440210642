<div class="table-container">
    <div class="table-header">
        <div class="table-cell">Invoice</div>
        <div class="table-cell">Created Date</div>
        <div class="table-cell">Required Payment Date</div>
        <div class="table-cell">Status</div>
        <div class="table-cell">Total</div>
        <div class="table-cell">Remaining Balance</div>
        <div class="table-cell"></div>
    </div>

<ng-container *ngIf="store.valuationResponse">
    <div class="table-row" *ngFor="let valuationResponse of [store.valuationResponse]">
         <div class="table-cell">Valuation {{  valuationResponse.valuation.id  }}</div>
         <div class="table-cell">{{ valuationResponse.timestamp | date : "dd/MM/yy" }}</div>
         <div class="table-cell">2024-03-01</div>
         <div class="table-cell">Request for payment</div>
         <div class="table-cell">{{ valuationResponse.valuation.total | currency:'GBP'}}</div>
         <div class="table-cell">{{ valuationResponse.valuation.total | currency:'GBP'}}</div>
         <div class="table-cell">
             <button (click)="onCreateInvoiceClick()"
                     ngbTooltip="Create Invoice"
                     class="btn btn-primary btn-border-radius create-invoice"
                     type="button">
                 Create Invoice
             </button>
         </div>
     </div>
</ng-container>
</div>
