import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IConfirmModalData } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'bp-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['confirm-modal.scss']
})
export class ConfirmModalComponent  implements OnInit {
    protected data!: IConfirmModalData;

    protected doNotShowAgain: FormControl = new FormControl(false, [Validators.required]);
    protected dropdownControl: FormControl;

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        if (this.data.dropdownValues?.length) {
            this.dropdownControl = new FormControl<{id: number, value: string}>(this.data.dropdownValues[0], [Validators.required]);
        }
    }

    protected close(): void {
        this.activeModal.dismiss('close');
    }

    protected onCancelClick(): void {
        this.activeModal.dismiss('close');
    }

    protected onConfirm(): void {
        if (this.data.showDoNotShowAgain || this.data.dropdownValues?.length) {
            this.activeModal.close({ result: true, doNotShowAgain: this.doNotShowAgain.value, dropdownValue: this.dropdownControl?.value });
        } else {
            this.activeModal.close(true);
        }
    }
}
