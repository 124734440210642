import { Injectable } from '@angular/core';
import { ScriptService } from 'app/shared/services/script-service.service';
import { PaymentProviderGocardlessService } from 'app/shared/dataservices/payment-provider-gocardless.service';
import { iif, Observable, Observer, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

declare const GoCardlessClient: any;

@Injectable({ providedIn: 'root' })
export class SetupGocardlessService {
    constructor(private scriptService: ScriptService, private paymentProviderGocardlessService: PaymentProviderGocardlessService) {}

    setup(): Observable<any> {
        return new Observable((observer: Observer<void>) => {
            this.scriptService.load('gocardless').then(() => {
                this.handle().subscribe(
                    res => {
                        observer.next(res);
                        observer.complete();
                    },
                    (error: any) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
            });
        });
    }

    private handle(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.paymentProviderGocardlessService.retrieveGocardlessToken().subscribe((token: string) => {
                const gocardlessClient = GoCardlessClient({
                    token
                });

                let savedPayerAuthorisationId = null;

                gocardlessClient.create({
                    onSave: (payerAuthorisationId, confirmCallback, metadata) => {
                        console.log(`onSave: ${metadata}, payerAuthorisationId: ${payerAuthorisationId}`);

                        // 1. Optional: store the payerAuthorisationId server-side
                        // 2. Mandatory: call confirmCallback to let the Drop-in flow continue
                        savedPayerAuthorisationId = payerAuthorisationId;
                        confirmCallback();
                    },
                    onComplete: (metadata: any) => {
                        console.log(`onComplete: ${metadata}, savedPayerAuthorisationId : ${savedPayerAuthorisationId}`);

                        const retryPipeline =
                            // Still using retryWhen to handle errors
                            retryWhen(errors =>
                                errors.pipe(
                                    // Use concat map to keep the errors in order and make sure they
                                    // aren't executed in parallel
                                    concatMap((e, i) =>
                                        // Executes a conditional Observable depending on the result
                                        // of the first argument
                                        iif(
                                            () => i > 3,
                                            // If the condition is true we throw the error (the last error)
                                            throwError(e),
                                            // Otherwise we pipe this back into our stream and delay the retry
                                            of(e).pipe(delay(1000))
                                        )
                                    )
                                )
                            );

                        // The customer completed successfully, Drop-in flow completed!
                        this.paymentProviderGocardlessService
                            .sendGocardlessPayerAuthorisationId(savedPayerAuthorisationId)
                            .pipe(retryPipeline)
                            .subscribe(() => {
                                observer.next(metadata);
                                observer.complete();
                            });
                    },
                    onExit: (error, metadata) => {
                        console.log(`onExit: ${metadata}, error: ${error}`);

                        if (error === undefined) {
                            // The customer left intentionally the Drop-in flow
                            // (for example they closed the modal interface).
                            observer.error({});
                            observer.complete();
                        } else {
                            // The customer left due to unrecoverable error.
                            observer.error(error);
                            observer.complete();
                        }
                    }
                });
            });
        });
    }
}
