import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IAccount } from 'app/shared/model/account.model';
import { ProjectState } from 'app/shared/constants/project-states.constants';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({ providedIn: 'root' })
export class ProjectFilterStateStorageService {
    account: IAccount;

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity().then((account: any) => {
            this.account = account;
        });
    }

    store(value: ProjectState): void {
        this.webStorage.store(this.getLocalStorageKey(), value);
    }

    retrieve(): ProjectState {
        return this.webStorage.retrieve(this.getLocalStorageKey()) || 'all';
    }

    private getLocalStorageKey(): string {
        return `scheduler_${this.account.id}_projectFilterState`;
    }
}
