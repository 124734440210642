import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    SuccessfullySubmittedForAnalysisModalComponent
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/successfully-submitted-for-analysis-modal/successfully-submitted-for-analysis-modal.component';

@Injectable({ providedIn: 'root' })
export class SuccessfullySubmittedForAnalysisModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }

    showModal(): Promise<boolean> {
        return this.modalService.open(SuccessfullySubmittedForAnalysisModalComponent as Component, {
            windowClass: 'bp-modal successfully-submitted-for-analysis-modal',
            size: 'lg',
            backdrop: 'static'
        }).result.then((res: boolean) => {
            return res;
        });
    }
}
