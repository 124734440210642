<div class="schedule-task-row w-full flex flex-row flex-wrap gap-10 justify-content-between items-center">
    <div style="width: 35px"></div>
    <div class="flex-1">
        <bp-search-by-select
            (onSelectionChange)="onTaskSelectionChange($event)"
            (onSuggestionClick)="onTaskSelectionChange($event)"
            [disabled]="scheduleService.readOnly || scheduleService.inProcess()"
            *ngIf="taskSearchBySelectInputData"
            [data]="taskSearchBySelectInputData"
            [removeUnderlines]="false"
            class="select-task-id">
        </bp-search-by-select>
    </div>

    <div class="flex-1 explanations-data-line">
    </div>

    <div class="flex-0_5 explanations-data-line">
    </div>

    <div class="qti explanations-data-line">
    </div>

    <div class="totals font-12 flex flex-row justify-content-between items-center gap-10 explanations-data-line">
    </div>

    <div class="controls">
    </div>
</div>
