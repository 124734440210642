import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IComparisonQoter } from 'app/shared/model/comparison.model';
import { GetReportDataParams } from 'app/shared/dataservices/project.api';
import {
    IndividualQuoteReportService
} from 'app/shared/services/export/individual-quote/individual-quote-report.service';
import { IProject } from 'app/shared/model/project.model';
import { ComparisonReportService } from 'app/shared/services/export/comparison/comparison-report.service';
import {
    getActualAreaIds,
    getActualStageIds,
    IMainViewFilterItemItem,
    IMainViewFilterState,
    isAreaGrouping
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';

export interface IMainReportData {
    project: IProject;
    filteredScheduleAreas?: IScheduleArea[],
    filterState: IMainViewFilterState;
    scheduleAreaItems: IMainViewFilterItemItem[];
    stageItems: IMainViewFilterItemItem[];
    cssElementItems: IMainViewFilterItemItem[];
    buildUpItems: IMainViewFilterItemItem[];
}

@Injectable({
    providedIn: 'root'
})
export class ExportReportsService {
    constructor(
        private individualQuoteReportService: IndividualQuoteReportService,
        private comparisonReportService: ComparisonReportService
    ) {
    }

    exportAsExcelIndividualQuoter(mainReportData: IMainReportData, defaultQuoter: IComparisonQoter): Promise<void> {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(mainReportData.filterState),
            areaIds: getActualAreaIds(mainReportData.filterState, mainReportData.scheduleAreaItems),
            stageIds: getActualStageIds(mainReportData.filterState, mainReportData.stageItems),
            quoterIds: [defaultQuoter.id]
        };

        return this.individualQuoteReportService.exportAsExcel(mainReportData.project, defaultQuoter, query);
    }

    exportAsDocxIndividualQuoter(mainReportData: IMainReportData, defaultQuoter: IComparisonQoter): Promise<void> {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(mainReportData.filterState),
            areaIds: getActualAreaIds(mainReportData.filterState, mainReportData.scheduleAreaItems),
            stageIds: getActualStageIds(mainReportData.filterState, mainReportData.stageItems),
            quoterIds: [defaultQuoter.id]
        };

        return this.individualQuoteReportService.exportAsDocx(mainReportData.project, defaultQuoter, query);
    }

    exportAsPDFIndividualQuoter(mainReportData: IMainReportData, defaultQuoter: IComparisonQoter): Promise<void> {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(mainReportData.filterState),
            areaIds: getActualAreaIds(mainReportData.filterState, mainReportData.scheduleAreaItems),
            stageIds: getActualStageIds(mainReportData.filterState, mainReportData.stageItems),
            quoterIds: [defaultQuoter.id]
        };

        return this.individualQuoteReportService.exportAsPDF(mainReportData.project, defaultQuoter, query);
    }

    exportAsExcelComparison(mainReportData: IMainReportData, defaultQuoters: IComparisonQoter[]): void {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(mainReportData.filterState),
            includeMargin: false,
            areaIds: getActualAreaIds(mainReportData.filterState, mainReportData.scheduleAreaItems),
            stageIds: getActualStageIds(mainReportData.filterState, mainReportData.stageItems),
            quoterIds: _.map(defaultQuoters, 'id')
        };

        this.comparisonReportService.exportAsExcel(mainReportData.project, query);
    }

    exportAsDocxComparison(mainReportData: IMainReportData, defaultQuoters: IComparisonQoter[]): void {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(mainReportData.filterState),
            includeMargin: false,
            areaIds: getActualAreaIds(mainReportData.filterState, mainReportData.scheduleAreaItems),
            stageIds: getActualStageIds(mainReportData.filterState, mainReportData.stageItems),
            quoterIds: _.map(defaultQuoters, 'id')
        };

        this.comparisonReportService.exportAsDocx(mainReportData.project, query);
    }

    exportAsPDFComparison(mainReportData: IMainReportData, defaultQuoters: IComparisonQoter[]): void {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(mainReportData.filterState),
            includeMargin: false,
            areaIds: getActualAreaIds(mainReportData.filterState, mainReportData.scheduleAreaItems),
            stageIds: getActualStageIds(mainReportData.filterState, mainReportData.stageItems),
            quoterIds: _.map(defaultQuoters, 'id')
        };

        this.comparisonReportService.exportAsPDF(mainReportData.project, query);
    }
}
