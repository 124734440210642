import { ActivatedRouteSnapshot, Resolve, Route, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjectsComponent } from './projects/projects.component';
import { QuoteComponent } from './quote/quote.component';
import { LabourRatesComponent } from './labour-rates/labour-rates.component';
import { TaskLabourRatesComponent } from './task-labour-rates/task-labour-rates.component';
import { MaterialRatesComponent } from './material-rates/material-rates.component';
import { QuoteTaskDetailsComponent } from './quote-task-details/quote-task-details.component';
import { ViewProjectComponent } from 'app/flows/quoter/view-project/view-project.component';
import { CostPlanComponent } from 'app/flows/quoter/cost-plan/cost-plan.component';
import { IScheduleTask, ScheduleTask } from 'app/shared/model/schedule-task.model';
import { ScheduleTaskApi } from 'app/shared/dataservices/schedule-task.api';
import { IScheduleArea, ScheduleArea } from 'app/shared/model/schedule-area.model';
import { ScheduleAreaApi } from 'app/shared/dataservices/schedule-area.api';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { ProjectResolve } from 'app/shared/resolvers/project-resolve.service';
import { ValuationsPage } from 'app/flows/quoter/valuations/valuations.page';

@Injectable({ providedIn: 'root' })
export class ScheduleTaskResolve implements Resolve<IScheduleTask> {
    constructor(private service: ScheduleTaskApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleTask> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<ScheduleTask>) => response.ok),
                map((scheduleArea: HttpResponse<ScheduleTask>) => scheduleArea.body)
            );
        }
        return of(new ScheduleTask());
    }
}

@Injectable({ providedIn: 'root' })
export class ScheduleAreaResolve implements Resolve<IScheduleArea> {
    constructor(private service: ScheduleAreaApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleArea> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<ScheduleArea>) => response.ok),
                map((scheduleArea: HttpResponse<ScheduleArea>) => scheduleArea.body)
            );
        }
        return of(new ScheduleArea());
    }
}

export const QUOTER_ROUTE: Route = {
    path: 'quoter',
    data: {
        authorities: ['ROLE_QUOTER'],
        pageTitle: 'Quoter'
    },
    canActivate: [UserRouteAccessService],
    children: [
        {
            path: 'projects',
            component: ProjectsComponent,
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Projects'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'valuations/:project_id',
            component: ValuationsPage,
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Valuations'
            },
            resolve: {
                project: ProjectResolve
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'dashboard/:project_id',
            component: CostPlanComponent,
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Cost Plan'
            },
            resolve: {
                project: ProjectResolve
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'project-details/:project_id',
            component: ViewProjectComponent,
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Project details'
            },
            resolve: {
                project: ProjectResolve
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'quote/:project_id',
            component: QuoteComponent,
            resolve: {
                project: ProjectResolve
            },
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Quote'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'material-rates',
            component: MaterialRatesComponent,
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Material List'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'labour-rates',
            component: LabourRatesComponent,
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Labour Rates'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'task-labour-rates',
            component: TaskLabourRatesComponent,
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Task Rates'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'quote-task-details/:project_id/:id',
            component: QuoteTaskDetailsComponent,
            resolve: {
                project: ProjectResolve,
                scheduleTask: ScheduleTaskResolve
            },
            data: {
                authorities: ['ROLE_QUOTER'],
                pageTitle: 'Quote Task details'
            },
            canActivate: [UserRouteAccessService]
        }
    ]
};
