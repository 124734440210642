<div class="sign-up">
    <img alt=""
         class="sign-up__login-logo"
         src="../../../content/images/bp_logo.png"/>

    <div class="sign-up__blur">
        <form [formGroup]="form"
              (ngSubmit)="register()"
              class="sign-up__form form validate-form"
              name="form"
              role="form"
              autocomplete="off">
            <div class="sign-up__title text-center">
                Create your free account
            </div>

            <div class="flex flex-column gap-20 w-full">
                <div class="flex flex-column">
                    <label class="form-label">
                        Email Address
                    </label>
                    <input formControlName="email"
                           class="form-control"
                           email
                           id="email"
                           name="email"
                           autocomplete="false"
                           type="email">
                    <div *ngIf="form.controls.email.dirty && form.controls.email.invalid">
                        <small *ngIf="form.controls.email.errors.maxlength"
                               class="form-text text-danger">
                            Your email cannot be longer than 100 characters.
                        </small>
                    </div>
                </div>

                <div class="flex flex-column">
                    <div class="flex justify-content-between">
                        <label class="form-label">
                            Password
                        </label>
                        <div (click)="showPassword = !showPassword"
                             class="show-hide-password-btn">
                            <i class="material-icons" *ngIf="showPassword">visibility_off</i>
                            <i class="material-icons" *ngIf="!showPassword">visibility</i>
                            <span>{{ showPassword ? 'Hide' : 'Show' }}</span>
                        </div>
                    </div>
                    <input formControlName="password"
                           class="form-control"
                           id="password"
                           name="password"
                           [type]="showPassword ? 'text' : 'password'"
                           autocomplete="false">
                    <div *ngIf="form.controls.password.dirty && form.controls.password.invalid">
                        <small *ngIf="form.controls.password.errors.minlength"
                               class="form-text text-danger">
                            Your password is required to be at least 4 characters.
                        </small>
                        <small *ngIf="form.controls.password.errors.maxlength"
                               class="form-text text-danger">
                            Your password cannot be longer than 50 characters.
                        </small>
                        <small *ngIf="form.controls.password.errors.whitespace"
                               class="form-text text-danger">
                            Your password must not contain whitespaces.
                        </small>
                    </div>
                    <bp-password-strength-bar
                        *ngIf="false"
                        [passwordToCheck]="form.controls.password.value">
                    </bp-password-strength-bar>
                </div>
            </div>

            <div class="sign-up__agree-license-container">
                By signing up you agree to BuildPartner's <a [href]="TERMS_OF_USE_URL"
                                                             target="_blank"
                                                             rel="noopener"
                                                             class="link">Terms
                of Services</a> and <a [href]="PRIVACY_POLICY_URL"
                                       target="_blank"
                                       rel="noopener" class="link">Privacy Policy</a>
            </div>

            <div class="flex flex-column align-items-center gap-20">
                <button class="sign-up__sign-up btn-primary btn-border-radius w-full"
                        [disabled]="form?.invalid || !defaultRegion"
                        type="submit">
                    Sign Up
                </button>

                <div class="flex gap-40 align-items-center w-full">
                    <div class="flex-1 border-bottom"></div>
                    <div>or</div>
                    <div class="flex-1 border-bottom"></div>
                </div>

                <button (click)="signInWithGoogle()"
                        class="sign-up__google google-auth w-full"
                        type="button">
                    <img alt="Google" height="24" src="/content/images/google.svg">
                    Sign up with Google
                </button>

                <div class="m-t-10">
                    <span class="link-title m-r-5">Already have an account?</span>
                    <a (click)="login()"
                       class="link underline"
                       href="javascript:void(0);">Sign in
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
