<div class="loader-wrapper do-now-show-background"
     *ngIf="!showBackground">
    <div class="backdrop" [style.backgroundColor]="backdropColor"></div>
    <div class="spinner" [style.border-top]="'4px solid ' + loaderColor"></div>
    <div *ngIf="showText"
         class="loader-text">
        {{ loaderText }}
    </div>
</div>

<div class="loader-wrapper show-background"
     [style.background-image]="'url(' + '/content/images/loading/' + backgroundImage + ')'"
     *ngIf="showBackground">
</div>
