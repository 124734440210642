import { Component, Input } from '@angular/core';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';
import {
    ProjectCommentsModalService
} from 'app/shared/components/common/comments-modal/project-comments-modal.service';
import { QuoteStore } from 'app/flows/quoter/quote/stores/quote.store';

@Component({
    selector: '[bpQuoteScheduleTask]',
    templateUrl: './schedule-task.component.html',
    styleUrls: ['schedule-task.scss']
})
export class QuoteScheduleTaskComponent {
    @Input() stage: IComparisonStage;
    @Input() element: IComparisonElement;
    @Input() scheduleTask: IComparisonScheduleTask;
    @Input() className: string;
    @Input() showHistoryView: boolean;

    constructor(
        private commentsModalService: ProjectCommentsModalService,
        protected store: QuoteStore
    ) {
    }

    protected thClassName() {
        return this.showHistoryView ? this.className.toLowerCase() : '';
    }

    protected onCommentsClick(event: any): void {
        event.preventDefault();
        this.commentsModalService.open(this.store.project, this.scheduleTask?.rootId);
    }
}
