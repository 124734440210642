import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IProject } from 'app/shared/model/project.model';
import { OptInPayModalComponent } from 'app/flows/quoter/components/opt-in-pay-modal/opt-in-pay-modal.component';

@Injectable({ providedIn: 'root' })
export class OptInPayModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    open(project: IProject): NgbModalRef {
        this.ngbModalRef = this.modalService.open(OptInPayModalComponent as Component, {
            windowClass: 'bp-modal opt-in-pay',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
