import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BPStore {

    private dataRetrievedSubject = new BehaviorSubject<boolean>(false);
    dataRetrieved$ = this.dataRetrievedSubject.asObservable();

    get dataRetrieved(): boolean {
        return this.dataRetrievedSubject.value;
    }

    set dataRetrieved(value: boolean) {
        this.dataRetrievedSubject.next(value);
    }
}
