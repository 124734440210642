import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment, { Moment } from 'moment';
import { NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerService } from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-service";

export interface DatePickerOnDateSelectEvent {
    newDate: Moment;
}

@Component({
    selector: 'bp-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['date-picker.scss']
})
export class DatePickerComponent implements OnInit {
    @Input() date: Moment | null;
    @Input() disabled = false;
    @Input() placeholder = 'Select Date';
    @Output() onDateChanged = new EventEmitter<DatePickerOnDateSelectEvent>();

    protected visible = false;

    @ViewChild(NgbDatepicker)
    protected datepicker;

    ngOnInit(): void {
    }

    onClick(event): void {
        event.stopPropagation();

        if (this.date != null) {
            const year = this.date.year();
            const month = this.date.month() + 1;
            const day = this.date.date();

            this.datepicker.navigateTo({ year, month, day });
        }

        this.visible = !this.visible;
    }

    onDateSelect(event): void {
        this.date = moment(event.year + '-' + event.month + '-' + event.day, 'YYYY-MM-DD');
        this.visible = false;

        if (this.onDateChanged) {
            this.onDateChanged.emit({ newDate: this.date });
        }
    }

    // listen to document click event and handle closeDponOutsideClick event
    @HostListener('document:click', ['$event'])
    closeDpOnOutsideClick(event: any): void {
        if (!this.visible) {
            return;
        }

        if (event.target.offsetParent !== null && event.target.offsetParent.tagName !== 'NGB-DATEPICKER') {
            this.visible = false;
        }
    }

    reset(): void {
        (this.datepicker['_service'] as NgbDatepickerService).select(new NgbDate(2000, 1, 1));
        this.datepicker.date = null;
        this.onDateChanged.emit({ newDate: null });
    }
}
