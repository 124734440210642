import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import {
    ComparisonExpandStagesStorageService
} from 'app/flows/scheduler/comparison/services/expand-stages-storage.service';
import { ComparisonStore } from 'app/flows/scheduler/comparison/comparison.store';

@Component({
    selector: 'bp-comparison-stage',
    templateUrl: './stage.component.html',
    styleUrls: ['stage.scss']
})
export class ComparisonStageComponent implements OnChanges {
    @Input() stage: IComparisonStage;
    @Input() showGroupNode = true;

    constructor(
        public comparisonStore: ComparisonStore,
        private expandStagesStorageService: ComparisonExpandStagesStorageService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.expanded != null &&
            !changes.expanded.firstChange &&
            !_.isEqual(changes.expanded.currentValue, changes.expanded.previousValue)
        ) {
            if (changes.expanded.currentValue) {
                this.expand();
            } else {
                this.collapse();
            }
        }
    }

    toggleExpand(): void {
        this.stage.expanded ? this.collapse() : this.expand();
    }

    private expand(): void {
        this.stage.expanded = true;
        this.expandStagesStorageService.store(this.comparisonStore.project, this.stage, true);
    }

    private collapse(): void {
        this.stage.expanded = false;
        this.expandStagesStorageService.store(this.comparisonStore.project, this.stage, false);
    }
}
