<div class="chart"
     [ngStyle]="{height: '330px'}">

    <canvas baseChart
            id="canvas-id"
            [data]="doughnutChartData"
            [type]="'doughnut'"
            [options]="doughnutChartOptions">
    </canvas>
</div>
