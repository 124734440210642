<div class="project-nav d-none d-md-inline-flex">
    <div class="project-nav__item"
         *ngFor="let item of visibleItems"
         [ngClass]="{'active': itemActive(item), 'checked': itemChecked(item), 'disabled': itemDisabled(item)}"
         (click)="navigate(item)">
        <fa-icon
            [icon]="icon(item)">
        </fa-icon>
        <span class="m-l-5">{{ item.title }}</span>
    </div>
</div>

<div class="project-nav-mobile d-md-none">
    <a class="project-nav-mobile__nav-link"
       *ngFor="let item of visibleItems"
       [ngClass]="{'active': itemActive(item), 'checked': itemChecked(item), 'disabled': itemDisabled(item)}"
       (click)="navigate(item)">
        <fa-icon
            [icon]="icon(item)">
        </fa-icon>
        <span class="m-l-5">{{ item.title }}</span>
    </a>
</div>

