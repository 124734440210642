<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-8">
            <div *ngIf="trade">
                <h1>
                    <span>Trade</span> {{trade.id}}
                </h1>
                <hr>
                <dl class="row-md jh-entity-details">
                    <dt><span>Trade</span></dt>
                    <dd>
                        <span>{{trade.trade}}</span>
                    </dd>
                </dl>

                <button (click)="previousState()"
                        class="btn btn-info"
                        type="submit">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon><span> Back</span>
                </button>

                <button [routerLink]="['/trade', trade.id, 'edit']"
                        class="btn btn-primary"
                        type="button">
                    <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>&nbsp;<span> Edit</span>
                </button>
            </div>
        </div>
    </div>
</div>
