import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { IBuildUp } from 'app/shared/model/bp.model';
import { BuildUpDeletePopupComponent } from 'app/entities/build-up/delete/build-up-delete-dialog.component';
import { BuildUpListComponent } from 'app/entities/build-up/list/build-up-list.component';
import { BuildUpUpdateComponent } from 'app/entities/build-up/edit/build-up-update.component';
import { BuildUpApi } from 'app/shared/dataservices/build-up.api';

@Injectable({ providedIn: 'root' })
export class BuildUpResolve implements Resolve<IBuildUp> {
    constructor(private api: BuildUpApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBuildUp> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.api.find(id).pipe(
                filter((response: HttpResponse<IBuildUp>) => response.ok),
                map((buildUp: HttpResponse<IBuildUp>) => buildUp.body)
            );
        }
        return of({});
    }
}

export const buildUpRoute: Routes = [
    {
        path: 'build-up',
        component: BuildUpListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Ups'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'build-up/new',
        component: BuildUpUpdateComponent,
        resolve: {
            buildUp: BuildUpResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Ups'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'build-up/:id/edit',
        component: BuildUpUpdateComponent,
        resolve: {
            buildUp: BuildUpResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Ups'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const buildUpPopupRoute: Routes = [
    {
        path: 'build-up/:id/delete',
        component: BuildUpDeletePopupComponent,
        resolve: {
            buildUp: BuildUpResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Ups'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
