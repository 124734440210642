import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    AddMaterialCategoryModalComponent
} from 'app/shared/components/common/add-material-category-modal/add-material-category-modal.component';

@Injectable({ providedIn: 'root' })
export class AddMaterialCategoryModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(materialCategoryName: string): NgbModalRef {
        this.ngbModalRef = this.modalService.open(AddMaterialCategoryModalComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.materialCategoryName = materialCategoryName;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
