import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStage } from 'app/shared/model/stage.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { StageService } from 'app/shared/dataservices/stage.service';

@Component({
    selector: 'bp-stage-update',
    templateUrl: './stage-update.component.html'
})
export class StageUpdateComponent implements OnInit {
    stage: IStage;
    isSaving: boolean;

    scheduleareas: IScheduleArea[];

    constructor(private stageService: StageService, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ stage }) => {
            this.stage = stage;
        });
    }

    previousState() {
        window.history.back();
    }

    save() {
        this.isSaving = true;
        if (this.stage.id !== undefined) {
            this.subscribeToSaveResponse(this.stageService.update(this.stage));
        } else {
            this.subscribeToSaveResponse(this.stageService.create(this.stage));
        }
    }

    trackScheduleAreaById(index: number, item: IScheduleArea) {
        return item.id;
    }

    getSelected(selectedVals: Array<any>, option: any) {
        if (selectedVals) {
            for (let i = 0; i < selectedVals.length; i++) {
                if (option.id === selectedVals[i].id) {
                    return selectedVals[i];
                }
            }
        }
        return option;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IStage>>) {
        result.subscribe(
            (res: HttpResponse<IStage>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
