import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UnitUpdateComponent } from 'app/entities/unit/edit/unit-update.component';
import { UnitListComponent } from 'app/entities/unit/list/unit-list.component';
import { IUnit, Unit } from 'app/shared/model/unit.model';
import { UnitService } from 'app/shared/dataservices/unit.service';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class UnitResolve implements Resolve<IUnit> {
    constructor(private service: UnitService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Unit> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<Unit>) => response.ok),
                map((unit: HttpResponse<Unit>) => unit.body)
            );
        }
        return of(new Unit());
    }
}

export const unitRoute: Routes = [
    {
        path: 'unit',
        component: UnitListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Units'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'unit/new',
        component: UnitUpdateComponent,
        resolve: {
            unit: UnitResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Units'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'unit/:id/edit',
        component: UnitUpdateComponent,
        resolve: {
            unit: UnitResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Units'
        },
        canActivate: [UserRouteAccessService]
    }
];
