<form (ngSubmit)="addBuildUp()"
      #addBuildUpForm="ngForm"
      name="addBuildUpForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Add Build Up
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body m-b-20">
        <div class="form-group">
            <label class="form-control-label"
                   for="build-up-category">
                Name
            </label>
            <input [(ngModel)]="buildUpName"
                   class="af-input"
                   id="build-up-name"
                   name="build-up-name"
                   placeholder="Enter build up name"
                   type="text"/>
        </div>

        <div class="form-group">
            <label class="form-control-label"
                   for="build-up-category">
                Category
            </label>
            <select [(ngModel)]="categoryId"
                    class="form-control"
                    id="build-up-category"
                    required
                    name="category">
                <option [ngValue]="null"></option>
                <option *ngFor="let category of buildUpCategories; trackBy: trackById"
                        [ngValue]="category.id">
                    {{ category.name }}
                </option>
            </select>
            <div [hidden]="!(addBuildUpForm.controls.category?.dirty && addBuildUpForm.controls.category?.invalid)">
                <small [hidden]="!addBuildUpForm.controls.category?.errors?.required"
                       class="form-text text-danger">
                    This field is required.
                </small>
            </div>
        </div>

        <div class="form-group">
            <label class="form-control-label"
                   for="field_build-up-order">
                Build Up Order
            </label>
            <input [(ngModel)]="order"
                   class="form-control"
                   id="field_build-up-order"
                   name="build-up-order"
                   type="number"/>
        </div>

        <div class="modal-footer">
            <button (click)="clear()"
                    class="btn btn-secondary btn-border-radius"
                    data-dismiss="modal"
                    type="button">
                Cancel
            </button>
            <button [disabled]="isFormInvalid()"
                    class="btn btn-primary btn-border-radius waves-effect add-build-up"
                    type="submit">
                Add Build Up
            </button>
        </div>
    </div>
</form>
