import * as _ from 'lodash';

const HOURS_PER_DAY = 8;

export interface IEndpointLabour {
    id?: number;
    trade?: string;
    tradeId?: number;
    cost?: number; // per h
    margin?: number;
    rate?: number; // per h
}

export interface IBuilderLabour {
    id?: number;
    trade?: string;
    tradeId?: number;
    cost?: number; // per h
    costPerDay?: number;
    margin?: number;
    rate?: number; // per h
    ratePerDay?: number;

    endpointData(): IEndpointLabour;
}

export class BuilderLabour implements IBuilderLabour {
    private _id?: number;
    private _trade?: string;
    private _tradeId?: number;
    private _cost?: number;
    private _costPerDay?: number;
    private _margin?: number;
    private _rate?: number;
    private _ratePerDay?: number;

    private _updated?: boolean;

    constructor(labour: IEndpointLabour) {
        this.id = labour.id;
        this.trade = labour.trade;
        this.tradeId = labour.tradeId;
        this.cost = labour.cost;
        this.margin = Math.round(labour.margin * 100 * 100) / 100;
        this.rate = labour.rate;
        this._updated = false;
    }

    get updated(): boolean {
        return this._updated;
    }

    set updated(value: boolean) {
        this._updated = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
        this._updated = true;
    }

    get trade(): string {
        return this._trade;
    }

    set trade(value: string) {
        this._trade = value;
        this._updated = true;
    }

    get tradeId(): number {
        return this._tradeId;
    }

    set tradeId(value: number) {
        this._tradeId = value;
        this._updated = true;
    }

    get cost(): number {
        return this._cost;
    }

    set cost(value: number) {
        this._cost = value;
        this._costPerDay = this._cost * HOURS_PER_DAY;
        this.updateRate();
        this._updated = true;
    }

    get costPerDay(): number {
        return this._costPerDay;
    }

    set costPerDay(value: number) {
        this._costPerDay = value;
        this._cost = this._costPerDay / HOURS_PER_DAY;
        this.updateRate();
        this._updated = true;
    }

    get margin(): number {
        return this._margin;
    }

    set margin(value: number) {
        this._margin = value;
        this.updateRate();
        this._updated = true;
    }

    get rate(): number {
        return this._rate;
    }

    set rate(value: number) {
        this._rate = value;
        this._updated = true;
    }

    get ratePerDay(): number {
        return this._ratePerDay;
    }

    set ratePerDay(value: number) {
        this._ratePerDay = value;
        this._updated = true;
    }

    endpointData(): IEndpointLabour {
        return {
            id: this.id,
            trade: this.trade,
            tradeId: this.tradeId,
            cost: this.cost,
            margin: this.margin / 100,
            rate: this.rate
        };
    }

    private updateRate(): void {
        const newRate = this._cost * (1 + this._margin / 100);
        this._rate = _.round(newRate, 2);
        this._ratePerDay = _.round(newRate * HOURS_PER_DAY, 2);
    }
}
