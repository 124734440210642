import { ILabourComponent } from './labour-component.model';
import { IActualLabourComponent } from './actual-labour-component.model';
import { IBuilderLabour } from './builder-labour.model';

export interface ITrade {
    id?: number;
    trade?: string;
    archive?: boolean;
    labourComponents?: ILabourComponent[];
    actualLabourComponents?: IActualLabourComponent[];
    builderLabours?: IBuilderLabour[];
}

export class Trade implements ITrade {
    constructor(
        public id?: number,
        public trade?: string,
        public archive?: boolean,
        public labourComponents?: ILabourComponent[],
        public actualLabourComponents?: IActualLabourComponent[],
        public builderLabours?: IBuilderLabour[]
    ) {}
}
