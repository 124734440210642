import { ITask } from './task.model';
import { IMaterial } from './material.model';

export interface IMaterialComponent {
    id?: number;
    quantity?: number;
    task?: ITask;
    material?: IMaterial;
}

export class MaterialComponent implements IMaterialComponent {
    constructor(public id?: number, public quantity?: number, public task?: ITask, public material?: IMaterial) {}
}
