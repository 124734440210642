import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISuccessModalData } from 'app/shared/components/common/success-modal/success-modal.service';

@Component({
    selector: 'bp-success-modal',
    templateUrl: './success-modal.component.html',
    styleUrls: ['success-modal.scss']
})
export class SuccessModalComponent {
    data!: ISuccessModalData;

    constructor(private activeModal: NgbActiveModal) {
    }

    close(): void {
        this.activeModal.dismiss('close');
    }
}
