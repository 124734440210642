<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-build-up-category-heading">
                    Create or edit a Build Up Category
                </h1>
                <div>
                    <div [hidden]="!item.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="item.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_name">
                            Name
                        </label>
                        <input [(ngModel)]="item.name"
                               class="form-control"
                               id="field_name"
                               name="name"
                               required type="text"/>
                        <div [hidden]="!(editForm.controls.name?.dirty && editForm.controls.name?.invalid)">
                            <small [hidden]="!editForm.controls.name?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_build-up-category-order">
                            Build Up Category Order
                        </label>
                        <input [(ngModel)]="item.order"
                               class="form-control"
                               id="field_build-up-category-order"
                               name="build-up-category-order"
                               type="number"/>
                    </div>

                    <div class="form-group">
                        <div class="form-check">
                            <label class="form-check-label"
                                   for="archive">
                                Archive
                                <input [(ngModel)]="item.archive"
                                       class="form-check-input"
                                       id="archive"
                                       name="archive"
                                       type="checkbox">
                                <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
