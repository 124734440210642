<div class="m-t-30">
    <div *ngIf="templates.length === 0"
         class="text-center">
        No areas to select
    </div>

    <div class="templates-container">
        <bp-schedule-template-card
            *ngFor="let template of templates; trackBy: trackId"
            [template]="template">
        </bp-schedule-template-card>

        <div class="new-request-card-container">
            <bp-request-new-card>
            </bp-request-new-card>
        </div>
    </div>
</div>
