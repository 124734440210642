import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class BpAlertService {
    info(_title: string, delay = 3000) {
        this.alert(_title, delay, 'info');
    }

    success(_title: string, delay = 3000) {
        this.alert(_title, delay, 'success');
    }

    warning(_title: string, delay = 3000) {
        this.alert(_title, delay, 'warning');
    }

    error(_title: string, delay = 5000) {
        this.alert(_title, delay, 'error');
    }

    question(_title: string, delay = 5000) {
        this.alert(_title, delay, 'question');
    }

    infoModal(_title: string, message: string) {
        Swal.fire(_title, message, 'info');
    }

    private alert(_title: string, delay = 3000, icon: SweetAlertIcon = 'info') {
        Swal.fire({
            title: _title,
            toast: true,
            icon,
            timer: delay,
            position: 'top',
            showConfirmButton: false,
            showCancelButton: false
        });
    }
}
