// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application
import { environment } from '../../../environments/environment';

export const DEBUG_INFO_ENABLED: boolean = environment.env === 'local' || environment.env === 'dev';
export const SERVER_API_URL = environment.server_api_url;
export const SERVER_BUILD_VERSION = environment.server_build_version;
export const BUILD_TIMESTAMP = `'${new Date().getTime()}'`;
export const ENVIRONMENT = environment.env;
export const GOCARDLES_SCRIPT = environment.gocardles_script;
export const PUBLIC_STRIPE_API_KEY = environment.public_stripe_api_key;
