import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IElement } from 'app/shared/model/element.model';
import { IStage } from 'app/shared/model/stage.model';
import { ElementService } from 'app/shared/dataservices/element.service';
import { StageService } from 'app/shared/dataservices/stage.service';
import { EntityElementService } from 'app/entities/element/element.service';

@Component({
    selector: 'bp-element-update',
    templateUrl: './element-update.component.html'
})
export class ElementUpdateComponent implements OnInit {
    element: IElement;
    isSaving: boolean;

    stages: IStage[];

    constructor(
        private elementService: ElementService,
        private stageService: StageService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ element }) => {
            this.element = element;
        });
        this.stageService.query().subscribe(
            (res: HttpResponse<IStage[]>) => {
                this.stages = res.body;
            }
        );
    }

    previousState(): void {
        window.history.back();
    }

    save(): void {
        this.isSaving = true;
        if (this.element.id !== undefined) {
            this.subscribeToSaveResponse(this.elementService.update(this.element));
        } else {
            this.subscribeToSaveResponse(this.elementService.create(this.element));
        }
    }

    trackStageById(index: number, item: IStage): number {
        return item.id;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IElement>>) {
        result.subscribe(
            (res: HttpResponse<IElement>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
