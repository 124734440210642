<div class="container-fluid">
    <ng-container *ngIf="projectCount == null || inProcess">
        <div class="text-center">
            Please wait...
        </div>
    </ng-container>

    <ng-container *ngIf="projectCount != null && !inProcess">
        <div class="m-b-35">
            <div class="row">
                <div class="col-lg-4 display-flex">
                    <bp-select-input
                        *ngIf="projectStateSelectInputData"
                        (onSelectionChange)="onChangeProjectState($event)"
                        [data]="projectStateSelectInputData"
                        [removeUnderlines]="false"
                        class="select-archived-live-project-select m-r-20">
                    </bp-select-input>

                    <input [formControl]="searchControl"
                           class="form-control v2"
                           placeholder="Search"
                           id="search"
                           name="search"
                           required
                           type="text">
                </div>

                <div class="col-lg-4 text-center projects-label-container">
                    <h1 class="m-b-0">
                        Projects
                    </h1>
                </div>
            </div>

            <div class="projects-list-container">
                <bp-project-list
                    *ngIf="projectCount > 0"
                    [showAddNewProjectCard]="false">
                </bp-project-list>
            </div>
        </div>
    </ng-container>
</div>
