import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IAccount } from 'app/shared/model/account.model';
import { PaymentProvider } from 'app/shared/model/payment-provider.model';
import { SetupGocardlessService } from 'app/shared/services/payment/setup-gocardless.service';
import { SetupStripeService } from 'app/shared/services/payment/setup-stripe.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AccountService } from 'app/core/auth/account.service';

@Component({
    selector: 'bp-select-payment-provider',
    templateUrl: './select-payment-provider.component.html',
    styleUrls: ['select-payment-provider.scss']
})
export class SelectPaymentProviderComponent implements OnInit {
    @Input() account: IAccount;

    selectedPaymentProvider: PaymentProvider;
    isSelectedPaymentProviderSetup = false;

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    constructor(
        private setupGocardlessService: SetupGocardlessService,
        private setupStripeService: SetupStripeService,
        private accountService: AccountService
    ) {}

    ngOnInit(): void {
        this.refresh();
    }

    onChangeSelectedPaymentProvider(): void {
        this.isSelectedPaymentProviderSetup = this.isPaymentProviderSetup(this.selectedPaymentProvider);
    }

    onSetupButtonClick(): void {
        switch (this.selectedPaymentProvider) {
            case 'GOCARDLESS':
                this.setupGocardlessService.setup().subscribe((res: any) => {
                    console.log(res);
                    this.accountService.identity(true).then(account => {
                        this.account = account;
                        this.refresh();
                    });
                });
                break;
            case 'STRIPE':
                this.setupStripeService.setup(null, null);
                break;
            default:
                console.log(`Unknown payment provider: ${this.selectedPaymentProvider}`);
                break;
        }
    }

    isPaymentProviderSetup(paymentProvider: PaymentProvider): boolean {
        return (this.account.paymentProviders || []).includes(paymentProvider);
    }

    private refresh(): void {
        if (this.selectedPaymentProvider != null) {
            this.isSelectedPaymentProviderSetup = this.isPaymentProviderSetup(this.selectedPaymentProvider);
        }

        if (this.ngSelectComponent) {
            this.ngSelectComponent.detectChanges();
        }
    }
}
