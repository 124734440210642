import { Routes } from '@angular/router';
import { signUpRoute } from './sign-up/sign-up.route';
import { createQuoterFromInvitationRoute } from './create-quoter-from-invitation/create-quoter-from-invitation.route';
import { quoterInvitationValidatorRoute } from './quoter-invitation-validator/quoter-invitation-validator.route';
import { logoutRoute } from 'app/shared/logout/logout.route';
import { stripePaymentResultRoute } from 'app/shared/stripe-payment-result/stripe-payment-result.route';
import { teamInvitationValidatorRoute } from 'app/account/team-invitation-validator/team-invitation-validator.route';
import { loginRoute } from 'app/shared/login/login.route';
import { passwordRoute } from 'app/account/password/password.route';
import { passwordResetFinishRoute } from 'app/account/password-reset/finish/password-reset-finish.route';
import { registerRoute } from 'app/account/register/register.route';
import { passwordResetInitRoute } from 'app/account/password-reset/init/password-reset-init.route';
import { settingsRoute } from 'app/account/settings/settings.route';
import { exportSettingsRoute } from 'app/account/export-settings/export-settings.route';
import { signUpLightRoute } from 'app/account/sign-up-light/sign-up-light.route';
import { signUpStripeRoute } from 'app/account/sign-up-stripe/sign-up-stripe.route';
import { quoterInvitationDeclinerRoute } from 'app/account/quoter-invitation-decliner/quoter-invitation-decliner.route';
import { signUpTrackingPageRoute } from 'app/account/sign-up-tracking-page/sign-up-tracking-page.route';
import { systemUpgradesRoute } from 'app/account/system-upgrades/system-upgrades.route';
import { homeownerRoute } from 'app/account/homeowner/homeowner.route';

const ACCOUNT_ROUTES = [
    passwordRoute,
    passwordResetFinishRoute,
    passwordResetInitRoute,
    registerRoute,
    settingsRoute,
    exportSettingsRoute,
    ...loginRoute,
    logoutRoute,
    signUpRoute,
    signUpLightRoute,
    signUpStripeRoute,
    createQuoterFromInvitationRoute,
    quoterInvitationValidatorRoute,
    quoterInvitationDeclinerRoute,
    teamInvitationValidatorRoute,
    stripePaymentResultRoute,
    signUpTrackingPageRoute,
    systemUpgradesRoute,
    homeownerRoute
];
export const accountState: Routes = [
    {
        path: '',
        children: ACCOUNT_ROUTES
    }
];
