<div class="container-fluid">
    <div class="card">
        <div class="header">
            <div class="row">
                <div class="col-lg-5 left">
                    <h1 class="profession-label">
                        Professions
                    </h1>
                </div>
                <div class="col-lg-7 right">
                    <input [formControl]="searchControl"
                           class="af-input"
                           id="profession-prefix"
                           name="profession-prefix"
                           placeholder="Search profession"
                           type="text"/>
                    <button [routerLink]="['/profession/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-profession pull-right">
                        Create new Profession
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="professions"
             class="body table-responsive">
            <table class="table table-striped">
                <thead>
                <tr [(ascending)]="reverse"
                    [(predicate)]="predicate"
                    [callback]="reset.bind(this)"
                    bpSort>
                    <th bpSortBy="id">
                        <span>ID</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="name">
                        <span>NAME</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let profession of professions; trackBy: trackId">
                    <td>
                        <a [routerLink]="['/profession', profession.id, 'view' ]">
                            {{ profession.id }}
                        </a>
                    </td>
                    <td>
                        {{ profession.name }}
                    </td>
                    <td class="text-right"
                        style="min-width: 180px">
                        <div class="bp-btn-group"
                             role="group">
                            <button [routerLink]="['/profession', profession.id, 'edit']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button (click)="onDeleteClick(profession)"
                                    class="btn btn-danger waves-effect"
                                    type="submit">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr [hidden]="!hasMore()"
                    class="bg-white">
                    <td class="text-center"
                        colspan="1000">
                        <button (click)="loadPage(page + 1)"
                                class="btn btn-light">
                            More
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
