<div class="container-fluid">
    <div class="card">
        <div class="header">
            <div class="row">
                <div class="col-lg-6 left">
                    <h1 class="region-label">
                        Regions
                    </h1>
                </div>
                <div class="col-sm-6">
                    <button [routerLink]="['/region/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-unit pull-right">
                        Create new Region
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="regions"
             class="body table-responsive">
            <table class="table table-striped">
                <thead>
                <tr [(ascending)]="reverse"
                    [(predicate)]="predicate"
                    [callback]="reset.bind(this)"
                    bpSort>
                    <th bpSortBy="id">
                        <span>ID</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="name">
                        <span>Region</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let region of regions; trackBy: trackId">
                    <td>
                        <a [routerLink]="['/region', region.id, 'view' ]">
                            {{ region.id }}
                        </a>
                    </td>
                    <td>
                        {{ region.region }}
                    </td>
                    <td class="text-right"
                        style="min-width: 180px">
                        <div class="bp-btn-group"
                             role="group">
                            <button [routerLink]="['/region', region.id, 'edit']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button
                                (click)="onDeleteClick(region)"
                                class="btn btn-danger waves-effect"
                                type="submit">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr [hidden]="!hasMore()"
                    class="bg-white">
                    <td class="text-center"
                        colspan="1000">
                        <button (click)="loadPage(page + 1)"
                                class="btn btn-light">
                            More
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


