import { Injectable } from '@angular/core';
import { IQuoterTaskCost, IScheduleTask } from 'app/shared/model/schedule-task.model';
import { QuoterDefaultPricesService } from 'app/shared/dataservices/quoter-default-prices.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FillTaskTotalForScheduleTaskService {
    constructor(private quoterDefaultPricesService: QuoterDefaultPricesService) {
    }

    fill(scheduleTask: IScheduleTask, defaultQuoterId: number): Promise<IQuoterTaskCost> {
        return new Promise((resolve) => {
            this.quoterDefaultPricesService
                .queryTaskCost(
                    defaultQuoterId,
                    scheduleTask.taskId,
                    scheduleTask.ratio,
                    scheduleTask.unitValue,
                    scheduleTask.primeMaterialId,
                    scheduleTask.id,
                    scheduleTask.actualMaterialComponents?.length ? !scheduleTask.actualMaterialComponents[0].clientSupplied : null,
                    (scheduleTask.actualMaterialComponents?.length && !scheduleTask.actualMaterialComponents[0].clientSupplied) ? scheduleTask.actualMaterialComponents[0].cost : null
                )
                .subscribe((res: HttpResponse<IQuoterTaskCost>) => {
                    scheduleTask.taskTotal = res.body;
                    resolve(scheduleTask.taskTotal)
                });
        });
    }
}
