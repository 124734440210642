import { Injectable } from '@angular/core';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { IProject } from 'app/shared/model/project.model';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, Observer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IStage } from 'app/shared/model/stage.model';
import {
    IOverviewProjectChartData
} from 'app/shared/components/projects/project-details-cost-visualization/overview-project-chart.service';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import * as _ from 'lodash';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import { ILabelValue } from 'app/shared/components/charts/pie-chart/cost-plan-chart.component';
import { IAccount } from 'app/shared/model/account.model';
import { AccountService } from 'app/core/auth/account.service';
import { CostPlanMode } from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.interfaces';

type ICostPlanMode = 'areas' | 'stages';

export interface ICostPlanValueColumn {
    quoter: IComparisonQoter;
    quoterTotal: number;
    costPerSqm: number;
    data: {
        item: IMainViewFilterItemItem,
        total: number;
    }[];
}

@Injectable({ providedIn: 'root' })
export class CostPlanService {
    project: IProject;

    nativeScheduleAreas: IScheduleArea[] = [];

    private _costPlanMode: CostPlanMode = 'pie-chart-view';

    set costPlanMode(value: CostPlanMode) {
        this._costPlanMode = value;
        this.update();
    }

    get costPlanMode(): CostPlanMode {
        return this._costPlanMode;
    }

    filterStateSubject: BehaviorSubject<IMainViewFilterState> = new BehaviorSubject<IMainViewFilterState>(null);
    filterState$: Observable<IMainViewFilterState> = this.filterStateSubject.asObservable();

    areaIds: number[];
    stageIds: number[];

    set filterState(value: IMainViewFilterState) {
        this.filterStateSubject.next(value);
        this.updateAreaAndStageIds();
        this.updateValueColumns();
    }

    get filterState(): IMainViewFilterState {
        return this.filterStateSubject.value;
    }

    get schedulerView(): boolean {
        return !(this.project.currentUserRelation.length === 1 && this.project.currentUserRelation.includes('ROLE_QUOTER'));
    }

    scheduleAreaItems: IMainViewFilterItemItem[];
    stageItems: IMainViewFilterItemItem[];

    quoters: IComparisonQoter[] = [];
    nativeComparison: IComparison = null;

    get selectedQuoters(): IComparisonQoter[] {
        switch (this.costPlanMode) {
            case 'comparison-view':
                return this.quoters.filter(q => q.selected);
            default:
                if (this.schedulerView) {
                    return [this.quoters.find(q => q.id === this.project.defaultQuoter.id)];
                } else if (this.accountService.isQuoter()) {
                    return this.quoters;
                } else {
                    // is not possible
                    return null;
                }
        }
    }

    valueColumns: ICostPlanValueColumn[] = null;

    inProcessLoadingDataForMainFilter = false;
    inProcessLoadingStatData = false;
    inProcessUpdatingValueColumns = false;
    inProcessUpdatingDefaultQuoter = false;

    overviewProjectChartDataSubject: BehaviorSubject<IOverviewProjectChartData> = new BehaviorSubject<IOverviewProjectChartData>(null);
    overviewProjectChartDataState$: Observable<IOverviewProjectChartData> = this.overviewProjectChartDataSubject.asObservable();

    set overviewProjectChartData(value: IOverviewProjectChartData) {
        this.overviewProjectChartDataSubject.next(value);
    }

    get overviewProjectChartData(): IOverviewProjectChartData {
        return this.overviewProjectChartDataSubject.value;
    }

    initedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    initedState$: Observable<boolean> = this.initedSubject.asObservable();

    set inited(value: boolean) {
        this.initedSubject.next(value);
    }

    get inited(): boolean {
        return this.initedSubject.value;
    }

    get mode(): ICostPlanMode {
        return this.filterState.groupBy === 'area' || this.filterState.groupBy === 'stage_room' ? 'areas' : 'stages';
    }

    get items(): IMainViewFilterItemItem[] {
        switch (this.mode) {
            case 'areas':
                return this.scheduleAreaItems;
            case 'stages':
                return this.stageItems;
        }
    }

    get itemIds(): number[] {
        switch (this.mode) {
            case 'areas':
                return this.areaIds;
            case 'stages':
                return this.stageIds;
        }
    }

    get filterStateIds(): number[] {
        switch (this.mode) {
            case 'areas':
                return this.filterState.areaIds;
            case 'stages':
                return this.filterState.stageIds;
        }
    }

    get itemShort(): string {
        switch (this.mode) {
            case 'areas':
                return 'areas';
            case 'stages':
                return 'stages';
        }
    }

    get inProcess(): boolean {
        return (
            this.inProcessLoadingDataForMainFilter ||
            this.inProcessLoadingStatData ||
            this.inProcessUpdatingValueColumns ||
            this.inProcessUpdatingDefaultQuoter
        );
    }

    constructor(
        private accountService: AccountService,
        private projectApi: ProjectApi
    ) {
    }

    init(project: IProject, filterState: IMainViewFilterState): void {
        this.inited = false;
        this.project = project;

        this.reloadProject().then(() => {
            this._costPlanMode = 'pie-chart-view';
            this.valueColumns = null;
            this.scheduleAreaItems = [];
            this.stageItems = [];
            this.stageIds = null;
            this.areaIds = null;

            if (this.schedulerView) {
                forkJoin([
                    this.projectApi.queryQuoters(this.project.id),
                    this.loadDataForMainFilter()
                ]).subscribe((res: any[]) => {
                    const res0 = res[0] as HttpResponse<IComparisonQoter[]>;
                    this.quoters = _.uniqBy(res0.body, 'id').map(q => {
                        q.selected = q.default || this.project.defaultQuoter?.email === q.email;
                        return q;
                    });

                    this.filterState = filterState;
                    this.update();
                });
            } else {
                this.accountService.identity().then((account: IAccount) => {
                    this.quoters = [
                        {
                            id: account.id,
                            email: account.email,
                            firstName: account.firstName,
                            lastName: account.lastName,
                            company: account.company,
                            selected: true
                        }
                    ];

                    this.loadDataForMainFilter().subscribe(() => {
                        this.filterState = filterState;
                        this.update();
                    });
                });
            }
        })
    }

    update(): void {
        this.loadComparisons().subscribe(() => {
            this.updateValueColumns();
        });
    }

    getChartData(): ILabelValue[] {
        const data: ILabelValue[] = [];
        const valueColumn = this.valueColumns?.length === 1 ? this.valueColumns[0] : this.valueColumns.find(q => q.quoter?.email === this.project?.defaultQuoter?.email);

        if (!valueColumn) {
            return null;
        }

        valueColumn.data.forEach((d) => {
            data.push({
                label: d.item.title,
                value: d.total,
                percentage: _.round(d.total / (valueColumn.quoterTotal / 100), 2)
            });
        });
        return data;
    }

    private loadComparisons(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.nativeComparison = {
                quoters: [],
                stageDTOs: []
            };

            const quoterIdsParam = this.quoters.map(q => q.id);

            if (quoterIdsParam.length === 0) {
                observer.next();
                observer.complete();
                return;
            }

            this.inProcessLoadingStatData = true;

            let sub = null;
            if (this.schedulerView) {
                sub = this.projectApi.queryComparison(this.project.id, quoterIdsParam);
            } else {
                sub = this.projectApi.queryQuoterView(this.project.id);
            }

            sub.pipe(
                finalize(() => {
                    this.inProcessLoadingStatData = false;
                })
            )
                .subscribe((res: HttpResponse<IComparison>) => {
                    this.nativeComparison = res.body;

                    observer.next();
                    observer.complete();
                });
        });
    }

    updateValueColumns(): void {
        if (!this.nativeComparison) {
            return;
        }

        const valueColumns: ICostPlanValueColumn[] = [];
        for (let index = 0; index < this.selectedQuoters.length; index++) {
            const selectedQuoter = this.selectedQuoters[index];
            const quoterIndex = this.nativeComparison.quoters.length === 1 ? 0 : this.nativeComparison.quoters.findIndex(q => q.id === selectedQuoter.id);

            const valueColumn: ICostPlanValueColumn = {
                quoter: selectedQuoter,
                quoterTotal: 0,
                costPerSqm: 0,
                data: []
            };

            switch (this.mode) {
                case 'stages':
                    for (const stageItem of (this.stageItems || [])) {
                        let total = 0;

                        const stage = this.nativeComparison.stageDTOs.find(stage => stage.id === stageItem.id);
                        if (stage && this.stageIds.indexOf(stage.id) !== -1) {
                            stage.elementDTOs.forEach(element => {
                                element.taskDTOs.forEach(task => {
                                    if (this.areaIds.indexOf(task.scheduleAreaRootId) !== -1) {
                                        total += task.totals[quoterIndex];
                                    }
                                })
                            })
                        }

                        valueColumn.data.push({ item: stageItem, total });
                    }
                    break;
                case 'areas':
                    for (const areaItem of (this.scheduleAreaItems || [])) {
                        let total = 0;

                        this.nativeComparison.stageDTOs.forEach((stage) => {
                            if (this.stageIds.indexOf(stage.id) !== -1) {
                                stage.elementDTOs.forEach(element => {
                                    element.taskDTOs.forEach(task => {
                                        if (this.areaIds.indexOf(task.scheduleAreaRootId) !== -1 && task.scheduleAreaRootId === areaItem.id) {
                                            total += task.totals[quoterIndex];
                                        }
                                    })
                                })
                            }
                        });

                        valueColumn.data.push({ item: areaItem, total });
                    }
                    break;
            }

            valueColumn.quoterTotal = _.sumBy(valueColumn.data, 'total');
            valueColumns.push(valueColumn);
        }

        this.valueColumns = valueColumns;
        this.inited = true;
    }

    updateDefaultQuoter(quoter: IComparisonQoter): void {
        this.inProcessUpdatingDefaultQuoter = true;
        this.valueColumns = null;

        this.projectApi
            .updateDefaultQuoter(this.project.id, quoter.id)
            .pipe(
                finalize(() => {
                    this.inProcessUpdatingDefaultQuoter = false;
                })
            )
            .subscribe(() => {
                this.projectApi.find(this.project.id).subscribe((res: HttpResponse<IProject>) => {
                    this.project.defaultQuoter = res.body.defaultQuoter;
                    this.update();
                });
            });
    }

    isQuoterDefault(quoter: IComparisonQoter): boolean {
        return quoter.id === this.project.defaultQuoter?.id;
    }

    toggleItem(item: IMainViewFilterItemItem): void {
        switch (this.mode) {
            case 'areas':
                if (this.filterState.areaIds != null) {
                    const items = this.filterState.areaIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.filterState.areaIds.length === this.scheduleAreaItems.length) {
                        this.filterState.areaIds = null;
                    }
                } else {
                    this.filterState.areaIds = this.scheduleAreaItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
            case 'stages':
                if (this.filterState.stageIds != null) {
                    const items = this.filterState.stageIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.filterState.stageIds.length === this.stageItems.length) {
                        this.filterState.stageIds = null;
                    }
                } else {
                    this.filterState.stageIds = this.stageItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
        }

        this.filterState = this.filterState;
        //   this.filterStateSubject.next(this.filterState);
    }

    reloadProject(): Promise<IProject> {
        return lastValueFrom(this.projectApi.find(this.project.id)).then((res: HttpResponse<IProject>) => {
            this.project = res.body;
            return this.project;
        });
    }

    private loadDataForMainFilter(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.inProcessLoadingDataForMainFilter = true;

            forkJoin([
                this.projectApi.queryAvailableScheduleAreas(this.project.id),
                this.projectApi.queryScheduleStages(this.project.id)
            ])
                .pipe(
                    finalize(() => {
                        this.inProcessLoadingDataForMainFilter = false;
                    })
                )
                .subscribe((result: HttpResponse<any>[]) => {
                    this.nativeScheduleAreas = result[0].body;
                    const stages: IStage[] = result[1].body;

                    this.scheduleAreaItems = _.map(this.nativeScheduleAreas, (a: IScheduleArea) => {
                        return {
                            id: a.id,
                            title: a.area
                        };
                    });

                    this.stageItems = _.map(stages, (a: IStage) => {
                        return {
                            id: a.id,
                            title: a.stage
                        };
                    });

                    this.updateAreaAndStageIds();

                    observer.next();
                    observer.complete();
                });
        });
    }

    private updateAreaAndStageIds(): void {
        this.areaIds = this.filterState.areaIds ? this.filterState.areaIds : this.scheduleAreaItems?.map(i => i.id);
        this.stageIds = this.filterState.stageIds ? this.filterState.stageIds : this.stageItems?.map(i => i.id);
    }

}
