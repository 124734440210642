import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IScopesHeader, IScope, IScopeRequest, ScopeStatus } from 'app/shared/model/bp.model';

@Injectable({ providedIn: 'root' })
export class ScopeApi {
    public scopeResourceUrl = SERVER_API_URL + 'api/scope';
    public scopeHeaderResourceUrl = this.scopeResourceUrl + '/header';

    constructor(private http: HttpClient) {
    }

    /**
     * returns list of headers with scopes for project
     *
     * @param projectId project id
     */
    queryListOfHeaderWithScopes(projectId: number): Observable<HttpResponse<IScopesHeader[]>> {
        return this.http.get<IScopesHeader[]>(`${SERVER_API_URL}api/projects/${projectId}/scope`, { observe: 'response' });
    }

    /**
     * create new scope header, body will be put to contet.
     * @param projectId project id
     * @param scopeHeaderContent scope header content
     */
    createScopeHeader(projectId: number, scopeHeaderContent: string): Observable<HttpResponse<IScopesHeader>> {
        const url = `${SERVER_API_URL}api/projects/${projectId}/scope-header`;
        return this.http.post<IScopesHeader>(url, scopeHeaderContent, { observe: 'response' });
    }

    /**
     * update scope header content, body will be put to content.
     * @param scopeHeaderId
     * @param content
     */
    updateScopeHeader(scopeHeaderId: number, content: string): Observable<HttpResponse<IScopesHeader>> {
        const url = `${SERVER_API_URL}api/scope/header/${scopeHeaderId}`;
        return this.http.put<IScopesHeader>(url, content, { observe: 'response' });
    }

    /**
     * Deletes scope header and scopes, return Void.
     *
     * @param scopeHeaderId
     */
    deleteScopeHeaderAndScope(scopeHeaderId: number): Observable<HttpResponse<void>> {
        const url = `${this.scopeHeaderResourceUrl}/${scopeHeaderId}`;
        return this.http.delete<void>(url, { observe: 'response' });
    }

    /**
     * update scope header status all scopes inside will be updated automatically, user could update to SUBMIT and DRAFT only,
     * if status is one of two states (SUBMIT or DRAFT), so if status is in states: PROCESSING, COMPLETED, UPDATED
     * status will not be updated (that statuses will be upated from admin side)
     *
     * @param scopeHeaderId
     * @param status
     */
    updateScopeHeaderStatus(scopeHeaderId: number, status: ScopeStatus): Observable<HttpResponse<IScopesHeader>> {
        const url = `${this.scopeHeaderResourceUrl}/${scopeHeaderId}/status/${status}`;
        return this.http.post<IScopesHeader>(url, null, { observe: 'response' });
    }

    /**
     * Get scope
     *
     * @param scopeId
     */
    retrieveScope(scopeId: number): Observable<HttpResponse<IScope>> {
        return this.http.get<IScope>(`${this.scopeResourceUrl}/${scopeId}`, { observe: 'response' });
    }

    /**
     * Create scope
     *
     * @param scopeHeaderId
     * @param scopeRequest
     */
    createScope(scopeHeaderId: number, scopeRequest: IScopeRequest): Observable<HttpResponse<IScope>> {
        return this.http.post<IScope>(`${this.scopeHeaderResourceUrl}/${scopeHeaderId}`, scopeRequest, { observe: 'response' });
    }

    /**
     * Update scope content and amount with body
     *
     * @param scopeId
     * @param status
     */
    updateScopeStatus(scopeId: number, status: ScopeStatus): Observable<HttpResponse<IScope>> {
        return this.http.put<IScope>(`${this.scopeResourceUrl}/${scopeId}/status/${status}`, null, { observe: 'response' });
    }

    /**
     * Update scope
     *
     * @param scopeId
     * @param scopeRequest
     */
    updateScope(scopeId: number, scopeRequest: IScopeRequest): Observable<HttpResponse<IScope>> {
        return this.http.put<IScope>(`${this.scopeResourceUrl}/${scopeId}`, scopeRequest, { observe: 'response' });
    }

    /**
     * Delete scope
     *
     * @param scopeId
     */
    deleteScope(scopeId: number): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.scopeResourceUrl}/${scopeId}`, { observe: 'response' });
    }

}
