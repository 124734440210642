<div #logo
     *ngIf="project.icon"
     class="img-preview">
</div>

<div class="map-container"
     *ngIf="!project.icon">
    <div style="height: 300px; background: #f7f7f7;"
         *ngIf="mapOptions"
         leaflet
         [leafletOptions]="mapOptions">
    </div>
</div>

<ng-container *ngIf="!disabled">
    <div class="controls m-t-20">
        <label class="image-upload-container btn btn-border-rectangle btn-primary">
            <span>Upload Image</span>
            <input #imageInput
                   (change)="handleFile(imageInput)"
                   accept="image/*"
                   type="file">
        </label>

        <div style="width: 40px">
            <button (click)="remove()"
                    [disabled]="inProcess()"
                    class="btn btn-secondary btn-circle bp-icon bp-remove m-l-10"
                    ngbTooltip="Remove image"
                    type="button">
            </button>
        </div>
    </div>

    <div class="m-t-10 gray-text text-left">
        To replace map image
    </div>
</ng-container>
