import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BpOnErrorService } from 'app/shared/services/bp-on-error.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private bpOnErrorService: BpOnErrorService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    // This is intentional
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 500) {
                            this.bpOnErrorService.showError(err);
                        }
                    }
                }
            )
        );
    }
}
