<div class="empty-projects-container text-center">
    <ng-container *ngIf="!canCreateProject">
        <div class="empty-projects-container__description">
            No projects applied yet
        </div>
    </ng-container>

    <ng-container *ngIf="canCreateProject">
        <div class="empty-projects-container__description m-b-60">
            Start your first project
        </div>

        <bp-add-project-card (onAddClick)="createNewProject()">
        </bp-add-project-card>

        <div class="empty-projects-container__view-sample-project m-t-30">
            Or view a <a href="javascript:void(0);"
                         role="button"
                         (click)="createSampleProject()">
            sample project
        </a>
        </div>

        <div class="m-t-40"
             *ngIf="false">
            <button class="btn btn-border-radius btn-primary">
                Watch video
                <fa-icon [icon]="['fas', 'play-circle']"></fa-icon>
            </button>
        </div>

    </ng-container>
</div>
