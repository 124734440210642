import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IMaterialCategory } from '../model/material-category.model';
import { IMaterial } from 'app/shared/model/material.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { map } from "rxjs/operators";

type EntityResponseType = HttpResponse<IMaterialCategory>;

@Injectable({ providedIn: 'root' })
export class MaterialCategoryService {
    public resourceUrl = SERVER_API_URL + 'api/material-categories';

    constructor(private http: HttpClient) {
    }

    create(materialComponent: IMaterialCategory): Observable<EntityResponseType> {
        return this.http.post<IMaterialCategory>(this.resourceUrl, materialComponent, { observe: 'response' });
    }

    update(materialComponent: IMaterialCategory): Observable<EntityResponseType> {
        return this.http.put<IMaterialCategory>(this.resourceUrl, materialComponent, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<IMaterialCategory>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    primeMaterialId(id: number, specId: number): Observable<HttpResponse<number>> {
        return this.http.get<number>(`${this.resourceUrl}/${id}/spec/${specId}/material`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IMaterialCategory[]>> {
        const options = createRequestOption(req);
        return this.http.get<IMaterialCategory[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
