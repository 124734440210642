import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { IAccount } from 'app/shared/model/account.model';
import { ArchiveFilterValue } from 'app/shared/components/common/archive-filter/archive-filter.component';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { ICssElement } from 'app/shared/model/bp.model';
import { CssElementService } from 'app/shared/dataservices/css-element.service';
import { EntityCssElementService } from 'app/entities/css-element/css-element.service';

@Component({
    selector: 'bp-css-element-list',
    templateUrl: './css-element-list.component.html',
    styleUrls: ['css-element-list.scss']
})
export class CssElementListComponent implements OnInit, OnDestroy {
    cssElements: ICssElement[];
    currentAccount: IAccount;
    itemsPerPage: number;
    page: number;
    predicate: string;
    queryCount: number;
    reverse: boolean;
    totalItems: number;
    cssElementPrefix: string;

    searchControl = new FormControl();
    searchSub = Subscription.EMPTY;
    itemUpdatedSub = Subscription.EMPTY;

    archiveFilterValue: ArchiveFilterValue = 'active';

    constructor(
        private cssElementService: CssElementService,
        private accountService: AccountService,
        private entityQueryStorageService: EntityQueryStorageService,
        private entityService: EntityCssElementService
    ) {
        this.cssElements = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('BUILD_UP');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
        this.cssElementPrefix = entityQuery.searchValue || '';
        this.archiveFilterValue = entityQuery.archive || 'active';

        this.searchControl.setValue(this.cssElementPrefix);
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });

        this.searchSub = this.searchControl.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe((term: string) => {
            this.cssElementPrefix = term;
            this.loadPage(0);
        });

        this.itemUpdatedSub = this.entityService.itemUpdated$.subscribe(() =>  this.loadAll());
    }

    ngOnDestroy(): void {
        this.searchSub?.unsubscribe();
        this.itemUpdatedSub?.unsubscribe();
    }

    trackId(index: number, item: ICssElement): number {
        return item.id;
    }

    reset(): void {
        this.page = 0;
        this.cssElements = [];
        this.saveEntityQuery();
        this.loadAll();
    }

    loadPage(page: number): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadAll();
    }

    hasMore(): boolean {
        return this.cssElements.length < this.totalItems;
    }

    onArchiveFilterValueChanged(newValue): void {
        this.archiveFilterValue = newValue;
        this.saveEntityQuery();
        this.reset();
    }

    private sort(): string {
        return this.predicate + ',' + (this.reverse ? 'asc' : 'desc');
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse,
            searchValue: this.cssElementPrefix,
            archive: this.archiveFilterValue
        };

        this.entityQueryStorageService.store('BUILD_UP', entityQuery);
    }

    private loadAll(): void {
        this.cssElementService
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort(),
                searchValue: this.cssElementPrefix,
                archive: this.archiveFilterValue === 'archived'
            })
            .subscribe(
                (res: HttpResponse<ICssElement[]>) => this.paginateCssElements(res.body, res.headers)
            );
    }

    private paginateCssElements(data: ICssElement[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.cssElements = [];
        }

        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);

        for (let i = 0; i < data.length; i++) {
            this.cssElements.push(data[i]);
        }
    }
}
