import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';
import { ProjectApi } from 'app/shared/dataservices/project.api';

@Component({
    selector: 'bp-homeowner',
    template: ''
})
export class HomeownerComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private authServerProvider: AuthServerProvider,
        private projectApi: ProjectApi,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.blockUI.start('Please wait...');
        const token = this.router.url.split('?')[0].split('/').pop();
        this.authServerProvider.loginForHomeowner(token).subscribe(() => {
            this.projectApi.defaultProject().subscribe((res) => {
                if (res.body) {
                    this.router.navigate(['/scheduler', 'dashboard', res.body.id]).finally(() => {
                        this.blockUI.stop();
                    });
                } else {
                    this.router.navigate(['login']);
                    this.blockUI.stop();
                }
            })
        });
    }

}
