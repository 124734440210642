import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { IComponentArea } from 'app/shared/model/component-area.model';
import { EntityComponentAreaService } from 'app/entities/component-area/component-area.service';

@Component({
    selector: 'bp-component-area-delete-dialog',
    templateUrl: './component-area-delete-dialog.component.html'
})
export class ComponentAreaDeleteDialogComponent {
    componentArea: IComponentArea;

    constructor(private componentAreaService: ComponentAreaService,
                private entityService: EntityComponentAreaService,
                public activeModal: NgbActiveModal) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.componentAreaService.delete(id).subscribe(response => {
            this.entityService.itemUpdatedSubject.next();
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'bp-component-area-delete-popup',
    template: ''
})
export class ComponentAreaDeletePopupComponent implements OnInit, OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(({ componentArea }) => {
            setTimeout(() => {
                this.ngbModalRef = this.modalService.open(ComponentAreaDeleteDialogComponent as Component, {
                    size: 'lg',
                    backdrop: 'static'
                });


                this.ngbModalRef.componentInstance.componentArea = componentArea;

                const doOnResult = () => {
                    this.router.navigate([{ outlets: { popup: null } }], {
                        replaceUrl: true,
                        queryParamsHandling: 'merge'
                    });
                    this.ngbModalRef = null;
                };

                this.ngbModalRef.result.then(
                    result => {
                        doOnResult();
                    },
                    reason => {
                        doOnResult();
                    }
                );
            }, 0);
        });
    }

    ngOnDestroy() {
        this.ngbModalRef = null;
    }
}
