import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { IQuoter } from 'app/shared/model/quoter.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { finalize } from 'rxjs/operators';

const MEDIUM_QUOTER_EMAIL = 'medium@buildpartner.com';

@Injectable({ providedIn: 'root' })
export class TryToSelectMediumQuoterAsDefaultService {
    constructor(private projectApi: ProjectApi) {
    }

    public try(projectId: number): Promise<number | null> {
        return new Promise(resolve => {
            this.projectApi.queryDefaultQuoters().subscribe(
                (res: HttpResponse<IQuoter[]>) => {
                    const quoters = res.body;
                    const mediumQuoter: IQuoter = _.find(quoters, { email: MEDIUM_QUOTER_EMAIL });

                    if (mediumQuoter != null) {
                        this.projectApi
                            .updateDefaultQuoter(projectId, mediumQuoter.id)
                            .pipe(
                                finalize(() => {
                                    resolve(mediumQuoter.id);
                                    return;
                                })
                            )
                            .subscribe();
                    } else {
                        resolve(null);
                        return;
                    }
                },
                () => {
                    resolve(null);
                    return;
                }
            );
        });
    }
}
