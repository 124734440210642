import { Component, OnDestroy } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Quotes
 */
@Component({
    selector: 'bp-scheduler-quotes',
    templateUrl: './quotes.component.html',
    styleUrls: ['quotes.scss']
})
export class SchedulerQuotesComponent implements OnDestroy {
    project: IProject;

    routeDataSub = Subscription.EMPTY;

    constructor(private activatedRoute: ActivatedRoute) {
        this.routeDataSub = this.activatedRoute.data.subscribe(data => {
            this.project = data.project;
        });
    }

    ngOnDestroy(): void {
        this.routeDataSub.unsubscribe();
    }
}
