import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { AccountService } from "app/core/auth/account.service";

@Component({
    selector: 'bp-builder-has-been-invited-to-quote-modal',
    templateUrl: './builder-has-been-invited-to-quote-modal.component.html',
    styleUrls: ['builder-has-been-invited-to-quote-modal.scss']
})
export class BuilderHasBeenInvitedToQuoteModalComponent {
    doNotShowAgain: FormControl = new FormControl(false, [Validators.required]);

    constructor(
        private accountService: AccountService,
        private activeModal: NgbActiveModal
    ) {
    }

    close(): void {
        if (this.doNotShowAgain.value) {
            this.accountService.doNotShowInviteModel().subscribe(() => {
                this.accountService.identity(true).then(() => {
                    this.activeModal.close(true);
                })
            })
        } else {
            this.activeModal.close(true);
        }
    }
}
