import { IComponentArea } from './component-area.model';
import { IMaterial } from './material.model';
import { IScheduleTask } from './schedule-task.model';

export interface IUnit {
    id?: number;
    unit?: string;
    plural?: string;
    componentAreas?: IComponentArea[];
    materials?: IMaterial[];
    scheduleTasks?: IScheduleTask[];
}

export class Unit implements IUnit {
    constructor(
        public id?: number,
        public unit?: string,
        public plural?: string,
        public componentAreas?: IComponentArea[],
        public materials?: IMaterial[],
        public scheduleTasks?: IScheduleTask[]
    ) {}
}
