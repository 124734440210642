import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CopyProjectService {
    constructor(private projectApi: ProjectApi) {
    }

    copy(project: IProject): Promise<IProject> {
        return lastValueFrom(this.projectApi.copyProject(project.id));
    }

    deepCopy(project: IProject): Promise<IProject> {
        return lastValueFrom(this.projectApi.deepCopyProject(project.id));
    }
}
