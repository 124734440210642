import { Route } from '@angular/router';
import { BpSingUpStripeComponent } from 'app/account/sign-up-stripe/sign-up-stripe.component';

export const signUpStripeRoute: Route = {
    path: 'sign-up-stripe',
    component: BpSingUpStripeComponent,
    data: {
        authorities: [],
        pageTitle: 'Sign Up'
    }
};
