import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
    selector: 'bp-sign-up-tracking-page',
    templateUrl: './sign-up-tracking-page.component.html'
})
export class SignUpTrackingPageComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.blockUI.start('Please wait...')
        setTimeout(() => {
            this.router.navigate(['']).finally(() => {
                this.blockUI.stop();
            });
        }, 1000);
    }
}
