import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IScopesHeader } from 'app/shared/model/bp.model';

@Component({
    selector: 'bp-scopes-container',
    templateUrl: './scopes-container.component.html',
    styleUrls: ['scopes-container.scss', './../../scopes.scss']
})
export class ScopesContainerComponent implements OnInit, OnDestroy {
    @Input() scopesHeader: IScopesHeader | null;

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }
}
