import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInvitationProjectTeamMember, IProjectTeamMember } from 'app/shared/model/project-team-member.model';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class ProjectTeamService {
    public resourceUrl = SERVER_API_URL + 'api/projects';
    public removeResourceUrl = SERVER_API_URL + 'api/project-team';

    constructor(private http: HttpClient) {}

    query(projectId: number): Observable<HttpResponse<IProjectTeamMember[]>> {
        return this.http.get<IProjectTeamMember[]>(`${this.resourceUrl}/${projectId}/team-members`, { observe: 'response' });
    }

    delete(projectTeamMemberId: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.removeResourceUrl}/${projectTeamMemberId}`, { observe: 'response' });
    }

    sendInvitation(projectId: number, invitationProjectTeamMember: IInvitationProjectTeamMember): Observable<any> {
        return this.http.post<any>(`${this.resourceUrl}/${projectId}/team-members`, invitationProjectTeamMember, { observe: 'response' });
    }

    acceptInvitation(token: string): Observable<any> {
        return this.http.put<any>(`${this.removeResourceUrl}/${token}`, null, { observe: 'response' });
    }
}
