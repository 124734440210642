import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { ComponentAreaListComponent } from 'app/entities/component-area/list/component-area-list.component';
import { ComponentAreaUpdateComponent } from 'app/entities/component-area/edit/component-area-update.component';
import {
    ComponentAreaDeleteDialogComponent,
    ComponentAreaDeletePopupComponent
} from 'app/entities/component-area/delete/component-area-delete-dialog.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { componentAreaPopupRoute, componentAreaRoute } from 'app/entities/component-area/component-area.route';

const ENTITY_STATES = [...componentAreaRoute, ...componentAreaPopupRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        ComponentAreaListComponent,
        ComponentAreaUpdateComponent,
        ComponentAreaDeleteDialogComponent,
        ComponentAreaDeletePopupComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpComponentAreaModule {
}
