<div #logo
     class="img-preview m-b-20">
</div>

<label class="image-upload-container btn btn-border-radius btn-primary">
    <span>{{ label }}</span>
    <input #imageInput
           [disabled]="disabled"
           (change)="handleFile(imageInput)"
           accept="image/*"
           type="file">
</label>
