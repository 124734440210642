import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MaterialUpdateComponent } from './edit/material-update.component';
import { MaterialDeletePopupComponent } from './delete/material-delete-dialog.component';
import { MaterialListComponent } from 'app/entities/material/list/material-list.component';
import { IMaterial, Material } from 'app/shared/model/material.model';
import { MaterialService } from 'app/shared/dataservices/material.service';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class MaterialResolve implements Resolve<IMaterial> {
    constructor(private service: MaterialService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Material> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<Material>) => response.ok),
                map((material: HttpResponse<Material>) => material.body)
            );
        }
        return of(new Material());
    }
}

export const materialRoute: Routes = [
    {
        path: 'material',
        component: MaterialListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Materials'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'material/new',
        component: MaterialUpdateComponent,
        resolve: {
            material: MaterialResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Materials'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'material/:id/edit',
        component: MaterialUpdateComponent,
        resolve: {
            material: MaterialResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Materials'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const materialPopupRoute: Routes = [
    {
        path: 'material/:id/delete',
        component: MaterialDeletePopupComponent,
        resolve: {
            material: MaterialResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Materials'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
