<div class="multi-toggler">
    <div class="multi-toggler__line">
    </div>
    <div class="multi-toggler__steps">
        <div *ngFor="let step of steps; let index = index; let first = first; let last = last"
             class="multi-toggler__step flex flex-column gap-20"
             [ngClass]="{'align-items-center': !first && !last, 'align-items-start': first, 'align-items-end': last}">
            <div class="multi-toggler__step__circle"
                 (click)="onClick($event, step)"
                 [ngClass]="{ 'active': currentStepId === step.id}">
            </div>
            <div class="multi-toggler__step__label"
                 [disableTooltip]="!step.tooltip"
                 [ngbTooltip]="step.tooltip">
                {{ step.label }}
            </div>
        </div>
    </div>
</div>
