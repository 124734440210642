import { Route } from '@angular/router';
import { BpSingUpComponent } from '../../account/sign-up/sign-up.component';

export const signUpRoute: Route = {
    path: 'sign-up-old',
    component: BpSingUpComponent,
    data: {
        authorities: [],
        pageTitle: 'Sign Up'
    }
};
