import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { SchedulerTeamComponent } from 'app/flows/scheduler/team/team.page';

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule
    ],
    declarations: [
        SchedulerTeamComponent
    ],
    providers: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class BpTeamsModule {
}
