<div class="show-substages-mode-container">
    <div class="title">
        Templates
    </div>

    <div class="values-container">
        <div class="show-substage-mode-option"
             *ngFor="let mode of showSubStagesModes;">
            <div class="form-check form-check-radio">
                <label>
                    <input type="radio"
                           name="show-sub-stages-mode"
                           class="with-gap size-20"
                           [value]="mode.id"
                           (click)="select(mode.id)"
                           [checked]="mode.id == showSubStagesMode">
                    <span>{{mode.label}}</span>
                </label>
            </div>
        </div>
    </div>
</div>

