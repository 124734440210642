import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    UnsubscribeCompletedModalComponent
} from 'app/account/settings/components/unsubscribe-completed-modal/unsubscribe-completed-modal.component';

@Injectable({ providedIn: 'root' })
export class UnsubscribeCompletedModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(): NgbModalRef {
        this.ngbModalRef = this.modalService.open(UnsubscribeCompletedModalComponent as Component, {
            windowClass: 'bp-modal unsubscribe-completed-modal',
            backdrop: 'static'
        });

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
