import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITrade } from 'app/shared/model/trade.model';
import { TradeService } from 'app/shared/dataservices/trade.service';

@Component({
    selector: 'bp-trade-update',
    templateUrl: './trade-update.component.html'
})
export class TradeUpdateComponent implements OnInit {
    trade: ITrade;
    isSaving: boolean;

    constructor(private tradeService: TradeService, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ trade }) => {
            this.trade = trade;
        });
    }

    protected previousState() {
        window.history.back();
    }

    protected save() {
        this.isSaving = true;
        if (this.trade.id !== undefined) {
            this.subscribeToSaveResponse(this.tradeService.update(this.trade));
        } else {
            this.subscribeToSaveResponse(this.tradeService.create(this.trade));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<ITrade>>) {
        result.subscribe(
            (res: HttpResponse<ITrade>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
