import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    AreaDetailsModeStorageService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/area-details-mode-storage.service';
import {
    EditScheduleAreaServiceService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/edit-schedule-area-service.service';

export type ReturnPage = 'cost_plan' | 'schedule';

@Component({
    selector: 'bp-edit-schedule-areas',
    templateUrl: './edit-schedule-areas.component.html',
    styleUrls: ['edit-schedule-areas.scss'],
    providers: [
        EditScheduleAreaServiceService,
        AreaDetailsModeStorageService
    ]
})
export class EditScheduleAreasComponent implements OnInit, OnDestroy {
    protected readonly Object = Object;
    private _subscriptions: Subscription[] = [];

    constructor(
        protected service: EditScheduleAreaServiceService,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    protected get actionButtonText(): string {
        return this.service.createMode ? 'Save and Exit' : 'Save';
    }

    ngOnInit(): void {
        const routeDataSub = this.activatedRoute.data.subscribe(data => {
            this.service.init(data.project);
        });

        this._subscriptions.push(routeDataSub);

        const routeQuerySub = this.activatedRoute.queryParams.subscribe(params => {
            if (params['returnPage']) {
                this.service.returnPage = params['returnPage'];
            }

            if (params['mode']) {
                this.service.createMode = params['mode'] === 'create';
            }
        });

        this._subscriptions.push(routeQuerySub);
    }

    ngOnDestroy(): void {
        this.service?.close();
        this._subscriptions.forEach(subs => subs.unsubscribe());
    }

    public save(calledOnExitFromScreen = false, saveToCache: boolean = false): Promise<boolean> {
        return this.service.save(calledOnExitFromScreen, saveToCache);
    }
}
