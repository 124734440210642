import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

const EMPTY_ICON_PATH = '/content/images/empty-icon.png';

@Component({
    selector: 'bp-image-previewer',
    templateUrl: './image-previewer.component.html',
    styleUrls: ['image-previewer.scss']
})
export class ImagePreviewerComponent implements OnInit {
    @Input() icon: string;
    @Input() width: string;
    @Input() height: string;

    @ViewChild('logo') logo: ElementRef;

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.updateLogo(this.icon);
    }

    private updateLogo(icon: string): void {
        this.changeDetector.detectChanges();
        this.logo.nativeElement.style.backgroundImage = `url(${icon || EMPTY_ICON_PATH})`;
    }
}
