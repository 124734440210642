import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddBuildUpModalComponent } from 'app/shared/components/common/add-build-up-modal/add-build-up-modal.component';

@Injectable({ providedIn: 'root' })
export class AddBuildUpModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    open(buildUpName: string): NgbModalRef {
        this.ngbModalRef = this.modalService.open(AddBuildUpModalComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.buildUpName = buildUpName;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
