import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, pairwise } from 'rxjs/operators';
import { IAccount } from 'app/shared/model/account.model';
import { CustomWindow } from 'app/shared/util/custom.window';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { ENVIRONMENT } from 'app/app.constants';
import { AccountService } from 'app/core/auth/account.service';
import * as WebFont from 'webfontloader';
import {
    ThanksForSubscribingModalService
} from 'app/shared/components/common/thanks-for-subscribing-modal/thanks-for-subscribing-modal.service';
import { LoginViaTokenFromGoogleService } from 'app/shared/services/login-via-token-from-google.service';
import {
    AfterSignupWizardModalService
} from 'app/shared/components/common/after-signup-wizard-modal/after-signup-wizard-modal.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LoaderService } from 'app/shared/services/loader-service.service';
import { environment } from "../../../../../environments/environment";
import { BPStore } from 'app/shared/stores/bp.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

declare const window: CustomWindow;

@UntilDestroy()
@Component({
    selector: 'bp-main',
    templateUrl: './main.component.html',
    styleUrls: ['main.scss']
})
export class BpMainComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    private _routeScrollPositions: { [url: string]: number }[] = [];
    private _subscriptions: Subscription[] = [];
    private _position = 0;

    constructor(
        private titleService: Title,
        public loaderService: LoaderService,
        private router: Router,
        private accountService: AccountService,
        private bpStore: BPStore,
        private topMenuStateService: TopMenuStateService,
        private thanksForSubscribingModalService: ThanksForSubscribingModalService,
        private afterSignupWizardModalService: AfterSignupWizardModalService,
        private loginViaTokenFromGoogleService: LoginViaTokenFromGoogleService
    ) {
    }

    get fullscreen(): boolean {
        return this.router.url.indexOf('/sign-up') !== -1 ||
            this.router.url.indexOf('/create-quoter-from-invitation') !== -1 ||
            this.router.url.indexOf('/system-upgrades') !== -1;
    };

    ngOnInit(): void {
        this.hideUnStyledTextWhileIconsAreLoading();

        this._subscriptions.push(
            // save or restore scroll position on route change
            this.router.events.pipe(pairwise()).subscribe(([prevRouteEvent, currRouteEvent]) => {
                if (prevRouteEvent instanceof Scroll && currRouteEvent instanceof NavigationStart) {
                    this._routeScrollPositions[prevRouteEvent.routerEvent.url] =
                        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                }
                if (currRouteEvent instanceof NavigationEnd) {
                    window.scrollTo(0, 0);

                    this._position = this._routeScrollPositions[currRouteEvent.url] || 0;
                }
            })
        );

        this._subscriptions.push(
            this.accountService.authState$().pipe(
                debounceTime(700),
                distinctUntilChanged()
            ).subscribe((value) => {
                if (value) {
                    this.thanksForSubscribingModalService.showIfNeeded();
                    this.afterSignupWizardModalService.showIfNeeded();
                }
            }))

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.titleService.setTitle(this.getPageTitle(this.router.routerState.snapshot.root));
                this.topMenuStateService.updateCurrentItem();

                this.accountService.identity().then((account: IAccount) => {
                    if (window.mt) {
                        window.mt('send', 'pageview', {
                            email: this.accountService.getEmail(),
                            firstname: this.accountService.getFirstName(),
                            lastname: this.accountService.getLastName()
                        });
                    }

                    this.attachUserPropertiesToHeap(account);

                    if (ENVIRONMENT === 'stage' || ENVIRONMENT === 'prod') {
                        this.addUsetiful(account);
                    }
                });


                window.dataLayer?.push({ event: 'pageview', url: event['url'] });
            }

            if (event instanceof NavigationStart) {
                const url = event['url'];

                if (environment.systemUpgrades && !url.includes('/system-upgrades')) {
                    this.router.navigate(['/system-upgrades']);
                }

                let params = (new URL(window.location.href)).searchParams;
                const code = params.get('code');

                if (!this.accountService.isIdentityResolved() && url === '/' && code?.length) {
                    this.loginViaTokenFromGoogleService.login(code).then((t) => {
                        this.accountService.identity(true).then((account) => {
                            this.redirect(account);
                        })
                    });
                } else {
                    this.accountService.identity().then((account: IAccount) => {
                        if (this.accountService.isAuthenticated()) {
                            if (url.startsWith('/login')
                                || url.startsWith('/welcome-login')
                                /*|| url.startsWith('/sign-up')
                                || url.startsWith('/sign-up-light')
                                || url.startsWith('/sign-up-stripe')
                                || url.startsWith('/create-quoter-from-invitation')*/) {

                                this.blockUI.start('You are already authorized, so you will be redirected to the application. Please logout to login under another account.');
                                this.router.navigate(['']).then(() => {
                                    setTimeout(() => {
                                        this.blockUI.stop();
                                    }, 2000);
                                });
                            } else if (url === '/') {
                                if (account) {
                                    this.redirect(account);
                                }
                            }
                        } else {
                            if (url.startsWith('/login')
                                || url.startsWith('/welcome-login')
                                || url.startsWith('/sign-up')
                                || url.startsWith('/sign-up-light')
                                || url.startsWith('/sign-up-stripe')
                                || /^\/activate\//.test(this.router.url)
                                || /^\/reset\//.test(this.router.url)
                                || url.startsWith('/create-quoter-from-invitation')) {
                                //
                            } else {
                                this.router.navigate(['login'], { queryParamsHandling: 'merge' });
                            }
                        }
                    })
                }
            }
        });

        this.bpStore.dataRetrieved$
            .pipe(
                untilDestroyed(this))
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                if (this._position > 0) {
                    setTimeout(() => {
                        window.scrollTo(0, this._position);
                    }, 200);
                }
            })
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    showNavbar(): boolean {
        return (
            ['/', '/login', '/welcome-login', '/sign-up', '/create-quoter-from-invitation'].indexOf(this.router.url) === -1 &&
            _.filter(['/reset/request', '/reset/finish'], (url: string) => this.router.url.includes(url)).length === 0
        );
    }

    @HostListener('document:wheel', ['$event'])
    onWheel(event): void {
        const target = event.target;

        if (target.tagName.toLowerCase() === 'input' && target.type === 'number') {
            target.blur();
        }
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
        let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : 'bpApp';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    private addUsetiful(account: IAccount): void {
        if (!account) {
            return;
        }
        // User segmentation
        const scriptCode1 = `window.usetifulTags = { userId : '${account.login}', segment: '${account.login}_segment', firstName : '${
            account.firstName
        }', role : '${account.authorities.join(',')}'}`;
        // Usetiful script
        const scriptCode2 = `(function (w, d, s) {
                                var a = d.getElementsByTagName('head')[0];
                                var r = d.createElement('script');
                                r.async = 1;
                                r.src = s;
                                r.setAttribute('id', 'usetifulScript');
                                r.dataset.token = 'adb9ba53e0f4feddea859c71140a8171';
                                a.appendChild(r);
                                })(window, document, 'https://www.usetiful.com/dist/usetiful.js');`;

        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.appendChild(document.createTextNode(scriptCode1));
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.appendChild(document.createTextNode(scriptCode2));
        document.body.appendChild(script2);
    }

    private attachUserPropertiesToHeap(account: IAccount): void {
        if (!window.heap || !account) {
            return;
        }

        const userProperties = {
            'email': account.email
        }
        window.heap.addUserProperties(userProperties);
    }

    /* this applies webfont css classes to material icons while they are loading */
    private hideUnStyledTextWhileIconsAreLoading(): void {
        WebFont.load({
            google: {
                families: ['Material Icons']
            }
        });
    }

    private redirect = (account: IAccount) => {
        if (_.indexOf(account.authorities, 'ROLE_SCHEDULER') > -1) {
            this.router.navigate(['/scheduler', 'projects']);
        } else if (_.indexOf(account.authorities, 'ROLE_QUOTER') > -1) {
            this.router.navigate(['/quoter', 'projects']);
        } else if (_.indexOf(account.authorities, 'ROLE_ADMIN') > -1) {
            this.router.navigate(['/admin']);
        } else {
            this.router.navigate(['/']);
        }
    };
}
