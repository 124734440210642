import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';
import { IBuildUp, IBuildUpCategory, IBuildUpRequest } from 'app/shared/model/bp.model';

@Injectable({ providedIn: 'root' })
export class BuildUpCategoryApi {
    public resourceUrl = SERVER_API_URL + 'api/build-up-category';

    constructor(private http: HttpClient) {
    }

    create(buildUpCategory: IBuildUpCategory): Observable<HttpResponse<IBuildUpCategory>> {
        return this.http.post<IBuildUpCategory>(this.resourceUrl, buildUpCategory, { observe: 'response' });
    }

    update(buildUpCategory: IBuildUpCategory): Observable<HttpResponse<IBuildUpCategory>> {
        return this.http.put<IBuildUpCategory>(this.resourceUrl, buildUpCategory, { observe: 'response' });
    }

    find(id: number): Observable<HttpResponse<IBuildUpCategory>> {
        return this.http.get<IBuildUpCategory>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IBuildUpCategory[]>> {
        const options = createRequestOption(req);
        return this.http.get<IBuildUpCategory[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
