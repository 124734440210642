<div class="wizard">
    <div class="modal-header flex flex-column gap-15 align-items-center">
        <div class="flex gap-20 align-items-end">
            <img alt="BuildPartner" height="30" src="../../../../../content/images/bp-logo.svg">
            <div class="wizard__title">
                Welcome to BuildPartner
            </div>
        </div>
    </div>
    <div class="modal-body flex flex-column gap-20">
        <div class="flex flex-column gap-10 align-items-center">
            <div class="wizard__text">
                One last step
            </div>
            <div class="wizard__steps">
                <div *ngFor="let step of steps; let index = index"
                     class="wizard__steps__step"
                     [ngClass]="{'active' : index === currentStepId }">
                </div>
            </div>
        </div>

        <div class="wizard__current-step">
            <div *ngFor="let field of steps[currentStepId].fields">
                <ng-container [ngSwitch]="field.type">
                    <ng-container *ngSwitchCase="'text'">
                        <input type="text"
                               #inputField
                               class="form-control w-full"
                               [(ngModel)]="field.value"
                               [placeholder]="field.placeholder + (field.required ? ' *' : '')"
                               [name]="field.code"
                               [id]="field.code"
                               [pattern]="field.pattern">
                        <small class="form-text text-danger"
                               *ngIf="!inputField.checkValidity()">
                            {{ field.validationMessage || 'Incorrect value' }}
                        </small>
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">
                        <bp-select-input
                            appendTo="body"
                            (onSelectionChange)="onSelectionChange(field, $event)"
                            *ngIf="field.selectInputData"
                            [data]="field.selectInputData"
                            [noBottomBorder]="false"
                            [removeUnderlines]="true"
                            [placeholder]="field.placeholder + (field.required ? ' *' : '')">
                        </bp-select-input>
                    </ng-container>
                    <ng-container *ngIf="field.additionalValue?.code?.length">
                        <div *ngFor="let rule of field.additionalValue?.showWhen">
                            <div *ngIf="field.value?.toLowerCase() === rule.selectValue?.toLowerCase()"
                                 class="m-t-20">
                                <input type="text"
                                       class="form-control w-full"
                                       [(ngModel)]="rule.value"
                                       [placeholder]="rule.placeholder"
                                       [name]="field.code + '_additional'"
                                       [id]="field.code + '_additional'">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <div class="flex flex-column m-t-20 w-full">
            <button (click)="onOkClick()"
                    [disabled]="!isStepValid(currentStepId)"
                    tabindex="0"
                    class="btn btn-primary btn-border-radius waves-effect ok"
                    type="button">
                {{ isCurrentStepLast ? 'Finish' : 'Continue' }}
            </button>
        </div>
    </div>
</div>
