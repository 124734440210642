import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialService } from 'app/shared/dataservices/material.service';
import { IMaterial } from 'app/shared/model/material.model';
import { HttpResponse } from '@angular/common/http';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IProject } from 'app/shared/model/project.model';

@Component({
    selector: 'bp-add-material-modal',
    templateUrl: './add-material-modal.component.html',
    styleUrls: ['add-material-modal.scss']
})
export class AddMaterialModalComponent {
    project: IProject;
    scheduleTask: IScheduleTask;
    materialName: string;
    materialCategoryId: number;
    unit: string;
    defaultPrice: number;
    referenceUrl: string;
    builderSupplied = true;

    constructor(public activeModal: NgbActiveModal,
                private materialService: MaterialService) {
    }

    clear(): void {
        this.activeModal.dismiss('cancel');
    }

    addMaterial(): void {
        this.materialService
            .createShort({
                projectId: this.project.id,
                materialName: this.materialName,
                materialCategoryId: this.materialCategoryId,
                defaultPrice: +this.defaultPrice,
                referenceUrl: this.referenceUrl
            })
            .subscribe((res: HttpResponse<IMaterial>) => {
                this.scheduleTask.primeMaterialCost = this.defaultPrice;
                this.scheduleTask.primeMaterialCostAvailable = !this.builderSupplied;
                this.scheduleTask.materialUrlRef = this.referenceUrl;
                this.activeModal.close(res.body);
            });
    }

    isFormValid(): boolean {
        return this.materialName == null || this.materialName.length === 0 || this.defaultPrice == null;
    }
}
