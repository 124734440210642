import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStage } from 'app/shared/model/stage.model';
import { StageService } from 'app/shared/dataservices/stage.service';
import { ICssElement } from 'app/shared/model/bp.model';
import { CssElementService } from 'app/shared/dataservices/css-element.service';

@Component({
    selector: 'bp-css-element-update',
    templateUrl: './css-element-update.component.html'
})
export class CssElementUpdateComponent implements OnInit {
    cssElement: ICssElement;
    isSaving: boolean;

    stages: IStage[];

    constructor(
        private cssElementService: CssElementService,
        private stageService: StageService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ cssElement }) => {
            this.cssElement = cssElement;
        });
        this.stageService.query().subscribe(
            (res: HttpResponse<IStage[]>) => {
                this.stages = res.body;
            }
        );
    }

    previousState(): void {
        window.history.back();
    }

    save(): void {
        this.isSaving = true;
        if (this.cssElement.id !== undefined) {
            this.subscribeToSaveResponse(this.cssElementService.update(this.cssElement));
        } else {
            this.subscribeToSaveResponse(this.cssElementService.create(this.cssElement));
        }
    }

    trackStageById(index: number, item: IStage): number {
        return item.id;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<ICssElement>>) {
        result.subscribe(
            (res: HttpResponse<ICssElement>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
