import { ITask } from './task.model';
import { IStage } from './stage.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';

export interface IElement {
    id?: number;
    element?: string;
    archive?: boolean;
    tasks?: ITask[];
    scheduleTasks?: IScheduleTask[];
    stage?: IStage;
    elementOrder?: number;
    scheduleAreaIds?: number[];
    referenceNumber?: string;
}

export class Element implements IElement {
    constructor(
        public id?: number,
        public element?: string,
        public archive?: boolean,
        public tasks?: ITask[],
        public scheduleTasks?: IScheduleTask[],
        public stage?: IStage,
        public elementOrder?: number,
        public scheduleAreaIds?: number[]
    ) {}
}
