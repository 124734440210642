import { Injectable } from '@angular/core';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { HttpResponse } from '@angular/common/http';
import { IProject } from 'app/shared/model/project.model';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import {
    TryToSelectMediumQuoterAsDefaultService
} from 'app/flows/scheduler/template-wizard/services/try-to-select-medium-quoter-as-default.service';

@Injectable({ providedIn: 'root' })
export class CreateSampleProjectService {

    constructor(private projectApi: ProjectApi,
                private invitationService: InvitationService,
                private tryToSelectMediumQuoterAsDefaultService: TryToSelectMediumQuoterAsDefaultService) {
    }

    create(): Promise<IProject> {
        return new Promise(resolve => {
            this.projectApi
                .createSampleProject()
                .subscribe(
                    (res: HttpResponse<IProject>) => {
                        const project = res.body;

                        // Adding default quoters
                        this.invitationService
                            .addDefaultQuoters(project.id)
                            .subscribe((defaultQuoterIds: number[]) => {
                                this.tryToSelectMediumQuoterAsDefaultService.try(project.id).then(() => {
                                    resolve(project);
                                });
                            })
                    })
        });
    }
}
