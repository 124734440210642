import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from 'app/shared/components/common/success-modal/success-modal.component';

export interface ISuccessModalData {
    header: string;
    textHtml?: string;
    closeButtonText?: string;
    classes?: string;
}

@Injectable({ providedIn: 'root' })
export class SuccessModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(data: ISuccessModalData): NgbModalRef {
        this.ngbModalRef = this.modalService.open(SuccessModalComponent as Component, {
            windowClass: 'bp-modal success',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.data = data;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
