import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SHOW_SUB_STAGES_MODES, ShowSubStagesMode } from 'app/shared/constants/show-substages-modes';

@Component({
    selector: 'bp-show-substages-mode-selector',
    templateUrl: './show-substages-mode-selector.component.html',
    styleUrls: ['show-substages-mode-selector.scss']
})
export class ShowSubstagesModeSelectorComponent implements OnInit {
    @Input() showSubStagesMode: ShowSubStagesMode;
    @Input() hideInUse = false;

    @Output() onChanged = new EventEmitter<ShowSubStagesMode>();

    showSubStagesModes = SHOW_SUB_STAGES_MODES;

    ngOnInit(): void {
        switch (this.hideInUse) {
            case false:
                this.showSubStagesModes = SHOW_SUB_STAGES_MODES;
                break;
            case true:
                this.showSubStagesModes = SHOW_SUB_STAGES_MODES.filter(m => m.id !== 'show-in-use');
                break;
        }
    }

    select(showSubStagesMode: ShowSubStagesMode): void {
        this.showSubStagesMode = showSubStagesMode;
        this.onChanged?.emit(this.showSubStagesMode);
    }
}
