import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BpAlertService } from 'app/shared/services/bp-alert.service';

const MAX_FILE_SIZE = 1024;
const EMPTY_ICON_PATH = '/content/images/empty-icon.png';

@Component({
    selector: 'bp-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['image-uploader.scss']
})
export class ImageUploaderComponent implements OnInit {
    @Input() initialImageUrl: string;
    @Input() label = 'Select Image';
    @Input() disabled = false;
    @Output() onUploaded = new EventEmitter();

    @ViewChild('logo') logo: ElementRef;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private alertService: BpAlertService
    ) {
    }

    ngOnInit(): void {
        this.updateLogo(this.initialImageUrl);
    }

    handleFile(imageInput: any): void {
        const file: File = imageInput.files[0];

        /* checking file size */
        const fileSize = file.size / 1024; // in kbytes
        if (fileSize > MAX_FILE_SIZE) {
            this.alertService.warning(`File size is more then ${MAX_FILE_SIZE} kbytes!`);
            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            const imageUrl = reader.result.toString();
            this.updateLogo(imageUrl);
            this.onUploaded.emit(imageUrl);
        });

        reader.readAsDataURL(file);
    }

    private updateLogo(imageUrl: string): void {
        this.changeDetector.detectChanges();
        this.logo.nativeElement.style.backgroundImage = `url(${imageUrl || EMPTY_ICON_PATH})`;
    }
}
