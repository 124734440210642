import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { GetReportDataParams, ProjectApi } from 'app/shared/dataservices/project.api';
import { BpDownloadExcelFileService } from 'app/shared/services/bp-download-excel-file.service';
import { take } from 'rxjs/operators';
import { AccountService } from 'app/core/auth/account.service';
import { BpDownloadDocFileService } from 'app/shared/services/bp-download-doc-file.service';
import { BpDownloadPDFFileService } from 'app/shared/services/bp-download-pdf-file.service';

@Injectable({
    providedIn: 'root'
})
export class QuoteReportService {
    constructor(
        private projectApi: ProjectApi,
        private downloadExcelFile: BpDownloadExcelFileService,
        private downloadDocFile: BpDownloadDocFileService,
        private downloadPDFFile: BpDownloadPDFFileService,
        private accountService: AccountService,
    ) {
    }

    exportAsExcel(project: IProject, params: GetReportDataParams) {
        const filename = `${this.getFileName(project)}.xlsx`;

        this.projectApi
            .getExcelData(project.id, params)
            .pipe(take(1))
            .subscribe(
                (data: string) => {
                    this.downloadExcelFile.call(data, filename);
                }
            );
    }

    exportAsDocx(project: IProject, params: GetReportDataParams) {
        const filename = `${this.getFileName(project)}.docx`;

        this.projectApi
            .getDocxData(project.id, params)
            .pipe(take(1))
            .subscribe(
                (data: string) => {
                    this.downloadDocFile.call(data, filename);
                }
            );
    }

    exportAsPDF(project: IProject, params: GetReportDataParams) {
        const filename = `${this.getFileName(project)}.pdf`;

        this.projectApi
            .getPDFData(project.id, params)
            .pipe(take(1))
            .subscribe(
                (data: string) => {
                    this.downloadPDFFile.call(data, filename);
                }
            );
    }

    private getFileName(project: IProject): string {
        return `${this.accountService.getCompany() || this.accountService.getLogin()}_quote_report_for_${project.address}_v${
            project.version
        }`;
    }
}
