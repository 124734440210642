<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6">
            <button [routerLink]="['../../dashboard', project.id]"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back to Cost Plan"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back to Cost Plan
            </button>
        </div>
    </div>

    <div class="card m-t-20">
        <div class="body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>
                                Client name
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.clientName || '--' }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>
                                Project owner
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.schedulerCompany || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-6">
                            <label>
                                First Line Address
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.address || '--' }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>
                                Second Line Address
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.addressSecondLine || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-6">
                            <label>
                                Phone
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.phone || '--' }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>
                                Email
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.email || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-12">
                            <label>
                                Region
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.region?.region || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-6">
                            <label>
                                City
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.city || '--' }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>
                                Postcode
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.postcode || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-6">
                            <label>
                                Tender Deadline Date
                            </label>
                            <div class="fw-400 gray-text">
                                {{ (project.tenderDeadline | date) || '--' }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>
                                Tender Decision Date
                            </label>
                            <div class="fw-400 gray-text">
                                {{ (project.tenderDecision | date) || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-6">
                            <label>
                                Project created
                            </label>
                            <div class="fw-400 gray-text">
                                {{ (project.createdDate | date) || '--' }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>
                                Specification
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.specification?.name || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-12">
                            <label>
                                Attachments URL (Dropbox, Google Drive or similar)
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.attachmentsUrl || '--' }}
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20">
                        <div class="col-sm-12">
                            <label>
                                Summary of Works
                            </label>
                            <div class="fw-400 gray-text">
                                {{ project.tenderNotes || '--' }}
                            </div>
                        </div>
                    </div>


                    <div class="row m-t-20">
                        <div class="col-sm-12">
                            <div class="gray-text m-b-20">
                                Shared attachments (Floorplans, sketches, planning and developments drawings
                                or
                                similar)
                            </div>
                            <bp-project-attachments [project]="project"
                                                    [disabled]="true"
                                                    id="project-attachment">
                            </bp-project-attachments>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 text-center">
                    <bp-project-image
                        [disabled]="true"
                        [project]="project"
                        [postcode]="project.postcode">
                    </bp-project-image>
                </div>
            </div>
        </div>
    </div>
</div>
