<div class="scope">
    <textarea #scopeInput
              bpTextareaAutoresize
              (blur)="applyChanges($event)"
              (keydown.enter)="applyChanges($event)"
              [formControl]="scopeContentControl"
              class="sc-input"
              placeholder="Enter new scope"
              rows="1"
              type="text">
            </textarea>

    <button (confirm)="delete()"
            class="btn btn-circle btn-small btn-secondary"
            ngbTooltip="Delete scope"
            [swal]="{
                    title: 'Are you sure you?',
                    text: 'Are you sure you want to delete this Scope?',
                    icon: 'warning',
                    showCancelButton: true,
                    customClass: {
                       confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                       cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                    }
                }"
            type="button">
        <i class="material-icons">delete_forever</i>
    </button>
</div>
