import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TradeComponent } from './trade.component';
import { TradeDetailComponent } from './trade-detail.component';
import { TradeUpdateComponent } from './trade-update.component';
import { ITrade, Trade } from 'app/shared/model/trade.model';
import { TradeService } from 'app/shared/dataservices/trade.service';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class TradeResolve implements Resolve<ITrade> {
    constructor(private service: TradeService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Trade> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<Trade>) => response.ok),
                map((trade: HttpResponse<Trade>) => trade.body)
            );
        }
        return of(new Trade());
    }
}

export const tradeRoute: Routes = [
    {
        path: 'trade',
        component: TradeComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Trades'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'trade/:id/view',
        component: TradeDetailComponent,
        resolve: {
            trade: TradeResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Trades'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'trade/new',
        component: TradeUpdateComponent,
        resolve: {
            trade: TradeResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Trades'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'trade/:id/edit',
        component: TradeUpdateComponent,
        resolve: {
            trade: TradeResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Trades'
        },
        canActivate: [UserRouteAccessService]
    }
];
