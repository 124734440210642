import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IScheduleUpdateData } from 'app/shared/model/schedule-update-data.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class ScheduleUpdateService {
    public resourceUrl = SERVER_API_URL + 'api/schedule-tasks';

    constructor(private http: HttpClient) {}

    flush(projectId: number, isMajor: boolean): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${this.resourceUrl}/batch/${projectId}?isMajor=${isMajor}`, null, { observe: 'response' });
    }

    batchUpdate(scheduleCache: IScheduleUpdateData): Observable<HttpResponse<IScheduleTask[]>> {
        return this.http.post<IScheduleTask[]>(`${this.resourceUrl}/batch`, scheduleCache, { observe: 'response' });
    }
}
