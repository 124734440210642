import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MainBpBackgroundService } from 'app/shared/services/main-bp-background.service';
import { EMAIL_NOT_FOUND_TYPE } from 'app/shared/constants/error.constants';
import { PasswordResetInitService } from 'app/account/password-reset/init/password-reset-init.service';

@Component({
    selector: 'bp-password-reset-init',
    templateUrl: './password-reset-init.component.html',
    styleUrls: ['password-reset-init.scss']
})
export class PasswordResetInitComponent implements OnInit, AfterViewInit, OnDestroy {
    error: string;
    errorEmailNotExists: string;
    resetAccount: any;
    success: string;

    constructor(
        private passwordResetInitService: PasswordResetInitService,
        private mainBpBackgroundService: MainBpBackgroundService,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.mainBpBackgroundService.activate();
        this.resetAccount = {};
    }

    ngOnDestroy(): void {
        this.mainBpBackgroundService.deactivate();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#email').focus();
        }, 0);
    }

    requestReset(): void {
        this.error = null;
        this.errorEmailNotExists = null;

        this.passwordResetInitService.save(this.resetAccount.email).subscribe(
            () => {
                this.success = 'OK';
            },
            response => {
                this.success = null;
                if (response.status === 400 && response.error.type === EMAIL_NOT_FOUND_TYPE) {
                    this.errorEmailNotExists = 'ERROR';
                } else {
                    this.error = 'ERROR';
                }
            }
        );
    }
}
