<div class="schedule-task-row"
     id="schedule-task-row_{{ scheduleTask?.id }}"
     style="margin-left: 0"
     [ngClass]="{'highlight': scheduleTask.highlight, 'is-grayed': isGrayed, 'schedule-task-row-which-can-be-grayed': !scheduleTask?.markedAsDeleted}">
    <div class="w-full flex flex-row flex-wrap gap-10 justify-content-between align-items-center">
        <div class="schedule-task-row__reference-number reference-number"
             [title]="scheduleTask.referenceNumber">
            {{ scheduleTask.referenceNumber }}
        </div>
        <div class="flex-1">
            <div *ngIf="taskSearchBySelectInputData && scheduleTask.id">
                {{ scheduleTask.displayName }}
            </div>

            <div class="task-request-text"
                 *ngIf="!scheduleTask.id">
                {{ scheduleTask.newTaskRequestId ? scheduleTask.task : 'Adding task...' }}
            </div>
        </div>

        <div class="flex-1">
            <ng-container *ngIf="scheduleTask.id != null; else taskRequestSpecification">
                <bp-select-input
                    *ngIf="materialCategories?.length && materialSelectInputData && materialSelectInputData.data?.length"
                    [disabled]="scheduleService.disabled"
                    (onSelectionChange)="onMaterialSelectionChange($event)"
                    [data]="materialSelectInputData"
                    [removeUnderlines]="false"
                    class="select-material-category-id">
                </bp-select-input>
                <div class="full-line-height specification-font overflow-ellipsis"
                     *ngIf="materialCategories?.length && materialSelectInputData && !materialSelectInputData.data?.length">
                    {{ primeMaterialName }}
                </div>
            </ng-container>
            <ng-template #taskRequestSpecification>
                <div class="task-request-text">
                    {{ scheduleTask.specification }}
                </div>
            </ng-template>
        </div>

        <div class="flex-0_5">
            <bp-select-input
                (onSelectionChange)="onScheduleAreaSelectionChange($event)"
                [disabled]="scheduleService.disabled"
                *ngIf="scheduleAreaSelectInputData"
                [data]="scheduleAreaSelectInputData"
                [removeUnderlines]="false"
                class="select-ares-id">
            </bp-select-input>
        </div>

        <div class="qti"
             style="position: relative">
            <input (change)="unitValueWasChanged = true"
                   (blur)="onUnitValueBlur()"
                   [disabled]="scheduleService.disabled"
                   [ngClass]="{ 'error-state': scheduleTask['error'] && scheduleTask.unitValue == null}"
                   [(ngModel)]="scheduleTask.unitValue"
                   [dropSpecialCharacters]="false"
                   [mask]="'0*.00'"
                   class="form-control quantity-input"
                   name="unitValue"
                   type="text">
            <span class="quantity-unit">
               <span *ngIf="scheduleTask.unit === 'm2'">m<sup>2</sup></span>
               <span
                   *ngIf="scheduleTask.unit !== 'm2'">{{ scheduleTask.unitValue > 1 ? scheduleTask.unitPlural : scheduleTask.unit }}</span>
            </span>
        </div>

        <div class="totals font-12 flex flex-row justify-content-between items-center gap-10">
            <div class="flex-1 text-center overflow-ellipsis">
                <span>{{ ((scheduleTask?.taskTotal?.laborCost / scheduleTask?.taskTotal?.qty)  | currency:'GBP') || '-' }}</span>
            </div>
            <div class="flex-1 text-center overflow-ellipsis">
                <span>{{ ((scheduleTask?.taskTotal?.materialCost / scheduleTask?.taskTotal?.qty) | currency:'GBP') || '-' }}</span>
            </div>
            <div class="flex-1 text-center overflow-ellipsis">
                <span>{{ ((scheduleTask?.taskTotal?.laborCost / scheduleTask?.taskTotal?.qty + scheduleTask?.taskTotal?.materialCost / scheduleTask?.taskTotal?.qty) | currency:'GBP') || '-' }}</span>
            </div>
            <div class="flex-1 text-center overflow-ellipsis">
                <span>{{ (scheduleTask?.taskTotal?.total | currency:'GBP') || '-' }}</span>
            </div>
            <div class="flex-1 flex justify-content-center">
                <div class="form-check clear">
                    <label class="form-check-label"
                           *ngIf="scheduleTask.primeMaterialId != null">
                        <input [(ngModel)]="scheduleTask.primeMaterialCostAvailable"
                               [disabled]="scheduleService.disabled"
                               [title]="!scheduleTask.primeMaterialCostAvailable ? (scheduleTask.primeMaterialCost | currency:'GBP') : ''"
                               (change)="onPrimeMaterialCostAvailableChanged()"
                               name="prime-material-cost-available_{{scheduleTask.id}}"
                               class="form-check-input"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="controls">
            <button ngbTooltip="{{scheduleTask.note}}"
                    *ngIf="scheduleTask.note"
                    class="show-notes-popup btn btn-circle btn-small btn-secondary">
                <i class="material-icons md-18">insert_comment</i>
            </button>

            <div *ngIf="scheduleTask.isProvisional"
                 class="provisional"
                 title="Provisional">
                P
            </div>

            <div *ngIf="scheduleTask.isSubcontract"
                 class="subcontract"
                 title="Subcontract">
                S
            </div>

            <div ngbDropdown class="bp-dropdown"
                 (click)="$event.stopPropagation()">
                <button type="button"
                        class="btn btn-circle btn-small btn-secondary"
                        ngbDropdownToggle>
                    <div style="margin-top: -3px">...</div>
                </button>
                <div ngbDropdownMenu>
                    <div *ngIf="scheduleTask.id">
                        <button ngbDropdownItem
                                [disabled]="scheduleService.disabled"
                                (click)="onEditScheduleClick($event)">
                            Edit Schedule
                        </button>
                    </div>

                    <div *ngIf="scheduleTask.newTaskRequestId">
                        <button ngbDropdownItem
                                [disabled]="scheduleService.disabled"
                                (click)="onEditNewTaskRequestScheduleClick($event)">
                            Edit New Task Request
                        </button>
                    </div>

                    <button ngbDropdownItem
                            [disabled]="scheduleService.disabled"
                            *ngIf="scheduleService.isEditRatesEnabled() &&  scheduleTask.taskId"
                            (click)="onEditRatesClick($event)">
                        Edit Rates
                    </button>

                    <button ngbDropdownItem
                            [disabled]="scheduleService.disabled"
                            *ngIf="scheduleTask.taskId"
                            (click)="onCommentsClick($event)">
                        Comment
                    </button>
                </div>
            </div>

            <button (click)="onDeleteTaskClick()"
                    [disabled]="scheduleService.disabled"
                    *ngIf="scheduleTask.taskId"
                    class="delete-task btn btn-circle btn-small btn-secondary"
                    ngbTooltip="Delete task">
                <i class="material-icons md-18">delete_forever</i>
            </button>

            <button (click)="onDeleteTaskRequestClick()"
                    [disabled]="scheduleService.disabled"
                    *ngIf="!scheduleTask.taskId"
                    class="delete-task btn btn-circle btn-small btn-secondary"
                    ngbTooltip="Delete task request">
                <i class="material-icons md-18">delete_forever</i>
            </button>
        </div>
    </div>
</div>
