import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'app/core/login/login.service';

@Component({
    selector: 'bp-logout',
    template: ''
})
export class BpLogoutComponent {
    constructor(private loginService: LoginService, private router: Router) {
        this.loginService.logout();
        this.router.navigate(['/login']);
    }
}
