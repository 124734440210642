import { Injectable } from '@angular/core';
import { ScopeApi } from 'app/shared/dataservices/scope.api';
import { lastValueFrom } from 'rxjs';
import { ScopesStore } from "app/flows/scheduler/scopes/stores/scopes.store";
import { IScope, IScopesHeader } from 'app/shared/model/bp.model';
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Injectable({ providedIn: 'root' })
export class ScopesService {

    @BlockUI() blockUI: NgBlockUI;

    constructor(private scopeApi: ScopeApi,
                private store: ScopesStore) {

    }

    public async init(): Promise<void> {
        return this.refresh();
    }

    public async refresh(): Promise<void> {
        this.blockUI.start('Reloading scopes..');
        return lastValueFrom(this.scopeApi.queryListOfHeaderWithScopes(this.store.projectId))
            .then((res) => {
                this.store.headersWithScopes = res.body;
            }).finally(() => {
                this.blockUI.stop();
            })
    }

    public async createScopeHeader(content: string): Promise<IScopesHeader> {
        //this.blockUI.start('Creating new scope heading..');
        const res = await lastValueFrom(this.scopeApi.createScopeHeader(this.store.projectId, content));
        //this.blockUI.stop();
        return res.body;
    }

    public async deleteScopeHeader(scopeHeaderId: number): Promise<void> {
        this.blockUI.start(`Removing scope header and it's scopes..`);
        const res = await lastValueFrom(this.scopeApi.deleteScopeHeaderAndScope(scopeHeaderId));
        this.blockUI.stop();
        return res.body;
    }

    public async updateScopeHeader(scopeHeaderId: number, content: string): Promise<IScopesHeader> {
        //this.blockUI.start('Updating scope heading..');
        const res = await lastValueFrom(this.scopeApi.updateScopeHeader(scopeHeaderId, content));
        //this.blockUI.stop();
        return res.body;
    }

    public async createScope(scopeHeaderId: number, content: string, amount: number): Promise<IScope> {
        //this.blockUI.start('Creating new scope..');
        const res = await lastValueFrom(this.scopeApi.createScope(scopeHeaderId, { content, amount }));
        //this.blockUI.stop();
        return res.body;
    }

    public async deleteScope(scopeId: number): Promise<void> {
        this.blockUI.start('Removing scope..');
        const res = await lastValueFrom(this.scopeApi.deleteScope(scopeId));
        this.blockUI.stop();
        return res.body;
    }

    public async updateScope(scopeId: number, content: string, amount: number): Promise<IScope> {
        //this.blockUI.start('Updating new scope..');
        const res = await lastValueFrom(this.scopeApi.updateScope(scopeId, { content, amount }));
        //this.blockUI.stop();
        return res.body;
    }
}
