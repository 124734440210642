import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { latLng, MapOptions, tileLayer } from 'leaflet';
import { IProject } from 'app/shared/model/project.model';
import { IGeoPoint } from 'app/shared/model/geo-point.model';
import { CoordinatesSearcherService } from 'app/shared/services/coordinates-searcher.service';

const defaultX = 51.50853;
const defaultY = -0.12574;

const MAX_FILE_SIZE = 1024;

@Component({
    selector: 'bp-project-image',
    templateUrl: './project-image.component.html',
    styleUrls: ['project-image.scss']
})
export class ProjectImageComponent implements OnInit, OnChanges {
    @Input() project: IProject;
    @Input() postcode: string;
    @Input() disabled = false;
    @Output() onUploaded = new EventEmitter();

    mapOptions: MapOptions;

    @ViewChild('logo') logo: ElementRef;
    @ViewChild('imageInput') imageInput: ElementRef;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private alertService: BpAlertService,
        private coordinatesSearcherService: CoordinatesSearcherService
    ) {
    }

    ngOnInit(): void {
        if (this.project.icon != null) {
            this.updateLogo(this.project.icon);
            return;
        }

        this.setMapOptions(this.project.latitude || defaultX, this.project.longitude || defaultY);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.postcode != null && !changes.postcode.firstChange && changes.postcode.currentValue !== changes.postcode.previousValue) {
            this.coordinatesSearcherService.search(this.postcode).then((geoPoint: IGeoPoint) => {
                this.project.latitude = geoPoint.lat;
                this.project.longitude = geoPoint.long;

                this.mapOptions = null;
                setTimeout(() => {
                    this.setMapOptions(geoPoint.lat || defaultX, geoPoint.long || defaultY);
                });
            });
        }
    }

    inProcess(): boolean {
        return false;
    }

    handleFile(imageInput: any): void {
        const file: File = imageInput.files[0];

        /* checking file size */
        const fileSize = file.size / 1024; // in kbytes
        if (fileSize > MAX_FILE_SIZE) {
            this.alertService.warning(`File size is more then ${MAX_FILE_SIZE} kbytes!`);
            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            const imageUrl = reader.result.toString();
            this.project.icon = imageUrl;
            this.updateLogo(imageUrl);
            this.onUploaded.emit(imageUrl);
        });

        reader.readAsDataURL(file);
    }

    remove(): void {
        this.project.icon = null;
        this.imageInput.nativeElement.value = null;
        this.setMapOptions(this.project.latitude || defaultX, this.project.longitude || defaultY);
        this.onUploaded.emit(null);
    }

    private updateLogo(imageUrl: string): void {
        this.changeDetector.detectChanges();
        this.logo.nativeElement.style.backgroundImage = `url(${imageUrl})`;
    }

    private setMapOptions(x: number, y: number): void {
        this.mapOptions = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18,
                    opacity: 0.8,
                    detectRetina: true,
                    attribution: '&copy; <a href="https://buildpartner.com">BuildPartner</a>'
                })
            ],
            attributionControl: false,
            zoom: 15,
            zoomControl: false,
            dragging: false,
            center: latLng(x, y)
        };
    }
}
