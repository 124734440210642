<div class="tile">
    <div class="tile__header">
        <div>
            {{ title }}
        </div>

        <div *ngIf="showViewButton">
            <button (click)="onViewClick($event)"
                    [disabled]="dashboardStore.inProcess"
                    class="btn waves-effect"
                    type="button">
                <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                View
            </button>
        </div>
    </div>

    <div class="tile__body {{ mode }}">
        <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="'pie-chart-view'">
                <bp-dashboard-project-cost [useInTile]="true">
                </bp-dashboard-project-cost>
            </ng-container>

            <ng-container *ngSwitchCase="'comparison-view'">
                <bp-dashboard-comparison [useInTile]="true">
                </bp-dashboard-comparison>
            </ng-container>

            <ng-container *ngSwitchCase="'timeline-view'">
                <bp-dashboard-timeline>
                </bp-dashboard-timeline>
            </ng-container>

            <ng-container *ngSwitchCase="'resource-cost-breakdown'">
                <bp-dashboard-resource-cost-breakdown>
                </bp-dashboard-resource-cost-breakdown>
            </ng-container>
        </ng-container>

        <div class="tile__body__over-panel"
             (click)="$event.preventDefault(); onViewClick($event)">
        </div>
    </div>
</div>
