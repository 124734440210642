import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { PopperContent } from 'ngx-popper';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type GroupBy =
    'area'
    | 'stage'
    | 'area_room'
    | 'stage_room'
    | 'css-element'
    | 'css-element_room'
    | 'build-up'
    | 'build-up_room';

export interface IMainViewFilterItemItem {
    id: number;
    title: string;

    order?: number;
}

export interface IMainViewFilterState {
    groupBy: GroupBy;
    areaIds: number[];
    stageIds: number[];
    cssElementIds?: number[];
    buildUpIds?: number[];
    areaRoomId: number;
    stageRoomId: number;
    cssElementRoomId?: number;
    buildUpRoomId?: number;
}

export function isAreaGrouping(filterState: IMainViewFilterState): boolean {
    return filterState.groupBy === 'area' || filterState.groupBy === 'area_room';
}

export function isCssElementGrouping(filterState: IMainViewFilterState): boolean {
    return filterState.groupBy === 'css-element' || filterState.groupBy === 'css-element_room';
}

export function getActualAreaIds(filterState: IMainViewFilterState, scheduleAreaItems: IMainViewFilterItemItem[] = []): number[] {
    let areaIds = [];

    switch (filterState.groupBy) {
        case 'area':
        case 'stage':
            areaIds = filterState.areaIds == null ? scheduleAreaItems.map(i => i.id) : filterState.areaIds;
            break;
        case 'area_room':
            areaIds = [filterState.areaRoomId];
            break;
        case 'stage_room':
            areaIds = [];
            break;
    }

    return areaIds;
}

export function getActualStageIds(filterState: IMainViewFilterState, stageItems: IMainViewFilterItemItem[] = []): number[] {
    let stageIds = [];

    switch (filterState.groupBy) {
        case 'area':
        case 'stage':
            stageIds = filterState.stageIds == null ? stageItems.map(i => i.id) : filterState.stageIds;
            break;
        case 'area_room':
            stageIds = [];
            break;
        case 'stage_room':
            stageIds = [filterState.stageRoomId];
            break;
    }

    return stageIds;
}

type IDropDownType = 'areas' | 'stages' | 'css-element' | 'build-up';

@UntilDestroy()
@Component({
    selector: 'bp-main-view-filter',
    templateUrl: './main-view-filter.component.html',
    styleUrls: ['main-view-filter.scss']
})
export class MainViewFilterComponent implements OnInit {
    @Input() refreshSub: Subject<void>;
    @Input() disabled = false;
    @Input() disabledRoomMode = false;
    @Input() initialFilterState: IMainViewFilterState;
    @Output() onChanged = new EventEmitter<IMainViewFilterState>();

    @ViewChild('bpMainViewFilterV3Selector') protected bpMainViewFilterV3Selector: PopperContent;

    protected filterState: IMainViewFilterState;
    protected dropDownType: IDropDownType;
    private allSelected = false;

    private _stageItems: IMainViewFilterItemItem[];

    get stageItems(): IMainViewFilterItemItem[] {
        return this._stageItems;
    }

    @Input()
    set stageItems(value: IMainViewFilterItemItem[]) {
        if (this.filterState?.stageIds?.length) {
            this.filterState.stageIds = this.filterState.stageIds.filter((stageId) => value.find(v => v.id === stageId) != null);
        }

        this._stageItems = value;

        if (this.allSelected) {
            this.filterState.stageIds = _.map(this._stageItems, 'id');
        }
    }

    private _areaItems: IMainViewFilterItemItem[];

    get areaItems(): IMainViewFilterItemItem[] {
        return this._areaItems;
    }

    @Input()
    set areaItems(value: IMainViewFilterItemItem[]) {
        if (this.filterState?.areaIds?.length) {
            this.filterState.areaIds = this.filterState.areaIds.filter((areaId) => value.find(v => v.id === areaId) != null);
        }

        this._areaItems = value;

        if (this.allSelected) {
            this.filterState.areaIds = _.map(this._areaItems, 'id');
        }
    }

    private _cssElementItems: IMainViewFilterItemItem[];

    get cssElementItems(): IMainViewFilterItemItem[] {
        return this._cssElementItems;
    }

    @Input()
    set cssElementItems(value: IMainViewFilterItemItem[]) {
        if (this.filterState?.cssElementIds?.length) {
            this.filterState.cssElementIds = this.filterState.cssElementIds.filter((cssElementId) => value.find(v => v.id === cssElementId) != null);
        }

        this._cssElementItems = value;

        if (this.allSelected) {
            this.filterState.cssElementIds = _.map(this._cssElementItems, 'id');
        }
    }

    private _buildUpItems: IMainViewFilterItemItem[];

    get buildUpItems(): IMainViewFilterItemItem[] {
        return this._buildUpItems;
    }

    @Input()
    set buildUpItems(value: IMainViewFilterItemItem[]) {
        if (this.filterState?.buildUpIds?.length) {
            this.filterState.buildUpIds = this.filterState.buildUpIds.filter((buildUpId) => value.find(v => v.id === buildUpId) != null);
        }

        this._buildUpItems = value;

        if (this.allSelected) {
            this.filterState.buildUpIds = _.map(this._buildUpItems, 'id');
        }
    }

    ngOnInit(): void {
        this.filterState = Object.assign({}, this.initialFilterState);

        if (this.filterState.stageIds == null) {
            this.filterState.stageIds = _.map(this.stageItems, 'id');
        }
        if (this.filterState.areaIds == null) {
            this.filterState.areaIds = _.map(this.areaItems, 'id');
        }

        if (this.filterState.cssElementIds == null) {
            this.filterState.cssElementIds = _.map(this.cssElementItems, 'id');
        }

        if (this.filterState.buildUpIds == null) {
            this.filterState.buildUpIds = _.map(this.buildUpItems, 'id');
        }

        this.allSelected = this.isAllSelected();

        this.emitOnChangeEvent();

        this.refreshSub?.pipe(
            untilDestroyed(this)
        ).subscribe(() => {
            this.refresh();
        })
    }

    toggleAreaDropdown(type: IDropDownType, event): void {
        event.preventDefault();
        event.stopPropagation();
        this.closeAreaDropDown();
        this.dropDownType = type;
        this.allSelected = this.isAllSelected();
    }

    switchToAreasView(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.closeAreaDropDown();
        this.filterState.groupBy = 'area';
        this.emitOnChangeEvent();
        this.allSelected = this.isAllSelected();
    }

    switchToStagesView(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.closeAreaDropDown();
        this.filterState.groupBy = 'stage';
        this.emitOnChangeEvent();
        this.allSelected = this.isAllSelected();
    }

    switchToCssElementView(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.closeAreaDropDown();
        this.filterState.groupBy = 'css-element';
        this.emitOnChangeEvent();
        this.allSelected = this.isAllSelected();
    }

    switchToBuildUpView(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.closeAreaDropDown();
        this.filterState.groupBy = 'build-up';
        this.emitOnChangeEvent();
        this.allSelected = this.isAllSelected();
    }

    apply(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.allSelected = this.isAllSelected();
        this.closeAreaDropDown();
        this.emitOnChangeEvent();
    }

    cancel(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.closeAreaDropDown();
    }

    insideClick(event: any): void {
        event.stopPropagation();
        this.leaveRoom();
    }

    selectArea(area: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        this.filterState.areaRoomId = null;

        if (this.filterState.areaIds == null) {
            const areaIds = _.map(this.areaItems, 'id');

            const index = areaIds.indexOf(area.id);
            if (index !== -1) {
                areaIds.splice(index, 1);
            }
            this.filterState.areaIds = areaIds;
        } else {
            if (this.filterState.areaIds.indexOf(area.id) === -1) {
                this.filterState.areaIds.push(area.id);
            } else {
                const index = this.filterState.areaIds.indexOf(area.id);
                if (index !== -1) {
                    this.filterState.areaIds.splice(index, 1);
                }
            }
        }

        this.allSelected = this.isAllSelected();
    }

    selectStage(stage: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        this.filterState.stageRoomId = null;

        if (this.filterState.stageIds == null) {
            const stageIds = _.map(this.stageItems, 'id');

            const index = stageIds.indexOf(stage.id);
            if (index !== -1) {
                stageIds.splice(index, 1);
            }
            this.filterState.stageIds = stageIds;
        } else {
            if (this.filterState.stageIds.indexOf(stage.id) === -1) {
                this.filterState.stageIds.push(stage.id);
            } else {
                const index = this.filterState.stageIds.indexOf(stage.id);
                if (index !== -1) {
                    this.filterState.stageIds.splice(index, 1);
                }
            }
        }

        this.allSelected = this.isAllSelected();
    }

    selectCssElement(cssElement: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        this.filterState.cssElementRoomId = null;

        if (this.filterState.cssElementIds == null) {
            const cssElementIds = _.map(this.cssElementItems, 'id');

            const index = cssElementIds.indexOf(cssElement.id);
            if (index !== -1) {
                cssElementIds.splice(index, 1);
            }
            this.filterState.cssElementIds = cssElementIds;
        } else {
            if (this.filterState.cssElementIds.indexOf(cssElement.id) === -1) {
                this.filterState.cssElementIds.push(cssElement.id);
            } else {
                const index = this.filterState.cssElementIds.indexOf(cssElement.id);
                if (index !== -1) {
                    this.filterState.cssElementIds.splice(index, 1);
                }
            }
        }

        this.allSelected = this.isAllSelected();
    }

    selectBuildUp(buildUp: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        this.filterState.buildUpRoomId = null;

        if (this.filterState.buildUpIds == null) {
            const buildUpIds = _.map(this.buildUpItems, 'id');

            const index = buildUpIds.indexOf(buildUp.id);
            if (index !== -1) {
                buildUpIds.splice(index, 1);
            }
            this.filterState.buildUpIds = buildUpIds;
        } else {
            if (this.filterState.buildUpIds.indexOf(buildUp.id) === -1) {
                this.filterState.buildUpIds.push(buildUp.id);
            } else {
                const index = this.filterState.buildUpIds.indexOf(buildUp.id);
                if (index !== -1) {
                    this.filterState.buildUpIds.splice(index, 1);
                }
            }
        }

        this.allSelected = this.isAllSelected();
    }

    onAreaRoomClick(area: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabledRoomMode) {
            return;
        }

        this.filterState.groupBy = 'area_room';

        this.filterState.areaRoomId = area.id;
        this.filterState.cssElementRoomId = null;
        this.filterState.buildUpRoomId = null;
        this.filterState.stageRoomId = null;
    }

    onStageRoomClick(stage: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabledRoomMode) {
            return;
        }

        this.filterState.groupBy = 'stage_room';

        this.filterState.areaRoomId = null;
        this.filterState.cssElementRoomId = null;
        this.filterState.buildUpRoomId = null;
        this.filterState.stageRoomId = stage.id;
    }

    onCssElementRoomClick(cssElement: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabledRoomMode) {
            return;
        }

        this.filterState.groupBy = 'css-element_room';

        this.filterState.areaRoomId = null;
        this.filterState.stageRoomId = null;
        this.filterState.cssElementRoomId = cssElement.id;
        this.filterState.buildUpRoomId = null;
    }

    onBuildUpRoomClick(buildUp: IMainViewFilterItemItem, event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabledRoomMode) {
            return;
        }

        this.filterState.groupBy = 'build-up_room';

        this.filterState.areaRoomId = null;
        this.filterState.stageRoomId = null;
        this.filterState.cssElementRoomId = null;
        this.filterState.buildUpRoomId = buildUp.id;
    }


    isAreaSelected(area: IMainViewFilterItemItem): boolean {
        return (
            this.filterState.areaIds == null ||
            this.filterState.areaIds.indexOf(area.id) !== -1 ||
            this.filterState.areaRoomId === area.id
        );
    }

    isStageSelected(stage: IMainViewFilterItemItem): boolean {
        return (
            this.filterState.stageIds == null ||
            this.filterState.stageIds.indexOf(stage.id) !== -1 ||
            this.filterState.stageRoomId === stage.id
        );
    }

    isCssElementSelected(cssElement: IMainViewFilterItemItem): boolean {
        return (
            this.filterState.cssElementIds == null ||
            this.filterState.cssElementIds.indexOf(cssElement.id) !== -1 ||
            this.filterState.cssElementRoomId === cssElement.id
        );
    }

    isBuildUpSelected(buildUp: IMainViewFilterItemItem): boolean {
        return (
            this.filterState.buildUpIds == null ||
            this.filterState.buildUpIds.indexOf(buildUp.id) !== -1 ||
            this.filterState.buildUpRoomId === buildUp.id
        );
    }

    selectAllTitle(): string {
        switch (this.dropDownType) {
            case 'areas':
                return 'All Areas';
            case 'stages':
                return 'All Stages';
            case 'css-element':
                return 'All css elements';
            case 'build-up':
                return 'All build-ups';
        }
    }

    toggleSelectAll(event): void {
        event.preventDefault();
        event.stopPropagation();

        this.leaveRoom();

        this.selectUnselectAll(!this.allSelected);
        this.allSelected = !this.allSelected;
    }

    selectUnselectAll(selectAll: boolean): void {
        switch (this.dropDownType) {
            case 'stages':
                this.filterState.stageIds = selectAll ? _.map(this.stageItems, 'id') : [];
                break;

            case 'areas':
                this.filterState.areaIds = selectAll ? _.map(this.areaItems, 'id') : [];
                break;

            case 'css-element':
                this.filterState.cssElementIds = selectAll ? _.map(this.cssElementItems, 'id') : [];
                break;

            case 'build-up':
                this.filterState.buildUpIds = selectAll ? _.map(this.buildUpItems, 'id') : [];
                break;
        }
    }

    isAllSelected(): boolean {
        switch (this.dropDownType) {
            case 'stages':
                return this.filterState.stageIds == null || this.filterState.stageIds?.length === this.stageItems?.length;
            case 'areas':
                return this.filterState.areaIds == null || this.filterState.areaIds?.length === this.areaItems?.length;
            case 'css-element':
                return this.filterState.cssElementIds == null || this.filterState.cssElementIds?.length === this.cssElementItems?.length;
            case 'build-up':
                return this.filterState.buildUpIds == null || this.filterState.buildUpIds?.length === this.buildUpItems?.length;

        }
    }

    private closeAreaDropDown(): void {
        this.bpMainViewFilterV3Selector.hide();
    }

    private emitOnChangeEvent(): void {
        if (this.filterState.stageIds != null && this.filterState.stageIds?.length === this.stageItems.length) {
            this.filterState.stageIds = null;
        }

        if (this.filterState.areaIds != null && this.filterState.areaIds?.length === this.areaItems?.length) {
            this.filterState.areaIds = null;
        }

        if (this.filterState.cssElementIds != null && this.filterState.cssElementIds?.length === this.cssElementItems?.length) {
            this.filterState.cssElementIds = null;
        }

        if (this.filterState.buildUpIds != null && this.filterState.buildUpIds?.length === this.buildUpItems?.length) {
            this.filterState.buildUpIds = null;
        }

        this.onChanged.emit(_.clone(this.filterState));
    }

    private leaveRoom(): void {
        switch (this.dropDownType) {
            case 'areas':
                this.filterState.areaRoomId = null;
                if (this.filterState.groupBy === 'area_room') {
                    this.filterState.groupBy = 'area';
                }
                break;
            case 'stages':
                this.filterState.stageRoomId = null;
                if (this.filterState.groupBy === 'stage_room') {
                    this.filterState.groupBy = 'stage';
                }
                break;
            case 'css-element':
                this.filterState.stageRoomId = null;
                if (this.filterState.groupBy === 'css-element_room') {
                    this.filterState.groupBy = 'css-element';
                }
                break;
        }
    }

    private refresh(): void {
        switch (this.dropDownType) {
            case 'areas':
                this.areaItems = this.areaItems;
                break;
            case 'stages':
                this.stageItems = this.stageItems;
                break;
            case 'css-element':
                this.cssElementItems = this.cssElementItems;
                break;
            case 'build-up':
                this.buildUpItems = this.buildUpItems;
                break;
        }
    }

}
