import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { EditScheduleAreasComponent } from 'app/flows/scheduler/schedule/components/edit-schedule-areas/edit-schedule-areas.component';

@Injectable({
    providedIn: 'root'
})
export class EditScheduleAreasCanDeactivateGuard implements CanDeactivate<EditScheduleAreasComponent> {
    canDeactivate(component: EditScheduleAreasComponent): Promise<boolean> | boolean {
        return component.save(true, false);
    }
}
