import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ITag } from 'app/shared/model/tag.model';
import { TagService } from 'app/shared/dataservices/tag.service';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { IAccount } from 'app/shared/model/account.model';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TagDeleteDialogComponent } from "app/entities/tag/delete/tag-delete-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BpAlertService } from "app/shared/services/bp-alert.service";

@UntilDestroy()
@Component({
    selector: 'bp-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['tag-list.scss']
})
export class TagListComponent implements OnInit {
    protected tags: ITag[];
    protected currentAccount: IAccount;
    protected itemsPerPage: number;
    protected page: number;
    protected predicate: any;
    protected queryCount: number;
    protected reverse: boolean;
    protected totalItems: number;
    protected tagPrefix: string;

    protected searchControl = new FormControl();

    constructor(
        private tagService: TagService,
        private modalService: NgbModal,
        private accountService: AccountService,
        private entityQueryStorageService: EntityQueryStorageService,
        private alertService: BpAlertService
    ) {
        this.tags = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('TAG');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
        this.tagPrefix = entityQuery.searchValue || '';

        this.searchControl.setValue(this.tagPrefix);
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });

        this.searchControl.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe((term: string) => {
            this.tagPrefix = term;
            this.loadPage(0);
        });
    }

    protected reset(): void {
        this.page = 0;
        this.tags = [];
        this.saveEntityQuery();
        this.loadAll();
    }

    protected loadPage(page): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadAll();
    }

    protected trackId(index: number, item: ITag): number {
        return item.id;
    }

    protected hasMore(): boolean {
        return this.tags.length < this.totalItems;
    }

    protected onDeleteClick(tag: ITag): void {
        const ngbModalRef = this.modalService.open(TagDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.tag = tag;

        ngbModalRef.result.then(
            (res) => {
                if (res) {
                    this.reset();
                } else {
                    this.alertService.error('Cannot delete tag as it is assigned to some project(s)!');
                }
            }, (() => {
                //
            })
        );
    }

    private loadAll(): void {
        this.tagService
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort(),
                searchValue: this.tagPrefix
            })
            .subscribe(
                (res: HttpResponse<ITag[]>) => this.paginateTags(res.body, res.headers)
            );
    }

    private sort(): string {
        return this.predicate + ',' + (this.reverse ? 'asc' : 'desc');
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse,
            searchValue: this.tagPrefix
        };

        this.entityQueryStorageService.store('TAG', entityQuery);
    }

    private paginateTags(data: ITag[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.tags = [];
        }

        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);

        for (let i = 0; i < data.length; i++) {
            this.tags.push(data[i]);
        }
    }
}
