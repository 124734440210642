import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { ProjectCommentsService } from 'app/shared/dataservices/project-comments.service';
import { IProject } from 'app/shared/model/project.model';
import { IProjectComment } from 'app/shared/model/bp.model';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { last } from 'rxjs/operators';
import { AccountService } from 'app/core/auth/account.service';

@Component({
    selector: 'bp-project-comments-modal',
    templateUrl: './project-comments-modal.component.html',
    styleUrls: ['project-comments-modal.scss']
})
export class ProjectCommentsModalComponent {

    project: IProject;
    scheduleTaskId?: number;

    newCommentControl = new FormControl(null, [Validators.required]);

    private commentsSubject = new BehaviorSubject<IProjectComment[] | undefined>(undefined);
    comments$ = this.commentsSubject.asObservable();

    get accountId(): number {
        return this.accountService.getAccountId();
    }

    constructor(private activeModal: NgbActiveModal,
                private accountService: AccountService,
                private projectCommentsService: ProjectCommentsService) {
    }

    close(): void {
        this.activeModal.dismiss('close');
    }

    addComment(): void {
        lastValueFrom(this.projectCommentsService.create(this.project.id, this.newCommentControl.value, this.scheduleTaskId)).then(() => {
            this.newCommentControl.setValue(null);
            this.refresh();
        });
    }

    refresh(): void {
        lastValueFrom(this.projectCommentsService.query(this.project.id, this.scheduleTaskId)).then((res: HttpResponse<IProjectComment[]>) => {
            this.commentsSubject.next(res.body);
        })
    }

    trackById(index: number, comment: IProjectComment): number {
        return comment.id;
    }

    remove(comment: IProjectComment): void {
        lastValueFrom(this.projectCommentsService.delete(this.project.id, comment.id)).then((res: HttpResponse<IProjectComment[]>) => {
            this.refresh();
        })
    }

    protected readonly last = last;
}
