import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { IAccount } from 'app/shared/model/account.model';
import { ITrade } from 'app/shared/model/trade.model';
import { TradeService } from 'app/shared/dataservices/trade.service';
import { AccountService } from 'app/core/auth/account.service';
import { TradeDeleteDialogComponent } from "app/entities/trade/trade-delete-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'bp-trade',
    templateUrl: './trade.component.html'
})
export class TradeComponent implements OnInit {
    protected trades: ITrade[];
    protected currentAccount: IAccount;

    constructor(
        private tradeService: TradeService,
        private accountService: AccountService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });
    }

    protected onDeleteClick(trade: ITrade): void {
        const ngbModalRef = this.modalService.open(TradeDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.trade = trade;

        ngbModalRef.result.then(
            result => {
            this.loadAll();
            },
            reason => {
             //
            }
        );
    }

    protected trackId(index: number, item: ITrade): number {
        return item.id;
    }

    private loadAll(): void {
        this.tradeService.query({size: 999}).subscribe(
            (res: HttpResponse<ITrade[]>) => {
                this.trades = res.body;
            }
        );
    }
}
