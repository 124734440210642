<div class="flex flex-column gap-20">
    <div>
        <div>
            Existing floorplans:
        </div>
        <div class="flex-1">
            <input [formControl]="existingFloorPlanURLControl"
                   class="form-control v2"
                   placeholder="Post a link to dropbox, google drive etc. or upload below"
                   id="existing-floor-plan-url"
                   name="existing-floor-plan-url"
                   type="text">
        </div>
        <div class="flex flex-row align-items-center gap-10">
            <bp-project-attachments-fe-only
                (onChanged)="existingFloorPlanFiles = $event"
                [project]="project"
                [accept]="'application/pdf'"
                [showLimitInfo]="true"
                [buttonLabel]="'Upload'">
            </bp-project-attachments-fe-only>
        </div>
    </div>
    <div>
        <div>
            Proposed floorplans:
        </div>
        <div class="flex-1">
            <input [formControl]="proposedFloorPlanURLControl"
                   class="form-control v2"
                   placeholder="Post a link to dropbox, google drive etc. or upload below"
                   id="proposed-floor-plan-url"
                   name="proposed-floor-plan-url"
                   type="text">
        </div>
        <div class="flex flex-row align-items-center gap-10">
            <bp-project-attachments-fe-only
                (onChanged)="proposedFloorPlanFiles = $event"
                [project]="project"
                [accept]="'application/pdf'"
                [showLimitInfo]="true"
                [buttonLabel]="'Upload'">
            </bp-project-attachments-fe-only>
        </div>
    </div>
</div>
