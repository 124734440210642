import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IProject } from 'app/shared/model/project.model';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class ShowSubStagesStorageService {
    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(project: IProject, value: boolean): void {
        this.webStorage.store(this.getLocalStorageKey(project), value);
    }

    retrieve(project: IProject): boolean {
        const value: boolean = this.webStorage.retrieve(this.getLocalStorageKey(project));
        return value != null ? value : true;
    }

    private getLocalStorageKey(project: IProject): string {
        return `quoter_${this.accountService.getAccountId()}_showSubStages_${project.id}`;
    }
}
