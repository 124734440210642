import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { IProject } from 'app/shared/model/project.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import {
    IndividualQuoteExpandStagesStorageService
} from 'app/flows/scheduler/individual-quote/services/expand-stages-storage.service';
import { IComparisonQoter } from 'app/shared/model/comparison.model';

@Component({
    selector: 'bp-individual-quote-stage',
    templateUrl: './stage.component.html'
})
export class IndividualQuoteStageComponent implements OnChanges {
    @Input() project: IProject;
    @Input() stage: IComparisonStage;
    @Input() quoter: IComparisonQoter;
    @Input() plain = false;
    @Input() showGroupNode: boolean;

    constructor(
        private expandStagesStorageService: IndividualQuoteExpandStagesStorageService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.expanded != null &&
            !changes.expanded.firstChange &&
            !_.isEqual(changes.expanded.currentValue, changes.expanded.previousValue)
        ) {
            if (changes.expanded.currentValue) {
                this.expand();
            } else {
                this.collapse();
            }
        }
    }

    toggleExpand(): void {
        this.stage.expanded ? this.collapse() : this.expand();
    }

    private expand(): void {
        this.stage.expanded = true;
        this.expandStagesStorageService.store(this.project, this.quoter.id, this.stage, true);
    }

    private collapse(): void {
        this.stage.expanded = false;
        this.expandStagesStorageService.store(this.project, this.quoter.id, this.stage, false);
    }
}
