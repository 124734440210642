import { Injectable } from '@angular/core';
import { CustomWindow } from 'app/shared/util/custom.window';

declare const window: CustomWindow;

@Injectable({ providedIn: 'root' })
export class GtagService {

    constructor() {
    }

    sendConversionEvent(): void {
        if (window.gtag) {
            window.gtag('event', 'conversion', {
                    'send_to': 'AW-647573610/T3S4CP_35oEYEOrg5LQC'
                }
            );
        }
    }
}
