import { Route } from '@angular/router';
import { AdminComponent } from 'app/flows/admin/admin.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

export const ADMIN_ROUTE: Route = {
    path: 'admin',
    component: AdminComponent,
    data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'Admin'
    },
    canActivate: [UserRouteAccessService]
};
