import { Injectable } from '@angular/core';
import { AccountService } from 'app/core/auth/account.service';
import { IProject } from 'app/shared/model/project.model';

@Injectable({ providedIn: 'root' })
export class AccountHelperService {

    constructor(private accountService: AccountService) {
    }

    public isUserNeedToBeSubscribed(): boolean {
        if (this.accountService.isAdmin()) {
            return false;
        }

        if (!this.accountService.isScheduler() || this.accountService.getTrialPeriodEnd() == null) {
            return false;
        }

        return !this.accountService.hasPaymentProviders();
    }

    public isIndividualQuoterTheSameAsScheduler(project?: IProject): boolean {
        if (!project || !project.defaultQuoter || project.defaultQuoter.userId == null) {
            return false;
        }
        return +project.defaultQuoter.userId === this.accountService.getAccountId();
    }
}
