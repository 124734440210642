import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScheduleAreasStore {
    private scheduleAreasSubject: BehaviorSubject<IScheduleArea[] | null> = new BehaviorSubject<IScheduleArea[] | null>(null);
    scheduleAreas$: Observable<IScheduleArea[] | null> = this.scheduleAreasSubject.asObservable();

    get scheduleAreas(): IScheduleArea[] | null {
        return this.scheduleAreasSubject.value;
    }

    set scheduleAreas(value: IScheduleArea[] | null) {
        this.scheduleAreasSubject.next(value);
    }

    projectId: number | null;

    constructor(private projectApi: ProjectApi) {
    }

    refresh(projectId: number, force = false): Promise<IScheduleArea[]> {
        if (this.projectId !== projectId || force || this.scheduleAreas == null) {
            this.projectId = projectId;
            return lastValueFrom(this.projectApi.queryAvailableScheduleAreas(this.projectId)
                .pipe(debounceTime(700), distinctUntilChanged()))
                .then((res: HttpResponse<IScheduleArea[]>) => {
                this.scheduleAreas = res.body;
                return this.scheduleAreas;
            })
        } else {
            return lastValueFrom(this.scheduleAreas$);
        }
    }
}
