<div class="modal-header">
    <div class="text-center"
         style="flex: 1">
        <h2>
            Request template
        </h2>
    </div>
    <button (click)="clear()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form"
          autocomplete="off"
          class="card m-t-20"
          name="request-template-form"
          novalidate>

        <div class="form-group">
            <div class="form-line">
                <label for="templateName">
                    Name
                </label>
                <input
                    #templateNameElement
                    [ngClass]="{ 'error': (submitted || form.controls.templateName.dirty || form.controls.templateName.touched) && form.controls.templateName.invalid }"
                    class="form-control v2 gray"
                    [formControlName]="'templateName'"
                    name="templateName"
                    id="templateName"
                    autocomplete="off"
                    type="text">
                <div *ngIf="(submitted || form.controls.templateName.dirty) && form.controls.templateName.invalid"
                     class="position-absolute">
                    <label *ngIf="form.controls.templateName.errors.required"
                           class="error">
                        This field is required.
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="form-line">
                <label for="templateName">
                    Description
                </label>
                <input
                    [ngClass]="{ 'error': (submitted || form.controls.templateDescription.dirty || form.controls.templateDescription.touched) && form.controls.templateDescription.invalid }"
                    class="form-control v2 gray"
                    [formControlName]="'templateDescription'"
                    name="template-description"
                    id="template-description"
                    autocomplete="off"
                    type="text">
                <div
                    *ngIf="(submitted || form.controls.templateDescription.dirty) && form.controls.templateDescription.invalid"
                    class="position-absolute">
                    <label *ngIf="form.controls.templateDescription.errors.required"
                           class="error">
                        This field is required.
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="project">
            <div class="form-line">
                <label for="existing-floor-plan-url">
                    Upload proposed floorplans (if any)
                </label>
                <div class="flex-1">
                    <input [formControlName]="'proposedFloorPlanURLControl'"
                           class="form-control v2"
                           placeholder="Add your attachments URL (Dropbox, Google Drive or similar)"
                           id="existing-floor-plan-url"
                           name="existing-floor-plan-url"
                           type="text">
                </div>
                <div class="flex flex-row align-items-center gap-10">
                    <bp-project-attachments-fe-only
                        (onChanged)="proposedFloorPlanFiles = $event"
                        [project]="project"
                        [buttonLabel]="'Upload'"
                        [showLimitInfo]="true"
                        [maxCountOfAttachments]="1">
                    </bp-project-attachments-fe-only>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer modal-footer-centered">
    <button [disabled]="!form.valid"
            (click)="onRequestTemplateClick()"
            tabindex="0"
            class="btn btn-primary btn-border-radius waves-effect request-template"
            type="button">
        Request
    </button>
</div>

