import { finalize } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { LoginService } from 'app/core/login/login.service';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'app/shared/constants/links.constants';
import { IRegion } from 'app/shared/model/region.model';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import {
    EMAIL_ALREADY_USED_TYPE,
    INVALID_PASSWORD,
    LOGIN_ALREADY_USED_TYPE
} from 'app/shared/constants/error.constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bp-create-quoter-from-invitation',
    templateUrl: './create-quoter-from-invitation.component.html',
    styleUrls: ['create-quoter-from-invitation.scss']
})
export class CreateQuoterFromInvitationComponent implements OnInit, OnDestroy, AfterViewInit {
    protected confirmPassword: string;
    protected registerAccount: any;
    protected agreeLicense: boolean;
    protected token: string;
    protected authenticationError: boolean;
    protected inProcessLoadRegions = false;
    protected regions: IRegion[];
    protected region: IRegion;

    protected TERMS_OF_USE_URL = TERMS_OF_USE_URL;
    protected PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;

    protected routeQuerySub = Subscription.EMPTY;

    constructor(
        private invitationService: InvitationService,
        private loginService: LoginService,
        private elementRef: ElementRef,
        private alertService: BpAlertService,
        private router: Router,
        private route: ActivatedRoute,
        private stateStorageService: StateStorageService,
        private regionService: RegionApi
    ) {
        this.routeQuerySub = this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    ngOnInit(): void {
        this.registerAccount = {};
        this.agreeLicense = false;
        this.loadRegions();
    }

    ngOnDestroy(): void {
        this.routeQuerySub.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#password').focus();
        }, 0);
    }

    cancel(): void {
        this.router.navigate(['/login']);
    }

    register(): void {
        if (this.registerAccount.password !== this.confirmPassword) {
            this.alertService.error('The password and its confirmation do not match!');
        } else {
            this.invitationService.registerQuoter(this.token, this.registerAccount.password, this.region).subscribe(
                (res: any) => {
                    this.loginService
                        .login({
                            username: res.body.email,
                            password: this.registerAccount.password
                        })
                        .then(() => {
                            const processLogin = () => {
                                this.authenticationError = false;

                                this.router.navigate(['']);

                                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                                // since login is successful, go to stored previousState and clear previousState
                                const redirect = this.stateStorageService.getUrl();
                                if (redirect) {
                                    this.stateStorageService.storeUrl(null);
                                    this.router.navigate([redirect]);
                                }
                            };

                            this.invitationService
                                .acceptInvitation(this.token)
                                .pipe(
                                    finalize(() => {
                                        processLogin();
                                    })
                                )
                                .subscribe();
                        })
                        .catch(() => {
                            this.authenticationError = true;
                        });
                },
                response => this.processError(response)
            );
        }
    }

    login(): void {
        this.router.navigate(['/login']);
    }

    private processError(response: HttpErrorResponse): void {
        if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
            this.alertService.error('Login name already registered! Please choose another one.');
        } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
            this.alertService.error('Email is already in use! Please choose another one.');
        } else if (response.status === 400 && response.error.type === INVALID_PASSWORD) {
            this.alertService.error(response.error.title);
        } else {
            this.alertService.error('Registration failed! Please try again later.');
        }
    }

    private loadRegions(): void {
        this.inProcessLoadRegions = true;

        this.regionService
            .query()
            .pipe(
                finalize(() => {
                    this.inProcessLoadRegions = false;
                })
            )
            .subscribe(
                (res: HttpResponse<IRegion[]>) => {
                    this.regions = res.body;
                    this.region = this.regions[0];
                }
            );
    }
}
