import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from '../../app.constants';
import { IProfession } from 'app/shared/model/profession.model';
import { IRegion } from 'app/shared/model/region.model';

export interface IRegistrationAccount {
    authorities?: string[];
    email?: string;
    firstName?: string;
    lastName?: string;
    login?: string;
    imageUrl?: string;
    company?: string;
    companyNumber?: string;
    profession?: IProfession;
    heardAboutUs?: string;
    activationKey?: string;
    password?: string;
    langKey?: string;
    kwTracking?: string;
    region?: IRegion
}

@Injectable({ providedIn: 'root' })
export class RegisterService {
    constructor(private http: HttpClient) {
    }

    save(account: any): Observable<{ id_token: string }> {
        return this.http.post<{ id_token: string }>(SERVER_API_URL + 'api/register', account);
    }
}
