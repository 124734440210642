import { Route } from '@angular/router';
import { BpLogoutComponent } from 'app/shared/logout/logout.component';

export const logoutRoute: Route = {
    path: 'logout',
    component: BpLogoutComponent,
    data: {
        authorities: [],
        pageTitle: 'Logout'
    }
};
