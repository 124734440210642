<div class="card">
    <ng-container
        *ngIf="comparisonStore.filterState.groupBy === 'css-element' || comparisonStore.filterState.groupBy === 'css-element_room'">
        <bp-quoter-totals [quoters]="comparisonStore.quoters"
                          [showTotals]="true">
        </bp-quoter-totals>

        <div class="flex flex-column">
            <bp-comparison-css-element *ngFor="let bp of comparisonStore.cssElementMap | keyvalue: originalOrder"
                                    [cssElement]="bp.value.cssElement"
                                    [scheduleTasks]="bp.value.scheduleTasks"
                                    [totals]="bp.value.totals">
            </bp-comparison-css-element>
        </div>

        <div class="m-l-20"
             *ngIf="!comparisonStore.cssElementItems.length">
            <span>No CSS elements to show</span>
        </div>
    </ng-container>

    <ng-container
        *ngIf="comparisonStore.filterState.groupBy === 'stage' || comparisonStore.filterState.groupBy === 'stage_room'">
        <bp-quoter-totals [quoters]="comparisonStore.quoters"
                          [showTotals]="true">
        </bp-quoter-totals>

        <div class="flex flex-column">
            <bp-comparison-stage
                *ngFor="let stage of comparisonStore.comparison.stageDTOs"
                [stage]="stage"
                [showGroupNode]="comparisonStore.filterState.groupBy === 'stage'">
            </bp-comparison-stage>
        </div>

        <div class="m-l-20 m-t-10"
             *ngIf="!comparisonStore.comparison.stageDTOs?.length">
            <span>No stages to show</span>
        </div>
    </ng-container>

    <ng-container
        *ngIf="comparisonStore.filterState.groupBy === 'area' || comparisonStore.filterState.groupBy === 'area_room'">
        <bp-quoter-totals [quoters]="comparisonStore.quoters"
                          [showTotals]="true">
        </bp-quoter-totals>

        <div *ngFor="let area of comparisonStore.filteredScheduleAreas; let first = first;"
             [ngClass]="{'m-t-10': first}"
             class="af-row-container no-bottom-border">
            <ng-container *ngIf="area['comparison']">
                <div *ngIf="comparisonStore.filterState.groupBy === 'area' && !area.expanded">
                    <div class="row">
                        <div class="col-sm-5 cursor-pointer">
                            <div (click)="onClosedAreaIconClick(area)">
                                <h3>
                                    {{ area.area }}
                                </h3>
                                <i class="material-icons md-18">chevron_right</i>
                            </div>
                        </div>
                        <div class="col-sm-7 flex-container align-items-center flex-end gap-40">
                            <h4 *ngFor="let quoter of area['comparison'].quoters"
                                class="flex-1 max-width-30-percents">
                                {{ quoter['total'] || 0 | currency:'GBP' }}
                            </h4>
                        </div>
                    </div>
                </div>

                <div *ngIf="comparisonStore.filterState.groupBy === 'area_room' || area.expanded">
                    <div class="row"
                         *ngIf="comparisonStore.filterState.groupBy === 'area'">
                        <div class="col-sm-5 cursor-pointer">
                            <div (click)="onOpenedAreaIconClick(area)">
                                <h3>
                                    {{ area.area }}
                                </h3>
                                <i class="material-icons md-18">expand_more</i>
                            </div>
                        </div>
                        <div class="col-sm-7 flex-container align-items-center flex-end gap-40">
                            <h4 *ngFor="let quoter of area['comparison'].quoters"
                                class="flex-1 max-width-30-percents">
                                {{ quoter['total'] || 0 | currency:'GBP' }}
                            </h4>
                        </div>
                    </div>

                    <div class="flex flex-column gap-20 element-container"
                         [ngClass]="{'m-t-10': first && comparisonStore.filterState.groupBy === 'area'}"
                         *ngFor="let stage of area['comparison']['stageDTOs'] || []; let first = first;">
                        <bp-comparison-element
                            *ngFor="let element of stage.elementDTOs"
                            [element]="element">
                        </bp-comparison-element>
                    </div>

                    <div class="m-l-20 m-t-10"
                         *ngIf="!(area['comparison']['stageDTOs'] || []).length">
                        <span>No stages to show</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>

