import { ITemplateRequest } from 'app/shared/model/template-request.model';
import { TemplateRequestApi } from 'app/shared/dataservices/template-request.api';
import { ITemplateRequestData, TemplateWizardStore } from 'app/flows/scheduler/template-wizard/template-wizard.store';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TemplateWizardService {
    constructor(
        private templateRequestApi: TemplateRequestApi,
        private templateWizardStore: TemplateWizardStore
    ) {
    }

    init(): void {
    }

    sendTemplateRequest(projectId: number, templateRequestData: ITemplateRequestData): Promise<boolean> {
        return new Promise(resolve => {
            const promises = this.generatePromises(projectId, [templateRequestData]);
            if (promises.length) {
                Promise.all(promises).then(() => {
                    resolve(true);
                })
            } else {
                resolve(false);
            }
        });
    }

    sendTemplateRequestsBatchIfNeeded(projectId: number): Promise<boolean> {
        return new Promise(resolve => {
            const promises = this.generatePromises(projectId, this.templateWizardStore.templateRequestDataArray);
            if (promises.length) {
                Promise.all(promises).then(() => {
                    resolve(true);
                })
            } else {
                resolve(false);
            }
        });
    }

    private generatePromises(projectId: number, templateRequestDataArray: ITemplateRequestData[]): Function[] {
        const promises = [];
        if (templateRequestDataArray?.length) {
            templateRequestDataArray.forEach((templateRequestData) => {
                const templateRequest: ITemplateRequest = {
                    name: templateRequestData.name,
                    description: templateRequestData.description,
                    projectId
                };
                promises.push(lastValueFrom(
                    this.templateRequestApi.create(templateRequest,
                    templateRequestData.attachments.map(a => a.file),
                    templateRequestData.attachments.map(a => a.additionalProperties)[0]))
                );
            });
        }

        return promises;
    }
}
