import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IStripeCheckoutSessionResponse, IStripePlan } from 'app/shared/model/stripe.model';

export interface IStripeCheckoutSession {
    priceId: string,
    email: string;
    trialDays: number, //set 0 if you dont wanna add trial
    quantity: 1, // quantity of subscription
    promotionCode?: string // ex: promo_1MlCkJIpV58FRykGCjezyLie, promocode, if this is added user will not have option to input manually
}

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    constructor(private http: HttpClient) {
    }

    queryPlans(): Observable<HttpResponse<IStripePlan[]>> {
        return this.http.get<IStripePlan[]>(SERVER_API_URL + 'api/subscription/stripe/plans', { observe: 'response' });
    }

    stripeCheckoutSession(stripeCheckoutSession: IStripeCheckoutSession): Observable<HttpResponse<IStripeCheckoutSessionResponse>> {
        return this.http.post<HttpResponse<IStripeCheckoutSessionResponse>>(
            SERVER_API_URL + 'api/subscription/stripe/checkout_session/', stripeCheckoutSession, { observe: 'response' });
    }
}
