import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsubscriptionService } from 'app/shared/dataservices/unsubscription.service';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from "rxjs";

@Component({
    selector: 'bp-unsubscribe-completed-modal',
    templateUrl: './unsubscribe-completed-modal.component.html',
    styleUrls: ['unsubscribe-completed-modal.scss']
})
export class UnsubscribeCompletedModalComponent implements OnInit {

    form: FormGroup = this.fb.group({
        reason: new FormControl(null, [Validators.required]),
        comment: new FormControl()
    });

    protected reasonSelectInputData: SelectInputData;

    constructor(private fb: FormBuilder,
                private activeModal: NgbActiveModal,
                private unsubscriptionService: UnsubscriptionService) {
    }

    ngOnInit(): void {
        lastValueFrom(this.unsubscriptionService.queryReasons()).then(res => {
            this.reasonSelectInputData = {
                data: res.body,
                indexProperty: 'id',
                titleProperty: 'text',
                dropdownPosition: 'bottom',
                searchable: false
            };
        })
    }

    confirm(): void {
        this.activeModal.close({
            reason: this.form.controls.reason.value,
            comment: this.form.controls.comment.value
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }
}
