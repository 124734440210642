import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISpecification } from 'app/shared/model/specification.model';
import { SpecificationService } from 'app/shared/dataservices/specification.service';

@Component({
    selector: 'bp-spec-update',
    templateUrl: './spec-update.component.html'
})
export class SpecUpdateComponent implements OnInit {
    spec: ISpecification;
    isSaving: boolean;

    constructor(private specService: SpecificationService, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ spec }) => {
            this.spec = spec;
        });
    }

    previousState() {
        window.history.back();
    }

    save() {
        this.isSaving = true;
        if (this.spec.id !== undefined) {
            this.subscribeToSaveResponse(this.specService.update(this.spec));
        } else {
            this.subscribeToSaveResponse(this.specService.create(this.spec));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<ISpecification>>) {
        result.subscribe(
            (res: HttpResponse<ISpecification>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
