import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { IProfession } from 'app/shared/model/profession.model';
import { ProfessionService } from 'app/shared/dataservices/profession.service';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { IAccount } from 'app/shared/model/account.model';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProfessionDeleteDialogComponent } from 'app/entities/profession/delete/profession-delete-dialog.component';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy()
@Component({
    selector: 'bp-profession-list',
    templateUrl: './profession-list.component.html',
    styleUrls: ['profession-list.scss']
})
export class ProfessionListComponent implements OnInit {
    professions: IProfession[];
    protected currentAccount: IAccount;
    protected itemsPerPage: number;
    protected page: number;
    protected predicate: string;
    protected queryCount: number;
    protected reverse: boolean;
    protected totalItems: number;
    protected professionPrefix: string;

    protected searchControl = new FormControl();

    constructor(
        private professionService: ProfessionService,
        private modalService: NgbModal,
        private accountService: AccountService,
        private entityQueryStorageService: EntityQueryStorageService,
        private alertService: BpAlertService
    ) {
        this.professions = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('PROFESSION');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
        this.professionPrefix = entityQuery.searchValue || '';

        this.searchControl.setValue(this.professionPrefix);
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });

        this.searchControl.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged(),
            untilDestroyed(this))
            .subscribe((term: string) => {
                this.professionPrefix = term;
                this.loadPage(0);
            });
    }

    reset(): void {
        this.page = 0;
        this.professions = [];
        this.saveEntityQuery();
        this.loadAll();
    }

    loadPage(page): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadAll();
    }

    trackId(index: number, item: IProfession): number {
        return item.id;
    }

    hasMore(): boolean {
        return this.professions.length < this.totalItems;
    }

    protected onDeleteClick(profession: IProfession): void {
        const ngbModalRef = this.modalService.open(ProfessionDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.profession = profession;

        ngbModalRef.result.then(
            () => {
                this.reset();
            },
            (reason) => {
                if (reason.type === 'error') {
                    this.alertService.error(reason.text);
                }
            }
        );
    }

    private loadAll(): void {
        this.professionService
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort(),
                searchValue: this.professionPrefix
            })
            .subscribe(
                (res: HttpResponse<IProfession[]>) => this.paginateProfessions(res.body, res.headers)
            );
    }

    private sort(): string {
        return this.predicate + ',' + (this.reverse ? 'asc' : 'desc');
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse,
            searchValue: this.professionPrefix
        };

        this.entityQueryStorageService.store('PROFESSION', entityQuery);
    }

    private paginateProfessions(data: IProfession[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.professions = [];
        }
        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
        for (let i = 0; i < data.length; i++) {
            this.professions.push(data[i]);
        }
    }
}
