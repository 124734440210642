import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
    BUILD_PARTNER_SUBSCRIBE_URL,
    EXCEL_REPORT_EXAMPLE_LINK,
    PDF_REPORT_EXAMPLE_LINK,
    WORD_REPORT_EXAMPLE_LINK
} from 'app/shared/constants/links.constants';

export type FreemiumModalType =
    'type_create_project'
    | 'type_create_project_monthly'
    | 'type_export'
    | 'type_upload_and_auto_calculate'
    | 'type_request_template'
    | 'type_delete_project';

@Component({
    selector: 'freemium-modal',
    templateUrl: './freemium-modal.component.html',
    styleUrls: ['freemium-modal.scss']
})
export class FreemiumModalComponent {
    @BlockUI() blockUI: NgBlockUI;

    type: FreemiumModalType = 'type_create_project';

    EXCEL_REPORT_EXAMPLE_LINK = EXCEL_REPORT_EXAMPLE_LINK;
    WORD_REPORT_EXAMPLE_LINK = WORD_REPORT_EXAMPLE_LINK;
    PDF_REPORT_EXAMPLE_LINK = PDF_REPORT_EXAMPLE_LINK;

    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    get title(): string {
        switch (this.type) {
            case 'type_create_project':
                return `You've hit your project limit!`;
            case 'type_create_project_monthly':
                return `You've hit the monthly project limit on</br> your plan!`;
            case 'type_delete_project':
                return `Sign up to use this feature!`;
            case 'type_export':
                return `Upgrade to download your project export`;
            case 'type_upload_and_auto_calculate':
                return `Upgrade to request your own automatic takeoff!`;
            case 'type_request_template':
                return `Upgrade to</br> request your own personalised template!`;
        }
    }

    onOkClick(): void {
        window.open(BUILD_PARTNER_SUBSCRIBE_URL, '_blank');
    }

    clear(): void {
        this.activeModal.dismiss('close');
    }
}
