import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPageableProjectQuoterRequest, IProjectQuoterRequest } from 'app/shared/model/project-quoter-request.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';

@Injectable({ providedIn: 'root' })
export class ProjectQuoterRequestService {
    public resourceUrl = SERVER_API_URL + 'api/project-quoter-request';

    constructor(private http: HttpClient) {}

    create(projectQuoterRequest: IProjectQuoterRequest): Observable<HttpResponse<IProjectQuoterRequest>> {
        return this.http.post<IProjectQuoterRequest>(this.resourceUrl, projectQuoterRequest, { observe: 'response' });
    }

    update(projectQuoterRequest: IProjectQuoterRequest): Observable<HttpResponse<IProjectQuoterRequest>> {
        return this.http.put<IProjectQuoterRequest>(this.resourceUrl, projectQuoterRequest, { observe: 'response' });
    }

    find(id: number): Observable<HttpResponse<IProjectQuoterRequest>> {
        return this.http.get<IProjectQuoterRequest>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IPageableProjectQuoterRequest>> {
        const options = createRequestOption(req);
        return this.http.get<IPageableProjectQuoterRequest>(this.resourceUrl, { params: options, observe: 'response' });
    }
}
