import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import {
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { AccountService } from 'app/core/auth/account.service';

export type MainFilterPlace =
    | 'quote'
    | 'quote_valuation'
    | 'project_overview_scheduler'
    | 'project_overview_quote'
    | 'schedule'
    | 'comparison'
    | 'individual_quote';

@Injectable({ providedIn: 'root' })
export class MainFilterInitialStateStorageService {
    private accountId: number;

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountId = this.accountService.getAccountId();
    }

    store(projectId: number, mainFilterPlace: MainFilterPlace, value: IMainViewFilterState): void {
        this.webStorage.store(this.getLocalStorageKey(projectId, mainFilterPlace), value);
    }

    retrieve(projectId: number, mainFilterPlace: MainFilterPlace): IMainViewFilterState {
        const result = this.webStorage.retrieve(this.getLocalStorageKey(projectId, mainFilterPlace));

        if (result == null) {
            switch (mainFilterPlace) {
                case 'quote':
                case 'quote_valuation':
                case 'comparison':
                case 'individual_quote':
                case 'schedule':
                    return {
                        groupBy: 'area',
                        stageIds: null,
                        areaIds: null,
                        cssElementIds: null,
                        buildUpIds: null,
                        stageRoomId: null,
                        areaRoomId: null,
                        cssElementRoomId: null,
                        buildUpRoomId: null
                    };
                case 'project_overview_scheduler':
                case 'project_overview_quote':
                    return {
                        groupBy: 'area',
                        stageIds: null,
                        areaIds: null,
                        cssElementIds: null,
                        buildUpIds: null,
                        stageRoomId: null,
                        areaRoomId: null,
                        cssElementRoomId: null,
                        buildUpRoomId: null
                    };
            }
        }

        return result;
    }

    private getLocalStorageKey(projectId: number, mainFilterPlace: MainFilterPlace): string {
        return `main_filter_${this.accountId}_project_${projectId}_state_v3_${mainFilterPlace}`;
    }
}
