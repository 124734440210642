<div class="flex flex-column gap-10">
    <bp-scope-header
        [scopesHeader]="scopesHeader">
    </bp-scope-header>

    <div *ngIf="scopesHeader"
         class="flex flex-column gap-10 m-l-40">
        <div *ngFor="let scope of scopesHeader.scopes">
            <bp-scope [scopeHeader]="scopesHeader"
                      [scope]="scope">
            </bp-scope>
        </div>

        <bp-scope [scopeHeader]="scopesHeader"></bp-scope>
    </div>
</div>
