import { Injectable } from '@angular/core';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { BehaviorSubject } from 'rxjs';
import { IValuationResponse } from 'app/shared/model/bp.model';
import { IProject } from "app/shared/model/project.model";

@Injectable({ providedIn: 'root' })
export class ValuationsStore {
    private valuationResponseSubject = new BehaviorSubject<IValuationResponse | null>(null);
    public valuationResponse$ = this.valuationResponseSubject.asObservable();

    constructor(private topMenuStateService: TopMenuStateService) {
    }

    public get valuationResponse(): IValuationResponse | null {
        return this.valuationResponseSubject.value;
    }

    public set valuationResponse(value: IValuationResponse | null) {
        this.valuationResponseSubject.next(value);
    }

    get project(): IProject {
        return this.topMenuStateService.project;
    }

}
