import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IStage } from 'app/shared/model/stage.model';
import { IElement } from 'app/shared/model/element.model';
import { IQuoterTaskCost, IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IProject } from 'app/shared/model/project.model';
import { HttpResponse } from '@angular/common/http';
import { DefaultPricesUpdaterService } from 'app/flows/scheduler/schedule/services/default-prices-updater.service';
import { IScheduleTaskQuoterTotalModificationEvent } from 'app/shared/constants/events.constants';
import { QuoterDefaultPricesService } from 'app/shared/dataservices/quoter-default-prices.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';

@Injectable({
    providedIn: 'root'
})
export class LoadScheduleTotalAsyncService {
    constructor(
        private quoterDefaultPricesService: QuoterDefaultPricesService,
        private defaultPricesUpdaterService: DefaultPricesUpdaterService,
        private scheduleEventsService: ScheduleEventsService
    ) {
    }

    public loadObs(project: IProject, allStages: IStage[], defaultQuoterId: number): Promise<void> {
        return new Promise((resolve) => {
            this.quoterDefaultPricesService
                .queryTaskCostForTheProject(project.id, defaultQuoterId)
                .subscribe((res: HttpResponse<{ prop: IQuoterTaskCost }[]>) => {
                    const promises = [];

                    _.each(allStages, (stage: IStage) => {
                        _.each(stage.elements, (element: IElement) => {
                            _.each(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                                const quoterTaskCost: any = res.body[scheduleTask.id];
                                if (quoterTaskCost != null) {
                                    scheduleTask.taskTotal = quoterTaskCost;
                                } else {
                                    promises.push(this.defaultPricesUpdaterService.fillDefaultPrices(project, scheduleTask));
                                }
                            });
                        });
                    });

                    if (promises.length === 0) {
                        this.scheduleEventsService.stageQuoterTotalModified();
                        resolve();
                        return;
                    }

                    Promise.all(promises).then((results: Array<IScheduleTaskQuoterTotalModificationEvent>) => {
                        _.each(results, (totalModificationResult: IScheduleTaskQuoterTotalModificationEvent) => {
                            if (totalModificationResult != null) {
                                this.scheduleEventsService.scheduleTaskTotalModified(totalModificationResult);
                            }
                        });

                        this.scheduleEventsService.stageQuoterTotalModified();
                        resolve();
                    });
                });
        });
    }
}
