import { Injectable } from '@angular/core';
import { IProject, ProjectStatus } from 'app/shared/model/project.model';
import { Observable, Observer } from 'rxjs';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ProjectStatusUpdaterService {
    constructor(private projectApi: ProjectApi) {}

    public updateIfNeeded(project: IProject, newStatus: ProjectStatus): Observable<ProjectStatus> {
        return new Observable((observer: Observer<ProjectStatus>) => {
            if (project != null) {
                switch (project.status) {
                    case 'Draft':
                        if (newStatus === 'Cost Plan' || newStatus === 'Schedule') {
                            this.projectApi.updateStatus(project.id, newStatus).subscribe((res: HttpResponse<ProjectStatus>) => {
                                project.status = res.body;
                                observer.next(res.body);
                                observer.complete();
                                return;
                            });
                        }
                        break;
                    case 'Cost Plan': {
                        if (newStatus === 'Schedule') {
                            this.projectApi.updateStatus(project.id, newStatus).subscribe((res: HttpResponse<ProjectStatus>) => {
                                project.status = res.body;
                                observer.next(res.body);
                                observer.complete();
                                return;
                            });
                        }
                        break;
                    }
                }
            }

            observer.next(null);
            observer.complete();
        });
    }
}
