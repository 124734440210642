<div *ngIf="account"
     class="m-b-20">
    <p>
        Here you can setup your payment methods
    </p>

    <ng-select [items]="account.availableProviders"
               [(ngModel)]="selectedPaymentProvider"
               (ngModelChange)="onChangeSelectedPaymentProvider()"
               [placeholder]="'Select payment method'"
               class="bp-select">
        <ng-template ng-label-tmp let-item="item">
            {{item | paymentProviderLabel}}
            <span class="label light-green" *ngIf="isSelectedPaymentProviderSetup">Setup</span> <span
            class="label red" *ngIf="!isSelectedPaymentProviderSetup">Not setup</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{item | paymentProviderLabel}} <span class="label light-green"
                                                  *ngIf="isPaymentProviderSetup(item)">Set up</span>
            <span
                class="label red" *ngIf="!isPaymentProviderSetup(item)">Not setup</span>
        </ng-template>
    </ng-select>

    <div *ngIf="selectedPaymentProvider"
         class="m-t-20">
        <div *ngIf="isSelectedPaymentProviderSetup">
            <p class="green-text">
                This payment method is setup. You can use it
            </p>
        </div>
        <div *ngIf="!isSelectedPaymentProviderSetup">
            <span class="red-text">This payment method is not setup.</span>
            <button (click)="onSetupButtonClick()"
                    class="btn btn-default btn-small btn-border-radius waves-effect setup m-l-20"
                    type="button">
                Setup
            </button>
        </div>
    </div>
</div>
