import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MainBpBackgroundService {
    private renderer: Renderer2;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public activate(): void {
        this.renderer.addClass(document.body, 'use-main-bp-background');
    }

    public deactivate(): void {
        this.renderer.removeClass(document.body, 'use-main-bp-background');
    }
}
