import { Injectable } from '@angular/core';
import { ProjectDetailsComponent } from 'app/flows/scheduler/project-details/project-details.component';
import { ProjectCacheService } from 'app/shared/dataservices/project-cache.service';
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ProjectDetailsCanDeactivateGuard {
    constructor(private projectCacheService: ProjectCacheService) {
    }

    canDeactivate(component: ProjectDetailsComponent): Promise<boolean> | boolean {
        return lastValueFrom(this.projectCacheService.get(component.project.id)).then(() => {
            return component.save(true, false);
        }, () => {
            return Promise.resolve(true);
        })
    };
}
