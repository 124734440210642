<div class="container-fluid text-center"
     *ngIf="!filterState">
    Please wait...
</div>

<div class="container-fluid"
     *ngIf="filterState">
    <div class="block-header m-b-10">
        <div class="row">
            <div class="col-4">
                <a [routerLink]="['../../../quotes', project.id]"
                   class="back-link">
                    <i class="material-icons">arrow_back</i> Back to quote
                </a>
            </div>

            <div class="col-4 text-center">
                <h1>
                    Quote from <b>{{ quoter?.company || quoter?.email }}</b>
                </h1>
            </div>

            <div class="col-4 text-right">
                <button [disabled]="inProcess()"
                        [popper]="bpExportSelector"
                        [popperAppendTo]="'body'"
                        [popperTrigger]="'click'"
                        [popperPlacement]="'bottom'"
                        [popperPreventOverflow]="true"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        [popperApplyClass]="'bp-popper'"
                        class="btn btn-secondary btn-border-radius m-r-5 export"
                        ngbTooltip="Export"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <i class="material-icons md-24">
                            file_download
                        </i>
                        Export

                        <i class="material-icons cursor-pointer">
                            expand_more
                        </i>
                    </div>
                </button>

                <popper-content #bpExportSelector>
                    <bp-export-selector
                        (onChanged)="onExportTypeSelected($event)">
                    </bp-export-selector>
                </popper-content>

                <button (click)="toggleExpand()"
                        [disabled]="inProcess()"
                        *ngIf="filterState.groupBy === 'area' || filterState.groupBy === 'stage'"
                        class="btn btn-secondary btn-border-radius expand-collapse m-r-5"
                        ngbTooltip="{{ expandedAll ? 'Collapse' : 'Expand'}}"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <ng-container *ngIf="expandedAll">
                            <i class="material-icons md-24">
                                fullscreen_exit
                            </i>
                            Collapse
                        </ng-container>

                        <ng-container *ngIf="!expandedAll">
                            <i class="material-icons md-24">
                                fullscreen
                            </i>
                            Expand
                        </ng-container>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <div class="row"
         *ngIf="inited">
        <div class="col-md-12">
            <bp-individual-quote-stages
                [comparison]="comparison"
                [project]="project"
                [quoter]="quoter"
                [nativeScheduleAreas]="filteredScheduleAreas"
                [filterState]="filterState"
                [scheduleAreaItems]="scheduleAreaItems"
                [stageItems]="stageItems"
                (onMainViewFilterChanged)="onMainViewFilterChangedFunc($event)">
            </bp-individual-quote-stages>
        </div>
    </div>

    <div class="row"
         *ngIf="!inited || inProcess()">
        <div class="col-md-12 text-center">
            <strong>
                Please wait..
            </strong>
        </div>
    </div>
</div>
