import { Component, Input, OnInit } from '@angular/core';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { QuoterExpandStagesStorageService } from 'app/flows/quoter/quote/services/expand-stages-storage.service';
import { QuoteStore } from 'app/flows/quoter/quote/stores/quote.store';

@Component({
    selector: 'bp-quote-stage',
    templateUrl: './stage.component.html',
    styleUrls: ['stage.scss']
})
export class QuoteStageComponent implements OnInit {
    @Input() stage: IComparisonStage;
    @Input() plain = false;

    constructor(private expandStagesStorageService: QuoterExpandStagesStorageService,
                protected store: QuoteStore) {
    }

    ngOnInit(): void {
        this.stage.expanded = this.expandStagesStorageService.retrieve(this.store.project, this.stage, this.store.filterState.groupBy)

        if (this.stage.expanded) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    toggleExpand(): void {
        if (this.stage.expanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    private expand(): void {
        this.stage.expanded = true;
        this.expandStagesStorageService.store(this.store.project, this.stage, this.store.filterState.groupBy, true);
    }

    private collapse(): void {
        this.stage.expanded = false;
        this.expandStagesStorageService.store(this.store.project, this.stage, this.store.filterState.groupBy, false);
    }
}
