import { Injectable } from '@angular/core';
import { IMainViewFilterItemItem } from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { forkJoin, Observable, Observer } from 'rxjs';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { IStage } from 'app/shared/model/stage.model';
import { IBuildUp, ICssElement } from 'app/shared/model/bp.model';
import * as _ from 'lodash';

export interface ItemsLoaderResult {
    scheduleAreas: IScheduleArea[];
    stages: IStage[];
    cssElements: ICssElement[];
    buildUps: IBuildUp[];
    scheduleAreaItems: IMainViewFilterItemItem[];
    stageItems: IMainViewFilterItemItem[];
    cssElementItems: IMainViewFilterItemItem[];
    buildUpItems: IMainViewFilterItemItem[];
}

@Injectable({ providedIn: 'root' })
export class ItemsLoaderService {

    constructor(private projectApi: ProjectApi) {
    }

    loadItems(projectId: number, loadCssElements = false, loadBuildUps = false): Observable<ItemsLoaderResult> {
        return new Observable((observer: Observer<ItemsLoaderResult>) => {
            let cssIndex = -1;
            let buildUpIndex = -1;

            const promises = [
                this.projectApi.queryAvailableScheduleAreas(projectId),
                this.projectApi.queryScheduleStages(projectId)
            ];

            if (loadCssElements) {
                promises.push(this.projectApi.queryCssElements(projectId));
                cssIndex = promises.length - 1;
            }

            if (loadBuildUps) {
                promises.push(this.projectApi.queryBuildUps(projectId));
                buildUpIndex = promises.length - 1;
            }

            forkJoin(promises).subscribe((res) => {
                const result: ItemsLoaderResult = {
                    scheduleAreas: res[0].body,
                    stages: res[1].body,
                    cssElements: cssIndex !== -1 ? _.sortBy(res[cssIndex].body, 'order') : [],
                    buildUps: buildUpIndex !== -1 ? _.sortBy(res[buildUpIndex].body, 'order') : [],
                    scheduleAreaItems: res[0].body.map((a: IScheduleArea) => {
                        return {
                            id: a.id,
                            title: a.area
                        };
                    }),
                    stageItems: res[1].body.map((a: IStage) => {
                        return {
                            id: a.id,
                            title: a.stage
                        };
                    }),
                    cssElementItems: cssIndex !== -1 ? _.sortBy(res[cssIndex].body, 'order').map((a: ICssElement) => {
                        return {
                            id: a.id,
                            title: a.name,
                            order: a.order
                        };
                    }) : [],
                    buildUpItems: buildUpIndex !== -1 ? _.sortBy(res[buildUpIndex].body, 'order').map((a: IBuildUp) => {
                        return {
                            id: a.id,
                            title: a.name,
                            order: a.order
                        };
                    }) : []
                };

                observer.next(result);
                observer.complete();
            })
        });
    }
}
