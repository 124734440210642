import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'bp-add-project-card',
    templateUrl: './add-project-card.component.html',
    styleUrls: ['add-project-card.scss']
})
export class AddProjectCardComponent implements OnInit {

    @Output() onAddClick = new EventEmitter();


    constructor() {
    }

    ngOnInit(): void {
    }
}
