<div class="modal-header">
    <div class="flex flex-column gap-20 justify-content-center align-items-center">
        <div class="swal2-icon swal2-success swal2-icon-show m-0 flex">
            <div class="swal2-icon-content">
                <fa-icon [icon]="['fas', 'check']" class="mt-1"></fa-icon>
            </div>
        </div>
        <h2>
            {{ data.header }}
        </h2>
    </div>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body" *ngIf="data.textHtml">
    <div [innerHTML]="data.textHtml">
    </div>
</div>

<div class="modal-footer">
    <button (click)="close()"
            tabindex="0"
            class="btn btn-primary btn-border-radius waves-effect"
            type="button">
        {{ data.closeButtonText || 'Close' }}
    </button>
</div>

