import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpecListComponent } from 'app/entities/spec/list/spec-list.component';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { specRoute } from 'app/entities/spec/spec.route';
import { SpecUpdateComponent } from 'app/entities/spec/edit/spec-update.component';
import { SpecDeleteDialogComponent } from 'app/entities/spec/delete/spec-delete-dialog.component';

const ENTITY_STATES = [...specRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        SpecListComponent,
        SpecUpdateComponent,
        SpecDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpSpecModule {
}
