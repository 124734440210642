import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITrade } from 'app/shared/model/trade.model';
import { TradeService } from 'app/shared/dataservices/trade.service';

@Component({
    selector: 'bp-trade-delete-dialog',
    templateUrl: './trade-delete-dialog.component.html'
})
export class TradeDeleteDialogComponent {
    trade: ITrade;

    constructor(private tradeService: TradeService,
                public activeModal: NgbActiveModal) {}

    protected clear(): void {
        this.activeModal.dismiss('cancel');
    }

    protected confirmDelete(id: number): void {
        this.tradeService.delete(id).subscribe(response => {
            this.activeModal.close(true);
        });
    }
}
