import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ImageUploadService } from 'app/account/settings/components/image-upload/image-upload.service';

class ImageSnippet {
    pending = false;

    constructor(public src: string, public file: File) {
    }
}

@Component({
    selector: 'bp-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['image-upload.scss']
})
export class ImageUploadComponent {
    @Output() onUploaded = new EventEmitter();

    selectedFile: ImageSnippet;

    @ViewChild('imageInput') imageInput: ElementRef;

    constructor(private imageService: ImageUploadService) {
    }

    processFile(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);

            this.selectedFile.pending = true;
            this.imageService.uploadImage(this.selectedFile.file).subscribe(
                (imageUrl: any) => {
                    this.selectedFile.pending = false;
                    this.onUploaded.emit(imageUrl);
                },
                () => {
                    this.onError();
                }
            );
        });

        reader.readAsDataURL(file);
    }

    remove(): void {
        this.selectedFile = null;
        this.imageInput.nativeElement.value = null;

        this.imageService.removeCurrentImage().subscribe(
            () => {
                this.onUploaded.emit(null);
            },
            () => {
                this.onError();
            }
        );
    }

    inProcess(): boolean {
        return false;
    }

    private onError() {
        this.selectedFile.pending = false;
        this.selectedFile.src = '';
    }
}
