import { Component, OnDestroy, OnInit } from '@angular/core';
import { ValuationsStore } from 'app/flows/scheduler/valuations/stores/valuations.store';

@Component({
    selector: 'bp-valuations-table',
    templateUrl: './valuations-table.component.html',
    styleUrls: ['valuations-table.scss']
})
export class ValuationsTableComponent implements OnInit, OnDestroy {

    constructor(
        protected store: ValuationsStore
    ) {
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    protected onOpenInvoiceClick(): void {

    }
}
