import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { IMaterial } from 'app/shared/model/material.model';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { IAccount } from 'app/shared/model/account.model';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { EntityComponentAreaService } from 'app/entities/component-area/component-area.service';
import { IComponentArea } from 'app/shared/model/component-area.model';

@Component({
    selector: 'bp-component-area-list',
    templateUrl: './component-area-list.component.html',
    styleUrls: ['component-area-list.scss']
})
export class ComponentAreaListComponent implements OnInit, OnDestroy {
    componentAreas: IComponentArea[];
    currentAccount: IAccount;
    itemsPerPage: number;
    page: number;
    predicate: string;
    queryCount: number;
    reverse: boolean;
    totalItems: number;

    itemUpdatedSub = Subscription.EMPTY;

    constructor(
        private componentAreaService: ComponentAreaService,
        private accountService: AccountService,
        private entityQueryStorageService: EntityQueryStorageService,
        private entityService: EntityComponentAreaService
    ) {
        this.componentAreas = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('COMPONENT_AREA');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });

        this.itemUpdatedSub = this.entityService.itemUpdated$.subscribe(() => this.loadAll());
    }

    ngOnDestroy(): void {
        this.itemUpdatedSub?.unsubscribe();
    }

    trackId(index: number, item: IComponentArea): number {
        return item.id;
    }

    reset(): void {
        this.page = 0;
        this.componentAreas = [];
        this.saveEntityQuery();
        this.loadAll();
    }

    loadPage(page: number): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadAll();
    }

    hasMore(): boolean {
        return this.componentAreas.length < this.totalItems;
    }

    private sort(): string {
        return this.predicate + ',' + (this.reverse ? 'asc' : 'desc');
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse
        };

        this.entityQueryStorageService.store('COMPONENT_AREA', entityQuery);
    }

    private loadAll(): void {
        this.componentAreaService
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort()
            })
            .subscribe(
                (res: HttpResponse<IMaterial[]>) => this.paginateComponentAreas(res.body, res.headers)
            );
    }

    private paginateComponentAreas(data: IComponentArea[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.componentAreas = [];
        }

        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);

        for (let i = 0; i < data.length; i++) {
            this.componentAreas.push(data[i]);
        }
    }
}
