import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { BpAreaModule } from './area/area.module';
import { BpElementModule } from './element/element.module';
import { BpMaterialModule } from './material/material.module';
import { BpSpecModule } from './spec/spec.module';
import { BpTaskModule } from './task/task.module';
import { BpTradeModule } from './trade/trade.module';
import { BpUnitModule } from './unit/unit.module';
import { BpStageModule } from 'app/entities/stage/stage.module';
import { BpMaterialCategoryModule } from 'app/entities/material-category/material-category.module';
import { BpTagModule } from 'app/entities/tag/tag.module';
import { BpRegionModule } from 'app/entities/region/region.module';
import { BpProfessionModule } from 'app/entities/profession/profession.module';
import { BpComponentAreaModule } from 'app/entities/component-area/component-area.module';
import { BpCssElementModule } from 'app/entities/css-element/css-element.module';
import { BpBuildUpModule } from 'app/entities/build-up/build-up.module';
import { BpBuildUpCategoryModule } from 'app/entities/build-up-category/build-up-category.module';

@NgModule({
    // prettier-ignore
    imports: [
        BpAreaModule,
        BpElementModule,
        BpMaterialModule,
        BpMaterialCategoryModule,
        BpSpecModule,
        BpTaskModule,
        BpTradeModule,
        BpUnitModule,
        BpStageModule,
        BpTagModule,
        BpRegionModule,
        BpProfessionModule,
        BpCssElementModule,
        BpBuildUpModule,
        BpBuildUpCategoryModule,
        BpComponentAreaModule
    ],
    declarations: [],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpFrontendEntityModule {}
