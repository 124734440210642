import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

export interface IExportSettings {
    includeCoverLetter: boolean
    includeCompanyLogo: boolean
    includeMyBusinessDetails: boolean;
    includeTitlePage: boolean;
    includeTermsAndConditions: boolean;
    termsAndConditions: string
    coverLetter: string
}

@Injectable({ providedIn: 'root' })
export class ExportSettingsService {
    public resourceUrl = SERVER_API_URL + 'api/export-settings';

    constructor(private http: HttpClient) {
    }

    update(exportSettings: IExportSettings): Observable<HttpResponse<IExportSettings>> {
        return this.http.put<IExportSettings>(this.resourceUrl, exportSettings, { observe: 'response' });
    }

    get(): Observable<HttpResponse<IExportSettings>> {
        return this.http.get<IExportSettings>(`${this.resourceUrl}`, { observe: 'response' });
    }

}
