import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    ServiceSelectorModalComponent
} from "app/shared/components/projects/service-selector-modal/service-selector-modal.component";
import { IEstimatingAddon } from "app/shared/model/bp.model";

@Injectable({ providedIn: 'root' })
export class ServiceSelectorModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal) {
    }

    open(addons: IEstimatingAddon[] = []): NgbModalRef {
        this.ngbModalRef = this.modalService.open(ServiceSelectorModalComponent as Component, {
            size: 'lg',
            windowClass: 'bp-modal fill-width-80 service-selector-modal blue-modal',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance._addons = addons;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
