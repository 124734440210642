import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from '../../app.constants';
import { IMaterial } from '../model/material.model';
import { createRequestOption } from 'app/shared/util/request-util';

type EntityResponseType = HttpResponse<IMaterial>;
type EntityArrayResponseType = HttpResponse<IMaterial[]>;

@Injectable({ providedIn: 'root' })
export class MaterialService {
    public resourceUrl = SERVER_API_URL + 'api/materials';

    constructor(private http: HttpClient) {}

    create(material: IMaterial): Observable<EntityResponseType> {
        return this.http.post<IMaterial>(this.resourceUrl, material, { observe: 'response' });
    }

    update(material: IMaterial, makeGlobal: boolean = false): Observable<EntityResponseType> {
        return this.http.put<IMaterial>(`${this.resourceUrl}?makeGlobal=${makeGlobal}`, material, { observe: 'response' });
    }

    switchReview(id: number): Observable<any> {
        return this.http.put<any>(`${this.resourceUrl}/${id}/switch-review`, null, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<IMaterial>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any, onlyUserMaterial: boolean = true, reviewed: boolean | null = null): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        let url = `${this.resourceUrl}?onlyUserMaterial=${onlyUserMaterial}`;
        if (reviewed != null) {
            url += `&reviewed=${reviewed}`
        }
        return this.http.get<IMaterial[]>(url, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    createShort(data: {
        projectId: number,
        materialName: string;
        materialCategoryId: number;
        defaultPrice: number;
        referenceUrl: string;
    }): Observable<EntityResponseType> {
        const body: any = {
            projectId: data.projectId,
            material: data.materialName,
            referenceUrl: data.referenceUrl,
            materialCategory: {
                id: data.materialCategoryId
            },
            defaultPrice: data.defaultPrice
        };
        return this.http.post<IMaterial>(`${this.resourceUrl}/short`, body, { observe: 'response' });
    }
}
