import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    EstimatorsWorkingOnProjectModalComponent
} from 'app/shared/components/projects/estimators-working-on-project-modal/estimators-working-on-project-modal.component';

@Injectable({ providedIn: 'root' })
export class EstimatorsWorkingOnProjectModalService implements OnDestroy {

    constructor(private modalService: NgbModal) {
    }

    ngOnDestroy(): void {
    }

    showModal(): Promise<boolean> {
        return this.modalService.open(EstimatorsWorkingOnProjectModalComponent as Component, {
            windowClass: 'bp-modal estimators-working-on-project-modal',
            size: 'lg',
            backdrop: 'static'
        }).result.then((res: boolean) => {
            return res;
        });
    }
}
