import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IProject } from 'app/shared/model/project.model';
import { IInvitation } from 'app/shared/model/invitation.model';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { HttpResponse } from '@angular/common/http';
import { InvitationService } from 'app/shared/dataservices/invitation.service';

@Injectable({ providedIn: 'root' })
export class ScheduleComparisonScreenNavigatorService {
    constructor(private invitationService: InvitationService, private router: Router) {}

    public navigate(project: IProject, invitations?: IInvitation[]): void {
        const doJob = () => {
            const filteredQuoters = _.filter(
                invitations,
                (invitation: IInvitation) =>
                    invitation.quoterId != null && (invitation.isDefault || (invitation.status === 'QUOTED' || invitation.status === 'ACCEPTED'))
            );

            const maxMajorVersion = _.maxBy(filteredQuoters, 'majorVersion').majorVersion;
            const maxMinorVersion = _.maxBy(_.filter(filteredQuoters, { majorVersion: maxMajorVersion }), 'minorVersion').minorVersion;
            const filteredQuotersToShow = _.filter(filteredQuoters, {
                majorVersion: maxMajorVersion,
                minorVersion: maxMinorVersion
            });

            const quoterIds: number[] = _.map(filteredQuotersToShow, 'quoterId');
            const filteredQuotersNotToShow = _.filter(filteredQuoters, (q: IInvitation) => _.indexOf(quoterIds, q.quoterId) === -1);

            const navigateToComparisonScreen = () => {
                this.router.navigate(['scheduler', 'comparison', project.id, quoterIds.join(',')]);
            };

            if (filteredQuotersNotToShow.length === 0) {
                navigateToComparisonScreen();
            } else {
                Swal.fire(
                    'Not all quoters will be shown',
                    `Some quoters have a previous version: ${_.map(
                        filteredQuotersNotToShow,
                        q => q.company || q.email
                    )}. Update their version to see their quotes in the comparison`,
                    'warning'
                ).then(() => {
                    navigateToComparisonScreen();
                });
            }
        };

        if (invitations == null) {
            this.invitationService.query(project.id).subscribe((res: HttpResponse<IInvitation[]>) => {
                invitations = res.body;
                doJob();
            });
        } else {
            doJob();
        }
    }
}
