<div class="main-filter-container">
    <div class="toggle-buttons">
        <a class="bp-area-dropdown stages"
           [ngClass]="{'selected': filterState.groupBy === 'stage' || filterState.groupBy === 'stage_room', 'disabled': disabled}">
            <div class="displayed-value"
                 (click)="!disabled ? switchToStagesView($event) : ''">
                Stages
            </div>
            <i class="material-icons cursor-pointer"
               [popper]="bpMainViewFilterV3Selector"
               [popperAppendTo]="'body'"
               [popperTrigger]="'click'"
               [popperPlacement]="'bottom'"
               [popperPreventOverflow]="true"
               [popperHideOnClickOutside]="true"
               [popperHideOnScroll]="true"
               [popperApplyClass]="'bp-popper'"
               (click)="toggleAreaDropdown('stages', $event)">
                expand_more
            </i>
        </a>

        <a class="bp-area-dropdown areas"
           [ngClass]="{'selected': filterState.groupBy === 'area' || filterState.groupBy === 'area_room', 'disabled': disabled}">
            <div class="displayed-value"
                 (click)="!disabled ? switchToAreasView($event) : ''">
                Areas
            </div>
            <i class="material-icons cursor-pointer"
               [popper]="bpMainViewFilterV3Selector"
               [popperAppendTo]="'body'"
               [popperTrigger]="'click'"
               [popperPlacement]="'bottom'"
               [popperPreventOverflow]="true"
               [popperHideOnClickOutside]="true"
               [popperHideOnScroll]="true"
               [popperApplyClass]="'bp-popper'"
               (click)="toggleAreaDropdown('areas', $event)">
                expand_more
            </i>
        </a>

        <a class="bp-area-dropdown css-element"
           *ngIf="cssElementItems?.length"
           [ngClass]="{'selected': filterState.groupBy === 'css-element', 'disabled': disabled}">
            <div class="displayed-value"
                 (click)="!disabled ? switchToCssElementView($event) : ''">
                CSS<br/>Elements
            </div>
            <i class="material-icons cursor-pointer"
               [popper]="bpMainViewFilterV3Selector"
               [popperAppendTo]="'body'"
               [popperTrigger]="'click'"
               [popperPlacement]="'bottom'"
               [popperPreventOverflow]="true"
               [popperHideOnClickOutside]="true"
               [popperHideOnScroll]="true"
               [popperApplyClass]="'bp-popper'"
               (click)="toggleAreaDropdown('css-element', $event)">
                expand_more
            </i>
        </a>

        <a class="bp-area-dropdown build-up"
           *ngIf="buildUpItems?.length"
           [ngClass]="{'selected': filterState.groupBy === 'build-up', 'disabled': disabled}">
            <div class="displayed-value"
                 (click)="!disabled ? switchToBuildUpView($event) : ''">
                Build<br/>Ups
            </div>
            <i class="material-icons cursor-pointer"
               [popper]="bpMainViewFilterV3Selector"
               [popperAppendTo]="'body'"
               [popperTrigger]="'click'"
               [popperPlacement]="'bottom'"
               [popperPreventOverflow]="true"
               [popperHideOnClickOutside]="true"
               [popperHideOnScroll]="true"
               [popperApplyClass]="'bp-popper'"
               (click)="toggleAreaDropdown('build-up', $event)">
                expand_more
            </i>
        </a>
    </div>


    <popper-content #bpMainViewFilterV3Selector>
        <div class="bp-dropdown-items-container {{ dropDownType }}"
             (click)="insideClick($event)">

            <div class="select-all-container form-check text-left"
                 [ngClass]="{'highlighted': isAllSelected()}"
                 (click)="toggleSelectAll($event)">
                <label class="form-check-label">
                    <input [checked]="isAllSelected()"
                           class="form-check-input"
                           type="checkbox">
                    <span class="form-check-sign"><span class="check"></span></span>
                    <div class="bp-cursor-pointer room-link">
                        {{ selectAllTitle() }}
                    </div>
                </label>
            </div>

            <div class="stage-area-item-container">
                <div *ngIf="dropDownType === 'areas'"
                     class="item-inner-container">
                    <div *ngFor="let areaItem of areaItems"
                         [ngClass]="{'disabled' : filterState.groupBy === 'area_room' && filterState.areaRoomId !== areaItem.id,
                             'highlighted': filterState.groupBy === 'area_room' && filterState.areaRoomId === areaItem.id }"
                         class="stage-area-item form-check pl-4 text-left">
                        <label class="form-check-label">
                            <input (change)="selectArea(areaItem, $event)"
                                   [checked]="isAreaSelected(areaItem)"
                                   [disabled]="filterState.groupBy === 'area_room' && filterState.areaRoomId !== areaItem.id"
                                   class="form-check-input"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                            <div (click)="onAreaRoomClick(areaItem, $event)"
                                 class="bp-cursor-pointer room-link">
                                {{ areaItem.title }}
                            </div>
                        </label>
                    </div>
                </div>

                <div *ngIf="dropDownType === 'stages'"
                     class="item-inner-container">
                    <div *ngFor="let stageItem of stageItems"
                         [ngClass]="{'disabled' : filterState.groupBy === 'stage_room' && filterState.stageRoomId !== stageItem.id,
                             'highlighted': filterState.groupBy === 'stage_room' && filterState.stageRoomId === stageItem.id }"
                         class="stage-area-item form-check pl-4 text-left">
                        <label class="form-check-label">
                            <input (change)="selectStage(stageItem, $event)"
                                   [checked]="isStageSelected(stageItem)"
                                   [disabled]="filterState.groupBy === 'stage_room' && this.filterState.stageRoomId !== stageItem.id"
                                   class="form-check-input"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                            <div (click)="onStageRoomClick(stageItem, $event)"
                                 class="bp-cursor-pointer room-link">
                                {{ stageItem.title }}
                            </div>
                        </label>
                    </div>
                </div>

                <div *ngIf="dropDownType === 'css-element'"
                     class="item-inner-container">
                    <div *ngFor="let cssElementItem of cssElementItems"
                         [ngClass]="{'disabled' : filterState.groupBy === 'css-element_room' && filterState.cssElementRoomId !== cssElementItem.id,
                             'highlighted': filterState.groupBy === 'css-element_room' && filterState.cssElementRoomId === cssElementItem.id }"
                         class="stage-area-item form-check pl-4 text-left">
                        <label class="form-check-label">
                            <input (change)="selectCssElement(cssElementItem, $event)"
                                   [checked]="isCssElementSelected(cssElementItem)"
                                   [disabled]="filterState.groupBy === 'css-element_room' && this.filterState.cssElementRoomId !== cssElementItem.id"
                                   class="form-check-input"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                            <div (click)="onCssElementRoomClick(cssElementItem, $event)"
                                 class="bp-cursor-pointer room-link">
                                {{ cssElementItem.title }}
                            </div>
                        </label>
                    </div>
                </div>

                <div *ngIf="dropDownType === 'build-up'"
                     class="item-inner-container">
                    <div *ngFor="let buildUpItem of buildUpItems"
                         [ngClass]="{'disabled' : filterState.groupBy === 'css-element_room' && filterState.buildUpRoomId !== buildUpItem.id,
                             'highlighted': filterState.groupBy === 'css-element_room' && filterState.buildUpRoomId === buildUpItem.id }"
                         class="stage-area-item form-check pl-4 text-left">
                        <label class="form-check-label">
                            <input (change)="selectBuildUp(buildUpItem, $event)"
                                   [checked]="isBuildUpSelected(buildUpItem)"
                                   [disabled]="filterState.groupBy === 'build-up_room' && this.filterState.buildUpRoomId !== buildUpItem.id"
                                   class="form-check-input"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                            <div (click)="onBuildUpRoomClick(buildUpItem, $event)"
                                 class="bp-cursor-pointer room-link">
                                {{ buildUpItem.title }}
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="controls flex-between-container gap-5 m-t-10">
                <button (click)="cancel($event)"
                        class="btn btn-secondary btn-border-radius flex-1"
                        type="button">
                    Cancel
                </button>
                <button (click)="apply($event)"
                        class="btn btn-primary btn-border-radius flex-1"
                        type="button">
                    Apply
                </button>
            </div>
        </div>
    </popper-content>
</div>

