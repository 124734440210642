import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BpOnErrorService } from 'app/shared/services/bp-on-error.service';

@Component({
    selector: 'bp-quoter-invitation-validator',
    templateUrl: './quoter-invitation-validator.component.html'
})
export class QuoterInvitationValidatorComponent implements OnInit {
    token: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private invitationService: InvitationService,
        private bpOnErrorService: BpOnErrorService
    ) {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    ngOnInit(): void {
        this.invitationService.validateInvitationToken(this.token).subscribe(
            (res: HttpResponse<any>) => {
                const quoterExist = res.body;
                if (quoterExist) {
                    this.router.navigate(['/login'], { queryParams: { token: this.token } });
                } else {
                    this.router.navigate(['/create-quoter-from-invitation'], { queryParams: { token: this.token } });
                }
            },
            (res: HttpErrorResponse) => {
                this.bpOnErrorService.showError(res);
                this.router.navigate(['/login']);
            }
        );
    }
}
