import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IProject, Project } from 'app/shared/model/project.model';
import { ITag } from 'app/shared/model/tag.model';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { TagService } from 'app/shared/dataservices/tag.service';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { noWhiteSpaceValidator } from 'app/shared/validators/no-white-space.validator';
import { projectAlreadyExistsValidator } from 'app/shared/validators/project-already-exists.validator';
import { ISpecification } from 'app/shared/model/specification.model';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import * as _ from 'lodash';
import { IRegion } from 'app/shared/model/region.model';
import { RegionApi } from 'app/shared/dataservices/region.api';
import {
    SelectInputComponent,
    SelectInputData
} from 'app/shared/components/common/select-input/select-input.component';
import { IGeoPoint } from 'app/shared/model/geo-point.model';
import { CoordinatesSearcherService } from 'app/shared/services/coordinates-searcher.service';
import { AccountService } from 'app/core/auth/account.service';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { CommonService, IAddressByPostCode } from 'app/shared/dataservices/common.service';
import { concat, lastValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { EstimatingAddonService } from 'app/shared/dataservices/estimating-addon.service';
import { IEstimatingAddon } from 'app/shared/model/bp.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DatePickerOnDateSelectEvent } from 'app/shared/components/common/date-picker/date-picker.component';
import { CreateProjectService, ICreateAttachmentsData } from 'app/flows/scheduler/services/create-project.service';
import {
    SubmitForAnalysisFormComponent
} from 'app/flows/scheduler/components/submit-for-analysis-form/submit-for-analysis-form.component';

/**
 * Create New Project by Scheduler
 */
@Component({
    selector: 'bp-create-project',
    templateUrl: './create-project.component.html',
    styleUrls: ['create-project.scss']
})
export class CreateProjectComponent implements OnInit, OnDestroy {
    @ViewChild(SubmitForAnalysisFormComponent) submitForAnalysisFormComponent: SubmitForAnalysisFormComponent;
    @BlockUI() protected blockUI: NgBlockUI;
    protected isAdmin = false;
    protected project: IProject;
    protected files: File[] = [];
    protected form: FormGroup;
    protected inProcessLoadTags = false;
    protected inProcessLoadSpecifications = false;
    protected inProcessLoadRegions = false;
    protected tags: ITag[];
    protected address: FormControl;
    protected addressSecondLine: FormControl;
    protected city: FormControl;
    protected postcode: FormControl;
    protected attachmentsUrl: FormControl;
    protected tenderNotes: FormControl;
    protected notes: FormControl;
    protected templateName: FormControl;
    protected templateSelectionView = false;
    protected regionsInputData: SelectInputData;
    @ViewChild('regionSelectInput')
    protected regionSelectInput: SelectInputComponent;
    protected submitted = false;
    protected addressSuggestions$: Observable<IAddressByPostCode[]>;
    protected addressSuggestionsLoading = false;
    protected postcodeInput$ = new Subject<string>();
    protected addon: IEstimatingAddon | null | undefined;
    private routeQuerySub = Subscription.EMPTY;

    constructor(
        private fb: FormBuilder,
        private el: ElementRef,
        private tagService: TagService,
        private specificationService: SpecificationService,
        private projectApi: ProjectApi,
        private route: ActivatedRoute,
        private estimatingAddonService: EstimatingAddonService,
        private accountService: AccountService,
        private regionService: RegionApi,
        private coordinatesSearcherService: CoordinatesSearcherService,
        private topMenuStateService: TopMenuStateService,
        private commonService: CommonService,
        private createProjectService: CreateProjectService
    ) {
    }

    ngOnInit(): void {
        this.blockUI.start('Loading..');

        this.routeQuerySub = this.route.queryParams.subscribe(params => {
            const addonId = params['addonId'];
            if (addonId != null) {
                this.estimatingAddonService.queryAllServices().then((addons) => {
                    this.addon = addons.find(a => a.id === +addonId);
                })
            } else {
                this.addon = null;
            }
        });

        this.isAdmin = this.accountService.isAdmin();

        this.project = new Project();

        if (this.isAdmin) {
            this.project.tags = [];
        }

        this.initForm();

        Promise.all([
            this.loadTags(),
            this.loadRegions(),
            this.loadSpecifications()
        ]).finally(() => {
            this.blockUI.stop();
        })
    }

    ngOnDestroy(): void {
        this.routeQuerySub.unsubscribe();
    }

    protected trackAddressByPostcodeByFn(item: IAddressByPostCode) {
        return item.formattedAddress;
    }

    protected onAddressByPostcodeChange(item: IAddressByPostCode) {
        if (!item) {
            return;
        }

        this.postcode.setValue(item.addressComponents.find(ac => ac.types.indexOf('POSTAL_CODE') !== -1)?.longName);
        this.address.setValue(item.formattedAddress);
        this.addressSecondLine.setValue(item.addressComponents.find(ac => ac.types.indexOf('ADMINISTRATIVE_AREA_LEVEL_2') !== -1)?.longName);
        const postalTown = item.addressComponents.find(ac => ac.types.indexOf('POSTAL_TOWN') !== -1)?.longName;
        this.city.setValue(postalTown);

        /*const postcodeRegion = item.addressComponents.find(ac => ac.types.indexOf('ADMINISTRATIVE_AREA_LEVEL_1') !== -1)?.longName;
        if (postcodeRegion?.length) {
            this.project.region = this.regionsInputData.data.find((r: IRegion) => r.region?.toLowerCase() === postcodeRegion?.toLowerCase() || r.region?.toLowerCase() === postalTown?.toLowerCase());
            this.regionSelectInput.data.initItem = this.project.region;
            this.regionSelectInput.refresh();
        }*/
    }

    protected inProcess(): boolean {
        return this.inProcessLoadTags || this.inProcessLoadSpecifications || this.inProcessLoadRegions;
    }

    protected onCreateProjectClick(): void {
        this.project.address = this.address.value;
        this.project.addressSecondLine = this.addressSecondLine.value;
        this.project.city = this.city.value;
        this.project.postcode = this.postcode.value;
        this.project.templateName = this.templateName.value;
        this.project.tenderNotes = this.tenderNotes.value;
        this.project.attachmentsUrl = this.attachmentsUrl.value;

        this.coordinatesSearcherService.search(this.project.postcode).then((geoPoint: IGeoPoint) => {
            this.project.latitude = geoPoint.lat;
            this.project.longitude = geoPoint.long;
        });

        this.topMenuStateService.setCurrentProject(this.project);

        if (this.addon == null) {
            this.templateSelectionView = true;
        } else {
            const data: ICreateAttachmentsData | undefined = this.submitForAnalysisFormComponent ? {
                proposedFloorPlanFiles: this.submitForAnalysisFormComponent.proposedFloorPlanFiles,
                existingFloorPlanFiles: this.submitForAnalysisFormComponent.existingFloorPlanFiles,
                proposedFloorPlanURL: this.submitForAnalysisFormComponent.proposedFloorPlanURLControl.value,
                existingFloorPlanURL: this.submitForAnalysisFormComponent.existingFloorPlanURLControl.value
            } : undefined;
            this.createProjectService.create(this.addon.id, this.route, this.project, this.files, [], data);
        }
    }

    protected onStartDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.project.startDate = event.newDate;
    }

    protected onTenderDeadlineDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.project.tenderDeadline = event.newDate;
    }

    protected onTenderDecisionDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.project.tenderDecision = event.newDate;
    }

    protected onRegionSelectionChange(event): void {
        if (event != null) {
            this.project.region = event;
        }
    }

    protected ngSubmitImpl(): void {
        this.submitted = true;

        if (this.form.valid && this.project.region != null) {
            this.onCreateProjectClick();
        } else {
            this.form.markAllAsTouched();
            this.scrollToFirstInvalidControl();
        }
    }

    private loadRegions(): Promise<void> {
        this.inProcessLoadRegions = true;

        return lastValueFrom(this.regionService
            .query())
            .then((res: HttpResponse<IRegion[]>) => {
                const regions = res.body;

                this.regionsInputData = {
                    indexProperty: 'region',
                    titleProperty: 'region',
                    data: regions,
                    dropdownPosition: 'bottom',
                    clearable: false
                };
            })
            .finally(() => {
                this.inProcessLoadRegions = false;
            });
    }

    private initForm(): void {
        this.address = new FormControl(
            '',
            [Validators.required, noWhiteSpaceValidator],
            [projectAlreadyExistsValidator(this.projectApi, this.project.address)]
        );

        this.addressSecondLine = new FormControl('');
        this.city = new FormControl('');
        this.postcode = new FormControl('', [Validators.required]);

        // https://www.regextester.com/94502
        this.attachmentsUrl = new FormControl('', Validators.maxLength(255));
        this.tenderNotes = new FormControl('', Validators.maxLength(1200));
        this.notes = new FormControl('', Validators.maxLength(255));
        this.templateName = new FormControl('');

        this.form = this.fb.group({
            address: this.address,
            addressSecondLine: this.addressSecondLine,
            city: this.city,
            postcode: this.postcode,
            attachmentsUrl: this.attachmentsUrl,
            tenderNotes: this.tenderNotes,
            notes: this.notes,
            templateName: this.templateName
        });

        this.address.setValue(this.project.address);
        this.addressSecondLine.setValue(this.project.addressSecondLine);
        this.city.setValue(this.project.city);
        this.postcode.setValue(this.project.postcode);
        this.attachmentsUrl.setValue(this.project.attachmentsUrl);
        this.tenderNotes.setValue(this.project.tenderNotes);
        this.notes.setValue(this.project.notes);
        this.templateName.setValue(this.project.templateName);

        this.addressSuggestions$ = concat(
            of([]), // default items
            this.postcodeInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.addressSuggestionsLoading = true),
                switchMap(term => {
                    if (term?.length) {
                        return this.commonService.addressesByPostCode(term).pipe(
                            catchError(() => of([])), // empty list on error
                            tap(() => this.addressSuggestionsLoading = false)
                        )
                    } else {
                        return of([]).pipe(
                            tap(() => this.addressSuggestionsLoading = false)
                        );
                    }
                })
            )
        );
    }

    private loadTags(): Promise<void> {
        this.inProcessLoadTags = true;

        return lastValueFrom(this.tagService
            .query())
            .then((res: HttpResponse<ITag[]>) => {
                this.tags = res.body;
            }).finally(() => {
                this.inProcessLoadTags = false;
            })
    }

    private loadSpecifications(): Promise<void> {
        this.inProcessLoadSpecifications = true;

        return lastValueFrom(this.specificationService
            .query())
            .then((res: HttpResponse<ISpecification[]>) => {
                this.project.specification = _.find(res.body, { name: 'Medium' }) || res.body[0];
            })
            .finally(() => {
                this.inProcessLoadSpecifications = false;
            })
    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
            'form .ng-invalid'
        );

        if (firstInvalidControl) {
            firstInvalidControl.scrollIntoView()
            firstInvalidControl.focus(); //without smooth behavior
        }
    }
}
