export type ExportType = 'pdf' | 'docx' | 'csv';

export interface ExportTypeInfo {
    id: ExportType;
    label: string;
}

export const EXPORT_TYPES: ExportTypeInfo[] = [
    { id: 'csv', label: 'Excel (.xlsx)' },
    { id: 'pdf', label: 'PDF' },
    { id: 'docx', label: 'Word (.docx)' }
];
