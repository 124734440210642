import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IStage } from 'app/shared/model/stage.model';
import { StageService } from 'app/shared/dataservices/stage.service';

@Component({
    selector: 'bp-stage-delete-dialog',
    templateUrl: './stage-delete-dialog.component.html'
})
export class StageDeleteDialogComponent {
    stage: IStage;

    constructor(private stageService: StageService,
                public activeModal: NgbActiveModal) {}

    protected clear() {
        this.activeModal.dismiss('cancel');
    }

    protected confirmDelete(id: number) {
        this.stageService.delete(id).subscribe(response => {
            this.activeModal.dismiss(true);
        });
    }
}
