<div class="card">
    <div class="header-container">
        <div class="total">
            Total
        </div>

        <div class="total">
            {{ store.data.total | currency:'GBP' }}
        </div>
    </div>

    <div *ngIf="store.filterState.groupBy === 'stage' || store.filterState.groupBy === 'stage_room'"
         class="no-bottom-border">
        <div *ngFor="let stage of store.data.comparison?.stageDTOs; let first = first;"
             [ngClass]="{'m-t-20': !first}">
            <bp-quote-stage
                [stage]="stage"
                [plain]="true">
            </bp-quote-stage>
        </div>

        <div class="m-l-20"
             *ngIf="!store.data.comparison?.stageDTOs?.length">
            <span>No stages to show</span>
        </div>
    </div>

    <div *ngIf="store.filterState.groupBy === 'area' || store.filterState.groupBy === 'area_room'">
        <div *ngFor="let area of store.nativeScheduleAreas; let first = first;"
             [ngClass]="{'m-t-20': !first}"
             class="no-bottom-border">
            <ng-container *ngIf="area['comparison']">
                <div *ngIf="store.filterState.groupBy === 'area' && !area.expanded">
                    <div class="row"
                         style="margin-bottom: 15px !important;">
                        <div class="col-sm-6">
                            <div class="bp-cursor-pointer area-header"
                                 (click)="onClosedAreaIconClick(area)">
                                <span>{{ area.area }}</span>
                                <i class="material-icons md-18">chevron_right</i>
                            </div>
                        </div>
                        <div class="col-sm-6 text-right">
                            <div class="area-total"
                                 [title]="area['comparison']['total'] || 0 | currency:'GBP'">
                                {{ area['comparison']['total'] || 0 | currency:'GBP' }}
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="store.filterState.groupBy === 'area_room' || area.expanded">
                    <div class="row"
                         *ngIf="store.filterState.groupBy === 'area'">
                        <div class="col-sm-6">
                            <div class="bp-cursor-pointer area-header"
                                 (click)="onOpenedAreaIconClick(area)">
                                <span>{{ area.area }}</span>
                                <i class="material-icons md-18">expand_more</i>
                            </div>
                        </div>
                        <div class="col-sm-6 no-margin no-padding-left text-right">
                            <div class="area-total"
                                 [title]="area['comparison']['total'] || 0 | currency:'GBP'">
                                {{ area['comparison']['total'] || 0 | currency:'GBP' }}
                            </div>
                        </div>
                    </div>

                    <div class="stages-container m-t-10"
                         *ngFor="let stage of area['comparison']['stageDTOs'] || []; let first = first;">
                        <bp-quote-stage
                            [stage]="stage"
                            [plain]="false">
                        </bp-quote-stage>
                    </div>

                    <div class="m-l-20"
                         *ngIf="!(area['comparison']['stageDTOs'] || []).length">
                        <span>No stages to show</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
