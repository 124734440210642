import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStatQuoter } from 'app/shared/model/stat-quoter.model';
import { SERVER_API_URL } from 'app/app.constants';
import { IResourceCost } from 'app/shared/model/bp.model';
import { createRequestOption } from 'app/shared/util/request-util';

@Injectable({ providedIn: 'root' })
export class StatQuoterService {
    public resourceUrl = SERVER_API_URL + 'api/stat';

    constructor(private http: HttpClient) {}

    queryStatForQuoter(projectId: number, quoterId: number): Observable<HttpResponse<IStatQuoter>> {
        return this.http.get<IStatQuoter>(`${this.resourceUrl}/project/${projectId}/quoter/${quoterId}`, { observe: 'response' });
    }

    resourceCost(projectId: number,
                 quoterId: number,
                 areaIds: number[],
                 stageIds: number[]): Observable<HttpResponse<IResourceCost>> {

        const query: any = {};

        if (areaIds && areaIds.length > 0) {
            query.areaIds = areaIds.join(',');
        }
        if (stageIds && stageIds.length > 0) {
            query.stageIds = stageIds.join(',');
        }

        const options = createRequestOption(query);

        return this.http.get<IResourceCost>(`${this.resourceUrl}/project/${projectId}/quoter/${quoterId}/resource-cost`, {
            params: options,
            observe: 'response'
        });
    }
}
