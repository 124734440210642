import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IBuilderLabour, IEndpointLabour } from '../model/builder-labour.model';
import { IBuilderMaterial, IEndpointMaterial } from '../model/builder-material.model';
import { ITaskLabour } from 'app/shared/model/task-labour.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';

export interface IMaterialsWrapper {
    margin: number;
    handlingCharge: number;
    materials: IBuilderMaterial[];
}

export interface ILaboursWrapper {
    margin: number;
    labours: IEndpointLabour[];
}

export interface IQuterProjectSpecificData {
    projectId?: number;
    quoterId?: number;
    labourMargin?: number;
    materialMargin?: number;
    materialHandlingCharges?: number;
}

@Injectable({ providedIn: 'root' })
export class QuoterService {
    public resourceUrl = SERVER_API_URL + 'api/quoters';
    public quoterMaterialsResourceUrl = SERVER_API_URL + 'api/quoter-materials';
    public quoterLaboursResourceUrl = SERVER_API_URL + 'api/quoter-labours';
    public projectQuoterResourceUrl = SERVER_API_URL + 'api/project_quoter';

    constructor(private http: HttpClient) {
    }

    queryAvailableMaterials(req?: any): Observable<HttpResponse<IMaterialsWrapper>> {
        const options = createRequestOption(req);
        return this.http.get<IMaterialsWrapper>(`${this.resourceUrl}/available-materials`, {
            params: options,
            observe: 'response'
        });
    }

    queryDefaultMaterials(req?: any): Observable<HttpResponse<IBuilderMaterial[]>> {
        return this.http.post<IBuilderMaterial[]>(`${this.resourceUrl}/default-materials`, null, { observe: 'response' });
    }

    refreshCosts(): Observable<HttpResponse<void>> {
        return this.http.post<void>(`${this.quoterMaterialsResourceUrl}/refresh-costs`, null, { observe: 'response' });
    }

    queryAvailableLabours(req?: any): Observable<HttpResponse<ILaboursWrapper>> {
        const options = createRequestOption(req);
        return this.http.get<ILaboursWrapper>(`${this.resourceUrl}/available-labours`, {
            params: options,
            observe: 'response'
        });
    }

    queryTaskLabours(req?: any): Observable<HttpResponse<ITaskLabour[]>> {
        const options = createRequestOption(req);
        return this.http.get<ITaskLabour[]>(`${this.resourceUrl}/task-labours`, {
            params: options,
            observe: 'response'
        });
    }

    queryDefaultLabours(req?: any): Observable<HttpResponse<IBuilderLabour[]>> {
        const options = createRequestOption(req);
        return this.http.get<IBuilderLabour[]>(`${this.resourceUrl}/default-labours`, {
            params: options,
            observe: 'response'
        });
    }

    batchUpdateMaterials(data: IMaterialsWrapper, isGlobal = false): Observable<HttpResponse<IBuilderMaterial[]>> {
        const query: any = { isGlobal };
        const options = createRequestOption(query);

        return this.http.post<IBuilderMaterial[]>(this.quoterMaterialsResourceUrl, data, {
            params: options,
            observe: 'response'
        });
    }

    applyMarginToAllMaterials(margin: number): Observable<HttpResponse<void>> {
        const options = createRequestOption({ margin });
        return this.http.post<void>(`${this.quoterMaterialsResourceUrl}/apply-all`, null, {
            params: options,
            observe: 'response'
        });
    }

    batchUpdateLabours(data: ILaboursWrapper, isGlobal = false): Observable<HttpResponse<IBuilderLabour[]>> {
        const query: any = { isGlobal };
        const options = createRequestOption(query);

        return this.http.post<IBuilderLabour[]>(this.quoterLaboursResourceUrl, data, {
            params: options,
            observe: 'response'
        });
    }

    updateLabour(data: IEndpointLabour): Observable<HttpResponse<void>> {
        return this.http.put<HttpResponse<void>>(`${this.quoterLaboursResourceUrl}/${data.id}`, data);
    }

    deleteTaskLabour(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/task-labours/${id}`, { observe: 'response' });
    }

    queryProjectSpecificMaterials(projectId: number): Observable<HttpResponse<IBuilderMaterial[]>> {
        return this.http.get<IBuilderMaterial[]>(`${this.quoterMaterialsResourceUrl}/project/${projectId}`, {
            observe: 'response'
        });
    }

    updateProjectSpecificMaterials(projectId: number, material: IEndpointMaterial): Observable<HttpResponse<IBuilderMaterial[]>> {
        return this.http.put<IBuilderMaterial[]>(`${this.quoterMaterialsResourceUrl}/project/${projectId}`, material, {
            observe: 'response'
        });
    }

    updateProjectSpecificMaterial(projectId: number, data: IQuterProjectSpecificData): Observable<HttpResponse<IQuterProjectSpecificData>> {
        return this.http.put<IQuterProjectSpecificData>(`${this.projectQuoterResourceUrl}/${projectId}`, data, {
            observe: 'response'
        });
    }

    getProjectSpecificData(projectId: number): Observable<HttpResponse<IQuterProjectSpecificData>> {
        return this.http.get<IQuterProjectSpecificData>(`${this.projectQuoterResourceUrl}/${projectId}`, {
            observe: 'response'
        });
    }

    setViewedRates(projectId: number): Observable<HttpResponse<IQuterProjectSpecificData>> {
        return this.http.put<IQuterProjectSpecificData>(`${this.projectQuoterResourceUrl}/${projectId}/viewedrates`, null, {
            observe: 'response'
        });
    }

    updateProjectSpecificMaterialMargin(projectId: number, margin: number): Observable<HttpResponse<IBuilderMaterial[]>> {
        const body = {
            material_margin: margin
        }
        return this.http.put<IBuilderMaterial[]>(`${this.projectQuoterResourceUrl}/${projectId}`, body, {
            observe: 'response'
        });
    }

    queryProjectSpecificLabours(projectId: number): Observable<HttpResponse<IBuilderLabour[]>> {
        return this.http.get<IBuilderLabour[]>(`${this.quoterLaboursResourceUrl}/project/${projectId}`, {
            observe: 'response'
        });
    }

    updateProjectSpecificLabours(projectId: number, labour: IEndpointLabour): Observable<HttpResponse<IBuilderLabour[]>> {
        return this.http.put<IBuilderLabour[]>(`${this.quoterLaboursResourceUrl}/project/${projectId}`, labour, {
            observe: 'response'
        });
    }
}
