<div class="stage-container w-full"
     id="stage-container_{{ stage.id }}"
     [ngClass]="{'expanded': expanded}">
    <div *ngIf="showGroupNode">
        <div [ngClass]="{'m-l-20': scheduleService.filterState.groupBy === 'stage'}">
            <div class="w-full flex flex-row flex-wrap gap-10 justify-content-between items-center">
                <div class="flex-1">
                    <div class="bp-cursor-pointer root-header"
                         [ngClass]="{'m-l--20': scheduleService.filterState.groupBy === 'stage'}"
                         (click)="toggleStageExpand()">
                        <span>{{ stage.stage }}</span>
                        <i class="material-icons">{{ expanded ? "expand_more" : "chevron_right" }}</i>
                    </div>
                </div>

                <div class="flex-1 explanations-data-line">
                    <span *ngIf="expanded" class="p-l-15">Specification</span>
                </div>

                <div class="flex-0_5 explanations-data-line">
                    <span *ngIf="expanded">Area</span>
                </div>

                <div class="qti explanations-data-line">
                    <span *ngIf="expanded">Qty</span>
                </div>

                <div
                    class="totals font-12 flex flex-row justify-content-between items-center gap-10 explanations-data-line">
                    <ng-container *ngIf="expanded">
                        <div class="flex-1 text-center overflow-ellipsis">
                            Labour<br>cost/unit
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Materials<br>cost/unit
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Unit<br>total cost
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Line<br>Total
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Client<br>Supplied
                        </div>
                    </ng-container>
                </div>

                <div class="controls">
                    <div *ngIf="stage.total != null"
                         class="total"
                         [title]="stage.total | currency:'GBP'">
                        {{ stage.total | currency:'GBP' }}
                    </div>

                    <button (click)="onDeleteStageClick()"
                            [disabled]="scheduleService.disabled"
                            class="delete-stage btn btn-circle btn-small btn-secondary"
                            ngbTooltip="Delete stage">
                        <i class="material-icons md-18">delete_forever</i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!showGroupNode || expanded">
        <div class="element-container"
             [ngClass]="{'m-l-20': scheduleService.filterState.groupBy === 'stage'}">
            <bp-schedule-element-for-filter-by-stage-grouping
                *ngFor="let element of stageElements;"
                [element]="element"
                [stage]="stage">
            </bp-schedule-element-for-filter-by-stage-grouping>
        </div>

        <div *ngIf="!scheduleService.readOnly && !scheduleService.showSubStages"
             class="add-item-container m-b-20"
             [ngClass]="{'m-l-20': scheduleService.filterState.groupBy === 'stage'}">
            <bp-add-schedule-task-for-filter-by-stage-grouping
                [stage]="stage">
            </bp-add-schedule-task-for-filter-by-stage-grouping>
        </div>
    </div>
</div>

