import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EXPORT_TYPES, ExportType } from 'app/shared/constants/export-types';

@Component({
    selector: 'bp-export-selector',
    templateUrl: './export-selector.component.html',
    styleUrls: ['export-selector.scss']
})
export class ExportSelectorComponent implements OnInit {
    @Input() visibleItems: ExportType[] = ['csv', 'pdf', 'docx'];
    @Output() onChanged = new EventEmitter<ExportType>();

    protected exportTypes = [];

    ngOnInit(): void {
        this.exportTypes = EXPORT_TYPES
            .filter(exportType => this.visibleItems.indexOf(exportType.id) !== -1);
    }

    select(exportType: ExportType): void {
        this.onChanged?.emit(exportType);
    }
}
