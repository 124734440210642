import { Injectable } from '@angular/core';
import { ValuationApi } from 'app/shared/dataservices/valuation.api';
import { ValuationsStore } from 'app/flows/scheduler/valuations/stores/valuations.store';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ValuationsService {

    constructor(private valuationApi: ValuationApi,
                private store: ValuationsStore) {
    }

    public async init(): Promise<void> {

        return lastValueFrom(this.valuationApi.queryValuation(this.store.project.id))
            .then((res) => {
                this.store.valuationResponse = res.body;
            })
    }
}
