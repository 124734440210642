import { Component, Input } from '@angular/core';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';
import { ValuationsStore } from "app/flows/quoter/valuations/stores/valuations.store";
import { ValuationsService } from "app/flows/quoter/valuations/services/valuations.service";

@Component({
    selector: 'bp-valuation-element',
    templateUrl: './element.component.html',
    styleUrls: ['element.scss']
})
export class ValuationElementComponent {
    @Input() element: IComparisonElement;

    constructor(
        protected store: ValuationsStore,
        protected service: ValuationsService
    ) {
    }
}
