import { Component } from '@angular/core';
import { IArea } from 'app/shared/model/area.model';
import {
    ComparisonExpandAreaGroupStorageService
} from 'app/flows/scheduler/comparison/services/expand-area-group-storage.service';
import { ComparisonStore } from 'app/flows/scheduler/comparison/comparison.store';
import { KeyValue } from "@angular/common";

@Component({
    selector: 'bp-comparison-root-container',
    templateUrl: './root-container.component.html',
    styleUrls: ['root-container.scss']
})
export class ComparisonRootContainerComponent {
    constructor(private expandAreaGroupStorageService: ComparisonExpandAreaGroupStorageService,
                public comparisonStore: ComparisonStore) {
    }

    onClosedAreaIconClick(area: IArea): void {
        this.expandArea(area);
    }

    onOpenedAreaIconClick(area: IArea): void {
        this.collapseArea(area);
    }

    originalOrder = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
        return 0;
    }

    private expandArea(area: IArea): void {
        area.expanded = true;
        this.expandAreaGroupStorageService.store(this.comparisonStore.project, area, true);
    }

    private collapseArea(area: IArea): void {
        area.expanded = false;
        this.expandAreaGroupStorageService.store(this.comparisonStore.project, area, false);
    }
}
