import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ValuationsStore } from 'app/flows/scheduler/valuations/stores/valuations.store';
import { ValuationsService } from 'app/flows/scheduler/valuations/services/valuations.service';

/**
 * Valuations
 */
@Component({
    selector: 'bp-scheduler-valuations-page',
    templateUrl: './valuations.page.html',
    styleUrls: ['valuations.scss']
})
export class ValuationsPage implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        protected store: ValuationsStore,
        protected service: ValuationsService
    ) {

    }

    ngOnInit(): void {
        this.blockUI.start('Loading data..');
        this.service.init().finally(() => {
            this.blockUI.stop();
        })
    }

    ngOnDestroy(): void {

    }

    protected onCreateValuationClick(): void {

    }
}
