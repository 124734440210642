import { Component, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReviewByQsModalComponent } from 'app/flows/scheduler/components/review-by-qs-modal/review-by-qs-modal.component';
import { IProject } from 'app/shared/model/project.model';

@Injectable({ providedIn: 'root' })
export class ReviewByQsModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private modalService: NgbModal) {}

    open(project: IProject): NgbModalRef {
        this.ngbModalRef = this.modalService.open(ReviewByQsModalComponent as Component, {
            windowClass: 'bp-modal review-by-qs',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
