<div (bpClickOutside)="editing = false">
    <div *ngIf="editing">
        <input [required]="required"
               type="{{type}}"
               class="form-control inline-edit"
               (blur)="onBlurInput()"
               [name]="value"
               [(ngModel)]="value"
               [placeholder]="label"/>
    </div>
    <div *ngIf="!editing">
        <a href="#"
           (click)="beginEdit(value)"
           (focus)="beginEdit(value)"
           tabindex="0">
            <span *ngIf="!currency">{{value || '-- NO VALUE -- '}}</span>
            <span *ngIf="currency">{{value || 0 | currency: currency}}</span>
        </a>
    </div>
</div>
