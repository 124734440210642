import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { tagRoute } from 'app/entities/tag/tag.route';
import { TagUpdateComponent } from 'app/entities/tag/edit/tag-update.component';
import { TagDeleteDialogComponent } from 'app/entities/tag/delete/tag-delete-dialog.component';
import { TagListComponent } from 'app/entities/tag/list/tag-list.component';

const ENTITY_STATES = [...tagRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [
        TagListComponent,
        TagUpdateComponent,
        TagDeleteDialogComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpTagModule {
}
