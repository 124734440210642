<div class="container-fluid">
    <ng-container *ngIf="projectCount == null || inProcess">
        <div class="text-center">
            Please wait...
        </div>
    </ng-container>

    <ng-container *ngIf="projectCount != null">
        <div class="m-b-35 top-container-big"
             *ngIf="!showEmptyProjects">
            <div class="row align-items-center">
                <div class="col-lg-3 text-left">
                    <input [formControl]="searchControl"
                           class="form-control v2"
                           placeholder="Search"
                           id="search"
                           name="search"
                           required
                           type="text">
                </div>

                <div class="col-lg-6 text-center">
                    <h1 class="m-b-0">
                        Projects
                    </h1>
                </div>

                <div class="col-lg-3 project-state-filter-container">
                    <bp-select-input
                        *ngIf="projectStateSelectInputData"
                        (onSelectionChange)="onChangeProjectState($event)"
                        [data]="projectStateSelectInputData"
                        [removeUnderlines]="false"
                        class="select-archived-live-project-select">
                    </bp-select-input>
                </div>
            </div>
        </div>

        <div class="m-b-15 top-container-small"
             *ngIf="!showEmptyProjects">
            <div class="flex-end-container items-center">
                <div class="flex-1">
                </div>

                <div class="flex-1 text-center">
                    <h1 class="m-b-0">
                        Projects
                    </h1>
                </div>

                <div class="flex-1">
                    <div class="project-state-filter-container">
                        <bp-select-input
                            *ngIf="projectStateSelectInputData"
                            (onSelectionChange)="onChangeProjectState($event)"
                            [data]="projectStateSelectInputData"
                            [removeUnderlines]="false"
                            class="select-archived-live-project-select">
                        </bp-select-input>
                    </div>
                </div>
            </div>

            <div class="m-t-10">
                <input [formControl]="searchControl"
                       class="form-control v2"
                       placeholder="Search"
                       id="search"
                       name="search"
                       required
                       type="text">
            </div>
        </div>

        <div class="m-t-30">
            <bp-empty-projects
                *ngIf="showEmptyProjects"
                [canCreateProject]="true"
                (onExampleProjectCreation)="onExampleProjectCreation($event)">
            </bp-empty-projects>

            <ng-container
                *ngIf="!showEmptyProjects">
                <bp-project-list
                    [projectStateFilterId]="projectStateFilterId">
                </bp-project-list>
            </ng-container>
        </div>
    </ng-container>
</div>
