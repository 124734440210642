import { Component, Input } from '@angular/core';
import { FormControl } from "@angular/forms";
import { IProject } from "app/shared/model/project.model";

@Component({
    selector: 'submit-for-analysis-form',
    templateUrl: './submit-for-analysis-form.component.html',
    styleUrls: ['submit-for-analysis-form.scss']
})
export class SubmitForAnalysisFormComponent {
    @Input() project: IProject;

    public existingFloorPlanURLControl = new FormControl(null);
    public proposedFloorPlanURLControl = new FormControl(null);

    public existingFloorPlanFiles: File[] = [];
    public proposedFloorPlanFiles: File[] = [];
}
