import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IUser } from 'app/shared/model/user.model';
import { IActivityInfo } from 'app/shared/model/bp.model';
import { map } from 'rxjs/operators';

export type ActivityEventType = 'SEEN_COMPLETED_SUBSCRIPTION_MESSAGE' | 'UPDATED_ADDITIONAL_INFO';

export interface IUserExportDataFilters {
    page: number,
    size: number,
    projectStartDate: string,
    projectEndDate: string,
    userCreatedFromDate: string,
    userCreatedToDate: string,
}

@Injectable({ providedIn: 'root' })
export class UserApi {
    public resourceUrl = SERVER_API_URL + 'api/users';

    constructor(private http: HttpClient) {
    }

    create(user: IUser): Observable<HttpResponse<IUser>> {
        return this.http.post<IUser>(this.resourceUrl, user, { observe: 'response' });
    }

    update(user: IUser): Observable<HttpResponse<IUser>> {
        return this.http.put<IUser>(this.resourceUrl, user, { observe: 'response' });
    }

    find(login: string): Observable<HttpResponse<IUser>> {
        return this.http.get<IUser>(`${this.resourceUrl}/${login}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IUser[]>> {
        const options = createRequestOption(req);
        return this.http.get<IUser[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(login: string): Observable<HttpResponse<any>> {
        return this.http.delete(`${this.resourceUrl}/${login}`, { observe: 'response' });
    }

    authorities(): Observable<string[]> {
        return of(['ROLE_SCHEDULER', 'ROLE_QUOTER', 'ROLE_ADMIN']);
    }

    getActivityInfo(): Observable<HttpResponse<IActivityInfo>> {
        return this.http.get<IActivityInfo>(`${this.resourceUrl}/activity-info`, { observe: 'response' });
    }

    activity(eventType: ActivityEventType): Observable<HttpResponse<IUser>> {
        return this.http.post<IUser>(`${this.resourceUrl}/activity`, { eventType }, { observe: 'response' });
    }

    getExcelData(filters: IUserExportDataFilters): Observable<string> {
        const queryParams = {
            page: filters.page,
            size: filters.size,
            projectStartDate: filters.projectStartDate,
            projectEndDate: filters.projectEndDate,
            userCreatedFromDate: filters.userCreatedFromDate,
            userCreatedToDate: filters.userCreatedToDate
        };

        const options = {};
        options['observe'] = 'response';
        options['responseType'] = 'blob';

        options['params'] = queryParams;

        return this.http.get<any>(`${this.resourceUrl}/excel?`, options).pipe(
            map((res: HttpResponse<string>) => {
                return res.body;
            })
        );
    }
}
