<div class="modal-header">
    <h4 class="modal-title">
        Labour Rates
    </h4>
</div>

<div class="modal-body">
    <div class="margin-table__header flex flex-row gap-60 items-center justify-content-between flex-wrap">
        <div class="font-weight-bold font-16 flex flex-row items-center gap-1">
            Overheads, equipment and profit

            <i class="material-icons md-18"
               [ngbTooltip]="'Margin applied to each labour within your quote'">
                info_outline
            </i>
        </div>

        <div class="relative flex flex-row gap-60 items-center justify-content-between flex-wrap">
            <div class="position-relative percent-input">
                <input [formControl]="applyToAllMarginControl"
                       class="af-input text-left min-w-60"
                       placeholder="0"
                       type="text"
                       autocomplete="off"
                       [ngClass]="{'invalid': applyToAllMarginControl?.errors}">
                <span>%</span>
            </div>

            <button (click)="applyMarginToAll()"
                    [disabled]="applyToAllMarginControl.invalid || applyToAllMarginControl.value == null"
                    class="btn btn btn-secondary btn-border-radius waves-effect"
                    type="button">
                Apply to All
            </button>
        </div>
    </div>

    <hr>

    <div class="w-full margin-table m-t-20 m-r-20"
         *ngIf="form">
        <div class="margin-table__header flex flex-row gap-60 items-center justify-content-start flex-wrap">
            <div class="margin-table__header__col min-w-300">
            </div>
            <div class="margin-table__header__col">
                Cost/hr
            </div>
            <div class="margin-table__header__col">
                Cost/day
            </div>
            <div class="margin-table__header__col">
                OH & P
            </div>
            <div class="margin-table__header__col">
                Rate/day
            </div>
        </div>
        <div class="margin-table__body">
            <div *ngFor="let labour of labours; let i = index; let odd = odd;"
                 [formGroup]="labourForm(i)"
                 [ngClass]="{ 'odd': odd}"
                 class="margin-table__body__row flex flex-row gap-60 items-center justify-content-start flex-wrap">
                <div class="margin-table__body__row__col min-w-300">
                    {{ labour.trade }}
                </div>
                <div class="margin-table__body__row__col number-input-container">
                    <input formControlName="costPerHour"
                           (blur)="onCostPerHourInputBlur($event, i)"
                           mask="separator.2"
                           thousandSeparator=","
                           prefix="&pound;&nbsp;"
                           type="text">

                    <error *ngIf="labourForm(i).get('costPerHour')?.errors?.pattern">
                        Numeric value
                    </error>
                    <error *ngIf="labourForm(i).get('costPerHour')?.errors?.required">
                        This field is required.
                    </error>
                </div>
                <div class="margin-table__body__row__col number-input-container">
                    <input formControlName="costPerDay"
                           (blur)="onCostPerDayInputBlur($event, i)"
                           mask="separator.2"
                           thousandSeparator=","
                           prefix="&pound;&nbsp;"
                           type="text">

                    <error *ngIf="labourForm(i).get('costPerDay')?.errors?.pattern">
                        Numeric value
                    </error>
                    <error *ngIf="labourForm(i).get('costPerDay')?.errors?.required">
                        This field is required.
                    </error>
                </div>
                <div class="margin-table__body__row__col number-input-container">
                    <input formControlName="margin"
                           (blur)="onMarginInputBlur($event, i)"
                           class="af-input"
                           placeholder="0%"
                           mask="percent.2"
                           suffix="%"
                           thousandSeparator=","
                           type="text"
                           autocomplete="off">

                    <error *ngIf="labourForm(i).get('costPerDay')?.errors?.pattern">
                        Numeric value
                    </error>
                    <error *ngIf="labourForm(i).get('margin')?.errors?.required">
                        This field is required.
                    </error>
                </div>
                <div class="margin-table__body__row__col number-input-container">
                    <input formControlName="ratePerDay"
                           (blur)="onRatePerDayInputBlur($event, i)"
                           mask="separator.2"
                           thousandSeparator=","
                           prefix="&pound;&nbsp;"
                           type="text">

                    <error *ngIf="labourForm(i).get('costPerDay')?.errors?.pattern">
                        Numeric value
                    </error>
                    <error *ngIf="labourForm(i).get('ratePerDay')?.errors?.required">
                        This field is required.
                    </error>
                </div>
            </div>
        </div>

        <hr>

        <div class="margin-table__note">
            Note: Changes only affect this project, to change default values navigate to settings > Default labour rates
        </div>
    </div>
</div>

<div class="modal-footer flex gap-20 flex-end">
    <button (click)="close()"
            data-dismiss="modal"
            class="btn btn-secondary btn-border-radius waves-effect"
            type="button">
        Close
    </button>

    <button (click)="save()"
            tabindex="0"
            class="btn btn-primary btn-border-radius waves-effect"
            type="button">
        Save & Exit
    </button>
</div>

