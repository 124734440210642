import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    SubmitForAnalysisModalComponent
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/submit-for-analysis-modal/submit-for-analysis-modal.component';
import { IProjectAttachment } from 'app/shared/model/project-attachment.model';

@Injectable({ providedIn: 'root' })
export class SubmitForAnalysisModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }

    async showModal(projectAttachments: IProjectAttachment[] = []): Promise<boolean> {
        this.ngbModalRef = this.modalService.open(SubmitForAnalysisModalComponent as Component, {
            windowClass: 'bp-modal submit-for-analysis-modal',
            size: 'lg',
            backdrop: 'static'
        })

        this.ngbModalRef.componentInstance.restore(projectAttachments);

        return this.ngbModalRef.result;
    }
}
