import { Component, Input, OnInit } from '@angular/core';
import { ITemplateData } from 'app/flows/scheduler/template-wizard/template-wizard.data';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IScheduleTemplate } from 'app/shared/model/schedule-template.model';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';

type TemplatesSortBy = 'alphabetical' | 'popularity';

@Component({
    selector: 'bp-template-wizard-step3',
    templateUrl: './step3.component.html',
    styleUrls: ['../../template-wizard.scss']
})
export class Step3Component implements OnInit {
    private _td: ITemplateData;

    filteredTemplates: IScheduleTemplate[] = [];

    get td(): ITemplateData {
        return this._td;
    }

    @Input('td') set allowDay(value: ITemplateData) {
        this._td = value;
        this.clearSearch();
    }

    searchControl = new FormControl();
    searchTerm = null;

    SORTS: { id: TemplatesSortBy; label: string }[] =
        [
            { id: 'alphabetical', label: `Alphabetical` },
            { id: 'popularity', label: `Popularity` }
        ];

    currentSort: TemplatesSortBy = 'popularity';
    sortSelectInputData: SelectInputData;

    ngOnInit(): void {
        this.filteredTemplates = this.getFilteredTemplates();

        this.searchControl.valueChanges
            .pipe(debounceTime(700), distinctUntilChanged())
            .subscribe((term: string) => {
                this.searchTerm = term;
                this.filteredTemplates = this.getFilteredTemplates();
            });

        this.sortSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'label',
            initItem: this.SORTS[1],
            data: this.SORTS,
            dropdownPosition: 'bottom',
            clearable: false,
            searchable: false
        };
    }

    inProcess(): boolean {
        return false;
    }

    onChangeSort(event: { id: TemplatesSortBy; label: string }): void {
        this.currentSort = event.id;
        this.filteredTemplates = this.getFilteredTemplates();
    }

    private clearSearch(): void {
        this.searchControl.setValue(null);
        this.searchTerm = null;
        this.filteredTemplates = this.getFilteredTemplates();
    }

    private getFilteredTemplates(): IScheduleTemplate[] {
        let result = this.td.templates;
        if (this.searchTerm?.length) {
            result = this.td.templates.filter(t => t.schedule.toLowerCase().includes(this.searchTerm.toLowerCase()));
        }

        switch (this.currentSort) {
            case 'alphabetical': {
                result.sort((a, b) => a.schedule.localeCompare(b.schedule));
                break;
            }
            case 'popularity': {
                result.sort((a, b) => b.popularity - a.popularity);
            }
        }

        return result;
    }
}
