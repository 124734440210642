import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
    selector: 'bp-team-invitation-validator',
    templateUrl: './team-invitation-validator.component.html'
})
export class TeamInvitationValidatorComponent implements OnInit {
    token: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private invitationService: InvitationService
    ) {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    ngOnInit(): void {
        this.invitationService.acceptTeamMember(this.token).subscribe(
            (res: HttpResponse<any>) => {
                this.router.navigate(['/login'], { queryParams: { token: this.token } });
            },
            (res: HttpErrorResponse) => {
                this.router.navigate(['/sign-up'], { queryParams: { teamInvitationToken: this.token } });
            }
        );
    }
}
