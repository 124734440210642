import { Injectable } from '@angular/core';
import { SERVER_API_URL } from "app/app.constants";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class GoogleApi {

    constructor(private http: HttpClient) {
    }

    authInWithGoogle(fromSignupPage: boolean): void {
        this.http.get(SERVER_API_URL + 'api/authenticate/google/url', { params: {fromSignupPage}, responseType: 'text' }).subscribe((url) => {
            window.location.href = url;
        });
    }
}
