import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IRegion, Region } from 'app/shared/model/region.model';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { RegionListComponent } from 'app/entities/region/list/region-list.component';
import { RegionUpdateComponent } from 'app/entities/region/edit/region-update.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class RegionResolve implements Resolve<IRegion> {
    constructor(private service: RegionApi) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Region> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<Region>) => response.ok),
                map((region: HttpResponse<Region>) => region.body)
            );
        }
        return of(new Region());
    }
}

export const regionRoute: Routes = [
    {
        path: 'region',
        component: RegionListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Regions'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'region/new',
        component: RegionUpdateComponent,
        resolve: {
            region: RegionResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Regions'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'region/:id/edit',
        component: RegionUpdateComponent,
        resolve: {
            region: RegionResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Regions'
        },
        canActivate: [UserRouteAccessService]
    }
];
