<div class="root">
    <table class="af-table align-right m-b-20">
        <tr class="tr-head">
            <th class="align-left"
                style="width: 35%">
                {{ element?.element }}
            </th>
            <th class="align-left"
                style="width: 15%">
                Specification
            </th>
            <th style="width: 10%">
                Qty
            </th>
            <th style="width: 8%">
                Total
            </th>
            <th style="width: 8%">
                Valuation
            </th>
            <th style="width: 8%">
                Remaining
            </th>
            <th style="width: 8%">
                To invoice
            </th>
            <th style="width: 8%">
                {{ service.getElementTotal(element) || 0 | currency:'GBP' }}
            </th>
        </tr>

        <ng-container *ngFor="let scheduleTask of element.taskDTOs"
                      style="display: contents">
            <tr [className]="scheduleTask.historyStatus == 'MODIFIED' ? 'NEW' : scheduleTask.historyStatus"
                [scheduleTask]="scheduleTask"
                bpValuationScheduleTask>
            </tr>
        </ng-container>
    </table>
</div>
