<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-trade-heading">
                    Create or edit a Trade
                </h1>
                <div>
                    <div [hidden]="!trade.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="trade.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_trade">
                            Trade
                        </label>
                        <input [(ngModel)]="trade.trade"
                               class="form-control"
                               id="field_trade"
                               name="trade"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.trade?.dirty && editForm.controls.trade?.invalid)">
                            <small [hidden]="!editForm.controls.trade?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <label class="form-check-label"
                                   for="archive">
                                Archive
                                <input [(ngModel)]="trade.archive"
                                       class="form-check-input"
                                       id="archive"
                                       name="archive"
                                       type="checkbox">
                                <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
