<div class="card">
    <div class="m-t-10 m-b-10 header-container">
        <div class="total">
            Total
        </div>

        <div *ngIf="!scheduleService.totalCanBeShown"
             class="total">
            <bp-spinner>
            </bp-spinner>
        </div>

        <div *ngIf="scheduleService.totalCanBeShown && scheduleService.total != null"
             class="total">
            {{ scheduleService.total | currency:'GBP' }}
        </div>
    </div>

    <div class="m-b-20 flex flex-column gap-20">
        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'css-element'">
            <bp-schedule-css-elements *ngFor="let cssEl of scheduleService.cssElementMap | keyvalue"
                                      [cssElement]="cssEl.value.cssElement"
                                      [scheduleTasks]="cssEl.value.scheduleTasks">
            </bp-schedule-css-elements>
        </ng-container>

        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'build-up'">
            <bp-schedule-build-ups *ngFor="let bp of scheduleService.buildUpMap | keyvalue"
                                   [buildUp]="bp.value.buildUp"
                                   [scheduleTasks]="bp.value.scheduleTasks">
            </bp-schedule-build-ups>
        </ng-container>

        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'stage' || scheduleService.filterState.groupBy === 'stage_room'">
            <div
                *ngIf="!scheduleService.inProcess() && !scheduleService.filteredStages(scheduleService.stages)?.length">
                <bp-schedule-tasks-were-not-found></bp-schedule-tasks-were-not-found>
            </div>

            <bp-schedule-stage-for-filter-by-stage-grouping
                *ngFor="let stage of scheduleService.filteredStages(scheduleService.stages); let first = first;"
                [stage]="stage"
                [first]="first"
                [showGroupNode]="scheduleService.filterState.groupBy === 'stage'">
            </bp-schedule-stage-for-filter-by-stage-grouping>
        </ng-container>

        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'area' || scheduleService.filterState.groupBy === 'area_room'">
            <div *ngIf="!scheduleService.inProcess() &&
                        !scheduleService.filteredStagesForScheduleAreas(scheduleService.filteredScheduleAreas || []).length; else areasContainer">
                <bp-schedule-tasks-were-not-found></bp-schedule-tasks-were-not-found>
            </div>

            <ng-template #areasContainer>
                <div
                    *ngFor="let scheduleArea of scheduleService.filteredScheduleAreas; let first = first; let last = last;">
                    <div *ngIf="scheduleService.filterState.groupBy === 'area'">
                        <bp-schedule-area-for-filter-by-area-grouping [scheduleArea]="scheduleArea">
                        </bp-schedule-area-for-filter-by-area-grouping>
                    </div>

                    <div *ngIf="scheduleService.filterState.groupBy === 'area_room'"
                         [ngClass]="{'m-l-20': scheduleService.filterState.groupBy === 'area'}"
                         class="flex flex-column gap-10">
                        <bp-schedule-stage-for-filter-by-area-grouping
                            *ngFor="let stage of scheduleService.filteredStages(scheduleArea['stages'], scheduleArea); let first = first;"
                            [stage]="stage"
                            [first]="first"
                            [scheduleArea]="scheduleArea">
                        </bp-schedule-stage-for-filter-by-area-grouping>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>

