import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { QuoterExpandStagesStorageService } from 'app/flows/quoter/quote/services/expand-stages-storage.service';
import { ValuationsStore } from 'app/flows/quoter/valuations/stores/valuations.store';
import { ValuationsService } from "app/flows/quoter/valuations/services/valuations.service";

@Component({
    selector: 'bp-valuation-stage',
    templateUrl: './stage.component.html',
    styleUrls: ['stage.scss']
})
export class ValuationStageComponent implements OnChanges {
    @Input() stage: IComparisonStage;

    constructor(private expandStagesStorageService: QuoterExpandStagesStorageService,
                private store: ValuationsStore,
                protected service: ValuationsService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.expanded != null &&
            !changes.expanded.firstChange &&
            !_.isEqual(changes.expanded.currentValue, changes.expanded.previousValue)
        ) {
            if (changes.expanded.currentValue) {
                this.expand();
            } else {
                this.collapse();
            }
        }
    }

    toggleExpand(): void {
        if (this.stage.expanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    private expand(): void {
        this.stage.expanded = true;
        this.expandStagesStorageService.store(this.store.project, this.stage, "stage", true);
    }

    private collapse(): void {
        this.stage.expanded = false;
        this.expandStagesStorageService.store(this.store.project, this.stage, "stage", false);
    }
}
