import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from '../../app.constants';
import { IComponentArea } from '../model/component-area.model';
import { createRequestOption } from 'app/shared/util/request-util';

@Injectable({ providedIn: 'root' })
export class ComponentAreaService {
    public resourceUrl = SERVER_API_URL + 'api/component-areas';

    constructor(private http: HttpClient) {}

    find(id: number): Observable<HttpResponse<IComponentArea>> {
        return this.http.get<IComponentArea>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IComponentArea[]>> {
        const options = createRequestOption(req);
        return this.http.get<IComponentArea[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    create(componentArea: IComponentArea): Observable<HttpResponse<IComponentArea>> {
        return this.http.post<IComponentArea>(this.resourceUrl, componentArea, { observe: 'response' });
    }

    update(componentArea: IComponentArea): Observable<HttpResponse<IComponentArea>> {
        return this.http.put<IComponentArea>(`${this.resourceUrl}`, componentArea, { observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
