import { Injectable } from '@angular/core';
import { IGeoPoint } from 'app/shared/model/geo-point.model';
import { HttpClient } from '@angular/common/http';
import { isValid } from 'postcode';

interface IPostcodeIOResponse {
    status: number;
    result: {
        postcode: string;
        quality: number;
        eastings: number;
        northings: number;
        country: string;
        nhs_ha: string;
        longitude: number;
        latitude: number;
        european_electoral_region: string;
        primary_care_trust: string;
        region: string;
        lsoa: string;
        msoa: string;
        incode: string;
        outcode: string;
        parliamentary_constituency: string;
        admin_district: string;
        parish: string;
        admin_county: string;
        admin_ward: string;
        ced: string;
        ccg: string;
        nuts: string;
        codes: {
            admin_district: string;
            admin_county: string;
            admin_ward: string;
            parish: string;
            parliamentary_constituency: string;
            ccg: string;
            ccg_id: string;
            ced: string;
            nuts: string;
            lsoa: string;
            msoa: string;
            lau2: string;
        };
    };
}

@Injectable({ providedIn: 'root' })
export class CoordinatesSearcherService {
    public resourceUrl = 'https://api.postcodes.io/postcodes';

    constructor(private http: HttpClient) {
    }

    public search(postcode: string): Promise<IGeoPoint> {
        return new Promise((resolve, reject) => {
            if (!postcode?.length || !isValid(postcode)) {
                resolve({ lat: null, long: null });
            } else {
                this.http.get<IPostcodeIOResponse>(`${this.resourceUrl}/${postcode}`).subscribe(
                    (postcodeIOResult: IPostcodeIOResponse) => {
                        if (postcodeIOResult.status === 200) {
                            resolve({ lat: postcodeIOResult.result.latitude, long: postcodeIOResult.result.longitude });
                        } else {
                            resolve({ lat: null, long: null });
                        }
                    },
                    () => {
                        resolve({ lat: null, long: null });
                    }
                );
            }
        });
    }

    public searchRegion(postcode: string): Promise<string | null> {
        return new Promise((resolve, reject) => {
            if (!isValid(postcode)) {
                resolve(null);
            } else {
                this.http.get<IPostcodeIOResponse>(`${this.resourceUrl}/${postcode}`).subscribe(
                    (postcodeIOResult: IPostcodeIOResponse) => {
                        if (postcodeIOResult.status === 200) {
                            resolve(postcodeIOResult.result.region);
                        } else {
                            resolve(null);
                        }
                    },
                    () => {
                        resolve(null);
                    }
                );
            }
        });
    }
}
