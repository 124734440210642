import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type HowToCreateProjectType = 'cost-plan-wizard' | 'bp-estimating-service';

@Component({
    selector: 'bp-how-to-create-project-modal',
    templateUrl: './how-to-create-project-modal.component.html',
    styleUrls: ['how-to-create-project-modal.scss']
})
export class HowToCreateProjectModalComponent {

    protected _selectedType: HowToCreateProjectType | null = null;

    constructor(private activeModal: NgbActiveModal) {
    }

    protected close(): void {
        this.activeModal.dismiss('close');
    }

    protected select(type: HowToCreateProjectType): void {
        this._selectedType = type;
    }

    protected ok(): void {
        this.activeModal.close({ type: this._selectedType });
    }
}
