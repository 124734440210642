<div class="thanks-for-subscribing">
    <div class="modal-header flex flex-column gap-15 align-items-center">
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body flex flex-column align-items-center gap-20 text-center m-t-30 m-b-10">
        <div>
            You’re all set up! Feel free to reach out to us via the chatbot on the right in case you need some extra
            help.
        </div>
    </div>
    <div class="modal-footer flex justify-content-center">
        <button (click)="onOkClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect ok"
                type="button">
            Close
        </button>
    </div>
</div>
