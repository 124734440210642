import { Component, Input, OnInit } from '@angular/core';
import { TemplateWizardData } from 'app/flows/scheduler/template-wizard/template-wizard.data';
import { ITag } from 'app/shared/model/tag.model';

@Component({
    selector: 'bp-template-wizard-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['../../template-wizard.scss', 'step2.scss']
})
export class Step2Component implements OnInit {
    @Input() data: TemplateWizardData;

    iconDefault = '/content/images/icons/templates/project-scope/default.png';

    inProcessLoadingTags = false;

    ngOnInit(): void {
        this.inProcessLoadingTags = true;

        this.data
            .loadTags().finally(() => {
            this.inProcessLoadingTags = false;
        });
    }

    inProcess(): boolean {
        return this.inProcessLoadingTags;
    }

    trackTagById(index: number, item: ITag): number {
        return item.id;
    }
}
