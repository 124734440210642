<div class="af-row-container">
    <div (click)="toggleExpand()"
         *ngIf="showGroupNode"
         class="row cursor-pointer">
        <div class="col-sm-5">
            <h3>
                {{ cssElement.name }}
            </h3>
            <i class="material-icons">{{ cssElement.expanded ? "expand_more" : "chevron_right" }}</i>
        </div>
        <div class="col-sm-7 flex-container align-items-center flex-end gap-40">
            <h4 *ngFor="let total of totals"
                class="flex-1 max-width-30-percents">
                {{ total || 0 | currency:'GBP' }}
            </h4>
        </div>
    </div>

    <div *ngIf="!showGroupNode || cssElement.expanded">
        <div class="flex flex-column gap-20 element-container m-t-10">
            <bp-comparison-schedule-task
                *ngFor="let scheduleTask of scheduleTasks"
                [scheduleTask]="scheduleTask">
            </bp-comparison-schedule-task>
        </div>
    </div>
</div>
