import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpSingUpComponent } from './sign-up/sign-up.component';
import { CreateQuoterFromInvitationComponent } from './create-quoter-from-invitation/create-quoter-from-invitation.component';
import { QuoterInvitationValidatorComponent } from './quoter-invitation-validator/quoter-invitation-validator.component';
import { ImageUploadComponent } from 'app/account/settings/components/image-upload/image-upload.component';
import { TeamInvitationValidatorComponent } from 'app/account/team-invitation-validator/team-invitation-validator.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { accountState } from 'app/account/account.route';
import { RegisterComponent } from 'app/account/register/register.component';
import { PasswordComponent } from 'app/account/password/password.component';
import { PasswordStrengthBarComponent } from 'app/account/password/password-strength-bar.component';
import { PasswordResetInitComponent } from 'app/account/password-reset/init/password-reset-init.component';
import { PasswordResetFinishComponent } from 'app/account/password-reset/finish/password-reset-finish.component';
import { SettingsComponent } from 'app/account/settings/settings.component';
import { ExportSettingsComponent } from 'app/account/export-settings/export-settings.component';
import { BpSingUpLightComponent } from 'app/account/sign-up-light/sign-up-light.component';
import {
    UnsubscribeCompletedModalComponent
} from 'app/account/settings/components/unsubscribe-completed-modal/unsubscribe-completed-modal.component';
import { BpSingUpStripeComponent } from 'app/account/sign-up-stripe/sign-up-stripe.component';
import { HowYourTrialWorksComponent } from 'app/account/how-your-trial-works/how-your-trial-works.component';
import {
    QuoterInvitationDeclinerComponent
} from 'app/account/quoter-invitation-decliner/quoter-invitation-decliner.component';
import { UserReportModalComponent } from 'app/account/user-report-modal/user-report-modal.component';
import { SignUpTrackingPageComponent } from 'app/account/sign-up-tracking-page/sign-up-tracking-page.component';
import { SystemUpgradesComponent } from 'app/account/system-upgrades/system-upgrades.component';
import { HomeownerComponent } from "app/account/homeowner/homeowner.component";

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(accountState),
        AngularEditorModule
    ],
    declarations: [
        RegisterComponent,
        PasswordComponent,
        PasswordStrengthBarComponent,
        PasswordResetInitComponent,
        PasswordResetFinishComponent,
        SettingsComponent,
        ExportSettingsComponent,
        BpSingUpComponent,
        BpSingUpLightComponent,
        BpSingUpStripeComponent,
        CreateQuoterFromInvitationComponent,
        QuoterInvitationValidatorComponent,
        QuoterInvitationDeclinerComponent,
        TeamInvitationValidatorComponent,
        ImageUploadComponent,
        UnsubscribeCompletedModalComponent,
        HowYourTrialWorksComponent,
        UserReportModalComponent,
        SignUpTrackingPageComponent,
        SystemUpgradesComponent,
        HomeownerComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpFrontendAccountModule {
}
