import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegionUpdateComponent } from 'app/entities/region/edit/region-update.component';
import { RegionDeleteDialogComponent } from 'app/entities/region/delete/region-delete-dialog.component';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { regionRoute } from 'app/entities/region/region.route';
import { RegionListComponent } from 'app/entities/region/list/region-list.component';

const ENTITY_STATES = [...regionRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [RegionListComponent, RegionUpdateComponent, RegionDeleteDialogComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpRegionModule {}
