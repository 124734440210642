<div class="image-upload">
    <div class="flex justify-content-between">
        <label class="image-upload__upload-button btn btn-secondary btn-border-rectangle">
            <span>Upload logo</span>
            <input #imageInput
                   (change)="processFile(imageInput)"
                   accept="image/*"
                   type="file">
        </label>

        <button (click)="remove()"
                [disabled]="inProcess()"
                class="btn btn-outline-secondary m-l-10"
                ngbTooltip="Remove image"
                type="button">
            Remove
        </button>
    </div>

    <div *ngIf="selectedFile"
         class="image-upload__preview-container">
        <div *ngIf="selectedFile.pending"
             class="img-loading-overlay">
            <div class="img-spinning-circle"></div>
        </div>
    </div>
</div>
