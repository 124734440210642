import { Component, HostListener, Input, OnInit } from '@angular/core';
import { IProject } from '../../../model/project.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { Observable, Observer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { IGeoPoint } from 'app/shared/model/geo-point.model';
import { CoordinatesSearcherService } from 'app/shared/services/coordinates-searcher.service';
import { ProjectState } from 'app/shared/constants/project-states.constants';
import { AccountService } from "app/core/auth/account.service";
import { EstimatingAddonService } from 'app/shared/dataservices/estimating-addon.service';
import { AddNewProjectService } from "app/shared/components/projects/services/add-new-project.service";

@Component({
    selector: 'bp-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['project-list.scss'],
    providers: [
        AddNewProjectService
    ]
})
export class ProjectListComponent implements OnInit {
    @Input() showAddNewProjectCard = true;
    @Input() projectStateFilterId: ProjectState;
    @Input() searchValue: string;

    projects: IProject[] = [];

    inProcess = false;

    totalItems: number;
    queryCount: number;
    itemsPerPage: number;
    page: number;

    private lastRnd: string;

    constructor(
        private accountService: AccountService,
        private projectApi: ProjectApi,
        private coordinatesSearcherService: CoordinatesSearcherService,
        private addNewProjectService: AddNewProjectService
    ) {
    }

    get isAdmin(): boolean {
        return this.accountService.isAdmin();
    }

    ngOnInit(): void {
        this.page = 1;
        this.itemsPerPage = 20;

        this.loadPage().subscribe();
    }

    trackId(index: number, item: IProject): number {
        return item.id;
    }

    @HostListener('scroll', ['$event'])
    handleScroll(event: any) {
        if (this.inProcess) {
            return;
        }

        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            if (this.projects.length < this.totalItems) {
                ++this.page;
                this.loadPage().subscribe();
            }
        }
    }

    reset(): void {
        this.projects = [];
        this.page = 1;
        this.loadPage().subscribe();
    }

    protected onAddNewProjectClick(): void {
        this.addNewProjectService.addNew();
    }

    private loadPage(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.lastRnd = new Date().getTime().toString();

            this.inProcess = true;

            const queryParams: any = {
                page: this.page - 1,
                size: this.itemsPerPage,
                sort: 'id,desc'
            };

            switch (this.projectStateFilterId) {
                case 'live':
                    queryParams.archive = false;
                    break;
                case 'archived':
                    queryParams.archive = true;
                    break;
                default:
                    break;
            }

            if (this.searchValue != null && this.searchValue.length) {
                queryParams.searchValue = this.searchValue;
            }

            this.projectApi
                .query(queryParams, this.lastRnd)
                .pipe(
                    finalize(() => {
                        this.inProcess = false;
                    })
                )
                .subscribe((res: HttpResponse<IProject[]>) => {
                    if (res.url.includes(`rnd=${this.lastRnd}`)) {
                        this.paginateProjects(res.body, res.headers);
                    }

                    observer.next();
                    observer.complete();
                });
        });
    }

    private paginateProjects(data: IProject[], headers: HttpHeaders): void {
        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
        this.queryCount = this.totalItems;

        if (this.accountService.isScheduler()) {
            for (const project of data) {
                if (project.postcode != null && (project.latitude == null || project.longitude == null)) {
                    this.coordinatesSearcherService.search(project.postcode).then((geoPoint: IGeoPoint) => {
                        project.latitude = geoPoint.lat;
                        project.longitude = geoPoint.long;

                        if (project.latitude != null && project.longitude != null) {
                            this.projectApi.updateLatLong(project.id, project.latitude, project.longitude).subscribe();
                        }
                    });
                }
            }
        }

        this.projects = this.projects.concat(data);
    }
}
