export default (url: string, callback) => {
    console.log(`url = ${url}`);
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = handler;
    xhr.onerror = function(e) {
        console.log(`onerror = ${e}`);
        callback(null);
    };
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.send();

    function handler() {
        console.log(`handler: xhr.readyState = ${xhr.readyState}, xhr.status = ${xhr.status}`);
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    callback(reader.result);
                };

                reader.readAsDataURL(this.response);
            } else {
                callback(null);
            }
        }
    }
};
