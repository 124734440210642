import { Route } from '@angular/router';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { ExportSettingsComponent } from 'app/account/export-settings/export-settings.component';

export const exportSettingsRoute: Route = {
    path: 'export-settings',
    component: ExportSettingsComponent,
    data: {
        authorities: ['ROLE_SCHEDULER', 'ROLE_QUOTER', 'ROLE_ADMIN'],
        pageTitle: 'Export Settings'
    },
    canActivate: [UserRouteAccessService]
};
