import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IMultiTogglerStep {
    id: number;
    label: string;
    tooltip?: string;
}

@Component({
    selector: 'bp-multi-toggler',
    templateUrl: './multi-toggler.component.html',
    styleUrls: ['multi-toggler.scss']
})
export class MultiTogglerComponent implements OnInit {
    @Input() disabled = false;
    @Input() steps: IMultiTogglerStep[] = [];
    @Input() currentStepId: number;

    @Output() changed = new EventEmitter<{ step: IMultiTogglerStep, callback: Function }>();

    ngOnInit(): void {
    }

    onClick(event: any, step: IMultiTogglerStep): void {
        event.preventDefault();
        const callback = (result: boolean) => {
            if (result) {
                this.currentStepId = step.id;
            }
        }
        this.changed?.emit({step, callback});
    }
}
