import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({ providedIn: 'root' })
export class ProjectSpecificationService {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {}

    change(projectId: number, specId: number): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${this.resourceUrl}/${projectId}/specification/${specId}`, {},{ observe: 'response' });
    }

}
