import * as _ from 'lodash';

export interface IEndpointMaterial {
    id?: number;
    materialId?: number;
    material?: string;
    unit?: string;
    unitId?: number;
    cost?: number;
    margin?: number;
    rate?: number;
    tasks?: string[];
}

export interface IBuilderMaterial {
    id?: number;
    materialId?: number;
    material?: string;
    unit?: string;
    unitId?: number;
    cost?: number;
    margin?: number;
    rate?: number;
    tasks?: string[];

    endpointData(): IEndpointMaterial;
}

export class BuilderMaterial implements IEndpointMaterial {
    private _id?: number;
    private _materialId?: number;
    private _material?: string;
    private _unit?: string;
    private _unitId?: number;
    private _cost?: number;
    private _margin?: number;
    private _rate?: number;
    private _tasks?: string[];

    private _updated?: boolean;

    constructor(material: IEndpointMaterial) {
        this.id = material.id;
        this.material = material.material;
        this.materialId = material.materialId;
        this.unit = material.unit;
        this.unitId = material.unitId;
        this.cost = material.cost;
        this.margin = Math.round(material.margin * 100 * 100) / 100;
        this.rate = material.rate;
        this.tasks = material.tasks;

        this._updated = false;
    }

    get updated(): boolean {
        return this._updated;
    }

    set updated(value: boolean) {
        this._updated = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
        this._updated = true;
    }

    get materialId(): number {
        return this._materialId;
    }

    set materialId(value: number) {
        this._materialId = value;
        this._updated = true;
    }

    get material(): string {
        return this._material;
    }

    set material(value: string) {
        this._material = value;
        this._updated = true;
    }

    get unit(): string {
        return this._unit;
    }

    set unit(value: string) {
        this._unit = value;
        this._updated = true;
    }

    get unitId(): number {
        return this._unitId;
    }

    set unitId(value: number) {
        this._unitId = value;
        this._updated = true;
    }

    get cost(): number {
        return this._cost;
    }

    set cost(value: number) {
        this._cost = value;
        this.updateRate();
        this._updated = true;
    }

    get margin(): number {
        return this._margin;
    }

    set margin(value: number) {
        this._margin = value;
        this.updateRate();
        this._updated = true;
    }

    get rate(): number {
        return this._rate;
    }

    set rate(value: number) {
        this._rate = value;
        this._updated = true;
    }

    get tasks(): string[] {
        return this._tasks;
    }

    set tasks(value: string[]) {
        this._tasks = value;
        this._updated = true;
    }

    endpointData(): IEndpointMaterial {
        return {
            id: this.id,
            material: this.material,
            materialId: this.materialId,
            unit: this.unit,
            unitId: this.unitId,
            cost: this.cost,
            margin: this.margin / 100,
            rate: this.rate,
            tasks: this.tasks
        };
    }

    private updateRate(): void {
        this._rate = _.round(this._cost * (1 + this._margin / 100), 2);
    }
}
