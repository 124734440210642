import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { IApplyMainFilterForQuoterInputData } from 'app/flows/quoter/quote/services/apply-main-filter.service';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IStage } from 'app/shared/model/stage.model';
import { IComparison } from 'app/shared/model/comparison.model';
import { IAccount } from 'app/shared/model/account.model';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { ShowSubStagesMode } from 'app/shared/constants/show-substages-modes';

@Injectable()
export class QuoteStore {
    project: IProject;

    data: IApplyMainFilterForQuoterInputData = {
        filteredScheduleAreas: [],
        comparison: null,
        total: 0
    };

    nativeScheduleAreas: IScheduleArea[];

    stages: IStage[];

    inProcessLoadingScheduleAreas = false;
    inProcessLoadingStages = false;
    inProcessLoadingComparisonForArea = false;
    inProcessLoadingQuoterView = false;
    inProcessLoadingGlobalStages = false;
    inProcessSubmittingQuote = false;
    inProcessReloadProjectQuote = false;

    nativeComparison: IComparison = null;

    account: IAccount;

    filterState: IMainViewFilterState;
    scheduleAreaItems: IMainViewFilterItemItem[] = [];
    stageItems: IMainViewFilterItemItem[] = [];

    inited = false;

    showSubStagesMode: ShowSubStagesMode = 'display-all';

    showSubStages = true;
    showHistoryView = true;

    expandedAll = false;
}
