import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { ScheduleComponent } from 'app/flows/scheduler/schedule/schedule.component';

@Injectable({
    providedIn: 'root'
})
export class ScheduleCanDeactivateGuard implements CanDeactivate<ScheduleComponent> {
    canDeactivate(component: ScheduleComponent): Observable<boolean> | boolean {
        return true;
    }
}
