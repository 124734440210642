import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { HttpResponse } from '@angular/common/http';
import { ProjectQuoterRequestService } from 'app/shared/dataservices/project-quoter-request.service';
import { IProjectQuoterRequest } from 'app/shared/model/project-quoter-request.model';

@Component({
    selector: 'bp-request-project-quoter-modal',
    templateUrl: './request-project-quoter-modal.component.html',
    styleUrls: ['request-project-quoter-modal.scss']
})
export class RequestProjectQuoterModalComponent implements AfterViewInit {
    projectId: number;

    form: FormGroup;

    projectQuoterRequestDescription: FormControl;

    submitted = false;

    @ViewChild('projectQuoterRequestDescriptionElement') projectQuoterRequestDescriptionElement: ElementRef;

    constructor(
        private alertService: BpAlertService,
        private projectQuoterRequestService: ProjectQuoterRequestService,
        private activeModal: NgbActiveModal,
        private fb: FormBuilder
    ) {
        this.initForm();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.projectQuoterRequestDescriptionElement.nativeElement.focus({
                preventScroll: true
            });
        }, 0);
    }

    clear(): void {
        this.activeModal.dismiss('close');
    }

    onRequestProjectQuoterClick(): void {
        const projectQuoterRequest: IProjectQuoterRequest = {
            projectId: this.projectId,
            description: this.projectQuoterRequestDescription.value
        };

        this.projectQuoterRequestService.create(projectQuoterRequest).subscribe(
            (res: HttpResponse<IProjectQuoterRequest>) => {
                this.alertService.success('Request submitted');
                this.activeModal.dismiss('close');
            },
            () => {
                this.alertService.error('Cannot submit request!');
                this.activeModal.dismiss('close');
            }
        );
    }

    private initForm(): void {
        this.projectQuoterRequestDescription = new FormControl('');

        this.form = this.fb.group({
            projectQuoterRequestDescription: this.projectQuoterRequestDescription
        });
    }
}
