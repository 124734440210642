<div class="tasks-were-not-found">
    <span *ngIf="!scheduleService.searchKey?.length; else cannotFindTerm">No data to show</span>
    <ng-template #cannotFindTerm>
        <div>
            Sorry, we couldn't find the term "{{ scheduleService.searchKey }}"{{ scheduleArea ? 'in the "' + scheduleArea.area + '"' : '' }}, would you like to
            <button class="btn btn-link"
                    (click)="addTask()"
                    [disabled]="!scheduleService.nativeScheduleAreas?.length || scheduleService.readOnly || scheduleService.inProcess()">
                Add this task
            </button>
            ?
        </div>
    </ng-template>
</div>
