import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    AfterSignupWizardModalComponent
} from 'app/shared/components/common/after-signup-wizard-modal/after-signup-wizard-modal.component';
import { IActivityInfo } from 'app/shared/model/bp.model';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({ providedIn: 'root' })
export class AfterSignupWizardModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal,
                private accountService: AccountService) {
    }

    showIfNeeded(): void {
        this.accountService.getActivityInfo(true).then((activityInfo: IActivityInfo) => {
            if (this.ngbModalRef != null || !activityInfo.showAdditionalInfoModel) {
                return;
            }
            this.showModal();
        });
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }

    private showModal(): void {
        this.ngbModalRef = this.modalService.open(AfterSignupWizardModalComponent as Component, {
            windowClass: 'bp-modal after-signup-wizard-modal',
            backdrop: 'static'
        });
    }
}
