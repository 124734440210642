import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IStage } from 'app/shared/model/stage.model';
import { IElement } from 'app/shared/model/element.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';

@Injectable({
    providedIn: 'root'
})
export class CalculateStageFilteredByAreaTotalService {
    calculate(stage: IStage, scheduleArea: IScheduleArea): number | null {
        let cannotBeCalculated = false;
        let result = 0;
        _.each(stage.elements, (element: IElement) => {
            _.each(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                if (scheduleTask.scheduleAreaId !== scheduleArea.id || scheduleTask.markedAsDeleted) {
                    return;
                }

                if (scheduleTask.taskTotal.total == null) {
                    cannotBeCalculated = true;
                    return null;
                }
                result += scheduleTask.taskTotal.total;
            });
        });

        return result;
    }
}
