<div class="card">
    <div class="header-container">
        <div class="total">
            Total
        </div>

        <div class="total">
            {{ service.getTotal() | currency:'GBP' }}
        </div>
    </div>

    <div class="no-bottom-border">
        <div *ngFor="let stage of store.nativeComparison?.stageDTOs; let first = first;"
             [ngClass]="{'m-t-20': !first}">
            <bp-valuation-stage
                [stage]="stage">
            </bp-valuation-stage>
        </div>

        <div *ngIf="!store.nativeComparison?.stageDTOs?.length"
             class="m-l-20">
            <span>No stages to show</span>
        </div>
    </div>
</div>
