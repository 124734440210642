<div class="modal-header">
    <div class="full-width flex-between-container items-center">
        <div class="w-100px">
            <img alt="BuildPartner" height="45" src="../../../../../content/images/bp-logo.svg">
        </div>
        <div class="text-center flex-1">
            <h2 class="mb-0">
                Welcome to your Project!
            </h2>
        </div>
        <div class="w-100px">
            <a (click)="returnHome()"
               class="link">
                < Return home
            </a>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="text-center font-weight-normal m-t-10">
        For 0.1% of project value (eg. £100 for £100,000 project) our qualified and insured quantity surveyors will
        calculate quantities, check specifications and apply your labour rates and margin. You can read more <a
        [href]="PAYMENT_TERMS_URL_QUOTER" target="_blank" rel="noopener" class="link">here</a>. Alternatively you can
        download the blank excel template
        below.
    </div>

    <div class="m-t-30 text-center">
        <button (click)="onPayNowClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect pay-now"
                type="button">
            Pay now
        </button>
    </div>

    <div class="m-t-20 text-center">
        Or <a class="link underlined" (click)="onDownloadExcelClick()">Download excel</a> here.
    </div>
</div>

<div class="modal-footer modal-footer-centered">
    <small>
        Note: Buildpartner suggests you review these quantities prior to submitting your quote
    </small>
</div>

