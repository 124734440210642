<div class="af-row-container">
    <div (click)="toggleExpand()"
         *ngIf="showGroupNode"
         class="row cursor-pointer">
        <div class="col-sm-5">
            <h3>
                {{ stage.stage }}
            </h3>
            <i class="material-icons">{{ stage.expanded ? "expand_more" : "chevron_right" }}</i>
        </div>
        <div class="col-sm-7 flex-container align-items-center flex-end gap-40">
            <h4 *ngFor="let total of stage.totals"
                class="flex-1 max-width-30-percents">
                {{ total || 0 | currency:'GBP' }}
            </h4>
        </div>
    </div>

    <div *ngIf="!showGroupNode || stage.expanded">
        <div class="element-container m-t-10">
            <bp-comparison-element
                *ngFor="let element of stage.elementDTOs"
                [element]="element">
            </bp-comparison-element>
        </div>
    </div>
</div>
