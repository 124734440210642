import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SCHEDULER_ROUTE } from './scheduler.route';
import { ProjectsComponent } from './projects/projects.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { IndividualQuoteComponent } from './individual-quote/individual-quote.component';
import { ComparisonComponent } from './comparison/comparison.component';
import { IndividualQuoteStageComponent } from './individual-quote/components/stage/stage.component';
import { IndividualQuoteStagesComponent } from './individual-quote/components/stages/stages.component';
import { IndividualQuoteElementComponent } from './individual-quote/components/element/element.component';
import {
    IndividualQuoteScheduleTaskComponent
} from './individual-quote/components/schedule-task/schedule-task.component';
import {
    ScheduleTaskDetailsComponent
} from './schedule/components/schedule-task-details/schedule-task-details.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ComparisonStageComponent } from './comparison/components/stage/stage.component';
import { ComparisonElementComponent } from './comparison/components/element/element.component';
import { ComparisonScheduleTaskComponent } from './comparison/components/schedule-task/schedule-task.component';
import { SelectQuotersComponent } from './comparison/components/select-quoters/select-quoters.component';
import { EditScheduleAreasComponent } from './schedule/components/edit-schedule-areas/edit-schedule-areas.component';
import {
    ScheduleAreaRowComponent
} from './schedule/components/edit-schedule-areas/components/schedule-area-row/schedule-area-row.component';
import { TemplateListComponent } from 'app/flows/scheduler/components/template-list/template-list.component';
import { TagCardComponent } from 'app/flows/scheduler/components/tag-card/tag-card.component';
import {
    QuoterTotalsStagesComponent
} from 'app/flows/scheduler/comparison/components/quoter-totals/quoter-totals.component';
import {
    AddGroupOfScheduleTasksModalComponent
} from 'app/flows/scheduler/schedule/components/add-group-of-schedule-tasks-modal/add-group-of-schedule-tasks-modal.component';
import {
    EditScheduleTaskInAddGroupComponent
} from 'app/flows/scheduler/schedule/components/edit-schedule-task-in-add-group/edit-schedule-task-in-add-group.component';
import {
    StageForFilterByStageGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-stage-grouping/stage/stage.component';
import {
    StageForFilterByAreaGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-area-grouping/stage/stage.component';
import {
    ElementForFilterByStageGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-stage-grouping/element/element.component';
import {
    ElementForFilterByAreaGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-area-grouping/element/element.component';
import {
    ScheduleTaskForFilterByStageGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-stage-grouping/schedule-task/schedule-task.component';
import {
    ScheduleTaskForFilterByAreaGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-area-grouping/schedule-task/schedule-task.component';
import { BpTemplateWizardModule } from 'app/flows/scheduler/template-wizard/template-wizard.module';
import {
    AddScheduleTaskForFilterByStageGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-stage-grouping/add-schedule-task/add-schedule-task.component';
import {
    AddScheduleTaskForFilterByAreaGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-area-grouping/add-schedule-task/add-schedule-task.component';
import { SchedulerQuotesComponent } from 'app/flows/scheduler/quotes/quotes.component';
import {
    ProjectDetailsInvitationsComponent
} from 'app/flows/scheduler/quotes/components/project-details-invitations/project-details-invitations.component';
import { ProjectDetailsComponent } from 'app/flows/scheduler/project-details/project-details.component';
import {
    RequestProjectQuoterModalComponent
} from 'app/flows/scheduler/components/request-project-quoter-modal/request-project-quoter-modal.component';
import {
    ReviewByQsModalComponent
} from 'app/flows/scheduler/components/review-by-qs-modal/review-by-qs-modal.component';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import {
    UpdateMaterialAndLabourRatesInfoModalComponent
} from 'app/flows/scheduler/components/update-material-and-labour-rates-info-modal/update-material-and-labour-rates-info-modal.component';
import {
    TaskRequestModalComponent
} from 'app/flows/scheduler/schedule/components/task-request-modal/task-request-modal.component';
import {
    BuilderHasBeenInvitedToQuoteModalComponent
} from 'app/flows/scheduler/components/builder-has-been-invited-to-quote-modal/builder-has-been-invited-to-quote-modal.component';
import {
    SubmitForAnalysisModalComponent
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/submit-for-analysis-modal/submit-for-analysis-modal.component';
import {
    SuccessfullySubmittedForAnalysisModalComponent
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/successfully-submitted-for-analysis-modal/successfully-submitted-for-analysis-modal.component';
import { BpDashboardModule } from 'app/flows/scheduler/dashboard/dashboard.module';
import {
    TasksWereNotFoundComponent
} from 'app/flows/scheduler/schedule/components/tasks-were-not-found/tasks-were-not-found.component';
import {
    ScheduleRootContainerComponent
} from 'app/flows/scheduler/schedule/components/root-container/root-container.component';
import {
    ComparisonRootContainerComponent
} from 'app/flows/scheduler/comparison/components/root-container/root-container.component';
import { BpScopeModule } from 'app/flows/scheduler/scopes/scopes.module';
import { BpTeamsModule } from 'app/flows/scheduler/team/teams.module';
import { BpValuationsModule } from 'app/flows/scheduler/valuations/valuations.module';
import {
    AreaForFilterByAreaGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-area-grouping/area/area.component';
import { ComparisonCssElementComponent } from 'app/flows/scheduler/comparison/components/css-element/css-element.component';
import {
    CssElementForFilterByStageGroupingComponent
} from "app/flows/scheduler/schedule/components/filter-by-css-element-grouping/css-element/css-element.component";
import {
    ScheduleTaskForFilterByCssElementGroupingComponent
} from "app/flows/scheduler/schedule/components/filter-by-css-element-grouping/schedule-task/schedule-task.component";
import {
    BuildUpForFilterByStageGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-build-up-grouping/build-up/build-up.component';
import {
    ScheduleTaskForFilterByBuildUpGroupingComponent
} from 'app/flows/scheduler/schedule/components/filter-by-build-up-grouping/schedule-task/schedule-task.component';
import {
    SubmitForAnalysisFormComponent
} from 'app/flows/scheduler/components/submit-for-analysis-form/submit-for-analysis-form.component';

@NgModule({
    imports: [
        BpFrontendSharedModule,
        BpTemplateWizardModule,
        BpDashboardModule,
        BpScopeModule,
        BpTeamsModule,
        BpValuationsModule,
        RouterModule.forRoot([SCHEDULER_ROUTE])
    ],
    declarations: [
        ProjectsComponent,
        ProjectDetailsInvitationsComponent,
        CreateProjectComponent,
        IndividualQuoteComponent,
        ComparisonComponent,
        IndividualQuoteStageComponent,
        IndividualQuoteStagesComponent,
        IndividualQuoteElementComponent,
        IndividualQuoteScheduleTaskComponent,
        ScheduleComponent,
        ScheduleRootContainerComponent,
        StageForFilterByStageGroupingComponent,
        AreaForFilterByAreaGroupingComponent,
        StageForFilterByAreaGroupingComponent,
        ElementForFilterByStageGroupingComponent,
        ElementForFilterByAreaGroupingComponent,
        ComparisonStageComponent,
        ComparisonCssElementComponent,
        ComparisonRootContainerComponent,
        ComparisonElementComponent,
        ComparisonScheduleTaskComponent,
        SelectQuotersComponent,
        ScheduleTaskDetailsComponent,
        EditScheduleAreasComponent,
        ScheduleAreaRowComponent,
        TemplateListComponent,
        TagCardComponent,
        ProjectDetailsComponent,
        QuoterTotalsStagesComponent,
        AddGroupOfScheduleTasksModalComponent,
        EditScheduleTaskInAddGroupComponent,
        ScheduleTaskForFilterByStageGroupingComponent,
        ScheduleTaskForFilterByAreaGroupingComponent,
        AddScheduleTaskForFilterByStageGroupingComponent,
        AddScheduleTaskForFilterByStageGroupingComponent,
        AddScheduleTaskForFilterByAreaGroupingComponent,
        SchedulerQuotesComponent,
        RequestProjectQuoterModalComponent,
        ReviewByQsModalComponent,
        UpdateMaterialAndLabourRatesInfoModalComponent,
        TaskRequestModalComponent,
        BuilderHasBeenInvitedToQuoteModalComponent,
        SubmitForAnalysisModalComponent,
        SuccessfullySubmittedForAnalysisModalComponent,
        TasksWereNotFoundComponent,
        CssElementForFilterByStageGroupingComponent,
        ScheduleTaskForFilterByCssElementGroupingComponent,
        BuildUpForFilterByStageGroupingComponent,
        ScheduleTaskForFilterByBuildUpGroupingComponent,
        SubmitForAnalysisFormComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpFrontendSchedulerModule {
}
