import { Component, Input, OnInit } from '@angular/core';
import { ISpecification } from 'app/shared/model/specification.model';
import { TemplateWizardData } from 'app/flows/scheduler/template-wizard/template-wizard.data';

@Component({
    selector: 'bp-template-wizard-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['../../template-wizard.scss']
})
export class Step1Component implements OnInit {
    @Input() data: TemplateWizardData;

    iconDefault = '/content/images/icons/templates/materials-spec/default.png';

    inProcessLoadingSpecifications = false;

    ngOnInit(): void {
        this.inProcessLoadingSpecifications = true;

        this.data
            .loadSpecifications().finally(() => {
            this.inProcessLoadingSpecifications = false;
        })
    }

    onSpecificationGroupClick(specificationOption): void {
        this.data.project.specification = specificationOption;
    }

    inProcess(): boolean {
        return this.inProcessLoadingSpecifications;
    }

    trackSpecificationById(index: number, item: ISpecification): number {
        return item.id;
    }
}
