import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IScheduleTask } from '../model/schedule-task.model';
import {
    IScheduleTaskSaveByQuoterUpd,
    SaveScheduleTasksByQuoterEffect
} from 'app/shared/model/schedule-task-save-by-quoter-upd.model';
import { IScheduleUpdateData } from 'app/shared/model/schedule-update-data.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';

@Injectable({providedIn: 'root'})
export class ScheduleTaskApi {
    public resourceUrl = SERVER_API_URL + 'api/schedule-tasks';

    constructor(private http: HttpClient) {
    }

    create(scheduleTask: IScheduleTask): Observable<HttpResponse<IScheduleTask>> {
        return this.http.post<IScheduleTask>(this.resourceUrl, scheduleTask, {observe: 'response'});
    }

    saveByQuoter(taskSaveByQuoterUpd: IScheduleTaskSaveByQuoterUpd, effect: SaveScheduleTasksByQuoterEffect): Observable<HttpResponse<any>> {
        const query: any = {effect};
        const options = createRequestOption(query);

        return this.http.put<any>(`${this.resourceUrl}/by-quoter`, taskSaveByQuoterUpd, {
            params: options,
            observe: 'response'
        });
    }

    update(scheduleTask: IScheduleTask): Observable<HttpResponse<IScheduleTask>> {
        const body = Object.assign({}, scheduleTask);
        delete body.buildUps;
        return this.http.put<IScheduleTask>(this.resourceUrl, body, {observe: 'response'});
    }

    find(id: number): Observable<HttpResponse<IScheduleTask>> {
        return this.http.get<IScheduleTask>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    query(req?: any): Observable<HttpResponse<IScheduleTask[]>> {
        const options = createRequestOption(req);
        return this.http.get<IScheduleTask[]>(this.resourceUrl, {params: options, observe: 'response'});
    }

    delete(id: number, projectId: number): Observable<HttpResponse<any>> {
        const scheduleCache = {projectId, deleteIds: [id]} as IScheduleUpdateData;
        return this.http.post<IScheduleTask[]>(`${this.resourceUrl}/batch`, scheduleCache, {observe: 'response'});
    }

    quoterView(id: number): Observable<HttpResponse<number>> {
        return this.http.get<number>(`${this.resourceUrl}/${id}/quoter-view`, {observe: 'response'});
    }
}
