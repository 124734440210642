<div (bpClickOutside)="onClickOutside()"
     class="bp-container">
    <a (click)="openDropdown()"
       class="bp-dropdown">
        <span class="m-r-10">All Quotes<i class="material-icons">unfold_more</i></span>
    </a>
    <div *ngIf="areaDropDown"
         class="bp-dropdown-items-container">
        <div *ngFor="let quoter of quoters"
             class="form-check pl-2">
            <label class="form-check-label">
                <input (ngModelChange)="onSelectionChange($event, quoter)"
                       [(ngModel)]="quoter.selected"
                       class="form-check-input"
                       type="checkbox">
                {{ quoter.company || quoter.email }}<span class="form-check-sign"><span class="check"></span></span>
            </label>
        </div>
    </div>
</div>
