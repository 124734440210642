import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { stageRoute } from 'app/entities/stage/stage.route';
import { StageComponent } from 'app/entities/stage/stage.component';
import { StageDetailComponent } from 'app/entities/stage/stage-detail.component';
import { StageUpdateComponent } from 'app/entities/stage/stage-update.component';
import { StageDeleteDialogComponent } from 'app/entities/stage/stage-delete-dialog.component';

const ENTITY_STATES = [...stageRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [
        StageComponent,
        StageDetailComponent,
        StageUpdateComponent,
        StageDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpStageModule {}
