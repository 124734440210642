<div class="text-center m-t-50">
    <div *ngIf="!result">
        Please wait..
    </div>

    <ng-container *ngIf="result">
        <div [ngSwitch]="result">
            <span *ngSwitchCase="'SUCCESS'">You can close this tab, payment was processed successfully.</span>
            <span *ngSwitchCase="'CANCEL'">Your payment was canceled, please try again or contact administrator.</span>
            <span *ngSwitchCase="'ERROR'">An error occurred, please try again or contact administrator.</span>
        </div>
    </ng-container>
</div>
