import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    show = false;

    showText = false;
    showBackground;
    loaderText;
    fullscreen;
    loaderColor;
    backdropColor;
    backgroundImage;


    constructor() {
        this.reset();
    }

    public start(data: {
                     loaderText?: string,
                     backgroundImage?: string,
                 }): void {
        if (data?.loaderText?.length) {
            this.showText = true;
            this.loaderText = data?.loaderText;
        }

        if (data?.backgroundImage?.length) {
            this.showBackground = true;
            this.backgroundImage = data?.backgroundImage;
        }

        this.show = true;
    }

    public stop(): void {
        this.reset();
        this.show = false;
    }

    private reset(): void {
        this.showText = false;
        this.showBackground = false;
        this.loaderText;
        this.fullscreen = false;
        this.loaderColor = 'rgba(255, 255, 255, 1)';
        this.backdropColor = 'rgba(0, 0, 63, 0.2)';
        this.backgroundImage = 'loading-splash.gif';
    }
}
