import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IAddServiceToProjectResponse, IEstimatingAddon } from 'app/shared/model/bp.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EstimatingAddonService {
    private resourceUrl = SERVER_API_URL + 'api/estimating_addon';

    private addonsSubject: BehaviorSubject<IEstimatingAddon[] | null> = new BehaviorSubject<IEstimatingAddon[] | null>(null);
    public addons$: Observable<IEstimatingAddon[] | null> = this.addonsSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    queryAllServices(force = false): Promise<IEstimatingAddon[]> {
        if (!force && this.addonsSubject.value != null) {
            return Promise.resolve(this.addonsSubject.value);
        }

        return lastValueFrom(this.http.get<IEstimatingAddon[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res) => {
                this.addonsSubject.next(res.body);
                return res.body;
            })));
    }

    queryAssignedServices(projectId: number): Observable<HttpResponse<IEstimatingAddon[]>> {
        return this.http.get<IEstimatingAddon[]>(`${SERVER_API_URL}api/projects/${projectId}/estimating_addon`, { observe: 'response' });
    }

    addServiceToProject(projectId: number, addonId: number): Observable<HttpResponse<IAddServiceToProjectResponse>> {
        return this.http.post<IAddServiceToProjectResponse>(`${SERVER_API_URL}api/projects/${projectId}/estimating_addon`, { id: addonId }, { observe: 'response' });
    }

    statusDone(projectId: number, addonId: number): Observable<HttpResponse<IAddServiceToProjectResponse>> {
        return this.http.put<IAddServiceToProjectResponse>(`${SERVER_API_URL}api/projects/${projectId}/estimating_addon/${addonId}/status-done`, null, { observe: 'response' });
    }
}
