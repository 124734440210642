import { Component } from '@angular/core';
import { ValuationsStore } from 'app/flows/quoter/valuations/stores/valuations.store';
import { ValuationsService } from 'app/flows/quoter/valuations/services/valuations.service';

@Component({
    selector: 'bp-valuation-stages',
    templateUrl: './stages.component.html',
    styleUrls: ['stages.scss']
})
export class ValuationStagesComponent {
    constructor(
        protected store: ValuationsStore,
        protected service: ValuationsService) {
    }
}
