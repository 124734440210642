import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { EntityMaterialCategoryService } from "app/entities/material-category/material-category.service";

@Component({
    selector: 'bp-material-category-delete-dialog',
    templateUrl: './material-category-delete-dialog.component.html'
})
export class MaterialCategoryDeleteDialogComponent {
    materialCategory: IMaterialCategory;

    constructor(private specService: MaterialCategoryService,
                public activeModal: NgbActiveModal,
                private entityService: EntityMaterialCategoryService) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.specService.delete(id).subscribe(response => {
            this.entityService.itemUpdatedSubject.next();
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'bp-material-category-delete-popup',
    template: ''
})
export class MaterialCategoryDeletePopupComponent implements OnInit, OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(({ materialCategory }) => {
            setTimeout(() => {
                this.ngbModalRef = this.modalService.open(MaterialCategoryDeleteDialogComponent as Component, {
                    size: 'lg',
                    backdrop: 'static'
                });
                this.ngbModalRef.componentInstance.materialCategory = materialCategory;

                const doOnResult = () => {
                    this.router.navigate([{ outlets: { popup: null } }], {
                        replaceUrl: true,
                        queryParamsHandling: 'merge'
                    });
                    this.ngbModalRef = null;
                };

                this.ngbModalRef.result.then(
                    result => {
                        doOnResult();
                    },
                    reason => {
                        doOnResult();
                    }
                );
            }, 0);
        });
    }

    ngOnDestroy() {
        this.ngbModalRef = null;
    }
}
