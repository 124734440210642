import { Route } from '@angular/router';
import { StripePaymentResultComponent } from 'app/shared/stripe-payment-result/stripe-payment-result.component';

export const stripePaymentResultRoute: Route = {
    path: 'stripe-payment-result',
    component: StripePaymentResultComponent,
    data: {
        authorities: [],
        pageTitle: 'Stripe Payment Result'
    }
};
