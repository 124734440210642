import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITag } from 'app/shared/model/tag.model';
import { TagService } from 'app/shared/dataservices/tag.service';

interface DismissReason {
    type: 'cancel' | 'error';
    text?: string;
}

@Component({
    selector: 'bp-tag-delete-dialog',
    templateUrl: './tag-delete-dialog.component.html'
})
export class TagDeleteDialogComponent {
    tag: ITag;

    constructor(private tagService: TagService,
                public activeModal: NgbActiveModal) {
    }

    clear(): void {
        this.activeModal.dismiss({ type: 'cancel' });
    }

    confirmDelete(id: number): void {
        this.tagService.delete(id).subscribe(
            response => {
                this.activeModal.close(true);
            },
            error => {
                this.activeModal.close(false);
            }
        );
    }
}
