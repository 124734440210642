import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IProject } from 'app/shared/model/project.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class ComparisonExpandAreaGroupStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(project: IProject, scheduleArea: IScheduleArea, value: boolean): void {
        this.webStorage.store(this.getLocalStorageKey(project, scheduleArea), value);
    }

    retrieve(project: IProject, scheduleArea: IScheduleArea): boolean {
        return this.webStorage.retrieve(this.getLocalStorageKey(project, scheduleArea)) || false;
    }

    private getLocalStorageKey(project: IProject, scheduleArea: IScheduleArea): string {
        return `scheduler_${this.accountService.getAccountId()}_comparison_expandedAreaGroup_${project.id}_${scheduleArea.id}'
        }`;
    }
}
