import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IProject } from 'app/shared/model/project.model';
import {
    MaterialRatesModalComponent
} from "app/flows/quoter/components/material-rates-modal/material-rates-modal.component";

@Injectable({providedIn: 'root'})
export class MaterialRatesModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(project: IProject): NgbModalRef {
        this.ngbModalRef = this.modalService.open(MaterialRatesModalComponent as Component, {
            windowClass: 'bp-modal fit-content material-rates blue-modal min-w-900',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
