import { Route } from '@angular/router';
import { TeamInvitationValidatorComponent } from 'app/account/team-invitation-validator/team-invitation-validator.component';

export const teamInvitationValidatorRoute: Route = {
    path: 'team/invitation/validate',
    component: TeamInvitationValidatorComponent,
    data: {
        authorities: []
    }
};
