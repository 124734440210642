import { IProject } from 'app/shared/model/project.model';

export type ProjectMemberStatus = 'SENT' | 'ACCEPTED';
export type ProjectMemberRole = 'OWNER' | 'READ' | 'EDIT';

export function getPrivilegeText(projectMemberRole: ProjectMemberRole): string {
    switch (projectMemberRole) {
        case 'OWNER':
            return 'Owner';
        case 'READ':
            return 'Can read';
        case 'EDIT':
            return 'Can edit';
    }
    return '';
}

export const TEAM_MEMBER_PERMISSIONS: { id: ProjectMemberRole; title: string; readonly: boolean }[] = [
    { id: 'OWNER', title: getPrivilegeText('OWNER'), readonly: true },
    { id: 'READ', title: getPrivilegeText('READ'), readonly: false },
    { id: 'EDIT', title: getPrivilegeText('EDIT'), readonly: false }
];

export interface IProjectTeamMember {
    id?: number;
    sendDate?: Date;
    expireDate?: Date;
    privilege?: ProjectMemberRole;
    status?: ProjectMemberStatus;
    token?: string;
    project?: IProject;
    email?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
}

export interface IInvitationProjectTeamMember {
    email?: string;
    phone?: string;
    privilege?: ProjectMemberRole;
}
