import { Pipe, PipeTransform } from '@angular/core';
import { PaymentProvider } from 'app/shared/model/payment-provider.model';

@Pipe({ name: 'paymentProviderLabel' })
export class PaymentProviderLabelPipe implements PipeTransform {
    transform(paymentProvider: PaymentProvider): string {
        switch (paymentProvider) {
            case 'STRIPE':
                return 'Credit/debit card';
            case 'GOCARDLESS':
                return 'Direct debit';
            default:
                return paymentProvider;
        }
    }
}
