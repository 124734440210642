import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IBuildUpCategory } from 'app/shared/model/bp.model';
import { BuildUpCategoryApi } from 'app/shared/dataservices/build-up-category.api';

@Component({
    selector: 'bp-build-up-category-update',
    templateUrl: './build-up-category-update.component.html'
})
export class BuildUpCategoryUpdateComponent implements OnInit {
    protected item: IBuildUpCategory;
    protected isSaving: boolean;

    constructor(
        private buildUpCategoryApi: BuildUpCategoryApi,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ buildUpCategory }) => {
            this.item = buildUpCategory;
        });
    }

    protected previousState(): void {
        window.history.back();
    }

    protected save(): void {
        this.isSaving = true;
        if (this.item.id !== undefined) {
            this.subscribeToSaveResponse(this.buildUpCategoryApi.update(this.item));
        } else {
            this.subscribeToSaveResponse(this.buildUpCategoryApi.create(this.item));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IBuildUpCategory>>) {
        result.subscribe(
            (res: HttpResponse<IBuildUpCategory>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
