import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IScheduleArea, IScheduleAreaCache } from "app/shared/model/schedule-area.model";

@Injectable({ providedIn: 'root' })
export class ProjectAreaCacheService {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {
    }

    update(projectId: number, scheduleAreas: IScheduleArea[], deleteScheduleAreas: number[] = []): Observable<HttpResponse<IScheduleAreaCache>> {
        const body: IScheduleAreaCache = {
            timeStamp: Date.now(),
            scheduleAreas,
            deleteAreas: deleteScheduleAreas
        };

        return this.http.post<IScheduleAreaCache>(`${this.resourceUrl}/${projectId}/area-cache`, body, { observe: 'response' });
    }

    get(projectId: number): Observable<HttpResponse<IScheduleAreaCache>> {
        return this.http.get<IScheduleAreaCache>(`${this.resourceUrl}/${projectId}/area-cache`, { observe: 'response' });
    }
}
