import { Injectable } from '@angular/core';
import { IInvitation } from "app/shared/model/invitation.model";
import { interval, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";
import { ProjectApi } from "app/shared/dataservices/project.api";
import * as _ from 'lodash';
import { IProject } from "app/shared/model/project.model";

@Injectable({providedIn: 'root'})
export class UpdateInvitationVersionStatusCheckingService {

    constructor(private projectApi: ProjectApi) {
    }

    addUpdateVersionStatusChecking(project: IProject, invitation: IInvitation): Promise<void> {
        invitation['checker$'] = new Subject<boolean>();

        return new Promise(resolve => {
            interval(2000)
                .pipe(
                    finalize(() => {
                        invitation['checker$'].complete();
                        delete invitation['checker$'];
                        resolve();
                    }),
                    takeUntil(invitation['checker$'])
                )
                .subscribe(() => {
                    this.projectApi
                        .getQuoterToCurrentProjectVersionUpdateStatus(project.id, invitation.quoterId)
                        .subscribe((res: HttpResponse<IInvitation>) => {
                            if (!res.body.processing) {
                                invitation = _.merge(invitation, res.body);
                            }
                            if (!invitation.processing) {
                                invitation['checker$'].next(true);
                            }
                        });
                });
        })
    }
}
