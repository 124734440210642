import { Component, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    BuilderHasBeenInvitedToQuoteModalComponent
} from "app/flows/scheduler/components/builder-has-been-invited-to-quote-modal/builder-has-been-invited-to-quote-modal.component";

@Injectable({providedIn: 'root'})
export class BuilderHasBeenInvitedToQuoteModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal) {
    }

    open(): NgbModalRef {
        this.ngbModalRef = this.modalService.open(BuilderHasBeenInvitedToQuoteModalComponent as Component, {
            windowClass: 'bp-modal builder-has-been-invited-to-quote',
            backdrop: 'static'
        });

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
