import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { CopyProjectService } from 'app/flows/scheduler/services/copy-project.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NUMBER_OF_MS_TO_HIDE_LOADER } from 'app/shared/constants/common.constants';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { lastValueFrom } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class ProjectActionsService {
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private projectApi: ProjectApi,
        private copyProjectService: CopyProjectService,
        private freemiumModalService: FreemiumModalService) {
    }

    deleteProject(project: IProject): Promise<boolean> {
        return new Promise((resolve) => {
            this.freemiumModalService.verify('delete_project').then((allowed) => {
                if (allowed) {
                    this.blockUI.start('Please wait while we remove your project');

                    lastValueFrom(this.projectApi.delete(project.id)).finally(() => {
                        setTimeout(() => {
                            this.blockUI.stop();
                        }, NUMBER_OF_MS_TO_HIDE_LOADER);
                    }).then(() => {
                        resolve(true);
                    })
                } else {
                    resolve(false);
                }
            })
        })
    }

    copyProject(project: IProject): Promise<IProject | null> {
        return new Promise((resolve) => {
            this.freemiumModalService.verify('create_project').then((allowed) => {
                if (allowed) {
                    Swal.fire({
                        title: `How would you like to copy this project?`,
                        icon: 'warning',
                        customClass: {
                            confirmButton: 'btn btn-primary btn-border-radius waves-effect m-r-10',
                            cancelButton: 'btn btn-warning btn-border-radius waves-effect'
                        },
                        showCancelButton: true,
                        showCloseButton: true,
                        confirmButtonText: 'Copy like-for-like',
                        cancelButtonText: 'Update to latest rates',
                    }).then((res: SweetAlertResult) => {
                        if (res.isConfirmed) {
                            this.blockUI.start('Please wait while we create a duplicate version of your project');
                            this.copyProjectService.deepCopy(project).finally(() => {
                                this.blockUI.stop();
                            }).then((newProject: IProject) => {
                                resolve(newProject);
                            })
                        } else if (res.dismiss === Swal.DismissReason.cancel) {
                            this.blockUI.start('Please wait while we create a duplicate version of your project');
                            this.copyProjectService.copy(project).finally(() => {
                                this.blockUI.stop();
                            }).then((newProject: IProject) => {
                                resolve(newProject);
                            })
                        }
                    });
                } else {
                    resolve(null);
                }
            })
        })
    }
}
