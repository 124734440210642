import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IArea } from 'app/shared/model/area.model';
import { HttpResponse } from '@angular/common/http';
import { GetReportDataParams, ProjectApi } from 'app/shared/dataservices/project.api';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IComparison } from 'app/shared/model/comparison.model';
import { QuoteReportService } from 'app/flows/quoter/quote/services/quote-report.service';
import { forkJoin, Observable, Observer } from 'rxjs';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import * as _ from 'lodash';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { QuoterExpandStagesStorageService } from 'app/flows/quoter/quote/services/expand-stages-storage.service';
import { IStage } from 'app/shared/model/stage.model';
import { ShowSubStagesStorageService } from 'app/flows/quoter/quote/services/show-sub-stages-storage.service';
import { finalize } from 'rxjs/operators';
import { ShowHistoryViewStorageService } from 'app/flows/quoter/quote/services/show-history-view-storage.service';
import { QuoterExpandAreaGroupStorageService } from 'app/flows/quoter/quote/services/expand-area-group-storage.service';
import { PaymentProvider } from 'app/shared/model/payment-provider.model';
import {
    SelectPaymentProviderQuoterModalResult
} from 'app/shared/components/common/select-payment-provider-quoter-modal/select-payment-provider-quoter-modal.component';
import {
    SelectPaymentProviderQuoterModalService
} from 'app/shared/components/common/select-payment-provider-quoter-modal/select-payment-provider-quoter-modal.service';
import { IAccount } from 'app/shared/model/account.model';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { ApplyMainFilterForQuoterService, } from 'app/flows/quoter/quote/services/apply-main-filter.service';
import { OptInPayModalService } from 'app/flows/quoter/components/opt-in-pay-modal/opt-in-pay-modal.service';
import { AccountService } from 'app/core/auth/account.service';
import { EXPORT_TYPES, ExportType } from 'app/shared/constants/export-types';
import { PopperContent } from 'ngx-popper';
import {
    SHOW_SUB_STAGES_MODES,
    ShowSubStagesMode,
    ShowSubStagesModeInfo
} from 'app/shared/constants/show-substages-modes';
import {
    ShowSubstagesModeSelectorComponent
} from 'app/shared/components/common/show-substages-mode-selector/show-substages-mode-selector.component';
import { SpecificMarginActionInfo } from 'app/shared/constants/specific-margin-actions';
import {
    MaterialRatesModalService
} from 'app/flows/quoter/components/material-rates-modal/material-rates-modal.service';
import { LabourRatesModalService } from 'app/flows/quoter/components/labour-rates-modal/labour-rates-modal.service';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { FormControl } from '@angular/forms';
import {
    getActualAreaIds,
    getActualStageIds,
    IMainViewFilterState,
    isAreaGrouping
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { UserReportModalService } from 'app/account/user-report-modal/user-report-modal.service';
import { QuoteStore } from 'app/flows/quoter/quote/stores/quote.store';
import { BPStore } from "app/shared/stores/bp.store";

/**
 * Main Quoter Component
 */
@Component({
    selector: 'bp-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['quote.scss'],
    providers: [QuoteStore]
})
export class QuoteComponent implements OnInit {
    routeData: any;
    MENU_TOOLTIP_OPEN_DELAY = 100;

    searchControl = new FormControl();

    @ViewChild('bpShowSubStagesModeSelector') bpShowSubStagesModeSelector: PopperContent;
    @ViewChild(ShowSubstagesModeSelectorComponent) showSubstagesModeSelectorComponent: ShowSubstagesModeSelectorComponent;
    @ViewChild(PopperContent) bpExportSelector: PopperContent;

    constructor(
        protected store: QuoteStore,
        private activatedRoute: ActivatedRoute,
        private alertService: BpAlertService,
        private projectApi: ProjectApi,
        private quoteReportService: QuoteReportService,
        private accountService: AccountService,
        private bpStore: BPStore,
        private expandStagesStorageService: QuoterExpandStagesStorageService,
        private expandAreaGroupStorageService: QuoterExpandAreaGroupStorageService,
        private showSubStagesStorageService: ShowSubStagesStorageService,
        private showHistoryViewStorageService: ShowHistoryViewStorageService,
        private selectPaymentProviderModalService: SelectPaymentProviderQuoterModalService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private applyMainFilterForQuoterService: ApplyMainFilterForQuoterService,
        private optInPayModalService: OptInPayModalService,
        private labourRatesModalService: LabourRatesModalService,
        private materialRatesModalService: MaterialRatesModalService,
        private freemiumModalService: FreemiumModalService,
        private topMenuStateService: TopMenuStateService,
        private _userReportModalService: UserReportModalService
    ) {
        this.routeData = this.activatedRoute.data.subscribe(data => {
            this.store.project = data.project;
            if (this.topMenuStateService.isSchedulerView() || this.store.project.isPaid) {
                this.accountService.identity().then((account: IAccount) => {
                    this.store.account = account;
                    this.store.showSubStages = this.showSubStagesStorageService.retrieve(this.store.project);
                    this.store.showSubStagesMode = this.store.showSubStages ? 'display-all' : 'hide-all';
                    this.store.showHistoryView = this.showHistoryViewStorageService.retrieve(this.store.project);
                    this.reloadAll();
                });
            } else {
                this.optInPayModalService.open(this.store.project);
            }
        });
    }

    get showSubstageModeInfo(): ShowSubStagesModeInfo {
        return SHOW_SUB_STAGES_MODES.find((ssm: ShowSubStagesModeInfo) => ssm.id === this.store.showSubStagesMode);
    }

    ngOnInit(): void {
    }

    search(): void {
        this.reloadAll();
    }

    clearSearch(): void {
        this.searchControl.setValue(null);
        this.reloadAll();
    }


    inProcess(): boolean {
        return (
            this.store.inProcessLoadingScheduleAreas ||
            this.store.inProcessLoadingStages ||
            this.store.inProcessLoadingComparisonForArea ||
            this.store.inProcessLoadingQuoterView ||
            this.store.inProcessLoadingGlobalStages ||
            this.store.inProcessSubmittingQuote ||
            this.store.inProcessReloadProjectQuote
        );
    }

    onShowSubStagesModeChanged(newShowSubStagesMode: ShowSubStagesMode): void {
        this.bpShowSubStagesModeSelector.hide();
        this.store.showSubStagesMode = newShowSubStagesMode;
        this.store.showSubStages = newShowSubStagesMode === 'display-all';
        this.showSubStagesStorageService.store(this.store.project, this.store.showSubStages);
    }

    onMainViewFilterChangedFunc(newFilterState: IMainViewFilterState): void {
        this.store.filterState = newFilterState;
        this.mainFilterInitialStateStorageService.store(this.store.project.id, 'quote', newFilterState);
        this.applyMainViewFilter();
    }

    reloadAll(): void {
        this.store.nativeScheduleAreas = [];
        this.store.data.comparison = null;

        this.loadStages().subscribe(() => {
            this.loadScheduleAreas().subscribe(() => {
                this.loadQuoterView().subscribe(() => {
                    try {
                        this.store.filterState = this.mainFilterInitialStateStorageService.retrieve(
                            this.store.project.id,
                            'quote'
                        );
                    } catch (e) {
                        console.warn('Cannot restore main filter view from local storage');
                    }

                    this.applyMainViewFilter();
                    this.updateExpandedAll();

                    this.store.inited = true;

                    setTimeout(() => {
                        this.bpStore.dataRetrieved = true;
                    }, 400);
                });
            });
        });
    }

    onSubmitButtonClick(): void {
        if (this.store.nativeComparison.quoters[0].payed || this.store.account.freeSubmit) {
            this.submitQuote();
        } else {
            this.selectPaymentProviderModalService.open(this.store.project, this.store.data.total).result.then(
                (result: SelectPaymentProviderQuoterModalResult) => {
                    if (result.error) {
                        this.alertService.error(`Cannot Submit Quote: ${result.error}`);
                    } else {
                        this.submitQuote(result.paymentProvider);
                    }
                },
                () => {
                    // nothing to do on cancelling
                }
            );
        }
    }

    reloadProject(): void {
        this.store.inProcessReloadProjectQuote = true;

        this.projectApi
            .find(this.store.project.id)
            .pipe(
                finalize(() => {
                    this.store.inProcessReloadProjectQuote = false;
                })
            )
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.store.project = res.body;
                }
            );
    }

    toggleHistoryView(): void {
        this.store.showHistoryView = !this.store.showHistoryView;
        this.showHistoryViewStorageService.store(this.store.project, this.store.showHistoryView);
        this.reloadAll();
    }

    toggleExpand(): void {
        this.store.expandedAll = !this.store.expandedAll;

        const stagesToExpand = this.getActualStages();

        if (this.store.filterState.groupBy === 'area') {
            _.each(this.store.data.filteredScheduleAreas, (area: IScheduleArea) => {
                area.expanded = this.store.expandedAll;
                this.expandAreaGroupStorageService.store(this.store.project, area, this.store.expandedAll);
            });
        }
        _.each(stagesToExpand, (stage: IComparisonStage) => {
            stage.expanded = this.store.expandedAll;
            this.expandStagesStorageService.store(this.store.project, stage, this.store.filterState.groupBy, this.store.expandedAll);
        });
    }

    onProjectSpecificMarginSelector(action: SpecificMarginActionInfo): void {
        switch (action.id) {
            case 'labour-rates':
                this.labourRatesModalService.open(this.store.project).result.then(() => {
                    this.reloadAll();
                });
                break;
            case 'material-rates':
                this.materialRatesModalService.open(this.store.project).result.then(() => {
                    this.reloadAll();
                });
                break;
        }
    }

    onExportTypeSelected(exportType: ExportType): void {
        this.bpExportSelector.hide();

        const showSuccessMessage = () => {
            this.alertService.success(`Your ${EXPORT_TYPES.find(et => et.id === exportType).label} document will be downloaded shortly. Please wait.`, 10000);
        }

        const makeExport = () => {
            switch (exportType) {
                case 'csv':
                    this._userReportModalService.open('EXCEL').result.then((res) => {
                        if (res) {
                            this.exportAsExcel();
                            showSuccessMessage();
                        }
                    })
                    break;
                case 'pdf':
                    this._userReportModalService.open('PDF').result.then((res) => {
                        if (res) {
                            this.exportAsPDF();
                            showSuccessMessage();
                        }
                    })
                    showSuccessMessage();
                    break;
                case 'docx':
                    this._userReportModalService.open('WORD').result.then((res) => {
                        if (res) {
                            this.exportAsDocx();
                            showSuccessMessage();
                        }
                    })
                    break;
            }
        }

        if (this.accountService.isQuoterOnly()) {
            makeExport();
        } else {
            this.freemiumModalService.verify('export').then((res) => {
                if (res) {
                    makeExport();
                }
            });
        }
    }

    private exportAsPDF(): void {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(this.store.filterState),
            areaIds: getActualAreaIds(this.store.filterState, this.store.scheduleAreaItems),
            stageIds: getActualStageIds(this.store.filterState, this.store.stageItems)
        };

        this.quoteReportService.exportAsPDF(this.store.project, query);
    }

    private exportAsExcel(): void {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(this.store.filterState),
            areaIds: getActualAreaIds(this.store.filterState, this.store.scheduleAreaItems),
            stageIds: getActualStageIds(this.store.filterState, this.store.stageItems)
        };

        this.quoteReportService.exportAsExcel(this.store.project, query);
    }

    private exportAsDocx(): void {
        const query: GetReportDataParams = {
            isAreaGroup: isAreaGrouping(this.store.filterState),
            areaIds: getActualAreaIds(this.store.filterState, this.store.scheduleAreaItems),
            stageIds: getActualStageIds(this.store.filterState, this.store.stageItems)
        };

        this.quoteReportService.exportAsDocx(this.store.project, query);
    }

    private loadStages(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.store.inProcessLoadingStages = true;

            this.projectApi
                .queryScheduleStages(this.store.project.id)
                .pipe(
                    finalize(() => {
                        this.store.inProcessLoadingStages = false;
                    })
                )
                .subscribe(
                    (res: HttpResponse<IStage[]>) => {
                        this.store.stages = res.body;
                        this.store.stageItems = _.map(this.store.stages, (a: IStage) => {
                            return {
                                id: a.id,
                                title: a.stage
                            };
                        });

                        observer.next();
                        observer.complete();
                    }
                );
        });
    }

    private applyMainViewFilter(): void {
        this.applyMainFilterForQuoterService.apply(
            this.store.nativeScheduleAreas,
            this.store.nativeComparison,
            this.store.filterState,
            this.store.scheduleAreaItems,
            this.store.stageItems,
            this.store.data);
    }

    private submitQuote(provider?: PaymentProvider): void {
        this.store.inProcessSubmittingQuote = true;

        this.projectApi
            .submitQuoterView(this.store.project.id, provider)
            .pipe(
                finalize(() => {
                    this.store.inProcessSubmittingQuote = false;
                })
            )
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.alertService.success('Quote was successfully submitted!');
                    this.reloadProject();
                }
            );
    }

    private updateExpandedAll(): void {
        const actualStages = this.getActualStages();
        const collapsedStage = _.find(actualStages, { expanded: false });

        this.store.expandedAll = collapsedStage == null;

        if (this.store.filterState.groupBy === 'area') {
            const collapsedArea = _.find(this.store.nativeScheduleAreas, { expanded: false });
            this.store.expandedAll = this.store.expandedAll && collapsedArea == null;
        }
    }

    private getActualStages(): IStage[] | IComparisonStage[] {
        let actualStages = [];

        switch (this.store.filterState.groupBy) {
            case 'area':
            case 'area_room':
                _.each(this.store.nativeScheduleAreas, (area: IArea) => {
                    actualStages = _.union(actualStages, area['comparison']['stageDTOs']);
                });
                break;
            case 'stage':
            case 'stage_room':
                actualStages = this.store.data.comparison?.stageDTOs || [];
                break;
        }

        return actualStages;
    }

    private loadQuoterView(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.store.inProcessLoadingQuoterView = true;

            const querySubscription = this.store.showHistoryView
                ? this.projectApi.queryQuoterViewHistory(this.store.project.id, null, this.searchControl.value)
                : this.projectApi.queryQuoterView(this.store.project.id, null, this.searchControl.value);

            querySubscription
                .pipe(
                    finalize(() => {
                        this.store.inProcessLoadingQuoterView = false;
                    })
                )
                .subscribe(
                    (res: HttpResponse<IComparison>) => {
                        this.store.nativeComparison = res.body;

                        observer.next();
                        observer.complete();
                    }
                );
        });
    }

    private loadScheduleAreas(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.store.inProcessLoadingScheduleAreas = true;

            this.projectApi
                .queryAvailableScheduleAreas(this.store.project.id)
                .pipe(
                    finalize(() => {
                        this.store.inProcessLoadingScheduleAreas = false;
                    })
                )
                .subscribe(
                    (res: HttpResponse<IScheduleArea[]>) => {
                        this.store.nativeScheduleAreas = _.clone(res.body);

                        this.store.scheduleAreaItems = _.map(this.store.nativeScheduleAreas, (a: IScheduleArea) => {
                            return {
                                id: a.id,
                                title: a.area
                            };
                        });

                        this.loadComparisonsForAreas().subscribe(() => {
                            observer.next();
                            observer.complete();
                        });
                    }
                );
        });
    }

    private loadComparisonsForAreas(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            const observables = [];

            _.each(this.store.nativeScheduleAreas, (area: IScheduleArea) => {
                observables.push(this.getComparisonForArea(area));
            });

            if (observables.length === 0) {
                observer.next();
                observer.complete();
                return;
            }

            this.store.inProcessLoadingComparisonForArea = true;

            forkJoin(observables)
                .pipe(
                    finalize(() => {
                        this.store.inProcessLoadingComparisonForArea = false;
                    })
                )
                .subscribe((result: Array<IComparison[]>) => {
                    let i = 0;

                    _.each(this.store.nativeScheduleAreas, (area: IScheduleArea) => {
                        area['comparison'] = result[i++];
                    });

                    observer.next();
                    observer.complete();
                });
        });
    }

    private getComparisonForArea(area: IScheduleArea): Observable<IComparison> {
        return new Observable((observer: Observer<IComparison>) => {
            const querySubscription = this.store.showHistoryView
                ? this.projectApi.queryQuoterViewHistory(this.store.project.id, area.id, this.searchControl.value)
                : this.projectApi.queryQuoterView(this.store.project.id, area.id, this.searchControl.value);

            querySubscription.subscribe(
                (res: HttpResponse<IComparison>) => {
                    const comparison = res.body;

                    observer.next(comparison);
                    observer.complete();
                }
            );
        });
    }
}
