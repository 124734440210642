import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'thanks-for-subscribing-modal',
    templateUrl: './thanks-for-subscribing-modal.component.html',
    styleUrls: ['thanks-for-subscribing-modal.scss']
})
export class ThanksForSubscribingModalComponent {

    constructor(
        public activeModal: NgbActiveModal,
    ) {
    }

    onOkClick(): void {
        this.activeModal.close(true);
    }

    clear(): void {
        this.activeModal.close(true);
    }
}
