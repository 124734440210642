import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnitService } from 'app/shared/dataservices/unit.service';
import { IUnit } from 'app/shared/model/unit.model';

@Component({
    selector: 'bp-unit-delete-dialog',
    templateUrl: './unit-delete-dialog.component.html'
})
export class UnitDeleteDialogComponent {
    unit: IUnit;

    constructor(private unitService: UnitService,
                public activeModal: NgbActiveModal) {
    }

    protected clear() {
        this.activeModal.dismiss('cancel');
    }

    protected confirmDelete(id: number) {
        this.unitService.delete(id).subscribe(response => {
            this.activeModal.close(true);
        });
    }
}
