<div class="project-card-container clickable"
     (click)="onSelect()"
     [ngClass]="{ 'admin-card': isAdmin, 'non-admin-card': !isAdmin, 'scheduler-card': firstPath === '/scheduler', 'quoter-card':  firstPath === '/quoter'}">
    <div class="flex flex-row justify-content-between align-items-start m-b-10 relative">
        <div style="width: calc(100% - 20px) !important;">
            <div class="project-card-container__project-name ellipsis-overflow-container-2"
                 [title]="project.address">
                <span>{{ project.address }}</span>
            </div>
            <div class="project-card-container__project-second-line-address ellipsis-overflow-container"
                 *ngIf="!isAdmin"
                 [title]="project.addressSecondLine">
                <span>{{ project.addressSecondLine }}</span>
            </div>
        </div>

        <div ngbDropdown class="project-card-container__context-menu"
             (click)="$event.stopPropagation()">
            <button type="button"
                    class="project-card-container__context-menu__dot-button"
                    ngbDropdownToggle>
                <span>...</span>
            </button>
            <div ngbDropdownMenu class="project-card-container__context-menu__menu">
                <button ngbDropdownItem
                        (click)="copyProject($event)">
                    Copy
                </button>

                <button ngbDropdownItem
                        (click)="toggleArchiveProject($event)">
                    {{ project.archive ? 'Unarchive' : 'Archive' }}
                </button>

                <button ngbDropdownItem
                        (confirm)="deleteProject($event)"
                        *ngIf="project.isOwner"
                        [swal]="{
                                    title: 'Are you sure?',
                                    text: 'You will not be able to recover this project!',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    customClass: {
                                       confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                       cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                    }
                                }">
                    Delete
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!isAdmin">
        <div class="project-card-container__map-container"
             *ngIf="!project.icon">
            <div style="height: 120px; background: #f7f7f7;"
                 *ngIf="mapOptions"
                 leaflet
                 [leafletOptions]="mapOptions">
            </div>
        </div>

        <div class="image-container"
             *ngIf="project.icon">
            <bp-image-previewer [icon]="project.icon"
                                [height]="'120px'">
            </bp-image-previewer>
        </div>

        <div>
            <div class="project-card-container__estimations m-t-10 ellipsis-overflow-container"
                 *ngIf="project.minCost != null && project.maxCost != null; else builderCost">
                Est. {{ project.minCost || 0 | currency:'GBP' }} - {{ project.maxCost || 0 | currency:'GBP' }}
            </div>
            <ng-template #builderCost>
                <div class="project-card-container__estimations m-t-10 ellipsis-overflow-container">
                    {{ project.builderCost || 0 | currency:'GBP' }}
                </div>
            </ng-template>
            <div class="project-card-container__description m-t-10 ellipsis-overflow-container">
                {{ project.description }}
            </div>
        </div>

        <div class="project-card-container__status">
            <bp-project-status
                [status]="project.status">
            </bp-project-status>
        </div>
    </ng-container>

    <ng-container *ngIf="isAdmin">
        <div class="image-container">
            <bp-image-previewer [icon]="project.icon"
                                *ngIf="project.icon"
                                [width]="'140px'">
            </bp-image-previewer>
        </div>
    </ng-container>

</div>
