import { Component, Input } from '@angular/core';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';

@Component({
    selector: '[bpIndividualQuoteScheduleTask]',
    templateUrl: './schedule-task.component.html',
    styleUrls: ['schedule-task.scss']
})
export class IndividualQuoteScheduleTaskComponent {
    @Input() scheduleTask: IComparisonScheduleTask;
}
