import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { ComparisonStore } from 'app/flows/scheduler/comparison/comparison.store';
import { ICssElement } from 'app/shared/model/bp.model';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';
import {
    ComparisonExpandCssElementStorageService
} from 'app/flows/scheduler/comparison/services/expand-css-element-storage.service';

@Component({
    selector: 'bp-comparison-css-element',
    templateUrl: './css-element.component.html',
    styleUrls: ['css-element.scss']
})
export class ComparisonCssElementComponent implements OnInit, OnChanges {
    @Input() cssElement: ICssElement;
    @Input() scheduleTasks: IComparisonScheduleTask[] = [];
    @Input() totals: number[];
    @Input() showGroupNode = true;

    constructor(
        public comparisonStore: ComparisonStore,
        private expandCssElementStorageService: ComparisonExpandCssElementStorageService
    ) {
    }

    ngOnInit(): void {
        //    this.totals = this.getTotals();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.expanded != null &&
            !changes.expanded.firstChange &&
            !_.isEqual(changes.expanded.currentValue, changes.expanded.previousValue)
        ) {
            if (changes.expanded.currentValue) {
                this.expand();
            } else {
                this.collapse();
            }
        }
    }

    protected toggleExpand(): void {
        this.cssElement.expanded ? this.collapse() : this.expand();
    }

    private expand(): void {
        this.cssElement.expanded = true;
        this.expandCssElementStorageService.store(this.comparisonStore.project, this.cssElement, true);
    }

    private collapse(): void {
        this.cssElement.expanded = false;
        this.expandCssElementStorageService.store(this.comparisonStore.project, this.cssElement, false);
    }
}
