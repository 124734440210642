import { Component, Input } from '@angular/core';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';

@Component({
    selector: 'bp-comparison-element',
    templateUrl: './element.component.html'
})
export class ComparisonElementComponent {
    @Input() element: IComparisonElement;
}
