<div class="root"
     [ngClass]="{'plain': plain}">
    <div class="row">
        <div class="col-sm-6">
            <div class="bp-cursor-pointer stage-header"
                 (click)="toggleExpand()">
                <span>{{ stage.stage }}</span>
                <i class="material-icons">{{ stage.expanded ? "expand_more" : "chevron_right" }}</i>
            </div>
        </div>
        <div class="col-sm-6 no-margin no-padding-left text-right">
            <div class="total"
                 [title]="stage.totals[0] || 0 | currency:'GBP'">
                {{ stage.totals[0] || 0 | currency:'GBP' }}
            </div>
        </div>
    </div>

    <div *ngIf="stage.expanded">
        <div class="element-container">
            <bp-quote-element
                *ngFor="let element of stage.elementDTOs;"
                [stage]="stage"
                [element]="element">
            </bp-quote-element>
        </div>
    </div>
</div>
