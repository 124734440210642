import { Component, Input } from '@angular/core';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';
import { ValuationsStore } from 'app/flows/quoter/valuations/stores/valuations.store';
import { ValuationsService } from 'app/flows/quoter/valuations/services/valuations.service';

@Component({
    selector: '[bpValuationScheduleTask]',
    templateUrl: './schedule-task.component.html',
    styleUrls: ['schedule-task.scss']
})
export class ValuationScheduleTaskComponent {
    @Input() scheduleTask: IComparisonScheduleTask;

    constructor(
        protected store: ValuationsStore,
        protected service: ValuationsService
    ) {
    }
}
