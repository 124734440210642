<div class="project-attachment-container">
    <div class="project-attachment">
        <div *ngFor="let selectedFile of selectedFiles; let first = first;"
             [ngClass]="{'m-t-10': !first, 'm-t-20': first}">
            <div style="width: 200px"
                 class="upload-document-button-container">
                <ng-container *ngIf="first">
                    <ng-container *ngTemplateOutlet="uploadButton"></ng-container>
                </ng-container>
            </div>
            <div style="flex: 1">
                <a (click)="download(selectedFile)"
                   class="download-link">
                    <img alt=""
                         class="document-icon m-r-10"
                         src="../../../../../content/images/document.png"/>
                    <div class="line-height-1">{{ selectedFile.file.name }}</div>
                </a>
            </div>
            <div style="width: 40px">
                <button (confirm)="remove(selectedFile)"
                        [disabled]="disabled"
                        [swal]="{
                                title: 'Are you sure you?',
                                text: 'Are you sure you want to delete this file?',
                                icon: 'warning',
                                showCancelButton: true,
                                customClass: {
                                   confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                   cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                }
                        }"
                        class="btn btn-secondary btn-circle bp-icon bp-remove m-l-10"
                        ngbTooltip="Remove document"
                        type="button">
                </button>
            </div>
        </div>

        <div [ngClass]="{'m-t-10': !first, 'm-t-10': first}"
             *ngFor="let attachment of attachmentsCopy; let first = first;">
            <div style="width: 200px"
                 class="upload-document-button-container">
                <ng-container *ngIf="!this.selectedFiles?.length && first">
                    <ng-container *ngTemplateOutlet="uploadButton"></ng-container>
                </ng-container>
            </div>
            <div style="flex: 1">
                <a (click)="downloadAttachment(attachment)"
                   class="download-link">
                    <img alt=""
                         class="document-icon m-r-10"
                         src="../../../../../content/images/document.png"/>
                    <div class="line-height-1">{{ attachment.originalName }}</div>
                </a>
            </div>
            <div style="width: 40px"
                 *ngIf="!disabled">
                <button (confirm)="removeAttachment(attachment)"
                        [disabled]="disabled"
                        [swal]="{
                                title: 'Are you sure you?',
                                text: 'Are you sure you want to delete this file?',
                                icon: 'warning',
                                showCancelButton: true,
                                customClass: {
                                   confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                   cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                }
                        }"
                        class="btn btn-secondary btn-circle bp-icon bp-remove m-l-10"
                        ngbTooltip="Remove document"
                        type="button">
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="!attachments?.length && !selectedFiles?.length"
         class="m-t-20">
        <ng-container *ngTemplateOutlet="uploadButton"></ng-container>
    </div>
</div>

<ng-template #uploadButton>
    <div class="flex flex-column gap-1 items-center fit-content-width">
        <label class="upload-document-container btn btn-primary btn-border-rectangle waves-effect"
               [ngClass]="{'disabled': disabled || attachmentCountExceeded}">
            <span><fa-icon [icon]="['fas', 'arrow-up']"></fa-icon></span>
            <span>{{ buttonLabel }}</span>
            <input #imageInput
                   [disabled]="disabled || attachmentCountExceeded"
                   (change)="processFile(imageInput)"
                   [accept]="accept"
                   type="file">
        </label>
        <small class="line-height-1" *ngIf="showLimitInfo">20 mb limit</small>
    </div>
</ng-template>

<small *ngIf="attachmentCountExceeded" class="m-t-20">Number of attachments exceeded</small>
