import { Component, Input, OnInit } from '@angular/core';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IScheduleTaskQuoterTotalModificationEvent } from 'app/shared/constants/events.constants';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs/operators';
import { ExpandBuildUpStorageService } from 'app/flows/scheduler/schedule/services/expand-build-up-storage.service';
import { IBuildUp } from 'app/shared/model/bp.model';

@UntilDestroy()
@Component({
    selector: 'bp-schedule-build-ups',
    templateUrl: './build-up.component.html',
    styleUrls: [
        '../../../schedule-common.scss',
        '../../root-container/stage.scss',
        'build-up.scss'
    ]
})
export class BuildUpForFilterByStageGroupingComponent implements OnInit {
    @Input() buildUp: IBuildUp;
    @Input() scheduleTasks: IScheduleTask[] = [];

    protected expanded = false;

    constructor(
        protected scheduleService: ScheduleService,
        private scheduleEventsService: ScheduleEventsService,
        private confirmModalService: ConfirmModalService,
        private expandstorageService: ExpandBuildUpStorageService
    ) {
    }

    protected get total(): number {
        let total = 0;
        this.filteredScheduleTasks().forEach((st) => total += st.taskTotal.total);
        return total;
    }

    ngOnInit(): void {
        this.expanded = this.expandstorageService.retrieve(this.scheduleService.project.id, this.buildUp.id);

        this.registerScheduleTaskQuoterTotalModificationEvent();

        this.scheduleService.expandAll$
            .pipe(
                skip(1),
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res;
            this.expandstorageService.store(this.scheduleService.project.id, this.buildUp.id, res);
        })
    }

    protected toggleStageExpand(): void {
        this.expanded ? this.collapse() : this.expand();
    }

    protected onDeleteCssElementClick(): void {
        this.confirmModalService.open({
            header: `Delete ${this.buildUp.name} css element?`,
            textHtml: `<div class='strong m-b-10'>
                            This will delete all its associated tasks and costs.
                        </div>
                        <div class='strong m-b-10'>
                            Note: You can re-add tasks to this css-element by clicking 'On' in the templates section or using the 'add task' function.
                        </div>`
        }).result.then((res) => {
            if (!res) {
                return;
            }
            this.scheduleService.deleteScheduleTasks(this.scheduleTasks.filter((st) => st.buildUpId === this.buildUp.id));
        })
    }

    protected filteredScheduleTasks(): IScheduleTask[] {
        return _.filter(this.scheduleTasks, scheduleTask => {
            return !scheduleTask.markedAsDeleted && (this.scheduleService.showSubStagesMode !== "finishes" || scheduleTask.finishes);
        });
    }

    private expand(): void {
        this.expanded = true;
        this.expandstorageService.store(this.scheduleService.project.id, this.buildUp.id, true);
    }

    private collapse(): void {
        this.expanded = false;
        this.expandstorageService.store(this.scheduleService.project.id, this.buildUp.id, false);
    }

    private registerScheduleTaskQuoterTotalModificationEvent(): void {
        this.scheduleEventsService.scheduleTaskTotalModified$
            .pipe(
                untilDestroyed(this)
            ).subscribe((event: IScheduleTaskQuoterTotalModificationEvent) => {
            let total = 0;
            this.scheduleTasks.forEach(scheduleTask => {
                if (!scheduleTask.markedAsDeleted) {
                    total += scheduleTask.taskTotal.total;
                }
            });

            this.buildUp.total = total;
            this.scheduleEventsService.stageQuoterTotalModified();
        })
    }
}
