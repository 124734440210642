import { Routes } from '@angular/router';
import { BpLoginComponent } from 'app/shared/login/login.component';

export const loginRoute: Routes = [
    {
        path: 'login',
        component: BpLoginComponent,
        data: {
            authorities: [],
            pageTitle: 'Login'
        }
    },
    {
        path: 'welcome-login',
        component: BpLoginComponent,
        data: {
            authorities: [],
            pageTitle: 'Welcome-Login'
        }
    }
];
