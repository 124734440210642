<div class="select-container">
    <div class="select-container__values-container">
        <div class="select-container__values-container__option cursor-pointer"
             *ngFor="let action of actions;">
            <div (click)="select(action)">
                {{ action.label }}
            </div>
        </div>
    </div>
</div>
