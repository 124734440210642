import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IStage } from 'app/shared/model/stage.model';
import { IElement } from 'app/shared/model/element.model';
import { IScheduleTask, ScheduleTask } from 'app/shared/model/schedule-task.model';
import { IArea } from 'app/shared/model/area.model';
import { ITask } from 'app/shared/model/task.model';
import { IProject } from 'app/shared/model/project.model';
import {
    DefaultUnitValueUpdaterService
} from 'app/flows/scheduler/schedule/services/default-unit-value-updater.service';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddItemToScheduleService {
    constructor(
        private defaultUnitValueUpdaterService: DefaultUnitValueUpdaterService,
        private materialCategoryService: MaterialCategoryService
    ) {
    }

    public addScheduleTask(
        project: IProject,
        globalStages: IStage[],
        stage: IStage,
        element: IElement,
        area: IArea,
        task: ITask
    ): Promise<IScheduleTask | null> {
        return new Promise((resolve) => {
            const globalStage = _.find(globalStages, { id: stage.id });

            if (globalStage == null) {
                resolve(null);
                return;
            }

            if (task == null) {
                resolve(null);
                return;
            }

            const newScheduleTask = new ScheduleTask();
            newScheduleTask.ratio = 1;
            newScheduleTask.area = area.area;
            newScheduleTask.scheduleAreaId = area.id;
            newScheduleTask.stageId = stage.id;
            newScheduleTask.elementId = element ? element.id : task ? task.elementId : null;
            newScheduleTask.unitValue = 1;

            newScheduleTask.taskId = task.id;
            newScheduleTask.task = task.task;
            newScheduleTask.displayName = task.task;
            newScheduleTask.specification = task.specification;
            newScheduleTask.componentAreaId = task.componentAreaId;
            newScheduleTask.unitId = task.unitId;
            newScheduleTask.unit = task.unit ? task.unit.toString() : null;
            newScheduleTask.unitPlural = task.unitPlural;
            newScheduleTask.materialCategories = task.materialCategories;
            newScheduleTask.primeMaterialId = null;
            newScheduleTask.newTaskRequestId = task.newTaskRequestId;
            newScheduleTask.scheduleArea = task.scheduleArea;
            newScheduleTask.taskTotal = task.taskTotal;

            const taskPromises: Promise<any>[] = [this.defaultUnitValueUpdaterService.setDefaultUnitValue(newScheduleTask, true)];

            const newScheduleTaskMaterialCategoryId = newScheduleTask.materialCategories?.length ? newScheduleTask.materialCategories[0].id : null;

            if (newScheduleTaskMaterialCategoryId != null) {
                taskPromises.push(
                    lastValueFrom(this.materialCategoryService.primeMaterialId(newScheduleTaskMaterialCategoryId, project.specification.id))
                );
            }

            Promise.all(taskPromises).then((res: any[]) => {
                if (newScheduleTaskMaterialCategoryId != null) {
                    newScheduleTask.primeMaterialId = res[1].body;
                }

                resolve(newScheduleTask);
                return;
            });
        });
    }
}
