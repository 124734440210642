import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IProfession } from 'app/shared/model/profession.model';
import { ProfessionService } from 'app/shared/dataservices/profession.service';

interface DismissReason {
    type: 'cancel' | 'error';
    text?: string;
}

@Component({
    selector: 'bp-profession-delete-dialog',
    templateUrl: './profession-delete-dialog.component.html'
})
export class ProfessionDeleteDialogComponent {
    profession: IProfession;

    constructor(private professionService: ProfessionService,
                public activeModal: NgbActiveModal) {}

    protected clear() {
        this.activeModal.dismiss({ type: 'cancel' });
    }

    protected confirmDelete(id: number): void {
        this.professionService.delete(id).subscribe(
            response => {
                this.activeModal.close(true);
            },
            () => {
                this.activeModal.dismiss({
                    type: 'error',
                    text: 'Cannot delete profession as it is assigned to some project(s)!'
                });
            }
        );
    }
}
