import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QSRequestBody, QSService } from 'app/shared/dataservices/qs.service';
import { IProject } from 'app/shared/model/project.model';
import { finalize } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { SERVICES_PRICING_URL } from 'app/shared/constants/links.constants';

@Component({
    selector: 'bp-review-by-qs-modal',
    templateUrl: './review-by-qs-modal.component.html',
    styleUrls: ['review-by-qs-modal.scss']
})
export class ReviewByQsModalComponent {
    MAIL_TO_EMAIL = 'contact@buildpartner.com';
    SERVICES_PRICING_URL = SERVICES_PRICING_URL;

    project: IProject;
    mode: 'submit' | 'thanks' = 'submit';

    haveAttachedProjectDrawingsControl = new FormControl(false, [Validators.required]);

    serviceTypeControl = new FormControl(null, [Validators.required]);
    additionalCommentsControl = new FormControl('');

    serviceTypes: { value: string, descriptionHtml: string }[] = [
        {
            value: 'Feasibility Study',
            descriptionHtml: `<span class="pound-symbol">&pound;</span>150 (0.1% of project value)`
        },
        {
            value: 'Detailed Cost Plan',
            descriptionHtml: `<span class="pound-symbol">&pound;</span>300 (0.2% of project value)`
        },
        {
            value: 'Bespoke Quote',
            descriptionHtml: `<span class="pound-symbol">&pound;</span>150-<span class="pound-symbol">&pound;</span>450 (0.1% - 0.3% of project value)`
        }
    ];


    inProcess = false;

    constructor(private qsService: QSService, private activeModal: NgbActiveModal) {
    }

    clear(): void {
        this.activeModal.dismiss('close');
    }

    onOkClick(): void {
        this.activeModal.close();
    }

    onSubmitForReviewClick(): void {
        this.inProcess = true;

        const data: QSRequestBody = {
            typeOfService: this.serviceTypeControl.value,
            comments: this.additionalCommentsControl.value || ''
        };

        this.qsService
            .request(this.project.id, data)
            .pipe(
                finalize(() => {
                    this.inProcess = false;
                })
            )
            .subscribe((res: any) => {
                this.mode = 'thanks';
            });
    }
}
