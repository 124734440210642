import { Injectable } from '@angular/core';
import {
    AddGroupOfScheduleTasksRequest,
    IAddScheduleTaskRequest,
    IScheduleTaskModificationEvent,
    IScheduleTaskQuoterTotalModificationEvent,
} from 'app/shared/constants/events.constants';
import { Observable, Subject } from 'rxjs';
import { IScheduleTask } from "app/shared/model/schedule-task.model";

@Injectable({
    providedIn: 'root'
})
export class ScheduleEventsService {
    private addScheduleTaskSubject: Subject<IAddScheduleTaskRequest> = new Subject<IAddScheduleTaskRequest>();
    addScheduleTask$ = this.addScheduleTaskSubject.asObservable();

    addScheduleTask(value: IAddScheduleTaskRequest): void {
        this.addScheduleTaskSubject.next(value);
    }

    private addGroupOfScheduleTasksSubject: Subject<AddGroupOfScheduleTasksRequest> = new Subject<AddGroupOfScheduleTasksRequest>();
    addGroupOfScheduleTasks$ = this.addGroupOfScheduleTasksSubject.asObservable();

    addGroupOfScheduleTasks(value: AddGroupOfScheduleTasksRequest): void {
        this.addGroupOfScheduleTasksSubject.next(value);
    }

    private requestToModifyScheduleTaskSubject: Subject<IScheduleTaskModificationEvent> = new Subject<IScheduleTaskModificationEvent>();
    requestToModifyScheduleTask$ = this.requestToModifyScheduleTaskSubject.asObservable();

    requestToModifyScheduleTask(value: IScheduleTaskModificationEvent): void {
        this.requestToModifyScheduleTaskSubject.next(value);
    }

    private loadingFinishedSubject: Subject<boolean> = new Subject<boolean>();
    loadingFinished$ = this.loadingFinishedSubject.asObservable();

    loadingFinished(value: boolean): void {
        this.loadingFinishedSubject.next(value);
    }

    private stageQuoterTotalModifiedSubject: Subject<void> = new Subject();
    stageQuoterTotalModified$ = this.stageQuoterTotalModifiedSubject.asObservable();

    stageQuoterTotalModified(): void {
        this.stageQuoterTotalModifiedSubject.next();
    }

    private scheduleTaskTotalModifiedSubject: Subject<IScheduleTaskQuoterTotalModificationEvent> = new Subject<IScheduleTaskQuoterTotalModificationEvent>();
    scheduleTaskTotalModified$ = this.scheduleTaskTotalModifiedSubject.asObservable();

    scheduleTaskTotalModified(value: IScheduleTaskQuoterTotalModificationEvent): void {
        this.scheduleTaskTotalModifiedSubject.next(value);
    }

    private scheduleTaskUpdatedOnBESubject: Subject<IScheduleTask> = new Subject<IScheduleTask>();
    scheduleTaskUpdatedOnBE$: Observable<IScheduleTask> = this.scheduleTaskUpdatedOnBESubject.asObservable();

    scheduleTaskUpdatedOnBE(scheduleTask: IScheduleTask): void {
        this.scheduleTaskUpdatedOnBESubject.next(scheduleTask);
    }

    constructor() {
    }
}
