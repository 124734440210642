<div class="container-fluid">
    <form #passwordForm="ngForm"
          (ngSubmit)="changePassword()"
          name="form"
          role="form">
        <div class="row">
            <div class="col-lg-6">
                <h1>
                    Change Password
                </h1>
            </div>
            <div class="col-lg-6 align-right">
                <button [disabled]="passwordForm.form.invalid"
                        class="btn btn-border-radius btn-primary"
                        type="submit">
                    Update Password
                </button>
            </div>

            <div *ngIf="success"
                 class="alert alert-success">
                <strong>Password changed!</strong>
            </div>
            <div *ngIf="error"
                 class="alert alert-danger">
                <strong>An error has occurred!</strong> The password could not be changed.
            </div>

            <div *ngIf="doNotMatch"
                 class="alert alert-danger">
                The password and its confirmation do not match!
            </div>
        </div>

        <div class="card m-t-20">
            <div class="body">
                <div class="form-group">
                    <label class="form-control-label"
                           for="currentPassword">
                        Current password
                    </label>
                    <input #currentPasswordInput="ngModel"
                           [(ngModel)]="currentPassword"
                           class="af-input"
                           id="currentPassword"
                           name="currentPassword"
                           placeholder="Current password"
                           required type="password">
                    <div *ngIf="currentPasswordInput.dirty && currentPasswordInput.invalid">
                        <small *ngIf="currentPasswordInput.errors.required"
                               class="form-text text-danger">
                            Your password is required.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label"
                           for="newPassword">
                        New password
                    </label>
                    <input #newPasswordInput="ngModel"
                           [(ngModel)]="newPassword"
                           class="af-input"
                           id="newPassword"
                           maxlength=50
                           minlength=8
                           name="newPassword"
                           placeholder="New password"
                           required
                           type="password">
                    <div *ngIf="newPasswordInput.dirty && newPasswordInput.invalid">
                        <small *ngIf="newPasswordInput.errors.required"
                               class="form-text text-danger">
                            Your password is required.
                        </small>
                        <small *ngIf="newPasswordInput.errors.minlength"
                               class="form-text text-danger">
                            Your password is required to be at least 8 characters.
                        </small>
                        <small *ngIf="newPasswordInput.errors.maxlength"
                               class="form-text text-danger">
                            Your password cannot be longer than 50 characters.
                        </small>
                    </div>
                    <bp-password-strength-bar
                        [passwordToCheck]="newPassword">
                    </bp-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label"
                           for="confirmPassword">
                        New password confirmation
                    </label>
                    <input #confirmPasswordInput="ngModel"
                           [(ngModel)]="confirmPassword"
                           class="af-input"
                           id="confirmPassword"
                           maxlength=50
                           minlength=8
                           name="confirmPassword"
                           placeholder="Confirm new password"
                           required
                           type="password">
                    <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                        <small *ngIf="confirmPasswordInput.errors.required"
                               class="form-text text-danger">
                            Your confirmation password is required.
                        </small>
                        <small *ngIf="confirmPasswordInput.errors.minlength"
                               class="form-text text-danger">
                            Your confirmation password is required to be at least 8 characters.
                        </small>
                        <small *ngIf="confirmPasswordInput.errors.maxlength"
                               class="form-text text-danger">
                            Your confirmation password cannot be longer than 50 characters.
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
