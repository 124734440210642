import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    ThanksForSubscribingModalComponent
} from 'app/shared/components/common/thanks-for-subscribing-modal/thanks-for-subscribing-modal.component';
import { UserApi } from 'app/shared/dataservices/user.api';
import { IActivityInfo } from 'app/shared/model/bp.model';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({ providedIn: 'root' })
export class ThanksForSubscribingModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal,
                private accountService: AccountService,
                private userApi: UserApi) {
    }

    showIfNeeded(): void {
        this.accountService.getActivityInfo(true).then((res: IActivityInfo) => {
            if (!this.ngbModalRef && !res.isOnTrial && res.showSubscriptionCompletedMessage) {
                this.showModal();
            }
        })
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }

    private showModal(): void {
        this.ngbModalRef = this.modalService.open(ThanksForSubscribingModalComponent as Component, {
            windowClass: 'bp-modal thanks-for-subscribing',
            backdrop: 'static'
        });

        this.ngbModalRef.result.then((res: boolean) => {
            if (res) {
                this.userApi.activity('SEEN_COMPLETED_SUBSCRIPTION_MESSAGE').subscribe(() => {
                    this.ngbModalRef = null;
                });
            }
        });
    }
}
