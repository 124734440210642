import { map } from 'rxjs/operators';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

import { ProjectApi } from 'app/shared/dataservices/project.api';
import { Observable } from 'rxjs';

export function projectAlreadyExistsValidator(projectApi: ProjectApi, initialProjectAddress: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if ((control.value || '').length === 0 || control.value === initialProjectAddress) {
            return Promise.resolve(null);
        }

        return projectApi.projectExists(control.value).pipe(
            map(result => {
                return result.body ? { alreadyExists: true } : null;
            })
        );
    };
}
