import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import { NgSelectComponent } from "@ng-select/ng-select";

export interface SelectInputData {
    indexProperty: string;
    titleProperty: string;
    calcLabelField?: Function;
    initItem?: any;
    initIndex?: any;
    data: any[];
    dropdownPosition?: 'top' | 'bottom' | 'auto';
    clearable?: boolean;
    searchable?: boolean;
    onEnterClickHandler?: Function;
    onOpenHandler?: () => Promise<any[]>;
}

@Component({
    selector: 'bp-select-input',
    templateUrl: './select-input.component.html'
})
export class SelectInputComponent implements OnChanges {
    @Input() disabled = false;
    @Input() placeholder = null;
    @Input() appendTo;
    @Input() data: SelectInputData;
    @Input() removeUnderlines = false;
    @Input() noBottomBorder = false;
    @Input() additionalClasses = '';
    @Input() replaceArrow: boolean = false;

    @Output() onSelectionChange = new EventEmitter();

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
    protected items: any[] = [];
    protected selectedIndex = -1;

    constructor(private ref: ChangeDetectorRef) {
    }

    get addNewPossible(): boolean {
        return (
            this.data?.onEnterClickHandler &&
            this.ngSelectComponent?.itemsList?.filteredItems?.length <= 0 &&
            this.ngSelectComponent?.searchTerm?.length > 0
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !_.isEqual(changes.data.currentValue, changes.data.previousValue)) {
            this.refresh();
        }
    }

    calcLabelForItems(items): any[] {
        return _.map(items, (item: any) => {
            item['_calculatedLabelField'] = this.data.calcLabelField ? this.data.calcLabelField(item) : item[this.data.titleProperty] || '';
            return item;
        });
    }

    prepareItems(items): void {
        this.items = this.calcLabelForItems(items);

        if (this.ngSelectComponent) {
            this.ngSelectComponent.items = this.items;
            this.ref.detectChanges();
        }
    }

    setData(): void {
        const item = this.findSelectedItem();
        this.onSelectionChange.emit(item);
    }

    refresh(): void {
        this.selectedIndex =
            this.data.initIndex != null ? this.data.initIndex : this.data.initItem ? this.data.initItem[this.data.indexProperty] : null;
        this.prepareItems(this.data.data);
    }

    onEnterClick(): void {
        if (!this.addNewPossible) {
            return;
        }

        this.data.onEnterClickHandler(this.ngSelectComponent.searchTerm);
        this.ngSelectComponent.close();
        this.ngSelectComponent.blur();
    }

    protected onAddNewTaskClick(): void {
        this.onEnterClick();
    }

    protected onOpenFunc(): void {
        if (this.data.onOpenHandler) {
            this.prepareItems([]);
            this.data.onOpenHandler().then((newData) => {
                if (newData) {
                    this.prepareItems(newData);
                }
            });
        } else {
            this.prepareItems(this.data.data);
        }
    }

    private findSelectedItem(): any[] {
        return _.find(this.items, (item: any) => {
            return item[this.data.indexProperty] === this.selectedIndex;
        });
    }
}
