import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPayment } from 'app/shared/model/payment.model';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';

export type PaymentStatus = 'PENDING' | 'FAILED' | 'SUCCESS' | 'CREATED';

@Injectable({ providedIn: 'root' })
export class PaymentService {
    public resourceUrl = SERVER_API_URL + 'api/payments';

    constructor(private http: HttpClient) {}

    query(req?: any): Observable<HttpResponse<IPayment[]>> {
        const options = createRequestOption(req);
        return this.http.get<IPayment[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    retrieveStripeStatus(sessionId: string): Observable<PaymentStatus> {
        return this.http.get(`${this.resourceUrl}/stripe/status/${sessionId}`, { responseType: 'text' }).pipe(
            map((res: string) => {
                /* hack to remove double quotes */
                return res as PaymentStatus;
            })
        );
    }

    postStripeStatus(status: 'success' | 'cancel', id: number, isCustomer?: boolean): Observable<HttpResponse<void>> {
        const url = `${this.resourceUrl}/stripe-status/${status}/${id}?isCustomer=${isCustomer ? 'true' : 'false'}`;
        return this.http.post<void>(url, null, { observe: 'response' });
    }
}
