import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import swal from 'sweetalert2';
import { AccountService } from 'app/core/auth/account.service';
import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';
import { IAccount } from 'app/shared/model/account.model';

@Injectable({ providedIn: 'root' })
export class LoginService {
    constructor(
        private accountService: AccountService,
        private authServerProvider: AuthServerProvider,
        private $sessionStorage: SessionStorageService
    ) {}

    login(credentials): Promise<void> {
        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe(
                data => {
                    this.accountService.identity(true).then(() => {
                        return resolve();
                    });
                },
                err => {
                    this.logout();
                    return reject(err);
                }
            );
        });
    }

    loginWithToken(jwt: string): Promise<IAccount> {
        return this.authServerProvider.loginWithToken(jwt).then(() => {
            return this.accountService.identity(true);
        });
    }

    logout(): void {
        swal.close(); /* Closes the currently open SweetAlert2 modal programmatically. */
        this.authServerProvider.logout();
        this.accountService.clear();
        this.$sessionStorage.clear();
    }
}
