<div [ngSwitch]="status">
    <span *ngSwitchCase="'Draft'" class="label bg-draft">Draft</span>
    <span *ngSwitchCase="'Cost Plan'" class="label bg-cost-plan">Cost Plan</span>
    <span *ngSwitchCase="'Schedule'" class="label bg-schedule">Schedule</span>
    <span *ngSwitchCase="'Quoting'" class="label bg-quoting">Quoting</span>
    <span *ngSwitchCase="'Tendering'" class="label bg-tendering">Tendering</span>
    <span *ngSwitchCase="'Awaiting Decision'" class="label bg-awaiting-decision">Awaiting Decision</span>
    <span *ngSwitchCase="'Completed'" class="label bg-completed">Completed</span>
    <span *ngSwitchCase="'Variations Made'" class="label bg-variations-made">Variations Made</span>
    <span *ngSwitchCase="'Quote Accepted'" class="label bg-quote-accepted">Quote Accepted</span>
</div>
