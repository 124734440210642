import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse } from '@angular/common/http';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { IMaterialCategory } from 'app/shared/model/material-category.model';

@Component({
    selector: 'bp-add-material-category-modal',
    templateUrl: './add-material-category-modal.component.html',
    styleUrls: ['add-material-category-modal.scss']
})
export class AddMaterialCategoryModalComponent {
    materialCategoryName: string;

    constructor(public activeModal: NgbActiveModal, private materialCategoryService: MaterialCategoryService) {}

    clear(): void {
        this.activeModal.dismiss('cancel');
    }

    addMaterialCategory(): void {
        const materialCategory: IMaterialCategory = {
            name: this.materialCategoryName
        };

        this.materialCategoryService.create(materialCategory).subscribe((res: HttpResponse<IMaterialCategory>) => {
            this.activeModal.close(res.body);
        });
    }

    isFormValid(): boolean {
        return this.materialCategoryName == null || this.materialCategoryName.length === 0;
    }
}
