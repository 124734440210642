import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { GetReportDataParams, ProjectApi } from 'app/shared/dataservices/project.api';
import { BpDownloadExcelFileService } from 'app/shared/services/bp-download-excel-file.service';
import { AccountService } from 'app/core/auth/account.service';
import { BpDownloadDocFileService } from 'app/shared/services/bp-download-doc-file.service';
import { BpDownloadPDFFileService } from 'app/shared/services/bp-download-pdf-file.service';

@Injectable({
    providedIn: 'root'
})
export class ComparisonReportService {
    constructor(
        private projectApi: ProjectApi,
        private accountService: AccountService,
        private downloadExcelFile: BpDownloadExcelFileService,
        private downloadDocFile: BpDownloadDocFileService,
        private downloadPDFFile: BpDownloadPDFFileService
    ) {
    }

    exportAsPDF(project: IProject, query: GetReportDataParams) {
        const filename = `${this.getFileName(project)}.pdf`;

        this.projectApi.getPDFData(project.id, query).subscribe(
            (data: string) => {
                this.downloadExcelFile.call(data, filename);
            }
        );
    }

    exportAsExcel(project: IProject, query: GetReportDataParams) {
        const filename = `${this.getFileName(project)}.xlsx`;

        this.projectApi.getExcelData(project.id, query).subscribe(
            (data: string) => {
                this.downloadExcelFile.call(data, filename);
            }
        );
    }

    exportAsDocx(project: IProject, query: GetReportDataParams) {
        const filename = `${this.getFileName(project)}.docx`;

        this.projectApi.getDocxData(project.id, query).subscribe(
            (data: string) => {
                this.downloadDocFile.call(data, filename);
            }
        );
    }

    private getFileName(project: IProject): string {
        return `${this.accountService.getCompany() || this.accountService.getLogin()}_comparison_report_for_${project.address}_v${
            project.version
        }`;
    }
}
