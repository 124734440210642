import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    MaterialDeleteDialogComponent,
    MaterialDeletePopupComponent,
    MaterialListComponent,
    materialPopupRoute,
    materialRoute,
    MaterialUpdateComponent
} from './';
import { BpFrontendSharedModule } from 'app/shared/shared.module';

const ENTITY_STATES = [...materialRoute, ...materialPopupRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [MaterialListComponent, MaterialUpdateComponent, MaterialDeleteDialogComponent, MaterialDeletePopupComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpMaterialModule {
}
