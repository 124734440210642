import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    ProjectCommentsModalComponent
} from 'app/shared/components/common/comments-modal/project-comments-modal.component';
import { IProject } from 'app/shared/model/project.model';

@Injectable({ providedIn: 'root' })
export class ProjectCommentsModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal) {
    }

    open(project: IProject, scheduleTaskId: number): NgbModalRef {
        this.ngbModalRef = this.modalService.open(ProjectCommentsModalComponent as Component, {
            size: 'lg',
            windowClass: 'bp-modal comments',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;
        this.ngbModalRef.componentInstance.scheduleTaskId = scheduleTaskId;
        this.ngbModalRef.componentInstance.refresh();

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
