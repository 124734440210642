<div class="archive-option-container">
    <div class="archive-option"
         *ngFor="let option of archiveFilterOptions;">
        <div class="form-check form-check-radio">
            <label>
                <input name="archiveFilterGroup"
                       type="radio"
                       class="with-gap"
                       [value]="archiveFilter"
                       (click)="onClick(option)"
                       [checked]="option.value === archiveFilter.value">
                <span>{{option.label}}</span>
            </label>
        </div>
    </div>
</div>
