import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { IAccount } from 'app/shared/model/account.model';
import { ArchiveFilterValue } from 'app/shared/components/common/archive-filter/archive-filter.component';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { IBuildUp } from 'app/shared/model/bp.model';
import { EntityBuildUpService } from 'app/entities/build-up/build-up.service';
import { BuildUpApi } from 'app/shared/dataservices/build-up.api';

@Component({
    selector: 'bp-build-up-list',
    templateUrl: './build-up-list.component.html',
    styleUrls: ['build-up-list.scss']
})
export class BuildUpListComponent implements OnInit, OnDestroy {
    protected buildUps: IBuildUp[] = [];
    protected currentAccount: IAccount;
    protected itemsPerPage: number;
    protected page: number;
    protected predicate: string;
    protected queryCount: number;
    protected reverse: boolean;
    protected totalItems: number;
    protected buildUpPrefix: string;

    protected searchControl = new FormControl();
    protected searchSub = Subscription.EMPTY;
    protected itemUpdatedSub = Subscription.EMPTY;

    protected archiveFilterValue: ArchiveFilterValue = 'active';

    constructor(
        private buildUpApi: BuildUpApi,
        private accountService: AccountService,
        private entityQueryStorageService: EntityQueryStorageService,
        private entityService: EntityBuildUpService
    ) {
        this.buildUps = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('BUILD_UP');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
        this.buildUpPrefix = entityQuery.searchValue || '';
        this.archiveFilterValue = entityQuery.archive || 'active';

        this.searchControl.setValue(this.buildUpPrefix);
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });

        this.searchSub = this.searchControl.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((term: string) => {
            this.buildUpPrefix = term;
            this.loadPage(0);
        });

        this.itemUpdatedSub = this.entityService.itemUpdated$.subscribe(() => this.loadAll());
    }

    ngOnDestroy(): void {
        this.searchSub?.unsubscribe();
        this.itemUpdatedSub?.unsubscribe();
    }

    protected trackId(index: number, item: IBuildUp): number {
        return item.id;
    }

    protected reset(): void {
        this.page = 0;
        this.buildUps = [];
        this.saveEntityQuery();
        this.loadAll();
    }

    protected loadPage(page: number): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadAll();
    }

    protected hasMore(): boolean {
        return this.buildUps.length < this.totalItems;
    }

    protected onArchiveFilterValueChanged(newValue): void {
        this.archiveFilterValue = newValue;
        this.saveEntityQuery();
        this.reset();
    }

    private sort(): string {
        return this.predicate + ',' + (this.reverse ? 'asc' : 'desc');
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse,
            searchValue: this.buildUpPrefix,
            archive: this.archiveFilterValue
        };

        this.entityQueryStorageService.store('BUILD_UP', entityQuery);
    }

    private loadAll(): void {
        this.buildUpApi
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort(),
                searchValue: this.buildUpPrefix,
                archive: this.archiveFilterValue === 'archived'
            })
            .subscribe(
                (res: HttpResponse<IBuildUp[]>) => {
                    this.paginateBuildUps(res.body, res.headers)
                }
            );
    }

    private paginateBuildUps(data: IBuildUp[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.buildUps = [];
        }

        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);

        for (let i = 0; i < data.length; i++) {
            this.buildUps.push(data[i]);
        }
    }
}
