import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ITemplateWithTag, ProjectApi } from 'app/shared/dataservices/project.api';
import { lastValueFrom } from 'rxjs';
import { NUMBER_OF_MS_TO_HIDE_LOADER } from 'app/shared/constants/common.constants';
import { HttpResponse } from '@angular/common/http';
import { LoaderService } from 'app/shared/services/loader-service.service';
import { TemplateWizardService } from 'app/flows/scheduler/template-wizard/template-wizard.service';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { EstimatingAddonService } from 'app/shared/dataservices/estimating-addon.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ThanksForSubmittingYourProjectModalService
} from 'app/shared/components/projects/thanks-for-submitting-your-project-modal/thanks-for-submitting-your-project-modal.service';
import {
    TryToSelectMediumQuoterAsDefaultService
} from 'app/flows/scheduler/template-wizard/services/try-to-select-medium-quoter-as-default.service';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import {
    IProjectAttachmentAdditionProperties,
    ProjectAttachmentService
} from 'app/shared/model/project-attachment.model';
import { ProjectAttachmentsService } from 'app/shared/dataservices/project-attachments.service';

export interface ICreateAttachmentsData {
    projectId?: number;
    proposedFloorPlanFiles: File[];
    existingFloorPlanFiles: File[];
    proposedFloorPlanURL?: string;
    existingFloorPlanURL?: string;
    serviceType?: ProjectAttachmentService;
}

@Injectable({ providedIn: 'root' })
export class CreateProjectService {
    @BlockUI() blockUI: NgBlockUI;

    constructor(private projectApi: ProjectApi,
                private invitationService: InvitationService,
                private estimatingAddonService: EstimatingAddonService,
                private templateWizardService: TemplateWizardService,
                private alertService: BpAlertService,
                private router: Router,
                private loaderService: LoaderService,
                private projectAttachmentsService: ProjectAttachmentsService,
                private tryToSelectMediumQuoterAsDefaultService: TryToSelectMediumQuoterAsDefaultService,
                private thanksForSubmittingYourProjectModalService: ThanksForSubmittingYourProjectModalService) {
    }

    public create(addonId: number | null,
                  currentRoute: ActivatedRoute,
                  project: IProject,
                  files: File[],
                  templateWithTag: ITemplateWithTag[] = [],
                  createAttachmentsData?: ICreateAttachmentsData): void {
        if (addonId != null) {
            this.blockUI.start();
        } else {
            this.loaderService.start({ backgroundImage: 'create-project.gif' });
        }

        lastValueFrom(this.projectApi
            .createWithTemplates(project, files, templateWithTag))
            .then(
                (res: HttpResponse<IProject>) => {
                    project = res.body;

                    if (addonId != null) {
                        lastValueFrom(this.estimatingAddonService.addServiceToProject(project.id, addonId))
                            .then(() => {
                                const finishIt = () => {
                                    this.blockUI.stop();

                                    this.router.navigate(['../projects'], { relativeTo: currentRoute }).then(() => {
                                        this.thanksForSubmittingYourProjectModalService.showModal();
                                    });
                                }

                                if (createAttachmentsData) {
                                    this.createAttachments(Object.assign({projectId: project.id}, createAttachmentsData)).then(() => {
                                        finishIt();
                                    })
                                } else {
                                    finishIt();
                                }
                            })
                    } else {
                        this.templateWizardService.sendTemplateRequestsBatchIfNeeded(res.body.id).then((templateRequestsWereSubmitted) => {
                            if (templateRequestsWereSubmitted) {
                                this.alertService.success('Request(s) have been successfully submitted.');
                            }
                        });

                        // Adding default quoters
                        this.invitationService
                            .addDefaultQuoters(project.id)
                            .subscribe(() => {
                                const navigate = () => {
                                    setTimeout(() => {
                                        this.loaderService.stop();
                                    }, NUMBER_OF_MS_TO_HIDE_LOADER);

                                    this.alertService.success(`Your project has been created`);
                                    this.router.navigate(['../edit-schedule-areas', project.id], {
                                        queryParams: { mode: 'create' },
                                        relativeTo: currentRoute
                                    });
                                };

                                this.tryToSelectMediumQuoterAsDefaultService
                                    .try(project.id)
                                    .finally(() => {
                                        navigate(); // navigate from this page in any case
                                    })
                            });
                    }
                }
            );
    }

    private createAttachments = (data: ICreateAttachmentsData): Promise<boolean> => {
        const promises = [];

        data.proposedFloorPlanFiles.forEach((file) => {
            const additionalProperties: IProjectAttachmentAdditionProperties = {
                'subtype': 'proposed',
                'url': data.proposedFloorPlanURL || ''
            };

            const promise = lastValueFrom(this.projectAttachmentsService.upload(
                data.projectId,
                file,
                'FLOOR_PLAN',
                promises.length === 0,
                true,
                data.serviceType,
                additionalProperties
            ));

            promises.push(promise);
        });

        if (data.proposedFloorPlanURL?.length) {
            const additionalProperties: IProjectAttachmentAdditionProperties = {
                'subtype': 'proposed',
                'url': data.proposedFloorPlanURL || ''
            };

            const promise = lastValueFrom(this.projectAttachmentsService.upload(
                data.projectId,
                null,
                'FLOOR_PLAN',
                promises.length === 0,
                true,
                data.serviceType,
                additionalProperties
            ));

            promises.push(promise);
        }

        data.existingFloorPlanFiles.forEach((file) => {
            const additionalProperties: IProjectAttachmentAdditionProperties = {
                'subtype': 'existing'
            };

            const promise = lastValueFrom(this.projectAttachmentsService.upload(
                data.projectId,
                file,
                'FLOOR_PLAN',
                promises.length === 0,
                true,
                data.serviceType,
                additionalProperties
            ));

            promises.push(promise);
        });

        if (data.existingFloorPlanURL?.length) {
            const additionalProperties: IProjectAttachmentAdditionProperties = {
                'subtype': 'existing',
                'url': data.existingFloorPlanURL || ''
            };

            const promise = lastValueFrom(this.projectAttachmentsService.upload(
                data.projectId,
                null,
                'FLOOR_PLAN',
                promises.length === 0,
                true,
                data.serviceType,
                additionalProperties
            ));

            promises.push(promise);
        }

        return promises.length ? Promise.all(promises).then(() => true) : Promise.resolve(true);
    }
}
