import { Injectable } from '@angular/core';
import { BpAlertService } from 'app/shared/services/bp-alert.service';

const MAX_FILE_SIZE_BYTES = 20971520; // 20mb

@Injectable({ providedIn: 'root' })
export class UploadedFileCheckerService {
    constructor(private alertService: BpAlertService) {}

    check(file: File): boolean {
        if (file.size > MAX_FILE_SIZE_BYTES) {
            this.alertService.error(`File size cannot be more than ${this.bytesToSize(MAX_FILE_SIZE_BYTES)}`);
            return false;
        }

        return true;
    }

    private bytesToSize(bytes: number): string {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) {
            return '0 Byte';
        }
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }
}
