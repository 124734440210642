<div class="css-element-container w-full"
     *ngIf="cssElement"
     id="css-element-container_{{ cssElement.id }}"
     [ngClass]="{'expanded': expanded}">
    <div>
        <div class="m-l-20">
            <div class="w-full flex flex-row flex-wrap gap-10 justify-content-between align-items-center"
                 style="height: 36px;">
                <div class="flex-1">
                    <div class="bp-cursor-pointer root-header m-l--20"
                         (click)="toggleStageExpand()">
                        <span>{{ cssElement.name }}</span>
                        <i class="material-icons">{{ expanded ? "expand_more" : "chevron_right" }}</i>
                    </div>
                </div>

                <div class="flex-1 explanations-data-line">
                    <span *ngIf="expanded" class="p-l-15">Specification</span>
                </div>

                <div class="flex-0_5 explanations-data-line">
                    <span *ngIf="expanded">Area</span>
                </div>

                <div class="qti explanations-data-line">
                    <span *ngIf="expanded">Qty</span>
                </div>

                <div
                    class="totals font-12 flex flex-row justify-content-between items-center gap-10 explanations-data-line">
                    <ng-container *ngIf="expanded">
                        <div class="flex-1 text-center overflow-ellipsis">
                            Labour<br>cost/unit
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Materials<br>cost/unit
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Unit<br>total cost
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Line<br>Total
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Client<br>Supplied
                        </div>
                    </ng-container>
                </div>
                <div class="controls">
                    <div class="total"
                         [title]="total | currency:'GBP'">
                        {{ total | currency:'GBP' }}
                    </div>
                    <button (click)="onDeleteCssElementClick()"
                            [disabled]="scheduleService.disabled"
                            class="delete-stage btn btn-circle btn-small btn-secondary"
                            ngbTooltip="Delete css-element">
                        <i class="material-icons md-18">delete_forever</i>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="expanded">
            <bp-schedule-task-for-filter-by-css-element-grouping
                *ngFor="let scheduleTask of filteredScheduleTasks()"
                [scheduleTask]="scheduleTask"
                [stage]="scheduleService.getStage(scheduleTask)"
                [element]="scheduleService.getElement(scheduleTask)">
            </bp-schedule-task-for-filter-by-css-element-grouping>
        </div>
    </div>
</div>

