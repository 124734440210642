import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { IPrimeMaterialViewInfo, ITask } from 'app/shared/model/task.model';
import { TaskApi } from 'app/shared/dataservices/task.api';
import { ArchiveFilterValue } from 'app/shared/components/common/archive-filter/archive-filter.component';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { IShortLabourComponent, IShortMaterialComponent, IShortTask } from 'app/shared/model/short-task.model';
import { InlineEditOnBlurEvent } from 'app/shared/components/common/inline-edit/inline-edit.component';
import * as _ from 'lodash';
import { ITrade } from 'app/shared/model/trade.model';
import { TradeService } from 'app/shared/dataservices/trade.service';
import { IStage } from 'app/shared/model/stage.model';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { FormControl } from '@angular/forms';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { IAccount } from 'app/shared/model/account.model';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TaskDeleteDialogComponent } from "app/entities/task/delete/task-delete-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@UntilDestroy()
@Component({
    selector: 'bp-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['task-list.scss']
})
export class TaskListComponent implements OnInit {
    protected tasks: ITask[];
    protected currentAccount: IAccount;
    protected itemsPerPage: number;
    protected page: number;
    protected predicate: any;
    protected queryCount: number;
    protected reverse: any;
    protected totalItems: number;
    protected taskPrefix: string;
    protected trades: ITrade[];
    protected archiveFilterValue: ArchiveFilterValue;
    protected inProcessAutosavingTask: boolean;

    protected searchControl = new FormControl();

    constructor(
        private taskService: TaskApi,
        private tradeService: TradeService,
        private accountService: AccountService,
        private modalService: NgbModal,
        private entityQueryStorageService: EntityQueryStorageService
    ) {
        this.tasks = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('TASK');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
        this.taskPrefix = entityQuery.searchValue || '';
        this.archiveFilterValue = entityQuery.archive || 'active';

        this.searchControl.setValue(this.taskPrefix);
    }

    private static isTaskEditable(task: ITask): boolean {
        return task.labours.length <= 1;
    }

    ngOnInit(): void {
        this.loadAll();

        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });

        this.searchControl.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged(),
            untilDestroyed(this))
            .subscribe((term: string) => {
                this.taskPrefix = term;
                this.loadPage(0);
            });
    }

    reset(): void {
        this.page = 0;
        this.tasks = [];
        this.saveEntityQuery();
        this.loadTasks();
    }

    loadPage(page): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadTasks();
    }

    protected trackId(index: number, item: ITask): number {
        return item.id;
    }

    protected hasMore(): boolean {
        return this.tasks.length < this.totalItems;
    }

    protected onArchiveFilterValueChanged(newValue): void {
        this.archiveFilterValue = newValue;
        this.saveEntityQuery();
        this.reset();
    }

    protected onBlurInlineEdit(event: InlineEditOnBlurEvent, task: ITask): void {
        if (event.oldValue === event.newValue) {
            return;
        }

        this.autoSaveTask(task);
    }

    protected onBlurLabourEdit(event: InlineEditOnBlurEvent, task: ITask): void {
        if (event.oldValue === event.newValue) {
            return;
        }

        const newTrade = _.find(this.trades, { id: event.newValue });
        if (newTrade == null) {
            return null;
        }

        task.labours[0].tradeId = newTrade.id;
        // task.labours[0].quantity = 0;

        this.autoSaveTask(task);
    }

    protected total(task: ITask): number {
        return (
            _.sumBy(task.labours, labour => labour.quantity * labour.rate) +
            _.sumBy(task.materials, material => material.quantity * material.rate)
        );
    }

    protected onDeleteTask(task: ITask): void {
        const ngbModalRef = this.modalService.open(TaskDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.task = task;

        ngbModalRef.result.then(
            result => {
               this.reset();
            },
            reason => {
              //
            }
        );
    }

    private sort(): string {
        return this.predicate + ',' + (this.reverse ? 'asc' : 'desc');
    }

    private loadAll(): void {
        this.loadTasks();
        this.loadTrades();
    }

    private loadTasks(): void {
        this.taskService
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort(),
                searchValue: this.taskPrefix,
                archive: this.archiveFilterValue === 'archived'
            })
            .pipe(debounceTime(500))
            .subscribe(
                (res: HttpResponse<ITask[]>) => this.paginateTasks(res.body, res.headers)
            );
    }

    private loadTrades(): void {
        this.tradeService.query().subscribe(
            (res: HttpResponse<IStage[]>) => {
                this.trades = res.body;
            }
        );
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse,
            searchValue: this.taskPrefix,
            archive: this.archiveFilterValue
        };

        this.entityQueryStorageService.store('TASK', entityQuery);
    }

    private autoSaveTask(task: ITask): void {
        this.inProcessAutosavingTask = true;

        const labourComponents: IShortLabourComponent[] = _.map(task.labours, (labour: any) => {
            return {
                id: labour.id,
                quantity: labour.quantity,
                trade: {
                    id: labour.tradeId,
                    trade: labour.trade
                }
            };
        });

        const materialComponents: IShortMaterialComponent[] = _.map(task.materials, (material: any) => {
            return {
                id: material.id,
                quantity: material.quantity,
                material: {
                    id: material.materialId,
                    material: material.material
                }
            };
        });

        const shortTask: IShortTask = {
            id: task.id,
            task: task.task,
            primeMaterials: task.primeMaterials,
            labourComponent: labourComponents[0],
            materialComponent: materialComponents[0]
        };

        this.taskService
            .updateShort(shortTask)
            .pipe(
                finalize(() => {
                    this.inProcessAutosavingTask = false;
                })
            )
            .subscribe(
                (res: HttpResponse<ITask>) => {
                    task = _.merge(task, res.body);

                    // if we will update prime cost in one task it will be changed in another task too
                    // because now prime materials is linked to material category not task
                    if (task.primeMaterials.length > 0) {
                        _.each(task.primeMaterials, (pmInfo: IPrimeMaterialViewInfo) => {
                            _.each(this.tasks, (t: ITask) => {
                                if (t.id === task.id) {
                                    return;
                                }
                                const tPMInfo = _.find(t.primeMaterials, { id: pmInfo.id });
                                if (tPMInfo) {
                                    _.merge(tPMInfo, pmInfo);
                                }
                            });
                        });
                    }
                }
            );
    }

    private paginateTasks(data: ITask[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.tasks = [];
        }

        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);

        for (let i = 0; i < data.length; i++) {
            const task: ITask = data[i];
            task._editable = TaskListComponent.isTaskEditable(task);
            this.tasks.push(data[i]);
        }
    }
}
