import { ITask } from './task.model';
import { ITrade } from './trade.model';

export interface ILabourComponent {
    id?: number;
    quantity?: number;
    task?: ITask;
    trade?: ITrade;
}

export class LabourComponent implements ILabourComponent {
    constructor(public id?: number, public quantity?: number, public task?: ITask, public trade?: ITrade) {}
}
