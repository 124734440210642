import { Injectable } from '@angular/core';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { ScheduleAreasStore } from 'app/shared/services/schedule-areas.store';

@Injectable({ providedIn: 'root' })
export class ScheduleAreasService {
    constructor(private scheduleAreasStore: ScheduleAreasStore) {
    }

    calcCostPerSqm(areaIds: number[], totalCost: number): Promise<number> {
        if (!this.scheduleAreasStore.scheduleAreas) {
            return Promise.resolve(0);
        }

        let gia = 0;
        const scheduleAreas: IScheduleArea[] = this.scheduleAreasStore.scheduleAreas
            .filter(area => areaIds.indexOf(area.id) !== -1 && !area.tag?.name.toLowerCase().includes('shell'));
        scheduleAreas.forEach((scheduleArea: IScheduleArea) => {
            gia += scheduleArea.floorArea;
        })
        if (gia > 0) {
            return new Promise(resolve => resolve(totalCost / gia));
        } else {
            return new Promise(resolve => resolve(0));
        }
    }
}
