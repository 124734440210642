import { Component, OnDestroy, OnInit } from '@angular/core';
import { ValuationsService } from 'app/flows/quoter/valuations/services/valuations.service';
import { ValuationsStore } from 'app/flows/quoter/valuations/stores/valuations.store';
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
    selector: 'bp-valuations-table',
    templateUrl: './valuations-table.component.html',
    styleUrls: ['valuations-table.scss']
})
export class ValuationsTableComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        protected service: ValuationsService,
        protected store: ValuationsStore
    ) {
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    protected onCreateInvoiceClick(): void {
        this.blockUI.start('Please wait');
        this.service.createInvoice().finally(() => {
            this.blockUI.stop();
        });
    }
}
