import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ILaboursWrapper, QuoterService } from 'app/shared/dataservices/quoter.service';
import { IBuilderLabour, IEndpointLabour } from 'app/shared/model/builder-labour.model';
import { BpOnErrorService } from 'app/shared/services/bp-on-error.service';
import {
    ILabourRatesDataModel,
    LabourRatesDataModel
} from 'app/flows/quoter/labour-rates/labour-rates-data-model.model';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'app/core/auth/account.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DEFAULT_LABOUR_RATES_HELP_URL } from 'app/shared/constants/links.constants';

const DEFAULT_LABOUR_RATES_HELP_TEXT =
    `<h2 style='text-align: center; margin-top: 10px'>What are default labour rates?</h2>` +
    `<div class="strong font-14 m-t-30" style='text-align: justify;'>` +
    `<div class="m-b-10">Default labour rates are labour rates applied automatically to new quotes. Existing quotes will be unaffected by any changes made on this page.</div>` +
    `<div class="m-b-10">New quotes = The project has just appeared on your account for quotation.</div>` +
    `<div class="m-b-10">To update an existing projects rates, navigate to that project and use the project rates feature.</div>` +
    `<a class="link underlined" href='${DEFAULT_LABOUR_RATES_HELP_URL}' target='_blank'>Learn more</a>` +
    `</div>`;

/**
 * Default labour rates
 */
@Component({
    selector: 'bp-quoter-labour-rates',
    templateUrl: './labour-rates.component.html',
    styleUrls: ['labour-rates.scss']
})
export class LabourRatesComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    dataModel: ILabourRatesDataModel;

    isAdmin = false;
    isGlobal = false;

    defaultLabourRatesHelpText = DEFAULT_LABOUR_RATES_HELP_TEXT;

    constructor(
        private accountService: AccountService,
        private quoterService: QuoterService,
        private alertService: BpAlertService,
        private bpOnErrorService: BpOnErrorService
    ) {
        this.dataModel = new LabourRatesDataModel();
    }

    ngOnInit(): void {
        this.isAdmin = this.accountService.isAdmin();
        this.loadAll();
    }

    loadAll(): void {
        this.blockUI.start('Please wait');

        this.quoterService
            .queryAvailableLabours()
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                })
            )
            .subscribe(
                (res: HttpResponse<ILaboursWrapper>) => {
                    this.dataModel.updateMargin(res.body.margin);
                    this.dataModel.fill(res.body.labours);

                    if (!this.accountService.getIntroPassed()) {
                        this.accountService.identity(true); // reload account to refresh introPassed
                    }
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    applyMarginToAll(): void {
        this.dataModel.applyMarginToAll();
    }

    saveAndApply(): void {
        this.blockUI.start('Please wait');

        this.quoterService
            .batchUpdateLabours(this.dataModel.endpointData(), this.isGlobal)
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                })
            )
            .subscribe(
                () => {
                    this.alertService.success('Save successful');
                    this.loadAll();
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    setToDefaults(): void {
        this.loadDefaultLabours();
    }

    trackId(index: number, item: IBuilderLabour): number {
        return item.id;
    }

    onCancel(event, submitFormButton): void {
        if (event !== 'backdrop') {
            this.isGlobal = false;
            submitFormButton.click();
        }
    }

    saveOneLabour(item: IBuilderLabour): void {
        this.blockUI.start('Please wait');

        const data: IEndpointLabour = {
            id: item.id,
            cost: item.cost,
            margin: item.margin / 100,
            rate: item.rate,
            trade: item.trade,
            tradeId: item.tradeId
        };

        this.quoterService
            .updateLabour(data)
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                })
            )
            .subscribe(
                () => {
                    this.alertService.success('Save successful');
                    this.loadAll();
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }

    private loadDefaultLabours(): void {
        this.blockUI.start('Please wait');

        this.quoterService
            .queryDefaultLabours()
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                })
            )
            .subscribe(
                (res: HttpResponse<IBuilderLabour[]>) => {
                    this.dataModel.fill(res.body);
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
    }
}
