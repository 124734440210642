<div class="table-container"
     role="table"
     aria-label="Task Total">
    <div class="flex-table header"
         role="rowgroup">
        <div class="flex-row first"
             role="columnheader">
            Labour net cost
        </div>
        <div class="flex-row"
             role="columnheader">
            Total labour cost
        </div>
        <div class="flex-row"
             role="columnheader">
            Materials net cost
        </div>
        <div class="flex-row"
             role="columnheader">
            Total materials cost
        </div>
        <div class="flex-row"
             role="columnheader">
            Prime material
        </div>
        <div class="flex-row"
             role="columnheader">
            Prime net cost
        </div>
        <div class="flex-row"
             role="columnheader">
            Prime cost
        </div>
        <div class="flex-row"
             role="columnheader">
            Total cost
        </div>
    </div>
    <div *ngIf="taskTotals.length === 0 && inProcessLoadingTaskTotal"
         class="text-center m-t-20">
        Loading... Please wait..
    </div>
    <div *ngIf="taskTotals.length === 0 && !inProcessLoadingTaskTotal"
         class="text-center m-t-20">
        No data
    </div>
    <div class="flex-table usual"
         role="rowgroup"
         *ngFor="let taskTotal of taskTotals">
        <div class="flex-row"
             role="cell">
            {{ taskTotal.laboursNetCost || 0 | currency:'GBP' }}
        </div>
        <div class="flex-row"
             role="cell">
            {{ taskTotal.laboursCost || 0 | currency:'GBP' }}
        </div>
        <div class="flex-row"
             role="cell">
            {{ taskTotal.materialsNetCost || 0 | currency:'GBP' }}
        </div>
        <div class="flex-row"
             role="cell">
            {{ taskTotal.materialsCost || 0 | currency:'GBP' }}
        </div>
        <div class="flex-row"
             role="cell">
            {{ taskTotal.primeMaterial }}
        </div>
        <div class="flex-row"
             role="cell">
            {{ taskTotal.primeNetCost || 0 | currency:'GBP' }}
        </div>
        <div class="flex-row"
             role="cell">
            {{ taskTotal.primeCost || 0 | currency:'GBP' }}
        </div>
        <div class="flex-row"
             role="cell">
            {{ taskTotal.total || 0 | currency:'GBP'}}
        </div>
    </div>
</div>
