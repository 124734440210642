import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITrade } from 'app/shared/model/trade.model';

@Component({
    selector: 'bp-trade-detail',
    templateUrl: './trade-detail.component.html'
})
export class TradeDetailComponent implements OnInit {
    trade: ITrade;

    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({ trade }) => {
            this.trade = trade;
        });
    }

    protected previousState(): void {
        window.history.back();
    }
}
