import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'app/shared/constants/links.constants';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { EMAIL_ALREADY_USED_TYPE, LOGIN_ALREADY_USED_TYPE } from 'app/shared/constants/error.constants';
import { IRegistrationAccount, RegisterService } from 'app/shared/dataservices/register.service';
import { LoginService } from 'app/core/login/login.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhiteSpaceValidator } from 'app/shared/validators/no-white-space.validator';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { IRegion } from 'app/shared/model/region.model';
import { GoogleApi } from 'app/shared/services/google.api';
import { GtagService } from 'app/shared/services/gtag.service';

const OTHER = 'Other';

@Component({
    selector: 'bp-sign-up-light',
    templateUrl: './sign-up-light.component.html',
    styleUrls: ['sign-up-light.scss']
})
export class BpSingUpLightComponent implements OnInit, AfterViewInit, OnDestroy {
    protected success: boolean;

    protected TERMS_OF_USE_URL = TERMS_OF_USE_URL;
    protected PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;

    protected teamInvitationToken: string;
    protected kwTracking?: string;

    protected showPassword = false;

    protected form: FormGroup;

    protected defaultRegion: IRegion | null = null;

    constructor(
        private registerService: RegisterService,
        private regionService: RegionApi,
        private elementRef: ElementRef,
        private alertService: BpAlertService,
        private router: Router,
        private invitationService: InvitationService,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private formBuilder: FormBuilder,
        private googleApi: GoogleApi,
        private gtagService: GtagService
    ) {
    }

    ngOnInit(): void {
        this.showPassword = false;

        this.route.queryParams.subscribe(params => {
            this.teamInvitationToken = params['teamInvitationToken'];
            this.kwTracking = params['kw'];
        });

        this.form = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50), noWhiteSpaceValidator]),
        });

        this.regionService.query().subscribe((regionsRes) => {
            this.defaultRegion = regionsRes.body.find(r => r.region?.toLowerCase() === 'london') || regionsRes.body[0];
        });
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#email').focus();
        }, 0);
    }

    protected register(): void {
        const registerAccount: IRegistrationAccount = {};
        registerAccount.login = 'unnamed__' + UUID.UUID();
        registerAccount.email = this.form.controls.email.value;
        registerAccount.password = this.form.controls.password.value;
        registerAccount.langKey = 'en';
        registerAccount.authorities = ['ROLE_SCHEDULER'];
        registerAccount.heardAboutUs = OTHER;
        registerAccount.profession = {
            id: 6,
            name: OTHER
        };
        registerAccount.region = this.defaultRegion;

        if (this?.teamInvitationToken?.length) {
            registerAccount.activationKey = this?.teamInvitationToken;
        }

        if (this?.kwTracking?.length) {
            registerAccount.kwTracking = this.kwTracking;
        }

        this.registerService.save(registerAccount).subscribe(
            (tokenRes: { id_token: string }) => {
                if (this?.teamInvitationToken?.length) {
                    this.invitationService.acceptTeamMember(this.teamInvitationToken)
                        .subscribe((res: HttpResponse<any>) => {
                            this.success = true;
                            onLogin(tokenRes.id_token);
                        });
                } else {
                    this.gtagService.sendConversionEvent();
                    this.success = true;
                    onLogin(tokenRes.id_token);
                }
            },
            response => this.processError(response)
        );

        const onLogin = (token: string) => {
            this.loginService.loginWithToken(token).then(() => {
                this.router.navigate(['sign-up-tracking-page']);
            });
            this.alertService.success('Registration saved! Confirmation has been sent to your email.', 7 * 1000);
        }
    }

    protected login(): void {
        this.router.navigate(['/login']);
    }

    protected signInWithGoogle(): void {
        this.googleApi.authInWithGoogle(true);
    }

    private processError(response: HttpErrorResponse): void {
        this.success = null;
        if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
            this.alertService.error('Login name already registered! Please choose another one.');
        } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
            this.alertService.error('Email is already in use! Please choose another one.');
        } else {
            this.alertService.error(response.error.title);
        }
    }
}
