<div [ngClass]="{'use-in-tile': useInTile }">
    <div class="flex flex-column text-center pie-chart-container d-md-none">
        <bp-cost-plan-chart #costPlanChartComponent
                            [data]="chartData">
        </bp-cost-plan-chart>

        <div class="flex align-self-center m-t-20 gap-20 position-relative"
             style="width: 80%;"
             *ngIf="showMtSteps">
            <div class="position-absolute m-t--30">Spec</div>
            <bp-multi-toggler
                [steps]="mtSteps"
                [currentStepId]="currentSpecStepId"
                (changed)="onSpecTogglerChanged($event)"
                class="w-full">
            </bp-multi-toggler>
        </div>
    </div>

    <div class="flex gap-50 m-t-10 flex-wrap-reverse justify-content-start align-self-stretch align-items-start">
        <bp-dashboard-cost-plan-table-default-quoter
            [useInTile]="useInTile"
            class="flex-1">
        </bp-dashboard-cost-plan-table-default-quoter>

        <div
            class="flex flex-column text-center justify-content-start align-items-center align-self-stretch pie-chart-container d-none d-md-flex">
            <bp-cost-plan-chart #costPlanChartComponentMobile
                                [data]="chartData">
            </bp-cost-plan-chart>

            <div class="flex m-t-50 gap-20 position-relative steps"
                 *ngIf="showMtSteps">
                <div class="position-absolute m-l--50"
                     style="margin-top: -7px">
                    Spec
                </div>
                <bp-multi-toggler
                    [steps]="mtSteps"
                    [currentStepId]="currentSpecStepId"
                    (changed)="onSpecTogglerChanged($event)"
                    class="w-full">
                </bp-multi-toggler>
            </div>
        </div>
    </div>
</div>
