import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { UnitService } from 'app/shared/dataservices/unit.service';
import { IUnit } from 'app/shared/model/unit.model';
import { IAccount } from 'app/shared/model/account.model';
import { AccountService } from 'app/core/auth/account.service';
import { UnitDeleteDialogComponent } from 'app/entities/unit/delete/unit-delete-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bp-unit-list',
    templateUrl: './unit-list.component.html'
})
export class UnitListComponent implements OnInit {
    protected units: IUnit[];
    protected currentAccount: IAccount;

    constructor(
        private unitService: UnitService,
        private accountService: AccountService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });
    }

    protected trackId(index: number, item: IUnit): number {
        return item.id;
    }

    protected onDeleteClick(unit: IUnit): void {
        const ngbModalRef = this.modalService.open(UnitDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.unit = unit;

        ngbModalRef.result.then(
            result => {
                this.loadAll();
            },
            reason => {
                //
            }
        );
    }

    private loadAll(): void {
        this.unitService.query().subscribe(
            (res: HttpResponse<IUnit[]>) => {
                this.units = res.body;
            }
        );
    }
}
