import { Component } from '@angular/core';
import { ValuationsStore } from 'app/flows/quoter/valuations/stores/valuations.store';

@Component({
    selector: 'bp-valuations-view-valuation',
    templateUrl: './view-valuation.component.html',
    styleUrls: ['view-valuation.scss']
})
export class ViewValuationComponent {

    constructor(
        protected store: ValuationsStore
    ) {
    }

    protected onCreateNewValuationClick(): void {
        this.store.valuationMode = 'create';
    }
}
