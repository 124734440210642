import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { elementPopupRoute, elementRoute } from 'app/entities/element/element.route';
import { ElementListComponent } from 'app/entities/element/list/element-list.component';
import { ElementUpdateComponent } from 'app/entities/element/edit/element-update.component';
import { ElementDeleteDialogComponent, ElementDeletePopupComponent } from 'app/entities/element/delete/element-delete-dialog.component';

const ENTITY_STATES = [...elementRoute, ...elementPopupRoute];

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [ElementListComponent, ElementUpdateComponent, ElementDeleteDialogComponent, ElementDeletePopupComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpElementModule {}
