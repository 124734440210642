import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { unitRoute } from 'app/entities/unit/unit.route';
import { UnitListComponent } from 'app/entities/unit/list/unit-list.component';
import { UnitUpdateComponent } from 'app/entities/unit/edit/unit-update.component';
import { UnitDeleteDialogComponent } from 'app/entities/unit/delete/unit-delete-dialog.component';

const ENTITY_STATES = [...unitRoute];

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        UnitListComponent,
        UnitUpdateComponent,
        UnitDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpUnitModule {
}
