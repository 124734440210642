import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IAccount } from 'app/shared/model/account.model';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'app/core/auth/account.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import * as _ from 'lodash';
import { ValuationsStore } from 'app/flows/quoter/valuations/stores/valuations.store';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ValuationsService } from 'app/flows/quoter/valuations/services/valuations.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';

@Component({
    selector: 'bp-valuations-create-valuation',
    templateUrl: './create-valuation.component.html',
    styleUrls: ['create-valuation.scss']
})
export class CreateValuationComponent implements OnInit, OnDestroy {
    @BlockUI() protected blockUI: NgBlockUI;

    protected routeData: Subscription = Subscription.EMPTY;
    protected MENU_TOOLTIP_OPEN_DELAY = 100;
    protected searchControl = new FormControl();

    constructor(
        private activatedRoute: ActivatedRoute,
        private accountService: AccountService,
        private projectApi: ProjectApi,
        private alertService: BpAlertService,
        protected service: ValuationsService,
        protected store: ValuationsStore
    ) {
    }

    ngOnInit(): void {
        this.routeData = this.activatedRoute.data.subscribe(data => {
            this.accountService.identity().then((account: IAccount) => {
                this.store.account = account;
                this.reloadAll();
            });
        });
    }

    ngOnDestroy(): void {
        this.routeData.unsubscribe();
    }

    protected search(): void {
        this.reloadAll();
    }

    protected clearSearch(): void {
        this.searchControl.setValue(null);
        this.reloadAll();
    }

    protected async reloadAll(): Promise<void> {
        this.blockUI.start('Please wait..');

        const res = await Promise.all([
            lastValueFrom(this.projectApi.queryQuoterView(this.store.project.id, null, this.searchControl.value))
        ]);
        this.store.nativeComparison = _.clone(res[0].body);
        this.service.fillCreateForm();
        this.blockUI.stop();
    }

    protected onSubmitForValuationButtonClick(): void {
        this.blockUI.start('Please wait..');
        this.service.submitForValuation().finally(() => {
            this.blockUI.stop();
        }).then(() => {
            this.alertService.success('Successfully submitted for Valuation');
        });
    }
}
