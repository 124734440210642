import { Component } from '@angular/core';

@Component({
    selector: 'bp-system-upgrades',
    templateUrl: './system-upgrades.component.html',
    styleUrls: ['system-upgrades.scss']
})
export class SystemUpgradesComponent {

    constructor() {
    }

}
