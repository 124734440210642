import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { ISpecification } from 'app/shared/model/specification.model';
import { SearchBySelectInputData } from 'app/shared/components/common/search-by-select/search-by-select.component';
import { IMaterial } from 'app/shared/model/material.model';
import { IUnit } from 'app/shared/model/unit.model';
import { MaterialService } from 'app/shared/dataservices/material.service';
import { UnitService } from 'app/shared/dataservices/unit.service';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';

const NASpecification: ISpecification = { id: -1, name: 'N/A' };

@Component({
    selector: 'bp-material-update',
    templateUrl: './material-update.component.html'
})
export class MaterialUpdateComponent implements OnInit {
    material: IMaterial;
    isSaving: boolean;

    units: IUnit[];
    specifications: ISpecification[];

    materialCategorySelectInputData: SearchBySelectInputData;

    constructor(
        private materialService: MaterialService,
        private unitService: UnitService,
        private materialCategoryService: MaterialCategoryService,
        private activatedRoute: ActivatedRoute,
        private specificationService: SpecificationService
    ) {
    }

    ngOnInit() {
        this.isSaving = false;

        this.activatedRoute.data.subscribe(({ material }) => {
            this.material = material;

            if (material.specification == null) {
                material.specification = NASpecification;
            }
        });

        this.unitService.query().subscribe(
            (res: HttpResponse<IUnit[]>) => {
                this.units = res.body;
            }
        );

        this.specificationService.query().subscribe(
            (res: HttpResponse<ISpecification[]>) => {
                this.specifications = res.body;
                this.specifications.unshift(NASpecification);
            }
        );

        this.fillMaterialCategorySelectInputData();
    }

    previousState() {
        window.history.back();
    }

    save() {
        this.isSaving = true;

        if (this.material.specification != null && this.material.specification.id === NASpecification.id) {
            this.material.specification = null;
        }

        if (this.material.id != null) {
            this.subscribeToSaveResponse(this.materialService.update(this.material));
        } else {
            this.subscribeToSaveResponse(this.materialService.create(this.material));
        }
    }

    onMaterialFinishChange(event: any): void {
        this.material.type = event.currentTarget.checked ? 'FINISH' : 'COMMON';
    }

    onMaterialPrimeChange(event: any): void {
        this.material.isPrime = event.currentTarget.checked;
    }

    trackUnitById(index: number, item: IUnit) {
        return item.id;
    }

    trackSpecificationById(index: number, item: ISpecification) {
        return item.id;
    }

    onMaterialCategorySelectionChange(event: IMaterialCategory) {
        this.material.materialCategory = event;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IMaterial>>) {
        result.subscribe(
            (res: HttpResponse<IMaterial>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private fillMaterialCategorySelectInputData() {
        this.materialCategorySelectInputData = {
            searchBy: 'name',
            indexProperty: 'id',
            titleProperty: 'name',
            displayProperty: 'name',
            itemService: this.materialCategoryService,
            dropdownPosition: 'bottom'
        };

        if (this.material.materialCategory != null) {
            this.materialCategorySelectInputData.initValue = this.material.materialCategory.name;
            this.materialCategorySelectInputData.initIndex = this.material.materialCategory.id;
        }
    }
}
