<div (bpClickOutside)="editing = false">
    <div *ngIf="editing">
        <div class="form-check"
             (blur)="onBlurDiv()">
            <label class="form-check-label"
                   for="default">
                {{ label || 'Value' }}
                <input [(ngModel)]="value"
                       class="form-check-input"
                       id="default"
                       name="default"
                       type="checkbox">
                <span class="form-check-sign"><span class="check"></span></span>
            </label>
        </div>

    </div>
    <div *ngIf="!editing">
        <a (click)="beginEdit(value)"
           (focus)="beginEdit(value)"
           href="#"
           tabindex="0">
            {{ value ? 'Yes' : 'No' }}
        </a>
    </div>
</div>


