<ng-container *ngIf="!costPlanService.inProcess && valueColumns">
    <div class="w-full flex flex-row gap-20 relative justify-content-center {{ costPlanService.costPlanMode}}">
        <div class="_items-container right-width flex flex-column gap-20">
            <div
                *ngFor="let item of costPlanService.items?.slice(0, viewMoreMode ? VIEW_MORE_LIMIT : costPlanService.items?.length)"
                class="cost-data-container flex flex-row w-full"
                [ngClass]="{'checked': itemChecked(item)}">
                <div class="form-check clear text-left"
                     style="width: 30px;">
                    <label class="form-check-label"
                           for="item_check_{{item.id}}">
                        <input [checked]="itemChecked(item)"
                               [disabled]="costPlanService.inProcess"
                               (click)="onCheckboxClick(item)"
                               [value]="item.id"
                               class="form-check-input"
                               id="item_check_{{item.id}}"
                               name="item_check_{{item.id}}"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                </div>

                <div class="text-left item-title"
                     [title]="item.title">
                    <span>{{ item.title }}</span>
                </div>
            </div>
        </div>

        <div class="flex justify-content-between">
            <div *ngFor="let column of valueColumns"
                 class="_value-column"
                 [ngClass]="{'selected': costPlanService.costPlanMode === 'comparison-view' && costPlanService.isQuoterDefault(column.quoter)}">

                <ng-container
                    *ngIf="quoters?.length > 1 && (costPlanService.project.isOwner || ['OWNER', 'EDIT'].indexOf(costPlanService.project.privilege) !== -1)">
                    <div class="_column-label b-font bp-cursor-pointer m-b-10"
                         [ngbTooltip]="getTooltip(column.quoter)"
                         (click)="selectDefaultQuoter(column.quoter)">
                        {{ column.quoter?.company || column.quoter?.email }}
                    </div>
                </ng-container>

                <ng-template *ngIf="costPlanService.costPlanMode === 'comparison-view' && quoters?.length === 1">
                    <div style="height: 42px">
                    </div>
                </ng-template>

                <div class="_column-data flex flex-column gap-20">
                    <div
                        *ngFor="let item of costPlanService.items?.slice(0, viewMoreMode ? VIEW_MORE_LIMIT : costPlanService.items?.length)"
                        class="height-24">
                        <span>{{ getValue(column, item) | currency:'GBP' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="view-more-container"
         *ngIf="costPlanService.items?.length > VIEW_MORE_LIMIT">
        <button (click)="viewMoreMode = !viewMoreMode"
                class="btn btn-link">
                            <span *ngIf="viewMoreMode">View More <fa-icon
                                [icon]="['fas', 'arrow-down']"></fa-icon></span>
            <span *ngIf="!viewMoreMode">View Less <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon></span>
        </button>
    </div>

    <div
        class="cost-data-total-container flex justify-content-center flex-row gap-20 m-t-20 {{ costPlanService.costPlanMode }}"
        *ngIf="valueColumns">
        <div class="text-left right-width p-t-10">
            <span class="b-font">Total</span>
        </div>
        <div class="flex justify-content-between">
            <div *ngFor="let column of valueColumns"
                 class="_value-column"
                 [ngClass]="{'selected': costPlanService.costPlanMode === 'comparison-view' && costPlanService.isQuoterDefault(column.quoter)}">
                <div class="_column-label b-font">
                    {{ column.quoterTotal || 0 | currency:'GBP' }}
                </div>
                <div class="ex-vat">Ex. VAT</div>

                <div class="m-t-10"
                     *ngIf="quoters?.length > 1 && (costPlanService.project.isOwner || ['OWNER', 'EDIT'].indexOf(costPlanService.project.privilege) !== -1)">
                    <input [checked]="costPlanService.isQuoterDefault(column.quoter)"
                           [disabled]="costPlanService.inProcess"
                           (click)="selectDefaultQuoter(column.quoter)"
                           class="form-check-input m-0"
                           id="item_check_default_quoter_{{column.quoter.id}"
                           name="item_check_default_quoter_{{column.quoter.id}"
                           type="checkbox">
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="costPlanService.inProcess"
     class="_value-column align-items-center justify-content-between m-t-40">
    <bp-spinner class="cost-plan-spinner">
    </bp-spinner>
</div>
