import { Route } from '@angular/router';
import { QuoterInvitationValidatorComponent } from './quoter-invitation-validator.component';

export const quoterInvitationValidatorRoute: Route = {
    path: 'quoter/invitation/validate',
    component: QuoterInvitationValidatorComponent,
    data: {
        authorities: []
    }
};
