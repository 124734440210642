<td class="align-left {{ thClassName() }}"
    style="width: 35%">
    <div class="w-full flex gap-10 items-center">
        <div class="reference-number">
            {{ scheduleTask.referenceNumber }}
        </div>
        <div class="flex-1">
            <span>{{ !scheduleTask.action?.length ? '' : scheduleTask.action.toLowerCase().startsWith('install') ? 'Install ' : 'Supply and Install '}}</span><span>{{scheduleTask.task}}{{ scheduleTask.spec ? ', ' + scheduleTask.spec : '' }}</span>
            <span class="faint"> in {{scheduleTask.scheduleArea}}</span>
            <span class="faint" *ngIf="scheduleTask.isProvisional">(Provisional)</span>
            <span class="faint" *ngIf="scheduleTask.isSubcontract">(Subcontract)</span>
        </div>
    </div>
</td>

<td class="align-left {{thClassName()}}"
    style="width: 15%">
    <a *ngIf="scheduleTask.primeMaterial && scheduleTask.primeReferenceUrl"
       href="{{scheduleTask.primeReferenceUrl}}"
       target="_blank"
       rel="noopener">
        {{ scheduleTask.primeMaterial }}
    </a>
    <span *ngIf="scheduleTask.primeMaterial && !scheduleTask.primeReferenceUrl">{{ scheduleTask.primeMaterial }}</span>
</td>

<td class="{{thClassName()}} number"
    style="width: 10%">
    <span *ngIf="scheduleTask.unitValue != null">
        {{ scheduleTask.unitValue | number : '1.2-2' }}
        <span *ngIf="scheduleTask.unit == 'm2'">m<sup>2</sup></span>
        <span
            *ngIf="scheduleTask.unit != 'm2'">{{ scheduleTask.unitValue > 1 ? scheduleTask.unitPlural : scheduleTask.unit }}</span>
    </span>
</td>

<td class="{{thClassName()}} number"
    style="width: 8%">
    <span
        *ngIf="!scheduleTask.isProvisional">{{ (scheduleTask.labourTotals[0] / scheduleTask.unitValue) || 0 | currency:'GBP' }}</span>
</td>

<td class="{{thClassName()}} number"
    style="width: 8%">
    <span
        *ngIf="!scheduleTask.isProvisional">{{ (scheduleTask.materialTotals[0] / scheduleTask.unitValue) || 0 | currency:'GBP' }}</span>
</td>

<td class="{{thClassName()}} number"
    style="width: 8%">
    <span
        *ngIf="!scheduleTask.isProvisional">{{ (scheduleTask.labourTotals[0] / scheduleTask.unitValue + scheduleTask.materialTotals[0] / scheduleTask.unitValue) || 0 | currency:'GBP' }}</span>
</td>

<td class="{{thClassName()}} number"
    style="width: 8%">
    <span>{{ scheduleTask.totals[0] || 0 | currency:'GBP' }}</span>
</td>

<td class="{{thClassName()}}"
    style="width: 8%">
    <div class="flex justify-content-end gap-5px">
        <button
            *ngIf="scheduleTask.note"
            ngbTooltip="{{scheduleTask.note}}"
            class="show-notes-popup btn btn-circle btn-secondary btn-small">
            <i class="material-icons md-18">insert_comment</i>
        </button>

        <div ngbDropdown class="bp-dropdown"
             (click)="$event.stopPropagation()">
            <button type="button"
                    class="btn btn-circle btn-small btn-secondary"
                    ngbDropdownToggle>
                <div style="margin-top: -3px">...</div>
            </button>
            <div ngbDropdownMenu>
                <div class="tooltip-container">
                    <button ngbDropdownItem
                            [routerLink]="['/quoter', 'quote-task-details', store.project.id, scheduleTask.id]"
                            [queryParams]="{returnPage: 'quote'}"
                            [disabled]="scheduleTask.id == null"
                            *ngIf="scheduleTask.taskId">
                        Edit
                    </button>
                </div>

                <button ngbDropdownItem
                        *ngIf="scheduleTask.taskId"
                        (click)="onCommentsClick($event)">
                    Comment
                </button>
            </div>
        </div>
    </div>
</td>
