import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { IMaterial } from 'app/shared/model/material.model';
import { MaterialService } from 'app/shared/dataservices/material.service';
import { ArchiveFilterValue } from 'app/shared/components/common/archive-filter/archive-filter.component';
import { InlineEditOnBlurEvent } from 'app/shared/components/common/inline-edit/inline-edit.component';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { ISpecification } from 'app/shared/model/specification.model';
import { IUnit } from 'app/shared/model/unit.model';
import { UnitService } from 'app/shared/dataservices/unit.service';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { IAccount } from 'app/shared/model/account.model';
import {
    InlineSelectEditOnBlurEvent
} from 'app/shared/components/common/inline-select-edit/inline-select-edit.component';
import {
    InlineCheckboxEditOnBlurEvent
} from 'app/shared/components/common/inline-checkbox-edit/inline-checkbox-edit.component';
import { FormControl } from '@angular/forms';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { EntityMaterialService } from "app/entities/material/material.service";

@Component({
    selector: 'bp-material-list',
    templateUrl: './material-list.component.html',
    styleUrls: ['material-list.scss']
})
export class MaterialListComponent implements OnInit, OnDestroy {
    materials: IMaterial[];
    currentAccount: IAccount;
    eventSubscriber: Subscription;
    itemsPerPage: number;
    page: number;
    predicate: string;
    queryCount: number;
    reverse: boolean;
    totalItems: number;
    materialPrefix: string;

    searchControl = new FormControl();
    searchSub: Subscription = Subscription.EMPTY;
    itemUpdatedSub: Subscription = Subscription.EMPTY;

    archiveFilterValue: ArchiveFilterValue = 'active';

    materialCategories: IMaterialCategory[];
    units: IUnit[];
    specifications: ISpecification[];

    private inProcessAutosavingMaterial = false;

    constructor(
        private materialService: MaterialService,
        private materialCategoryService: MaterialCategoryService,
        private unitService: UnitService,
        private specificationService: SpecificationService,
        private entityService: EntityMaterialService,
        private accountService: AccountService,
        private entityQueryStorageService: EntityQueryStorageService,
    ) {
        this.materials = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('MATERIAL');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
        this.materialPrefix = entityQuery.searchValue || '';
        this.archiveFilterValue = entityQuery.archive || 'active';

        this.searchControl.setValue(this.materialPrefix);
    }

    ngOnInit(): void {
        this.materialCategoryService.query().subscribe(
            (res: HttpResponse<IMaterialCategory[]>) => {
                this.materialCategories = res.body;
            }
        );

        this.specificationService.query().subscribe(
            (res: HttpResponse<ISpecification[]>) => {
                this.specifications = res.body;
            }
        );

        this.unitService.query().subscribe(
            (res: HttpResponse<IUnit[]>) => {
                this.units = res.body;
            }
        );

        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });

        this.loadMaterialsPage();

        this.searchSub = this.searchControl.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe((term: string) => {
            this.materialPrefix = term;
            this.loadPage(0);
        });

        this.itemUpdatedSub = this.entityService.itemUpdated$.subscribe(() => this.reset());
    }

    ngOnDestroy(): void {
        this.searchSub?.unsubscribe();
        this.itemUpdatedSub?.unsubscribe();
    }

    loadMaterialsPage(): void {
        this.materialService
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort(),
                searchValue: this.materialPrefix,
                archive: this.archiveFilterValue === 'archived'
            }, false)
            .subscribe(
                (res: HttpResponse<IMaterial[]>) => this.paginateMaterials(res.body, res.headers)
            );
    }

    reset(): void {
        this.page = 0;
        this.materials = [];
        this.saveEntityQuery();
        this.loadMaterialsPage();
    }

    loadPage(page: number): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadMaterialsPage();
    }

    trackId(index: number, item: IMaterial): number {
        return item.id;
    }

    hasMore(): boolean {
        return this.materials.length < this.totalItems;
    }

    onArchiveFilterValueChanged(newValue): void {
        this.archiveFilterValue = newValue;
        this.saveEntityQuery();
        this.reset();
    }

    onBlurInlineEdit(event: InlineEditOnBlurEvent, material: IMaterial): void {
        if (event.oldValue === event.newValue) {
            return;
        }

        this.autoSaveMaterial(material);
    }

    onBlurMaterialIsFinishEdit(event: InlineCheckboxEditOnBlurEvent, material: IMaterial): void {
        if (event.oldValue === event.newValue) {
            return;
        }
        console.log(event.newValue);
        material.type = event.newValue ? 'FINISH' : 'COMMON';
        console.log(material);
        this.autoSaveMaterial(material);
    }

    onBlurMaterialIsPrimeEdit(event: InlineCheckboxEditOnBlurEvent, material: IMaterial): void {
        if (event.oldValue === event.newValue) {
            return;
        }
        console.log(event.newValue);
        material.isPrime = event.newValue;
        console.log(material);
        this.autoSaveMaterial(material);
    }

    onBlurMaterialIsDefaultEdit(event: InlineCheckboxEditOnBlurEvent, material: IMaterial): void {
        if (event.oldValue === event.newValue) {
            return;
        }

        this.autoSaveMaterial(material);
    }

    onBlurMaterialCategoryEdit(event: InlineSelectEditOnBlurEvent, material: IMaterial): void {
        if (event.oldValue === event.newValue) {
            return;
        }

        const newMaterialCategory = _.find(this.materialCategories, { id: event.newValue });
        if (newMaterialCategory == null) {
            return null;
        }

        material.materialCategory.id = newMaterialCategory.id;

        this.autoSaveMaterial(material);
    }

    onBlurUnitEdit(event: InlineSelectEditOnBlurEvent, material: IMaterial): void {
        if (event.oldValue === event.newValue) {
            return;
        }

        const newUnit = _.find(this.units, { id: event.newValue });
        if (newUnit == null) {
            return null;
        }

        material.unit.id = newUnit.id;

        this.autoSaveMaterial(material);
    }

    onBlurSpecificationEdit(event: InlineSelectEditOnBlurEvent, material: IMaterial): void {
        if (event.oldValue === event.newValue) {
            return;
        }

        const newSpec = _.find(this.specifications, { id: event.newValue });
        if (newSpec == null) {
            return null;
        }

        material.specification.id = newSpec.id;

        this.autoSaveMaterial(material);
    }

    private sort(): string {
        return this.predicate + ',' + (this.reverse ? 'asc' : 'desc');
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse,
            searchValue: this.materialPrefix,
            archive: this.archiveFilterValue
        };

        this.entityQueryStorageService.store('MATERIAL', entityQuery);
    }

    private autoSaveMaterial(material: IMaterial): void {
        this.inProcessAutosavingMaterial = true;

        this.materialService
            .update(material)
            .pipe(
                finalize(() => {
                    this.inProcessAutosavingMaterial = false;
                })
            )
            .subscribe(
                (res: HttpResponse<IMaterial>) => {
                    material = _.merge(material, res.body);
                }
            );
    }

    private paginateMaterials(data: IMaterial[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.materials = [];
        }

        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
        this.materials.push(...data);
    }
}
