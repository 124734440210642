<div class="how-your-trial-works">
    <div class="text-center h1">
        How your free trial works
    </div>

    <div class="flex flex-column m-t-20">
        <div class="how-your-trial-works__block">
            <div class="how-your-trial-works__block__left">
                <div class="how-your-trial-works__block__left__line">
                </div>
                <img alt=""
                     src="../../../../content/images/how-your-trial-works/icon-1.png"/>
            </div>
            <div class="how-your-trial-works__item">
                <div class="how-your-trial-works__item__header">
                    Today
                </div>
                <div class="how-your-trial-works__item__description">
                    Get instant access to your account and create quick cost plans and schedulers
                </div>
            </div>
        </div>

        <div class="how-your-trial-works__block">
            <div class="how-your-trial-works__block__left">
                <div class="how-your-trial-works__block__left__line" style="opacity: 0.8">
                </div>
                <img alt=""
                     src="../../../../content/images/how-your-trial-works/icon-2.png"/>
            </div>
            <div class="how-your-trial-works__item">
                <div class="how-your-trial-works__item__header">
                    Day 12
                </div>
                <div class="how-your-trial-works__item__description">
                    We’ll send you an email/notification. Cancel anytime before.
                </div>
            </div>
        </div>

        <div class="how-your-trial-works__block">
            <div class="how-your-trial-works__block__left">
                <div class="how-your-trial-works__block__left__line" style="opacity: 0.5">
                </div>
                <img alt=""
                     src="../../../../content/images/how-your-trial-works/icon-3.png"/>
            </div>
            <div class="how-your-trial-works__item">
                <div class="how-your-trial-works__item__header">
                    Day 14
                </div>
                <div class="how-your-trial-works__item__description">
                    Your subscription will start on {{ day14 | date: 'dd/MM/yy' }}
                </div>
            </div>
        </div>
    </div>
</div>
