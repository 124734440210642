import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from 'app/shared/dataservices/payment.service';

type StripeResultMessage = 'SUCCESS' | 'CANCEL' | 'ERROR';

@Component({
    selector: 'bp-stripe-payment-result',
    templateUrl: './stripe-payment-result.component.html',
    styleUrls: ['stripe-payment-result.scss']
})
export class StripePaymentResultComponent {
    result: StripeResultMessage = null;

    constructor(private route: ActivatedRoute, private paymentService: PaymentService) {
        this.route.queryParams.subscribe(params => {
            /**
             * stripe-payment-result?status=success&id=32
             * stripe-payment-result?status=cancel&id=32
             */
            const status = params['status'];
            const id = +params['id'];
            const isCustomer = params['customer'] ? params['customer'].toLocaleLowerCase() === 'true' : false;

            if (status == null || id == null) {
                this.result = 'ERROR';
                return;
            }

            this.paymentService.postStripeStatus(status, id, isCustomer).subscribe(
                () => {
                    this.result = status === 'success' ? 'SUCCESS' : 'CANCEL';
                },
                () => {
                    this.result = 'ERROR';
                }
            );
        });
    }
}
