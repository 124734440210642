import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ADMIN_ROUTE } from 'app/flows/admin/admin.route';
import { AdminComponent } from 'app/flows/admin/admin.component';

@NgModule({
    imports: [RouterModule.forRoot([ADMIN_ROUTE])],
    declarations: [AdminComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpFrontendAdminModule {}
