import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IArea } from 'app/shared/model/area.model';
import { AreaService } from 'app/shared/dataservices/area.service';

@Component({
    selector: 'bp-area-delete-dialog',
    templateUrl: './area-delete-dialog.component.html'
})
export class AreaDeleteDialogComponent {
    area: IArea;

    constructor(private areaService: AreaService,
                public activeModal: NgbActiveModal
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.areaService.delete(id).subscribe(response => {
            this.activeModal.close(true);
        });
    }
}
