<div class="project-attachment-container">
    <div class="project-attachment-container__controls">
        <div class="flex flex-column gap-15">
            <div *ngIf="!disabled"
                 class="text-left">
                <label
                    class="project-attachment-container__upload-document-container btn btn-primary btn-border-rectangle waves-effect">
                    <span><fa-icon [icon]="['fas', 'arrow-up']"></fa-icon></span>
                    <span>Upload Document</span>
                    <input #imageInput
                           (change)="processFile(imageInput)"
                           accept="*/*"
                           type="file">
                </label>
            </div>

            <button (click)="downloadAll()"
                    *ngIf="attachments?.length"
                    [disabled]="inProcess()"
                    ngbTooltip="Download All"
                    class="btn btn-secondary btn-border-radius waves-effect mr-2">
                <i class="material-icons md-24">
                    file_download
                </i>
                Download All
            </button>
        </div>
    </div>

    <div class="project-attachment-container__attachments">
        <div *ngIf="!attachments?.length" style="line-height: 40px;">
            No attachments
        </div>

        <div *ngFor="let attachment of attachments;"
             class="project-attachment-container__attachment">
            <div style="width: calc(100% - 80px); max-width: calc(100% - 80px)">
                <a (click)="download(attachment)"
                   class="download-link flex align-items-center gap-10">
                    <img alt=""
                         class="document-icon"
                         src="../../../../../content/images/document.png"/>
                    <div class="text-truncate" [title]="attachment.originalName">{{ attachment.originalName }}</div>
                </a>
            </div>
            <div style="width: 40px"
                 *ngIf="!disabled">
                <button (confirm)="remove(attachment)"
                        [swal]="{
                                title: 'Are you sure you?',
                                text: 'Are you sure you want to delete this file?',
                                icon: 'warning',
                                showCancelButton: true,
                                customClass: {
                                   confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                   cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                }
                        }"
                        [disabled]="inProcess()"
                        class="btn btn-secondary btn-circle bp-icon bp-remove m-l-20"
                        ngbTooltip="Remove document"
                        type="button">
                </button>
            </div>
        </div>
    </div>
</div>
