import { IProject } from 'app/shared/model/project.model';
import { IScheduleTemplate } from 'app/shared/model/schedule-template.model';
import { ISpecification } from 'app/shared/model/specification.model';
import { ITag } from 'app/shared/model/tag.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { BpOnErrorService } from 'app/shared/services/bp-on-error.service';
import { forkJoin, lastValueFrom, Observable, Observer } from 'rxjs';
import * as _ from 'lodash';
import { TagService } from 'app/shared/dataservices/tag.service';
import { ScheduleService } from 'app/shared/dataservices/schedule.service';
import { IAccount } from 'app/shared/model/account.model';
import { ITemplateWithTag } from 'app/shared/dataservices/project.api';

export interface ITemplateData {
    tag: ITag;
    templates: IScheduleTemplate[];
}

export class TemplateWizardData {
    project: IProject = null;
    account: IAccount = null;

    templateData: ITemplateData[] = [];
    specifications?: ISpecification[] = null;
    tags: ITag[] = null;

    specification: ISpecification = null;

    constructor(
        private specificationService: SpecificationService,
        private tagService: TagService,
        private scheduleService: ScheduleService,
        private bpOnErrorService: BpOnErrorService
    ) {
    }

    get parentTags(): ITag[] {
        return _.filter(this.tags, (t: ITag) => t.parentTag == null);
    }

    getTemplatesWithTags(): ITemplateWithTag[] {
        const finalTemplateWithTag: ITemplateWithTag[] = [];

        _.each(this.templateData, td => {
            _.each(td.templates || [], (template: IScheduleTemplate) => {
                for (let i = 0; i < template.count; i++) {
                    finalTemplateWithTag.push({ templateId: template.id, tagId: td.tag.id });
                }
            });
        });

        return finalTemplateWithTag;
    }

    loadSpecifications(): Promise<void> {
        return new Promise((resolve) => {
            if (this.specifications != null) {
                resolve();
                return;
            }

            this.specificationService.query().subscribe(
                (res: HttpResponse<ISpecification[]>) => {
                    this.specifications = res.body;
                    this.specification = this.project.specification;

                    resolve();
                }
            );
        });
    }

    loadTags(): Promise<void> {
        return new Promise((resolve) => {
            if (this.tags != null) {
                resolve();
                return;
            }
            lastValueFrom(this.tagService.query()).then(
                (res: HttpResponse<ITag[]>) => {
                    this.tags = _.orderBy(res.body, (t: ITag) => t.tagOrder || 0);
                    resolve();
                }
            );
        });
    }

    getAllNeededSubtags(): ITag[] {
        return _.filter(this.tags, (tag: ITag) => {
            return tag.parentTag != null && _.find(this.tags, { id: tag.parentTag.id }).selected;
        });
    }

    loadTemplates(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.templateData = [];

            const allNeededSubtags = this.getAllNeededSubtags();

            if (allNeededSubtags.length === 0) {
                observer.next();
                observer.complete();
                return;
            }

            const observables = [];

            _.each(allNeededSubtags, (tag: ITag) => {
                observables.push(this.scheduleService.queryScheduleTemplates({ tagIds: [tag.id] }));
            });

            forkJoin(observables).subscribe(
                (result: Array<HttpResponse<IScheduleTemplate[]>>) => {
                    for (let i = 0; i < allNeededSubtags.length; i++) {
                        const tag = allNeededSubtags[i];
                        this.templateData.push({
                            tag,
                            templates: _.map(result[i].body, (template: IScheduleTemplate) => {
                                template.count = 0;
                                return template;
                            })
                        });
                    }

                    observer.next();
                    observer.complete();
                },
                (res: HttpErrorResponse) => this.bpOnErrorService.showError(res)
            );
        });
    }
}
