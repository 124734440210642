import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'successfully-submitted-for-analysis-modal',
    templateUrl: './successfully-submitted-for-analysis-modal.component.html',
    styleUrls: ['successfully-submitted-for-analysis-modal.scss']
})
export class SuccessfullySubmittedForAnalysisModalComponent {

    constructor(
        public activeModal: NgbActiveModal,
    ) {
    }

    onOkClick(): void {
        this.activeModal.close(true);
    }

    clear(): void {
        this.activeModal.close(true);
    }
}
