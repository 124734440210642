<div *ngIf="!dashboardStore.inProcess && valueColumn"
     [ngClass]="{'use-in-tile': useInTile }">
    <div class="w-full flex flex-row gap-20 relative justify-content-center">
        <div class="_items-container right-width flex flex-column gap-20">
            <div *ngFor="let item of dashboardStore.items?.slice(0, viewMoreMode ? VIEW_MORE_LIMIT : dashboardStore.items?.length)"
                class="cost-data-container flex flex-row w-full"
                [ngClass]="{'checked': itemChecked(item)}">
                <div class="form-check clear text-left"
                     style="width: 30px;">
                    <label class="form-check-label"
                           for="item_check_{{item.id}}">
                        <input [checked]="itemChecked(item)"
                               [disabled]="dashboardStore.inProcess"
                               (click)="onCheckboxClick(item)"
                               [value]="item.id"
                               class="form-check-input"
                               id="item_check_{{item.id}}"
                               name="item_check_{{item.id}}"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                </div>

                <div class="text-left item-title"
                     [title]="item.title">
                    <span>{{ item.title }}</span>
                </div>
            </div>
        </div>

        <div class="flex justify-content-between">
            <div class="_value-column">
                <div class="_column-data flex flex-column gap-20">
                    <div
                        *ngFor="let item of dashboardStore.items?.slice(0, viewMoreMode ? VIEW_MORE_LIMIT : dashboardStore.items?.length)"
                        class="height-24">
                        <span>{{ getValue(valueColumn, item) | currency:'GBP' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="view-more-container"
         *ngIf="dashboardStore.items?.length > VIEW_MORE_LIMIT">
        <button (click)="viewMoreMode = !viewMoreMode"
                class="btn btn-link">
                            <span *ngIf="viewMoreMode">View More <fa-icon
                                [icon]="['fas', 'arrow-down']"></fa-icon></span>
            <span *ngIf="!viewMoreMode">View Less <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon></span>
        </button>
    </div>

    <div class="cost-data-total-container flex align-items-baseline justify-content-center flex-row gap-20 m-t-20">
        <div class="text-left right-width p-t-10">
            <span class="b-font">Total</span>
        </div>
        <div class="flex justify-content-between">
            <div class="_value-column">
                <div class="_column-label b-font" style="max-width: fit-content;">
                    {{ valueColumn.quoterTotal || 0 | currency:'GBP' }}
                </div>
                <div class="ex-vat">Ex. VAT</div>
                <div class="cost-sqm m-t-10">{{ valueColumn.costPerSqm || 0 | currency:'GBP' }} / m<sup>2</sup> </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dashboardStore.inProcess"
     class="_value-column align-items-center justify-content-between m-t-40">
    <bp-spinner class="cost-plan-spinner">
    </bp-spinner>
</div>
