import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfessionService } from 'app/shared/dataservices/profession.service';
import { IProfession } from 'app/shared/model/profession.model';

@Component({
    selector: 'bp-profession-update',
    templateUrl: './profession-update.component.html'
})
export class ProfessionUpdateComponent implements OnInit {
    profession: IProfession;
    tags: IProfession[];
    isSaving: boolean;

    constructor(
        private tagService: ProfessionService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ profession }) => {
            this.profession = profession;
        });
    }

    previousState(): void {
        window.history.back();
    }

    save(): void {
        this.isSaving = true;
        if (this.profession.id !== undefined) {
            this.subscribeToSaveResponse(this.tagService.update(this.profession));
        } else {
            this.subscribeToSaveResponse(this.tagService.create(this.profession));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IProfession>>): void {
        result.subscribe(
            (res: HttpResponse<IProfession>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess(): void {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError(): void {
        this.isSaving = false;
    }
}
