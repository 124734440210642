import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPageableTemplateRequest, ITemplateRequest } from 'app/shared/model/template-request.model';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';
import { IProjectAttachmentAdditionProperties } from "app/shared/model/project-attachment.model";

@Injectable({ providedIn: 'root' })
export class TemplateRequestApi {
    public resourceUrl = SERVER_API_URL + 'api/template-request';

    constructor(private http: HttpClient) {
    }

    create(templateRequest: ITemplateRequest,
           files: File[],
           additionalProperties: IProjectAttachmentAdditionProperties = {}): Observable<string> {
        const formData = new FormData();

        formData.append('template', JSON.stringify(templateRequest));
        if (files.length) {
            formData.append('file', files[0]);
        }

        formData.append('additionalProperties', JSON.stringify(additionalProperties));

        return this.http.post(this.resourceUrl, formData, {
            observe: 'body',
            responseType: 'text'
        });
    }

    update(templateRequest: ITemplateRequest): Observable<HttpResponse<ITemplateRequest>> {
        return this.http.put<ITemplateRequest>(this.resourceUrl, templateRequest, { observe: 'response' });
    }

    find(id: number): Observable<HttpResponse<ITemplateRequest>> {
        return this.http.get<ITemplateRequest>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IPageableTemplateRequest>> {
        const options = createRequestOption(req);
        return this.http.get<IPageableTemplateRequest>(this.resourceUrl, { params: options, observe: 'response' });
    }
}
