import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ITask } from 'app/shared/model/task.model';
import { ITaskTotalComponentInput, TaskApi } from 'app/shared/dataservices/task.api';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { ITaskTotal } from 'app/shared/model/task-total.model';
import { lastValueFrom, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'bp-task-total',
    templateUrl: './task-total.component.html',
    styleUrls: ['task-total.scss']
})
export class TaskTotalComponent implements OnInit, OnDestroy {
    @Input() task: ITask;
    @Input() updateObservable: Observable<void>;

    protected updateSub = Subscription.EMPTY;

    protected inProcessLoadingTaskTotal = false;
    protected taskTotals: ITaskTotal[] = [];

    constructor(private taskApi: TaskApi) {
    }

    ngOnInit(): void {
        this.refreshTaskTotals();

        this.updateSub = this.updateObservable.subscribe(() => {
            this.refreshTaskTotals();
        });
    }

    ngOnDestroy() {
        this.updateSub.unsubscribe();
    }

    private loadTaskTotals(): void {
        this.inProcessLoadingTaskTotal = true;

        const materialComponentIds: ITaskTotalComponentInput[] = _.map(
            _.filter(this.task.materialComponents, comp => comp.material),
            comp => {
                return {
                    id: comp.material.id,
                    qty: comp.quantity
                };
            }
        );
        const labourComponentIds: ITaskTotalComponentInput[] = _.map(
            _.filter(this.task.labourComponents, comp => comp.trade),
            comp => {
                return {
                    id: comp.trade.id,
                    qty: comp.quantity
                };
            }
        );

        lastValueFrom(this.taskApi
            .taskTotals(this.task.materialCategories.map(mc => mc.id), materialComponentIds, labourComponentIds)
            .pipe(
                finalize(() => {
                    this.inProcessLoadingTaskTotal = false;
                })
            )).then(
            (res: HttpResponse<ITaskTotal[]>) => {
                this.taskTotals = res.body;
            }
        );
    }

    private refreshTaskTotals(): void {
        if (!this.task.materialCategories?.length) {
            this.taskTotals = [];
        } else {
            this.loadTaskTotals();
        }
    }
}
