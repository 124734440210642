import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse } from '@angular/common/http';
import { IBuildUp, IBuildUpCategory } from 'app/shared/model/bp.model';
import { BuildUpApi } from 'app/shared/dataservices/build-up.api';
import { BuildUpCategoryApi } from 'app/shared/dataservices/build-up-category.api';

@Component({
    selector: 'bp-add-build-up-modal',
    templateUrl: './add-build-up-modal.component.html',
    styleUrls: ['add-build-up-modal.scss']
})
export class AddBuildUpModalComponent {
    protected buildUpName: string;
    protected categoryId: number | null = null;
    protected order: number | null = null;

    protected buildUpCategories: IBuildUpCategory[];

    constructor(private activeModal: NgbActiveModal,
                private buildUpApi: BuildUpApi,
                private buildUpCategoryApi: BuildUpCategoryApi) {
        this.buildUpCategoryApi.query().subscribe(
            (res: HttpResponse<IBuildUpCategory[]>) => {
                this.buildUpCategories = res.body;
            }
        );
    }

    protected clear(): void {
        this.activeModal.dismiss('cancel');
    }

    protected trackById(index: number, item: IBuildUpCategory): number {
        return item.id;
    }

    protected addBuildUp(): void {
        const buildUp: IBuildUp = {
            name: this.buildUpName,
            categoryId: this.categoryId,
            order: this.order
        };

        this.buildUpApi.create(buildUp).subscribe((res: HttpResponse<IBuildUp>) => {
            this.activeModal.close(res.body);
        });
    }

    protected isFormInvalid(): boolean {
        return this.buildUpName == null || this.buildUpName.length === 0 || this.categoryId == null;
    }
}
