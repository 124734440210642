import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InlineCheckboxEditComponent),
    multi: true
};

export interface InlineCheckboxEditOnBlurEvent {
    oldValue: boolean;
    newValue: boolean;
}

@Component({
    selector: 'bp-inline-checkbox-edit',
    templateUrl: './inline-checkbox-edit.component.html',
    providers: [VALUE_ACCESSOR],
    styleUrls: ['./inline-checkbox-edit.component.scss']
})
export class InlineCheckboxEditComponent implements ControlValueAccessor {
    @Input() label;

    @Output() onBlur = new EventEmitter<InlineCheckboxEditOnBlurEvent>();

    editing = false;

    public onChange: Function = Function.prototype;
    public onTouched: Function = Function.prototype;

    private preValue = false;
    private _value = false;

    get value(): boolean {
        return this._value;
    }

    set value(v: boolean) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    writeValue(value: boolean): void {
        this._value = value;
    }

    public registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    onBlurDiv(): void {
        this.editing = false;
        if (this._value == null) {
            this._value = this.preValue;
        }
        this.onBlur.emit({ oldValue: this.preValue, newValue: this._value });
    }

    beginEdit(value): void {
        this.preValue = value;
        this.editing = true;
    }
}
