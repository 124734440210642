import { Component } from '@angular/core';

/**
 * Admin Main Component
 */
@Component({
    selector: 'bp-admin',
    templateUrl: './admin.component.html'
})
export class AdminComponent {}
