import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IBuildUp } from 'app/shared/model/bp.model';
import { EntityBuildUpService } from 'app/entities/build-up/build-up.service';
import { BuildUpCategoryApi } from "app/shared/dataservices/build-up-category.api";

@Component({
    selector: 'bp-build-up-category-delete-dialog',
    templateUrl: './build-up-category-delete-dialog.component.html'
})
export class BuildUpCategoryDeleteDialogComponent {
    protected buildUpCategory: IBuildUp;

    constructor(private buildUpCategoryApi: BuildUpCategoryApi,
                private entityService: EntityBuildUpService,
                public activeModal: NgbActiveModal) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.buildUpCategoryApi.delete(id).subscribe(response => {
            this.entityService.itemUpdatedSubject.next();
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'bp-build-up-category-delete-popup',
    template: ''
})
export class BuildUpCategoryDeletePopupComponent implements OnInit, OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(({ buildUpCategory }) => {
            setTimeout(() => {
                this.ngbModalRef = this.modalService.open(BuildUpCategoryDeleteDialogComponent as Component, {
                    size: 'lg',
                    backdrop: 'static'
                });
                this.ngbModalRef.componentInstance.buildUpCategory = buildUpCategory;

                const doOnResult = () => {
                    this.router.navigate([{ outlets: { popup: null } }], {
                        replaceUrl: true,
                        queryParamsHandling: 'merge'
                    });
                    this.ngbModalRef = null;
                };

                this.ngbModalRef.result.then(
                    result => {
                        doOnResult();
                    },
                    reason => {
                        doOnResult();
                    }
                );
            }, 0);
        });
    }

    ngOnDestroy() {
        this.ngbModalRef = null;
    }
}
