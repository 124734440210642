import { Component, Input } from '@angular/core';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';

@Component({
    selector: 'bp-comparison-schedule-task',
    templateUrl: './schedule-task.component.html'
})
export class ComparisonScheduleTaskComponent {
    @Input() scheduleTask: IComparisonScheduleTask;
}
