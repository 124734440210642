<div class="container-fluid" *ngIf="project">
    <div class="flex justify-content-between">
        <div class="col-sm-2">
            <button [routerLink]="['../../dashboard', project.id]"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back to Dashboard"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back to Dashboard
            </button>
        </div>
        <div class="col-sm-10 text-right">
            <button (click)="makeProjectLive()"
                    *ngIf="proxyAdmin && projectHavePendingAddons"
                    [disabled]="inProcess()"
                    ngbTooltip="Make Project Live"
                    class="btn btn-secondary btn-border-radius waves-effect mr-2">
                Make Project Live
            </button>

            <button [routerLink]="['/scheduler/team', project.id]"
                    [disabled]="inProcess()"
                    ngbTooltip="Manage Team"
                    class="btn btn-secondary btn-border-radius waves-effect mr-2">
                Manage Team
            </button>

            <button (click)="copyProject()"
                    *ngIf="!readOnly"
                    [disabled]="inProcess()"
                    ngbTooltip="Copy Project"
                    class="btn btn-secondary btn-border-radius waves-effect mr-2">
                Copy Project
            </button>
            <button (click)="submitFormButton.click()"
                    *ngIf="!readOnly"
                    [disabled]="inProcess()"
                    ngbTooltip="Save and Exit"
                    class="btn btn-primary btn-border-radius waves-effect">
                Save and Exit
            </button>

            <div class="flex items-center justify-content-end autosave-line m-t-10 d-none d-md-flex">
                <ng-container *ngIf="!inProcess(); else isSavingContainer">
                    <fa-icon [icon]="['fas', 'save']" class="mr-2"></fa-icon>
                    Autosaved: {{ (autoSaveDate || project.scheduleLastModifiedDate) | date:'dd/MM/yy HH:mm' }}
                </ng-container>
                <ng-template #isSavingContainer>
                    <fa-icon [icon]="['fas', 'spinner']" class="mr-2"></fa-icon>
                    Saving...
                </ng-template>
            </div>

            <div class="m-b-5 m-t-10 d-md-none">
                <div class="flex items-center justify-content-end autosave-line">
                    <ng-container *ngIf="!inProcess(); else isSavingContainer">
                        <fa-icon [icon]="['fas', 'save']" class="mr-2"></fa-icon>
                        Autosaved: {{ (autoSaveDate || project.scheduleLastModifiedDate) | date:'dd/MM/yy HH:mm' }}
                    </ng-container>
                    <ng-template #isSavingContainer>
                        <fa-icon [icon]="['fas', 'spinner']" class="mr-2"></fa-icon>
                        Saving...
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="card m-t-20">
        <div class="body">
            <form (ngSubmit)="(submitted = true) && form.valid && save(false, false)"
                  [formGroup]="form"
                  name="form"
                  novalidate>
                <button #submitFormButton
                        id="submitFormButton"
                        type="submit">
                </button>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <div class="form-line">
                                <input
                                    [ngClass]="{ 'error': (submitted || form.controls.clientName.dirty || form.controls.clientName.touched) && form.controls.clientName.invalid }"
                                    [readOnly]="readOnlyStrict"
                                    class="af-input"
                                    formControlName="clientName"
                                    name="clientName"
                                    placeholder="Client name"
                                    type="text"
                                    autocomplete="off"
                                    autofocus>
                            </div>
                            <div
                                *ngIf="(submitted || form.controls.clientName.dirty) && form.controls.clientName.invalid">
                                <label *ngIf="form.controls.clientName.errors.required"
                                       class="error">
                                    This field is required.
                                </label>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="form-line">
                                <input
                                    [ngClass]="{ 'error': (submitted || form.controls.address.dirty || form.controls.address.touched) && form.controls.address.invalid }"
                                    [readOnly]="readOnlyStrict"
                                    class="af-input"
                                    formControlName="address"
                                    name="address"
                                    placeholder="First Line Address*"
                                    autocomplete="off"
                                    type="text">
                                <div
                                    *ngIf="(submitted || form.controls.address.dirty) && form.controls.address.invalid">
                                    <label *ngIf="form.controls.address.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                    <label *ngIf="form.controls.address.errors.whitespace"
                                           class="error">
                                        Please enter valid data.
                                    </label>
                                    <label *ngIf="form.controls.address.errors.alreadyExists"
                                           class="error">
                                        Project with such address already exists.
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="!isAdmin">
                            <div class="form-line">
                                <input
                                    [ngClass]="{ 'error': (submitted || form.controls.addressSecondLine.dirty || form.controls.addressSecondLine.touched) && form.controls.addressSecondLine.invalid }"
                                    [readOnly]="readOnlyStrict"
                                    class="af-input"
                                    formControlName="addressSecondLine"
                                    name="addressSecondLine"
                                    placeholder="Second Line Address"
                                    autocomplete="off"
                                    type="text">
                                <div
                                    *ngIf="(submitted || form.controls.addressSecondLine.dirty) && form.controls.addressSecondLine.invalid">
                                    <label *ngIf="form.controls.addressSecondLine.errors.whitespace"
                                           class="error">
                                        Please enter valid data.
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-line">
                                    <input
                                        [ngClass]="{ 'error': (submitted || form.controls.phone.dirty || form.controls.phone.touched) && form.controls.phone.invalid }"
                                        [readOnly]="readOnlyStrict"
                                        class="af-input"
                                        formControlName="phone"
                                        name="phone"
                                        placeholder="Phone"
                                        autocomplete="off"
                                        type="text">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input
                                            [ngClass]="{ 'error': (submitted || form.controls.email.dirty || form.controls.email.touched) && form.controls.email.invalid }"
                                            [readOnly]="readOnlyStrict"
                                            class="af-input"
                                            formControlName="email"
                                            name="email"
                                            placeholder="Email"
                                            autocomplete="off"
                                            type="text">
                                        <div
                                            *ngIf="(submitted || form.controls.email.dirty) && form.controls.email.invalid">
                                            <label *ngIf="form.controls.email.errors.email"
                                                   class="error">
                                                Please use correct email.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="form-line">
                                <bp-select-input
                                    *ngIf="regionsInputData"
                                    (onSelectionChange)="onRegionSelectionChange($event)"
                                    [data]="regionsInputData"
                                    [removeUnderlines]="true"
                                    [placeholder]="'Region'"
                                    [additionalClasses]="'fs-14'"
                                    class="region-select-id">
                                </bp-select-input>
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="isAdmin">
                            <div class="form-line">
                                <input
                                    class="af-input"
                                    [readOnly]="readOnly"
                                    formControlName="templateName"
                                    name="templateName"
                                    placeholder="Property template name"
                                    autocomplete="off"
                                    type="text">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input
                                            [ngClass]="{ 'error': (submitted || form.controls.city.dirty || form.controls.city.touched) && form.controls.city.invalid }"
                                            [readOnly]="readOnlyStrict"
                                            class="af-input"
                                            formControlName="city"
                                            name="city"
                                            placeholder="City"
                                            autocomplete="off"
                                            type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input
                                            [ngClass]="{ 'error': (submitted || form.controls.postcode.dirty || form.controls.postcode.touched) && form.controls.postcode.invalid }"
                                            [readOnly]="readOnlyStrict"
                                            class="af-input"
                                            formControlName="postcode"
                                            name="postcode"
                                            placeholder="Postcode *"
                                            autocomplete="off"
                                            type="text">
                                        <div
                                            *ngIf="(submitted || form.controls.postcode.dirty) && form.controls.postcode.invalid">
                                            <label *ngIf="form.controls.postcode.errors.required"
                                                   class="error">
                                                This field is required.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a class="flex-between-container align-items-center cursor-pointer additional-information-toggle m-t-20"
                           (click)="isVisibleAdditionalBlock = !isVisibleAdditionalBlock"
                           ngbTooltip="{{ isVisibleAdditionalBlock ? 'Hide Additional Information' : 'Show Additional Information'}}">
                            <div class="gray-text font-weight-normal">
                                Additional information
                            </div>
                            <div>
                                <i *ngIf="isVisibleAdditionalBlock"
                                   class="material-icons md-18">
                                    arrow_upward
                                </i>
                                <i *ngIf="!isVisibleAdditionalBlock"
                                   class="material-icons md-18">
                                    arrow_downward
                                </i>
                            </div>
                        </a>

                        <ng-container *ngIf="isVisibleAdditionalBlock">
                            <div class="row m-t-20">
                                <div class="col-sm-4">
                                    <div class="date-container">
                                        <label class="gray-text form-control-label"
                                               for="field_ProjectCreatedDate">
                                          Project Created Date
                                        </label>
                                        <div>
                                            <bp-date-picker
                                                id="field_ProjectCreatedDate"
                                                [disabled]="true"
                                                [date]="project.createdDate">
                                            </bp-date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="date-container">
                                        <label class="gray-text form-control-label"
                                               for="field_TenderDeadlineDate">
                                            Tender Deadline Date
                                        </label>
                                        <div>
                                            <bp-date-picker
                                                id="field_TenderDeadlineDate"
                                                [disabled]="readOnly"
                                                [date]="project.tenderDeadline"
                                                (onDateChanged)="onTenderDeadlineDateSelect($event)">
                                            </bp-date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4" *ngIf="false">
                                    <div class="date-container">
                                        <label class="gray-text form-control-label"
                                               for="field_TenderDecisionDate">
                                            Tender Decision Date
                                        </label>
                                        <div>
                                            <bp-date-picker
                                                id="field_TenderDecisionDate"
                                                [disabled]="readOnly"
                                                [date]="project.tenderDecision"
                                                (onDateChanged)="onTenderDecisionDateSelect($event)">
                                            </bp-date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="date-container">
                                        <label class="gray-text form-control-label"
                                               for="field_startDate">
                                            Project Start Date
                                        </label>
                                        <div>
                                            <bp-date-picker
                                                id="field_startDate"
                                                [date]="project.startDate"
                                                [disabled]="readOnly"
                                                (onDateChanged)="onStartDateSelect($event)">
                                            </bp-date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-line">
                                <textarea
                                    [ngClass]="{ 'error': (submitted || form.controls.tenderNotes.dirty || form.controls.tenderNotes.touched) && form.controls.tenderNotes.invalid }"
                                    [readOnly]="readOnly"
                                    class="af-input tender-notes"
                                    formControlName="tenderNotes"
                                    name="tenderNotes"
                                    autocomplete="off"
                                    placeholder="Summary of Works">
                                </textarea>
                                    <div
                                        *ngIf="(submitted || form.controls.tenderNotes.dirty) && form.controls.tenderNotes.invalid">
                                        <label *ngIf="form.controls.tenderNotes.errors.maxlength"
                                               class="error">
                                            Maximum length
                                            is {{ form.controls.tenderNotes.errors.maxlength.requiredLength }}
                                            symbols
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-line">
                                <textarea
                                    [ngClass]="{ 'error': (submitted || form.controls.attachmentsUrl.dirty || form.controls.attachmentsUrl.touched) && form.controls.attachmentsUrl.invalid }"
                                    [readOnly]="readOnly"
                                    class="af-input attachmentsUrl"
                                    formControlName="attachmentsUrl"
                                    name="attachmentsUrl"
                                    autocomplete="off"
                                    placeholder="Attachments URL (Dropbox, Google Drive or similar)">
                                </textarea>
                                    <div
                                        *ngIf="(submitted || form.controls.attachmentsUrl.dirty) && form.controls.attachmentsUrl.invalid">
                                        <label *ngIf="form.controls.attachmentsUrl.errors.maxlength"
                                               class="error">
                                            Maximum length
                                            is {{ form.controls.attachmentsUrl.errors.maxlength.requiredLength }}
                                            symbols
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="flex flex-column gap-20">
                                    <div>
                                        <div class="gray-text">
                                            Shared attachments (Floorplans, sketches, planning and developments drawings
                                            or
                                            similar)
                                        </div>
                                        <small>
                                            Supports: Jpeg, png, bmp, pdf, svg, csv. 5mb limit per file upload.
                                        </small>
                                    </div>
                                    <bp-project-attachments [project]="project"
                                                            [disabled]="readOnly || inProcess()"
                                                            id="project-attachment">
                                    </bp-project-attachments>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isAdmin">
                            <div class="form-group">
                                <div class="form-line">
                                    <label class="form-control-label"
                                           for="field_tags_for_existed_project">
                                        Tags
                                    </label>
                                    <ng-select [(ngModel)]="project.tags"
                                               [ngModelOptions]="{standalone: true}"
                                               [items]="tags"
                                               [hideSelected]="true"
                                               [virtualScroll]="true"
                                               multiple="true"
                                               bindLabel="name"
                                               placeholder="-- start typing to find tag --"
                                               id="field_tags_for_existed_project">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-line">
                                        <textarea
                                            [ngClass]="{ 'error': (submitted || form.controls.notes.dirty || form.controls.notes.touched) && form.controls.notes.invalid }"
                                            [ngStyle]="{'height': isAdmin ? '100px' : '160px'}"
                                            class="af-input notes"
                                            formControlName="notes"
                                            name="notes"
                                            autocomplete="off"
                                            placeholder="Enter tender notes">
                                        </textarea>
                                    <div
                                        *ngIf="(submitted || form.controls.notes.dirty) && form.controls.notes.invalid">
                                        <label *ngIf="form.controls.notes.errors.maxlength"
                                               class="error">
                                            Maximum length is {{ form.controls.notes.errors.maxlength.requiredLength }}
                                            symbols
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="m-t-40">
                            <small>
                                *Required fields
                            </small>
                        </div>
                    </div>

                    <div class="col-sm-6 text-center">
                        <bp-image-uploader
                            *ngIf="isAdmin"
                            [disabled]="inProcess()"
                            [initialImageUrl]="project.icon"
                            (onUploaded)="onImageUploaded($event)">
                        </bp-image-uploader>

                        <bp-project-image
                            *ngIf="!isAdmin"
                            [disabled]="readOnly || inProcess()"
                            [project]="project"
                            [postcode]="form.controls.postcode.value"
                            (onUploaded)="onImageUploaded($event)">
                        </bp-project-image>
                    </div>
                </div>

                <div class="flex-between-container m-t-20">
                    <div class="text-left">
                        <button (confirm)="onDeleteProjectClick()"
                                *ngIf="!readOnlyStrict"
                                [swal]="{
                                    title: 'Are you sure?',
                                    text: 'You will not be able to recover this project!',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    customClass: {
                                       confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                       cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                    }
                                }"
                                class="btn bp-delete-button">
                            <span><i class="material-icons md-18">delete_forever</i>DELETE PROJECT</span>
                        </button>
                    </div>

                    <div class="text-right">
                        <div class="form-group"
                             *ngIf="!readOnlyStrict">
                            <div class="form-line">
                                <div class="form-check pl-2">
                                    <label class="form-check-label">
                                        Archive project
                                        <input formControlName="archive"
                                               class="form-check-input archive-project"
                                               type="checkbox">
                                        <span class="form-check-sign"><span class="check"></span></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <button (confirm)="onRefreshProjectTaskClick()"
                                *ngIf="!readOnlyStrict"
                                [disabled]="inProcess()"
                                [swal]="{
                                     title: 'Are you sure?',
                                     text: 'This will update prices but you may lose project specific information such as comments and notes.',
                                     icon: 'warning',
                                     showCancelButton: true,
                                     customClass: {
                                       confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                                       cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
                                     }
                                }"
                                class="btn btn-secondary btn-border-radius btn-small refresh-schedule mr-2">
                            Refresh schedule
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
