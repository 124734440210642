import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class ExpandStagesStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(projectId: number, stageId: number, value: boolean): void {
        this.webStorage.store(this.getLocalStorageKey(projectId, stageId), value);
    }

    retrieve(projectId: number, stageId: number): boolean {
        return this.webStorage.retrieve(this.getLocalStorageKey(projectId, stageId)) || false;
    }

    private getLocalStorageKey(projectId: number, stageId: number): string {
        return `scheduler_${this.accountService.getAccountId()}_expandedStage_project_${projectId}_${stageId}`;
    }
}
