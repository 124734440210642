import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

export type ArchiveFilterValue = 'active' | 'archived';

interface IArchiveFilterOption {
    value: ArchiveFilterValue;
    label: string;
}

@Component({
    selector: 'bp-archive-filter',
    templateUrl: './archive-filter.component.html',
    styleUrls: ['archive-filter.scss']
})
export class ArchiveFilterComponent implements OnInit {
    @Input() defaultValue: ArchiveFilterValue;
    @Output() onValueChanged = new EventEmitter();

    archiveFilterOptions: IArchiveFilterOption[] = [
        { value: 'active', label: 'Active' },
        { value: 'archived', label: 'Archived' }
    ];

    archiveFilter: IArchiveFilterOption;

    ngOnInit(): void {
        this.archiveFilter = _.find(this.archiveFilterOptions, { value: this.defaultValue }) || this.archiveFilterOptions[0];
    }

    onClick(option): void {
        this.archiveFilter = option;
        this.onValueChanged.emit(option.value);
    }
}
