import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { EMAIL_ALREADY_USED_TYPE, LOGIN_ALREADY_USED_TYPE } from 'app/shared/constants/error.constants';
import { RegisterService } from 'app/shared/dataservices/register.service';
import { LoginService } from 'app/core/login/login.service';
import { RegionApi } from "app/shared/dataservices/region.api";

@Component({
    selector: 'bp-register',
    templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit, AfterViewInit {
    confirmPassword: string;
    doNotMatch: string;
    error: string;
    errorEmailExists: string;
    errorUserExists: string;
    registerAccount: any;
    success: boolean;
    modalRef: NgbModalRef;

    constructor(private registerService: RegisterService,
                private regionService: RegionApi,
                private loginService: LoginService,
                private elementRef: ElementRef,
                private router: Router) {
    }

    ngOnInit(): void {
        this.success = false;
        this.registerAccount = {};
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#login').focus();
        }, 0);
    }

    register(): void {
        if (this.registerAccount.password !== this.confirmPassword) {
            this.doNotMatch = 'ERROR';
        } else {
            this.doNotMatch = null;
            this.error = null;
            this.errorUserExists = null;
            this.errorEmailExists = null;
            this.registerAccount.langKey = 'en';

            this.regionService.query().subscribe((regionsRes) => {
                this.registerService.save(this.registerAccount).subscribe(
                    (res: { id_token: string }) => {
                        this.success = true;
                        this.loginService.loginWithToken(res.id_token).then(() => {
                            this.router.navigate(['']);
                        });
                    },
                    response => this.processError(response)
                );
            })

        }
    }

    openLogin(): void {
        this.router.navigate(['/login']);
    }

    private processError(response: HttpErrorResponse): void {
        this.success = null;
        if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
            this.errorUserExists = 'ERROR';
        } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
            this.errorEmailExists = 'ERROR';
        } else {
            this.error = 'ERROR';
        }
    }
}
