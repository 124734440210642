<div class="modal-header">
    <div class="text-center"
         style="flex: 1">
        <h1>
            Request Professionals
        </h1>
    </div>
    <button (click)="clear()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="description m-t-20">
        <p>
            On pressing submit our team will get in touch with our network of builders, architects and surveyors.
        </p>
        <p>
            We will try and make sure they are appropriate and available but will not send them your details.
            You will be sent theirs to select from.
        </p>
        <p>
            We do not charge for this service and it remains your responsibility to vet and manage the relationship.
        </p>
    </div>
    <form [formGroup]="form"
          autocomplete="off"
          class="card m-t-30"
          name="project-quoter-request-form"
          novalidate>

        <div class="form-group">
            <div class="form-line">
                <label for="project-quoter-request-description">
                    Additional comments
                </label>
                <textarea
                    #projectQuoterRequestDescriptionElement
                    class="af-input project-quoter-request-description"
                    formControlName="projectQuoterRequestDescription"
                    id="project-quoter-request-description"
                    name="project-quoter-request-description"
                    placeholder="Please enter required professional(s) and any extra information you think will help us match you with an appropriate person.">
                </textarea>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer modal-footer-centered">
    <button [disabled]="!form.valid"
            (click)="onRequestProjectQuoterClick()"
            tabindex="0"
            class="btn btn-primary btn-border-radius waves-effect request-template"
            type="button">
        Request Professional
    </button>
</div>

