<div class="schedule container-fluid">
    <div class="flex-between-container gap-10"
         *ngIf="scheduleService.inited">
        <div class="flex flex-column gap-5px">
            <div class="flex gap-15">
                <bp-main-view-filter
                    [disabled]="scheduleService.inProcess()"
                    class="m-b-10"
                    (onChanged)="onMainViewFilterChangedFunc($event)"
                    [initialFilterState]="scheduleService.filterState"
                    [areaItems]="scheduleService.scheduleAreaItems"
                    [stageItems]="scheduleService.stageItems"
                    [cssElementItems]="scheduleService.cssElementItems"
                    [buildUpItems]="scheduleService.buildUpItems"
                    [refreshSub]="scheduleService.refreshSub">
                </bp-main-view-filter>

                <button (click)="toggleExpand()"
                        [disabled]="scheduleService.inProcess()"
                        class="btn btn-secondary btn-border-radius-5 expand-collapse"
                        ngbTooltip="{{ scheduleService.expandAll ? 'Collapse' : 'Expand'}}"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <ng-container *ngIf="scheduleService.expandAll">
                            <i class="material-icons md-24">
                                fullscreen_exit
                            </i>
                            Expanded
                        </ng-container>

                        <ng-container *ngIf="!scheduleService.expandAll">
                            <i class="material-icons md-24">
                                fullscreen
                            </i>
                            Collapsed
                        </ng-container>
                    </div>
                </button>

                <button [disabled]="scheduleService.inProcess()"
                        [popper]="bpShowSubStagesModeSelector"
                        [popperAppendTo]="'body'"
                        [popperTrigger]="'click'"
                        [popperPlacement]="'bottom'"
                        [popperPreventOverflow]="true"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        [popperApplyClass]="'bp-popper'"
                        class="btn btn-secondary btn-border-radius-5 toggle-show-sub-stages"
                        ngbTooltip="{{ 'Select show sub-stages mode'}}"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <i class="material-icons md-24">
                            {{ showSubstageModeInfo?.icon }}
                        </i>
                        {{ showSubstageModeInfo?.extendedLabel }}

                        <i class="material-icons cursor-pointer">
                            expand_more
                        </i>
                    </div>
                </button>
            </div>
            <div>
                <div class="schedule__search-container">
                    <input [formControl]="searchControl"
                           (keydown.enter)="search()"
                           placeholder="Search"
                           type="text"/>

                    <button (click)="clearSearch()"
                            *ngIf="searchControl.value?.length"
                            aria-hidden="true"
                            class="schedule__search-container__clear-search"
                            type="button">&times;
                    </button>

                    <div class="schedule__search-container__vr">
                    </div>

                    <fa-icon [icon]="['fas', 'search']" (click)="search()"></fa-icon>
                </div>
            </div>
        </div>

        <div class="flex gap-15" style="margin-right: 307px;">
            <ng-container *ngIf="{value: scheduleService.bulkUpdateMode$ | async} as bulkUpdateMode">
                <button (click)="onBulkUpdateClick()"
                        [disabled]="scheduleService.readOnly || scheduleService.inProcess()"
                        [ngClass]="{'selected': bulkUpdateMode.value}"
                        class="btn btn-secondary btn-border-radius bulk-update"
                        ngbTooltip="Bulk update"
                        type="button">
                    <div class="flex align-items-center gap-1">
                        <i class="material-icons md-24">
                            auto_fix_high
                        </i>
                        Bulk update - {{ bulkUpdateMode.value ? 'On' : 'Off' }}
                    </div>
                </button>
            </ng-container>

            <button [disabled]="scheduleService.inProcess()"
                    *ngIf="showRatesSelector"
                    [popper]="bpProjectSpecificMarginSelector"
                    [popperAppendTo]="'body'"
                    [popperTrigger]="'click'"
                    [popperPlacement]="'bottom'"
                    [popperPreventOverflow]="true"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="true"
                    [popperApplyClass]="'bp-popper'"
                    class="btn btn-secondary btn-border-radius rates"
                    ngbTooltip="Rates"
                    [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                <div class="flex align-items-center gap-1">
                    <i class="material-icons md-24">
                        price_change
                    </i>
                    Rates

                    <i class="material-icons cursor-pointer">
                        expand_more
                    </i>
                </div>
            </button>

            <button [disabled]="scheduleService.inProcess()"
                    [popper]="bpExportSelector"
                    [popperAppendTo]="'body'"
                    [popperTrigger]="'click'"
                    [popperPlacement]="'bottom'"
                    [popperPreventOverflow]="true"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="true"
                    [popperApplyClass]="'bp-popper'"
                    class="btn btn-secondary btn-border-radius export"
                    ngbTooltip="Export"
                    [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                <div class="flex align-items-center gap-1">
                    <i class="material-icons md-24">
                        file_download
                    </i>
                    Export

                    <i class="material-icons cursor-pointer">
                        expand_more
                    </i>
                </div>
            </button>
        </div>
    </div>

    <div class="sticky-button-container flex flex-column">
        <div class="flex flex-row justify-content-end gap-15">
            <button (click)="onSaveButtonClick()"
                    [disabled]="scheduleService.readOnly || scheduleService.inProcess()"
                    class="btn btn-secondary btn-border-radius save p-l-10 p-r-10"
                    [ngbTooltip]="'Save'"
                    [placement]="'bottom'"
                    [openDelay]="MENU_TOOLTIP_OPEN_DELAY"
                    type="button">
                <div class="flex align-items-center gap-1">
                    <i class="material-icons md-24">
                        save
                    </i>
                    Save
                </div>
            </button>

            <button (click)="onAddGroupOfScheduleTasksClick()"
                    [disabled]="!scheduleService.nativeScheduleAreas?.length || scheduleService.readOnly || scheduleService.inProcess()"
                    class="btn btn-primary btn-border-radius add-new-task"
                    [ngbTooltip]="!scheduleService.nativeScheduleAreas?.length ? 'Add area to the project' : 'Add task(s)'"
                    [placement]="'bottom'"
                    [openDelay]="MENU_TOOLTIP_OPEN_DELAY"
                    type="button">
                + Add task
            </button>
        </div>
        <div class="flex m-t-10 items-center justify-content-end autosave-line"
             *ngIf="scheduleService.inited">
            <ng-container
                *ngIf="!(scheduleService.inProcess() && !scheduleService.inProcessLoading()); else isSavingContainer">
                <fa-icon [icon]="['fas', 'save']" class="mr-2"></fa-icon>
                Autosaved: {{ scheduleService.project.scheduleLastModifiedDate | date:'dd/MM/yy HH:mm' }}
                (v{{ scheduleService.project.version }})
            </ng-container>
            <ng-template #isSavingContainer>
                <fa-icon [icon]="['fas', 'spinner']" class="mr-2"></fa-icon>
                Saving...
            </ng-template>
        </div>
    </div>

    <div *ngIf="scheduleService.inited && !scheduleService.inProcessLoading()">
        <bp-schedule-root-container>
        </bp-schedule-root-container>
    </div>

    <div *ngIf="!scheduleService.inited || scheduleService.inProcessLoading()"
         class="text-center m-t-40">
        <label>Please wait..</label>
    </div>

    <div class="flex-between-container align-items-center m-t-100 m-b-50"
         *ngIf="!scheduleService.inProcess()">
        <div class="flex-1">
            <button [routerLink]="['/scheduler/dashboard', scheduleService.project.id]"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back to Dashboard"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                Back to Dashboard
            </button>
        </div>
        <div class="flex-1 text-right">
            <button [routerLink]="['/scheduler/quotes', scheduleService.project.id]"
                    class="btn btn-primary btn-border-radius waves-effect next"
                    ngbTooltip="Next"
                    type="button">
                Next
                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            </button>
        </div>
    </div>
</div>

<popper-content #bpShowSubStagesModeSelector>
    <bp-show-substages-mode-selector
        [showSubStagesMode]="scheduleService.showSubStagesMode"
        (onChanged)="onShowSubStagesModeChanged($event)">
    </bp-show-substages-mode-selector>
</popper-content>

<popper-content #bpExportSelector>
    <bp-export-selector
        (onChanged)="onExportTypeSelected($event)">
    </bp-export-selector>
</popper-content>

<popper-content #bpProjectSpecificMarginSelector>
    <bp-specific-margin-action-selector (onChanged)="onProjectSpecificMarginSelector($event)"
                                        [showTasksItem]="true">
    </bp-specific-margin-action-selector>
</popper-content>

