<div class="modal-header">
    <h4 class="modal-title">
        Material Rates
    </h4>
</div>

<div class="modal-body">
    <div class="flex flex-row gap-20 flex-wrap justify-content-between items-center gap-20">
        <div class="font-weight-bold font-16 flex flex-row items-center gap-1">
            Margin

            <i class="material-icons md-18"
               [ngbTooltip]="'Margin applied to each material within your quote'">
                info_outline
            </i>
        </div>

        <div class="relative flex flex-row gap-20">
            <div class="position-relative percent-input">
                <input [formControl]="applyToAllMarginControl"
                       class="af-input text-right"
                       placeholder="0"
                       type="text"
                       autocomplete="off"
                       [ngClass]="{'invalid': applyToAllMarginControl?.errors}">
                <span>%</span>
            </div>

            <button (click)="applyMarginToAll()"
                    [disabled]="applyToAllMarginControl.invalid || applyToAllMarginControl.value == null"
                    class="btn btn btn-secondary btn-border-radius waves-effect"
                    type="button">
                Apply to All
            </button>
        </div>
    </div>

    <div class="flex flex-row gap-20 flex-wrap justify-content-between items-center gap-20 m-t-10">
        <div class="font-weight-bold font-16 flex flex-row items-center gap-1">
            Handling Charge

            <i class="material-icons md-18"
               [ngbTooltip]="'A fee/ margin paid to cover the packaging , transport , etc, of a commodity. Applies only to Client supplied items'">
                info_outline
            </i>
        </div>

        <div class="relative flex flex-row gap-20">
            <div class="position-relative percent-input">
                <input [formControl]="handlingChargeControl"
                       class="af-input text-right"
                       placeholder="0"
                       type="text"
                       autocomplete="off"
                       [ngClass]="{'invalid': handlingChargeControl?.errors}">
                <span>%</span>
            </div>

            <button (click)="handlingChargeWasClicked = true"
                    [disabled]="handlingChargeControl.invalid || handlingChargeControl.value == null"
                    class="btn btn btn-secondary btn-border-radius waves-effect"
                    type="button">
                Apply to All
            </button>
        </div>
    </div>

    <hr>

    <div class="margin-table m-t-20"
         *ngIf="form">
        <div class="margin-table__header flex flex-row gap-20 items-center justify-content-start flex-wrap">
            <div class="margin-table__header__col min-w-250">
                Task
            </div>
            <div class="margin-table__header__col min-w-250">
                Material
            </div>
            <div class="margin-table__header__col">
                Unit
            </div>
            <div class="margin-table__header__col">
                Cost
            </div>
            <div class="margin-table__header__col">
                Margin
            </div>
            <div class="margin-table__header__col">
                Rate
            </div>
        </div>
        <div class="margin-table__body">
            <div *ngFor="let material of materials; let i = index; let odd = odd;"
                 [ngClass]="{ 'odd': odd}"
                 [formGroup]="materialForm(i)"
                 class="margin-table__body__row flex flex-row gap-20 items-center justify-content-start flex-wrap">
                <div class="margin-table__body__row__col min-w-250">
                    {{ material.tasks?.join(', ') || '-' }}
                </div>
                <div class="margin-table__body__row__col min-w-250">
                    {{ material.material }}
                </div>
                <div class="margin-table__body__row__col">
                    {{ material.unit }}
                </div>
                <div class="margin-table__body__row__col number-input-container">
                    <input formControlName="cost"
                           (blur)="onCostInputBlur($event, i)"
                           mask="separator.2"
                           thousandSeparator=","
                           prefix="&pound;&nbsp;"
                           type="text">

                    <error *ngIf="materialForm(i).get('cost')?.errors?.pattern">
                        Numeric value
                    </error>
                    <error *ngIf="materialForm(i).get('cost')?.errors?.required">
                        This field is required.
                    </error>
                </div>

                <div class="margin-table__body__row__col number-input-container">
                    <input formControlName="margin"
                           (blur)="onMarginInputBlur($event, i)"
                           class="af-input"
                           placeholder="0%"
                           mask="percent.2"
                           suffix="%"
                           thousandSeparator=","
                           type="text"
                           autocomplete="off">

                    <error *ngIf="materialForm(i).get('margin')?.errors?.pattern">
                        Numeric value
                    </error>
                    <error *ngIf="materialForm(i).get('margin')?.errors?.required">
                        This field is required.
                    </error>
                </div>

                <div class="margin-table__body__row__col number-input-container">
                    <input formControlName="rate"
                           (blur)="onRateInputBlur($event, i)"
                           mask="separator.2"
                           decimalMarker="."
                           thousandSeparator=","
                           prefix="&pound;&nbsp;"
                           type="text">

                    <error *ngIf="materialForm(i).get('rate')?.errors?.pattern">
                        Numeric value
                    </error>
                    <error *ngIf="materialForm(i).get('rate')?.errors?.required">
                        This field is required.
                    </error>
                </div>
            </div>
        </div>

        <hr>

        <div class="margin-table__note">
            Note: Changes only affect this project, to change default values navigate to settings > Default material
            rates
        </div>
    </div>
</div>

<div class="modal-footer flex gap-20 flex-end">
    <button (click)="close()"
            data-dismiss="modal"
            class="btn btn-secondary btn-border-radius waves-effect"
            type="button">
        Close
    </button>

    <button (click)="save()"
            [disabled]="isSaveDisabled()"
            tabindex="0"
            class="btn btn-primary btn-border-radius waves-effect"
            type="button">
        Save & Exit
    </button>
</div>

