import { Injectable } from '@angular/core';
import { IQuoterTaskCost, IScheduleTask } from 'app/shared/model/schedule-task.model';
import { HttpResponse } from '@angular/common/http';
import { IProject } from 'app/shared/model/project.model';
import { QuoterDefaultPricesService } from 'app/shared/dataservices/quoter-default-prices.service';
import { IScheduleTaskQuoterTotalModificationEvent } from 'app/shared/constants/events.constants';

@Injectable({
    providedIn: 'root'
})
export class DefaultPricesUpdaterService {
    constructor(private quoterDefaultPricesService: QuoterDefaultPricesService) {
    }

    public fillDefaultPrices(project: IProject, scheduleTask: IScheduleTask): Promise<IScheduleTaskQuoterTotalModificationEvent> {
        return new Promise((resolve) => {
            const defaultQuoterId = project.defaultQuoter != null ? project.defaultQuoter.id : null;

            if (defaultQuoterId != null && scheduleTask.taskId != null) {
                this.quoterDefaultPricesService
                    .queryTaskCost(
                        defaultQuoterId,
                        scheduleTask.taskId,
                        scheduleTask.ratio,
                        scheduleTask.unitValue,
                        scheduleTask.primeMaterialId,
                        scheduleTask.id
                    )
                    .subscribe((res: HttpResponse<IQuoterTaskCost>) => {
                        scheduleTask.taskTotal = res.body;
                        resolve({
                            stageId: scheduleTask.stageId
                        });
                    });
            } else {
                resolve(null);
            }
        });
    }
}
