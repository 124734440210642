import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { ProjectFilterStateStorageService } from 'app/shared/services/project-filter-state-storage.service';
import { ProjectListComponent } from 'app/shared/components/projects/project-list/project-list.component';
import { FormControl } from '@angular/forms';
import { PROJECT_STATES, ProjectState } from 'app/shared/constants/project-states.constants';
import { AccountService } from 'app/core/auth/account.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

/**
 * Project list
 */
@Component({
    selector: 'bp-scheduler-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['projects.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    projectCount: number;

    searchControl = new FormControl();

    subSearch = Subscription.EMPTY;

    isAdmin = false;

    inProcess = false;

    PROJECT_STATE_FILTER = PROJECT_STATES;

    projectStateSelectInputData: SelectInputData;
    projectStateFilterId: ProjectState;

    @ViewChild(ProjectListComponent) projectListComponent: ProjectListComponent;

    get showEmptyProjects(): boolean {
        return this.projectStateFilterId !== 'archived' && this.projectCount === 0;
    }

    constructor(
        private accountService: AccountService,
        private projectApi: ProjectApi,
        private filterStateStorage: ProjectFilterStateStorageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.inProcess = true;

        this.isAdmin = this.accountService.isAdmin();
        this.projectStateFilterId = this.filterStateStorage.retrieve();

        this.projectStateSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'label',
            initIndex: this.projectStateFilterId,
            data: this.PROJECT_STATE_FILTER,
            dropdownPosition: 'bottom',
            clearable: false,
            searchable: false
        };

        this.subSearch = this.searchControl.valueChanges
            .pipe(debounceTime(700), distinctUntilChanged())
            .subscribe((term: string) => {
                if (this.projectListComponent != null) {
                    this.projectListComponent.searchValue = term;
                    this.projectListComponent.reset();
                }
            });

        this.loadCountOfProjects().then((projectCount: number) => {
            this.projectCount = projectCount;
            this.inProcess = false;
        });
    }

    ngOnDestroy(): void {
        this.subSearch?.unsubscribe();
    }

    onChangeProjectState(event: { id: ProjectState }): void {
        this.projectStateFilterId = event.id;
        this.filterStateStorage.store(this.projectStateFilterId);

        this.searchControl.setValue(null);

        if (this.projectListComponent != null) {
            this.projectListComponent.projectStateFilterId = this.projectStateFilterId;
            this.projectListComponent.searchValue = null;
            this.projectListComponent.reset();
        }
    }

    onExampleProjectCreation(createdExampleProject: IProject): void {
        this.blockUI.start('Please wait..');

        this.accountService.identity(true).then(() => {
            this.loadCountOfProjects().then(pcNew => {
                this.projectCount = pcNew;
                this.router.navigate(['/scheduler/dashboard', createdExampleProject.id])
                    .finally(() => {
                        this.blockUI.stop();
                    })
            })
        })
    }

    private loadCountOfProjects(): Promise<number> {
        return new Promise(resolve => {
            const queryParams: any = {
                page: 0,
                size: 1,
                sort: 'id,desc'
            };

            this.projectApi
                .query(queryParams)
                .subscribe(
                    (res: HttpResponse<IProject[]>) => {
                        resolve(parseInt(res.headers.get('X-Total-Count'), 10));
                    }
                );
        });
    }
}
