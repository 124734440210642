import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';
import { ICssElement } from 'app/shared/model/bp.model';

@Injectable({ providedIn: 'root' })
export class CssElementService {
    public resourceUrl = SERVER_API_URL + 'api/css-element';

    constructor(private http: HttpClient) {
    }

    create(cssElement: ICssElement): Observable<HttpResponse<ICssElement>> {
        return this.http.post<ICssElement>(this.resourceUrl, cssElement, { observe: 'response' });
    }

    update(cssElement: ICssElement): Observable<HttpResponse<ICssElement>> {
        return this.http.put<ICssElement>(this.resourceUrl, cssElement, { observe: 'response' });
    }

    find(id: number): Observable<HttpResponse<ICssElement>> {
        return this.http.get<ICssElement>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<ICssElement[]>> {
        const options = createRequestOption(req);
        return this.http.get<ICssElement[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
