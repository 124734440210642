import { ILabourComponent } from './labour-component.model';
import { IMaterialComponent } from './material-component.model';
import { IComponentArea } from './component-area.model';
import { IElement } from './element.model';
import { IStage } from './stage.model';
import { IUnit } from 'app/shared/model/unit.model';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { IQuoterTaskCost } from 'app/shared/model/schedule-task.model';
import { IBuildUp, ICssElement } from "app/shared/model/bp.model";

export interface IPrimeMaterialViewInfo {
    id: number;
    primeMaterialName: string;
    primeMaterialRate: number;
    primeMaterialCost: number;
}

export interface ITaskInfo {
    id: number;
    displayName: string;
    elementId: number;
    componentAreaId: number;
    materialCategories: IMaterialCategory[];
    stage: string;
    stageId: number;
    task: string;
    unit: string;
    unitId: number;
    unitPlural: string;

    _selectedCount?: number;
    focused?: boolean;
}
export type TaskType = 'COMMON' | 'FINISH';

export interface ITask {
    id?: number;
    task?: string;
    archive?: boolean;
    labourComponents?: ILabourComponent[];
    materialComponents?: IMaterialComponent[];
    componentarea?: IComponentArea;
    materialCategories?: IMaterialCategory[];
    cssElement?: ICssElement;
    buildUps?: IBuildUp[];
    componentAreaId?: number;
    unit?: IUnit;
    element?: IElement;
    stage?: IStage;
    ratio?: number;
    taskOrder?: number;
    displayName?: string;
    unitId?: number;
    unitPlural?: string;
    elementId?: number;
    stageId?: number;
    selected?: boolean;
    focused?: boolean;
    primeMaterial?: string;
    primeMaterialRate?: number;
    primeCost?: number;
    total?: number;
    labours?: { tradeId?: number; quantity?: number; rate?: number; trade?: string; cost?: number }[];
    materials?: { materialId?: number; quantity?: number; rate?: number; material?: string; cost?: number }[];
    primeMaterials?: IPrimeMaterialViewInfo[];
    newTaskRequestId?: number;
    scheduleArea?: string;
    _selectedCount?: number;
    _editable?: boolean;
    taskTotal?: IQuoterTaskCost;
    type?: TaskType;
    otherStage?: boolean;
    specification?: string;
}

export class Task implements ITask {
    constructor(
        public id?: number,
        public task?: string,
        public archive?: boolean,
        public labourComponents: ILabourComponent[] = [],
        public materialComponents: IMaterialComponent[] = [],
        public componentarea?: IComponentArea,
        public materialCategory?: IMaterialCategory,
        public materialCategoryId?: number,
        public componentAreaId?: number,
        public unit?: IUnit,
        public element?: IElement,
        public stage?: IStage,
        public ratio = 1,
        public taskOrder?: number,
        public displayName?: string,
        public unitId?: number,
        public unitPlural?: string,
        public elementId?: number,
        public stageId?: number,
        public selected?: boolean,
        public focused?: boolean,
        public primeMaterial?: string,
        public primeMaterialRate?: number,
        public primeCost?: number,
        public total?: number,
        public primeMaterials: IPrimeMaterialViewInfo[] = [],
        public newTaskRequestId?: number,
        public scheduleArea?: string,
        public specification?: string
    ) {}
}
