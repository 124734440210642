import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IProject } from 'app/shared/model/project.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { AccountService } from 'app/core/auth/account.service';
import { GroupBy } from 'app/shared/components/common/main-view-filter/main-view-filter.component';

@Injectable({
    providedIn: 'root'
})
export class QuoterExpandStagesStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(project: IProject, stage: IComparisonStage, groupBy: GroupBy, value: boolean): void {
        this.webStorage.store(this.getLocalStorageKey(project, stage, groupBy), value);
    }

    retrieve(project: IProject, stage: IComparisonStage,  groupBy: GroupBy): boolean {
        return this.webStorage.retrieve(this.getLocalStorageKey(project, stage, groupBy)) || false;
    }

    private getLocalStorageKey(project: IProject, stage: IComparisonStage, groupBy: GroupBy): string {
        return `quoter_${this.accountService.getAccountId()}_expandedComparisonStage_${project.id}_${stage.id}_${groupBy}`;
    }
}
