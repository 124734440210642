import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IProject } from 'app/shared/model/project.model';
import { Router } from '@angular/router';
import { GetReportDataParams, IProjectAmount, ProjectApi } from 'app/shared/dataservices/project.api';
import { QuoteReportService } from 'app/flows/quoter/quote/services/quote-report.service';
import { SelectPaymentProviderQuoterModalResult } from 'app/shared/components/common/select-payment-provider-quoter-modal/select-payment-provider-quoter-modal.component';
import { SelectPaymentProviderQuoterModalService } from 'app/shared/components/common/select-payment-provider-quoter-modal/select-payment-provider-quoter-modal.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { HttpResponse } from '@angular/common/http';
import { PAYMENT_TERMS_URL_QUOTER } from 'app/shared/constants/links.constants';

@Component({
    selector: 'bp-opt-in-pay-modal',
    templateUrl: './opt-in-pay-modal.component.html',
    styleUrls: ['opt-in-pay-modal.scss']
})
export class OptInPayModalComponent {
    project: IProject;

    PAYMENT_TERMS_URL_QUOTER = PAYMENT_TERMS_URL_QUOTER;

    constructor(
        private router: Router,
        private activeModal: NgbActiveModal,
        private projectApi: ProjectApi,
        private quoteReportService: QuoteReportService,
        private alertService: BpAlertService,
        private selectPaymentProviderModalService: SelectPaymentProviderQuoterModalService
    ) {}

    returnHome(): void {
        this.activeModal.dismiss('close');
        this.router.navigate(['/']);
    }

    onDownloadExcelClick(): void {
        const query: GetReportDataParams = {
            isAreaGroup: false,
            areaIds: [],
            stageIds: []
        };

        this.quoteReportService.exportAsExcel(this.project, query);
    }

    onOkClick(): void {
        this.activeModal.close();
    }

    onPayNowClick(): void {
        this.projectApi.getProjectAmount(this.project.id).subscribe((res: HttpResponse<IProjectAmount>) => {
            const total = res.body.projectAmount;
            this.selectPaymentProviderModalService.open(this.project, total).result.then(
                (result: SelectPaymentProviderQuoterModalResult) => {
                    if (result.error) {
                        this.alertService.error(`Something went wrong: ${result.error}`);
                    } else {
                        window.location.reload();
                    }
                },
                () => {
                    // nothing to do on cancelling
                }
            );
        });
    }
}
