import { Injectable } from '@angular/core';
import { IComparisonQoter } from 'app/shared/model/comparison.model';
import { IProject } from 'app/shared/model/project.model';
import { GetReportDataParams, ProjectApi } from 'app/shared/dataservices/project.api';
import { BpDownloadExcelFileService } from 'app/shared/services/bp-download-excel-file.service';
import { AccountService } from 'app/core/auth/account.service';
import { BpDownloadDocFileService } from 'app/shared/services/bp-download-doc-file.service';

@Injectable({
    providedIn: 'root'
})
export class IndividualQuoteReportService {
    constructor(
        private readonly projectApi: ProjectApi,
        private readonly accountService: AccountService,
        private readonly downloadExcelFile: BpDownloadExcelFileService,
        private readonly downloadDocFileService: BpDownloadDocFileService
    ) {
    }

    exportAsExcel(project: IProject, quoter: IComparisonQoter, query: GetReportDataParams): Promise<void> {
        const filename = `${this.getFileName(project, quoter)}.xlsx`;

        return new Promise<void>((resolve) => {
            return this.projectApi.getExcelData(project.id, query).subscribe(
                (data: string) => {
                    this.downloadExcelFile.call(data, filename);
                    resolve();
                }
            );
        })
    }

    exportAsDocx(project: IProject, quoter: IComparisonQoter, query: GetReportDataParams): Promise<void> {
        const filename = `${this.getFileName(project, quoter)}.docx`;

        return new Promise<void>((resolve) => {
            this.projectApi.getDocxData(project.id, query).subscribe(
                (data: string) => {
                    this.downloadDocFileService.call(data, filename);
                    resolve();
                }
            );
        })
    }

    exportAsPDF(project: IProject, quoter: IComparisonQoter, query: GetReportDataParams): Promise<void> {
        const filename = `${this.getFileName(project, quoter)}.pdf`;

        return new Promise<void>((resolve) => {
            this.projectApi.getPDFData(project.id, query).subscribe(
                (data: string) => {
                    this.downloadDocFileService.call(data, filename);
                    resolve();
                }
            );
        })
    }

    private getFileName(project: IProject, quoter: IComparisonQoter): string {
        return `${this.accountService.getCompany() || this.accountService.getEmail()}_${quoter.company ||
        quoter.email}_individual_quote_report_for_${project.address}_v${project.version}`;
    }
}
