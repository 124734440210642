import { Component, Input } from '@angular/core';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';
import { QuoteStore } from 'app/flows/quoter/quote/stores/quote.store';

@Component({
    selector: 'bp-quote-element',
    templateUrl: './element.component.html',
    styleUrls: ['element.scss']
})
export class QuoteElementComponent {
    @Input() stage: IComparisonStage;
    @Input() element: IComparisonElement;

    constructor(
        protected store: QuoteStore
    ) {
    }
}
