import { AbstractControl } from '@angular/forms';

export const noWhiteSpaceValidator = (control: AbstractControl): { [key: string]: any } | null => {
    if ((control.value || '').length === 0) {
        return null;
    }
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
};
