import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IActivityInfo } from 'app/shared/model/bp.model';
import {
    FreemiumModalComponent,
    FreemiumModalType
} from 'app/shared/components/common/freemium-modal/freemium-modal.component';
import { AccountService } from "app/core/auth/account.service";

@Injectable({ providedIn: 'root' })
export class FreemiumModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal,
                private accountService: AccountService) {
    }

    verify(action: 'create_project' | 'delete_project' | 'export' | 'upload-and-auto-calculate' | 'request-template'): Promise<boolean> {
        return new Promise(resolve => {
            if (this.accountService.isAdmin()) {
                resolve(true);
                return;
            }

            this.accountService.getActivityInfo(true).then((activityInfo: IActivityInfo) => {
                switch (action) {
                    case 'create_project':
                        if (!activityInfo.projectCreation.allowed) {
                            if (!activityInfo.projectCreation.monthlyLimit) {
                                this.showModal('type_create_project');
                            } else {
                                this.showModal('type_create_project_monthly');
                            }
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                        break;
                    case 'delete_project':
                        if (!activityInfo.projectDeletionAllowed) {
                            this.showModal('type_delete_project');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                        break;
                    case 'export':
                        if (activityInfo.isOnTrial) {
                            this.showModal('type_export');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                        break;
                    case 'upload-and-auto-calculate':
                        if (activityInfo.isOnTrial) {
                            this.showModal('type_upload_and_auto_calculate');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                        break;
                    case 'request-template':
                        if (activityInfo.isOnTrial) {
                            this.showModal('type_request_template');
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                        break;
                    default:
                        resolve(true);
                }
            });
        })
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }

    private showModal(type: FreemiumModalType): void {
        this.ngbModalRef = this.modalService.open(FreemiumModalComponent as Component, {
            windowClass: 'bp-modal',
            backdrop: 'static',
        });

        this.ngbModalRef.componentInstance.type = type;
    }
}
