import { Injectable } from '@angular/core';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IStage } from 'app/shared/model/stage.model';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import {
    ComparisonExpandStagesStorageService
} from 'app/flows/scheduler/comparison/services/expand-stages-storage.service';
import {
    ComparisonExpandAreaGroupStorageService
} from 'app/flows/scheduler/comparison/services/expand-area-group-storage.service';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import {
    ApplyMainFilterToComparisonService,
    IMainFilterApplyingToComparisonResult
} from 'app/flows/scheduler/services/apply-main-filter-to-comparison.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ComparisonStore } from 'app/flows/scheduler/comparison/comparison.store';
import { forkJoin, lastValueFrom, Observable, Observer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { IArea } from 'app/shared/model/area.model';
import * as _ from 'lodash';
import { ExportReportsService, IMainReportData } from 'app/shared/services/export/export-reports.service';
import { EXPORT_TYPES, ExportType } from 'app/shared/constants/export-types';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { IMainViewFilterState } from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { ICssElement } from 'app/shared/model/bp.model';
import { AccountService } from 'app/core/auth/account.service';
import { UserReportModalService } from 'app/account/user-report-modal/user-report-modal.service';

@Injectable()
export class ComparisonService {
    constructor(
        private accountService: AccountService,
        private projectApi: ProjectApi,
        private expandStagesStorageService: ComparisonExpandStagesStorageService,
        private expandAreaGroupStorageService: ComparisonExpandAreaGroupStorageService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private applyMainFilterToComparisonService: ApplyMainFilterToComparisonService,
        private exportReportsService: ExportReportsService,
        private freemiumModalService: FreemiumModalService,
        private comparisonStore: ComparisonStore,
        private alertService: BpAlertService,
        private _userReportModalService: UserReportModalService
    ) {
    }

    reloadAll(): void {
        this.loadQuoters().subscribe(() => {
            this.loadcssElements().subscribe(() => {
                this.loadStages().subscribe(() => {
                    this.loadScheduleAreas().subscribe(() => {
                        this.loadComparisons().subscribe(() => {
                            try {
                                this.comparisonStore.filterState = this.mainFilterInitialStateStorageService.retrieve(
                                    this.comparisonStore.project.id,
                                    'comparison'
                                );
                            } catch (e) {
                                console.warn('Cannot restore main filter view from local storage');
                            }


                            this.applyMainFilter();

                            this.updateExpandedAll();
                            this.restoreExpandState();
                            this.comparisonStore.inited = true;
                        });
                    });
                });
            })
        });
    }

    inProcess(): boolean {
        return (
            this.comparisonStore.inProcessLoadingQoters ||
            this.comparisonStore.inProcessLoadingScheduleAreas ||
            this.comparisonStore.inProcessLoadingComparison ||
            this.comparisonStore.inProcessLoadingStages ||
            this.comparisonStore.inProcessLoadingComparisonForArea ||
            this.comparisonStore.inProcessExportingAsPDF
        );
    }

    toggleExpand(): void {
        this.comparisonStore.expandedAll = !this.comparisonStore.expandedAll;

        switch (this.comparisonStore.filterState.groupBy) {
            case 'area':
                _.each(this.comparisonStore.filteredScheduleAreas, (area: IArea) => {
                    area.expanded = this.comparisonStore.expandedAll;
                    this.expandAreaGroupStorageService.store(this.comparisonStore.project, area, this.comparisonStore.expandedAll);
                });
                break;
            case 'stage':
                _.each(this.comparisonStore.comparison.stageDTOs, (stage: IComparisonStage) => {
                    stage.expanded = this.comparisonStore.expandedAll;
                    this.expandStagesStorageService.store(this.comparisonStore.project, stage, this.comparisonStore.expandedAll);
                });
                break;
        }
    }

    setMainViewFilter(newFilterState: IMainViewFilterState): void {
        this.comparisonStore.filterState = newFilterState;
        this.mainFilterInitialStateStorageService.store(this.comparisonStore.project.id, 'comparison', newFilterState);
        this.applyMainFilter();
        this.restoreExpandState();
    }

    setSelectedQuoters(quoters: IComparisonQoter[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.comparisonStore.quoters = quoters;

            this.loadComparisons().subscribe(() => {
                this.loadComparisonsForAreas().subscribe(() => {
                    this.applyMainFilter();
                    this.restoreExpandState();
                    resolve();
                }, () => {
                    reject();
                });
            }, () => {
                reject();
            });
        })
    }

    export(exportType: ExportType): void {
        const showSuccessMessage = () => {
            this.alertService.success(
                `Your ${EXPORT_TYPES.find(et => et.id === exportType).label} document will be downloaded shortly. Please wait.`, 10000);
        }

        this.freemiumModalService.verify('export').then((res) => {
            if (res) {
                const mainReportData: IMainReportData = {
                    project: this.comparisonStore.project,
                    filteredScheduleAreas: this.comparisonStore.filteredScheduleAreas,
                    filterState: this.comparisonStore.filterState,
                    scheduleAreaItems: this.comparisonStore.scheduleAreaItems,
                    stageItems: this.comparisonStore.stageItems,
                    cssElementItems: this.comparisonStore.cssElementItems,
                    buildUpItems: [],
                }

                switch (exportType) {
                    case 'csv':
                        this._userReportModalService.open('EXCEL').result.then((res) => {
                            if (res) {
                                this.exportReportsService.exportAsExcelComparison(mainReportData, this.getSelectedQuoters());
                                showSuccessMessage();
                            }
                        })
                        break;
                    case 'docx':
                        this._userReportModalService.open('WORD').result.then((res) => {
                            if (res) {
                                this.exportReportsService.exportAsDocxComparison(mainReportData, this.getSelectedQuoters());
                                showSuccessMessage();
                            }
                        })
                        break;
                    case 'pdf':
                        this._userReportModalService.open("PDF").result.then((res) => {
                            if (res) {
                                this.exportReportsService.exportAsPDFComparison(mainReportData, this.getSelectedQuoters());
                                showSuccessMessage();
                            }
                        })
                        break;
                }
            }
        });
    }

    private loadStages(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.comparisonStore.inProcessLoadingStages = true;

            this.projectApi
                .queryScheduleStages(this.comparisonStore.project.id)
                .pipe(
                    finalize(() => {
                        this.comparisonStore.inProcessLoadingStages = false;
                    })
                )
                .subscribe((res: HttpResponse<IStage[]>) => {
                    this.comparisonStore.stages = res.body;
                    this.comparisonStore.stageItems = _.map(this.comparisonStore.stages, (a: IStage) => {
                        return {
                            id: a.id,
                            title: a.stage
                        };
                    });

                    observer.next();
                    observer.complete();
                });
        });
    }

    private loadComparisons(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.comparisonStore.nativeComparison = {
                quoters: [],
                stageDTOs: []
            };

            const quoterIdsParam = this.getSelectedQuoterIds();

            if (quoterIdsParam.length === 0) {
                observer.next();
                observer.complete();
                return;
            }

            this.comparisonStore.inProcessLoadingComparison = true;

            this.projectApi
                .queryComparison(this.comparisonStore.project.id, quoterIdsParam)
                .pipe(
                    finalize(() => {
                        this.comparisonStore.inProcessLoadingComparison = false;
                    })
                )
                .subscribe((res: HttpResponse<IComparison>) => {
                    this.comparisonStore.nativeComparison = res.body;

                    observer.next();
                    observer.complete();
                });
        });
    }

    private loadScheduleAreas(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.comparisonStore.inProcessLoadingScheduleAreas = true;

            this.projectApi
                .queryAvailableScheduleAreas(this.comparisonStore.project.id)
                .pipe(
                    finalize(() => {
                        this.comparisonStore.inProcessLoadingScheduleAreas = false;
                    })
                )
                .subscribe((res: HttpResponse<IScheduleArea[]>) => {
                    this.comparisonStore.nativeScheduleAreas = _.clone(res.body);

                    this.comparisonStore.scheduleAreaItems = _.map(this.comparisonStore.nativeScheduleAreas, (a: IScheduleArea) => {
                        return {
                            id: a.id,
                            title: a.area
                        };
                    });

                    this.loadComparisonsForAreas().subscribe(() => {
                        observer.next();
                        observer.complete();
                    });
                });
        });
    }

    private loadcssElements(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.accountService.identity().then(account => {
                if (account.beta) {
                    lastValueFrom(this.projectApi.queryCssElements(this.comparisonStore.project.id)).then((res: HttpResponse<ICssElement[]>) => {
                        this.comparisonStore.cssElementItems = _.sortBy(res.body, 'order').map((a: ICssElement) => {
                            return {
                                id: a.id,
                                title: a.name,
                                order: a.order
                            };
                        });

                        observer.next();
                        observer.complete();
                    })
                } else {
                    observer.next();
                    observer.complete();
                }
            });
        });
    };

    private loadQuoters(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.comparisonStore.inProcessLoadingQoters = true;

            this.projectApi
                .queryQuoters(this.comparisonStore.project.id)
                .pipe(
                    finalize(() => {
                        this.comparisonStore.inProcessLoadingQoters = false;
                    })
                )
                .subscribe((res: HttpResponse<IComparisonQoter[]>) => {
                    let resQuoters: IComparisonQoter[];

                    if (this.comparisonStore.quoterIdsAsInitialFilter && this.comparisonStore.quoterIdsAsInitialFilter.length > 0) {
                        resQuoters = [];
                        _.each(this.comparisonStore.quoterIdsAsInitialFilter, (quoterId: number) => {
                            const q = _.find(res.body, { id: quoterId });
                            if (q != null) {
                                resQuoters.push(q);
                            }
                        });
                    } else {
                        resQuoters = res.body;
                    }
                    resQuoters = _.uniqBy(resQuoters, 'id');

                    this.comparisonStore.quoters = _.map(resQuoters, (quoter: IComparisonQoter) => {
                        quoter.selected = true;
                        return quoter;
                    });

                    observer.next();
                    observer.complete();
                });
        });
    }

    private getActualStages(): IStage[] | IComparisonStage[] {
        let actualStages = [];

        switch (this.comparisonStore.filterState.groupBy) {
            case 'area':
            case 'area_room':
                _.each(this.comparisonStore.nativeScheduleAreas, (area: IArea) => {
                    actualStages = _.union(actualStages, area['comparison']['stageDTOs']);
                });
                break;
            case 'stage':
            case 'stage_room':
                actualStages = this.comparisonStore.comparison.stageDTOs;
                break;
        }

        return actualStages;
    }

    private loadComparisonsForAreas(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            const observables = [];

            _.each(this.comparisonStore.nativeScheduleAreas, (area: IScheduleArea) => {
                observables.push(this.getComparisonForArea(area));
            });

            if (observables.length === 0) {
                observer.next();
                observer.complete();
                return;
            }

            this.comparisonStore.inProcessLoadingComparisonForArea = true;

            forkJoin(observables)
                .pipe(
                    finalize(() => {
                        this.comparisonStore.inProcessLoadingComparisonForArea = false;
                    })
                )
                .subscribe((result: Array<IComparison[]>) => {
                    let i = 0;

                    _.each(this.comparisonStore.nativeScheduleAreas, (area: IScheduleArea) => {
                        area['comparison'] = result[i++];
                    });

                    observer.next();
                    observer.complete();
                });
        });
    }

    private getComparisonForArea(area: IScheduleArea): Observable<IComparison> {
        return new Observable((observer: Observer<IComparison>) => {
            const quoterIdsParam = this.getSelectedQuoterIds();

            if (quoterIdsParam.length === 0) {
                const comparison = {
                    quoters: [],
                    stageDTOs: []
                };

                observer.next(comparison);
                observer.complete();
                return;
            }

            this.projectApi.queryComparison(this.comparisonStore.project.id, quoterIdsParam, [area.id]).subscribe((res: HttpResponse<IComparison>) => {
                const comparison = res.body;

                observer.next(comparison);
                observer.complete();
            });
        });
    }

    private restoreExpandState(): void {
        switch (this.comparisonStore.filterState.groupBy) {
            case 'area':
                _.each(this.comparisonStore.filteredScheduleAreas, (area: IArea) => {
                    area.expanded = this.expandAreaGroupStorageService.retrieve(this.comparisonStore.project, area);
                });

                const collapsedArea = _.find(this.comparisonStore.filteredScheduleAreas, { expanded: false });
                this.comparisonStore.expandedAll = this.comparisonStore.expandedAll && collapsedArea == null;

                break;
            case 'stage':
                _.each(this.comparisonStore.comparison.stageDTOs, (stage: IComparisonStage) => {
                    stage.expanded = this.expandStagesStorageService.retrieve(this.comparisonStore.project, stage);
                });

                const collapsedStage = _.find(this.comparisonStore.comparison.stageDTOs, { expanded: false });
                this.comparisonStore.expandedAll = this.comparisonStore.expandedAll && collapsedStage == null;

                break;
        }
    }

    private getSelectedQuoters(): IComparisonQoter[] {
        return _.filter(this.comparisonStore.quoters, { selected: true });
    }

    private getSelectedQuoterIds(): number[] {
        return _.map(this.getSelectedQuoters(), 'id');
    }

    private updateExpandedAll(): void {
        const actualStages = this.getActualStages();
        const collapsedStage = _.find(actualStages, { expanded: false });
        this.comparisonStore.expandedAll = collapsedStage == null;

        if (this.comparisonStore.filterState.groupBy === 'area' || this.comparisonStore.filterState.groupBy === 'area_room') {
            const collapsedArea = _.find(this.comparisonStore.nativeScheduleAreas, { expanded: false });
            this.comparisonStore.expandedAll = this.comparisonStore.expandedAll && collapsedArea == null;
        }
    }

    private applyMainFilter(): void {
        const res: IMainFilterApplyingToComparisonResult = this.applyMainFilterToComparisonService.apply(
            this.getSelectedQuoters(),
            this.comparisonStore.nativeComparison,
            this.comparisonStore.nativeScheduleAreas,
            this.comparisonStore.filterState,
            this.comparisonStore.scheduleAreaItems,
            this.comparisonStore.stageItems,
            this.comparisonStore.cssElementItems
        )
        this.comparisonStore.filteredScheduleAreas = res.scheduleAreas;
        this.comparisonStore.comparison = res.comparison;
        this.comparisonStore.cssElementMap = res.cssElementMap;
    }
}
