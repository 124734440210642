import { Route } from '@angular/router';
import { TemplateWizardComponent } from 'app/flows/scheduler/template-wizard/template-wizard.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { ProjectResolve } from "app/shared/resolvers/project-resolve.service";

export const TEMPLATE_WIZARD_ROUTE: Route = {
    path: 'scheduler/template-wizard/:project_id',
    component: TemplateWizardComponent,
    resolve: {
        project: ProjectResolve
    },
    data: {
        authorities: ['ROLE_SCHEDULER'],
        pageTitle: 'Template wizard'
    },
    canActivate: [UserRouteAccessService]
};
