import { Component } from '@angular/core';
import { BUILD_TIMESTAMP, SERVER_BUILD_VERSION } from '../../app.constants';
import moment from 'moment';

@Component({
    selector: 'bp-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['footer.scss']
})
export class FooterComponent {
    version: String;
    buildDate: moment.Moment;

    constructor() {
        this.version = SERVER_BUILD_VERSION ? 'v' + SERVER_BUILD_VERSION : '';
        this.buildDate = moment(Number(BUILD_TIMESTAMP));
    }
}
