import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IProject } from 'app/shared/model/project.model';
import { ITemplateRequestData, TemplateWizardStore } from 'app/flows/scheduler/template-wizard/template-wizard.store';
import { TemplateWizardService } from 'app/flows/scheduler/template-wizard/template-wizard.service';

@Component({
    selector: 'bp-request-template-modal',
    templateUrl: './request-template-modal.component.html',
    styleUrls: ['request-template-modal.scss']
})
export class RequestTemplateModalComponent implements AfterViewInit {
    project: IProject;

    form: FormGroup = this.fb.group({
        templateName: new FormControl('', [Validators.required]),
        templateDescription: new FormControl('', [Validators.required]),
        proposedFloorPlanURLControl: new FormControl(null)
    });

    proposedFloorPlanFiles: File[] = [];

    submitted = false;

    @ViewChild('templateNameElement') templateNameElement: ElementRef;

    constructor(
        private alertService: BpAlertService,
        private templateWizardStore: TemplateWizardStore,
        private templateWizardService: TemplateWizardService,
        private activeModal: NgbActiveModal,
        private fb: FormBuilder,
    ) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.templateNameElement.nativeElement.focus({
                preventScroll: true
            });
        }, 0);
    }

    clear(): void {
        this.activeModal.dismiss('close');
    }

    onRequestTemplateClick(): void {
        const templateRequestData: ITemplateRequestData = {
            name: this.form.controls.templateName.value,
            description: this.form.controls.templateDescription.value,
            attachments: this.proposedFloorPlanFiles.map((file) => {
                return {
                    file,
                    additionalProperties: {
                        subtype: 'proposed',
                        url: this.form.controls.proposedFloorPlanURLControl.value || ''
                    }
                }
            })
        }

        if (this.project?.id != null || !templateRequestData.attachments?.length) {
            this.templateWizardService.sendTemplateRequest(this.project?.id, templateRequestData).then((res) => {
                if (res) {
                    this.alertService.success('Template request have been submitted');
                }
                this.activeModal.dismiss('close');
            });
        } else {
            this.alertService.success('Template request will be submitted at the end of the wizard');
            this.templateWizardStore.templateRequestDataArray.push(templateRequestData);
            this.activeModal.dismiss('close');
        }
    }
}
