import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class BulkModeStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    storeMode(projectId: number, value: boolean): void {
        this.webStorage.store(this.getModeLocalStorageKey(projectId), value);
    }

    retrieveMode(projectId: number): boolean {
        let result = this.webStorage.retrieve(this.getModeLocalStorageKey(projectId));
        if (result == null) {
            result = true;
        }
        return result;
    }

    storeDoNotShowAgain(projectId: number, value: boolean): void {
        this.webStorage.store(this.getDoNotShowAgainLocalStorageKey(projectId), value);
    }

    retrieveDoNotShowAgain(projectId: number): boolean {
        let result = this.webStorage.retrieve(this.getDoNotShowAgainLocalStorageKey(projectId));
        if (result == null) {
            result = false;
        }
        return result;
    }

    storeDoNotShowAgainForTheTask(projectId: number, taskId: number, value: boolean): void {
        this.webStorage.store(this.getDoNotShowAgainForTheTaskLocalStorageKey(projectId, taskId), value);
    }

    retrieveDoNotShowAgainForTheTask(projectId: number, taskId: number): boolean {
        return this.webStorage.retrieve(this.getDoNotShowAgainForTheTaskLocalStorageKey(projectId, taskId)) || false;
    }

    private getModeLocalStorageKey(projectId: number): string {
        return `scheduler_${this.accountService.getAccountId()}_bulk-mode_project_${projectId}`;
    }

    private getDoNotShowAgainLocalStorageKey(projectId: number): string {
        return `scheduler_${this.accountService.getAccountId()}_bulk-mode_do_not_show_again_project_${projectId}`;
    }

    private getDoNotShowAgainForTheTaskLocalStorageKey(projectId: number, taskId: number): string {
        return `scheduler_${this.accountService.getAccountId()}_bulk-mode_do_not_show_again_project_${projectId}_task_${taskId}`;
    }
}
