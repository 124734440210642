import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISpecification } from 'app/shared/model/specification.model';
import { SpecificationService } from 'app/shared/dataservices/specification.service';

@Component({
    selector: 'bp-spec-delete-dialog',
    templateUrl: './spec-delete-dialog.component.html'
})
export class SpecDeleteDialogComponent {
    spec: ISpecification;

    constructor(private specService: SpecificationService,
                public activeModal: NgbActiveModal) {
    }

    protected clear() {
        this.activeModal.dismiss('cancel');
    }

    protected confirmDelete(id: number) {
        this.specService.delete(id).subscribe(response => {
            this.activeModal.close(true);
        });
    }
}
