import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';

export interface QSRequestBody {
    typeOfService: string;
    comments?: string
}

@Injectable({ providedIn: 'root' })
export class QSService {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {}

    request(id: number, data: QSRequestBody): Observable<HttpResponse<void>> {
        const url = `${this.resourceUrl}/${id}/request-qs`;
        return this.http.put<void>(url, data, { observe: 'response' });
    }

    complete(id: number): Observable<HttpResponse<void>> {
        const url = `${this.resourceUrl}/${id}/complete-qs`;
        return this.http.put<void>(url, null, { observe: 'response' });
    }
}
