import { Component, Input } from '@angular/core';
import { IComparisonQoter } from 'app/shared/model/comparison.model';
import * as _ from 'lodash';

@Component({
    selector: 'bp-quoter-totals',
    templateUrl: './quoter-totals.component.html',
    styleUrls: ['quoter-totals.scss']
})
export class QuoterTotalsStagesComponent {
    @Input() quoters: IComparisonQoter[] = null;
    @Input() showTotals = false;

    selectedQoters(): IComparisonQoter[] {
        return _.filter(this.quoters, { selected: true });
    }
}
