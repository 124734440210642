<div class="af-row-container">
    <div class="row">
        <div class="col-sm-5">
        </div>
        <div class="col-sm-7 flex-container align-items-center flex-end gap-40">
            <h4 *ngFor="let quoter of selectedQoters()"
                class="flex-1 max-width-30-percents ellipsis-overflow-container-2-no-max-w">
                {{ quoter.company || quoter.email }}
            </h4>
        </div>
    </div>
</div>

<div class="af-row-container"
     *ngIf="showTotals">
    <div class="row">
        <div class="col-sm-5">
            <h3>
                Total
            </h3>
        </div>
        <div class="col-sm-7 flex-container align-items-center flex-end gap-40">
            <h4 *ngFor="let quoter of selectedQoters()"
                class="flex-1 max-width-30-percents ellipsis-overflow-container-2-no-max-w">
                {{ quoter.total || 0 | currency:'GBP' }}
            </h4>
        </div>
    </div>
</div>
