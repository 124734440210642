import { IElement } from './element.model';
import { ITask } from './task.model';
import { IArea } from 'app/shared/model/area.model';

export interface IStage {
    id?: number;
    stage?: string;
    stageOrder?: number;
    elements?: IElement[];
    tasks?: ITask[];
    area?: string;
    scheduleAreaId?: number;
    scheduleAreaIds?: number[];
    referenceNumber?: string;
    _areas?: IArea[];
    total?: number;
    _quoterTotals?: any;
}

export class Stage implements IStage {
    constructor(
        public id?: number,
        public stage?: string,
        public stageOrder?: number,
        public elements?: IElement[],
        public tasks?: ITask[],
        public area?: string,
        public scheduleAreaId?: number,
        public expanded?: boolean,
        public scheduleAreaIds?: number[],
        public total?: number,
        public _quoterTotals?: any
    ) {}
}
