import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AreaComponent } from './area.component';
import { AreaDetailComponent } from './area-detail.component';
import { AreaUpdateComponent } from './area-update.component';
import { Area, IArea } from 'app/shared/model/area.model';
import { AreaService } from 'app/shared/dataservices/area.service';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class AreaResolve implements Resolve<IArea> {
    constructor(private service: AreaService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Area> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<Area>) => response.ok),
                map((area: HttpResponse<Area>) => area.body)
            );
        }
        return of(new Area());
    }
}

export const areaRoute: Routes = [
    {
        path: 'area',
        component: AreaComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Areas'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'area/:id/view',
        component: AreaDetailComponent,
        resolve: {
            area: AreaResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Areas'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'area/new',
        component: AreaUpdateComponent,
        resolve: {
            area: AreaResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Areas'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'area/:id/edit',
        component: AreaUpdateComponent,
        resolve: {
            area: AreaResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Areas'
        },
        canActivate: [UserRouteAccessService]
    }
];
