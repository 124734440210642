import { IProjectAttachmentAdditionProperties } from "app/shared/model/project-attachment.model";
import { Injectable } from "@angular/core";

export interface ITemplateRequestData {
    name?: string;
    description?: string;

    attachments: {
        file: File,
        additionalProperties: IProjectAttachmentAdditionProperties;
    }[];
}

@Injectable({ providedIn: 'root' })
export class TemplateWizardStore {
    templateRequestDataArray: ITemplateRequestData[];

    constructor(
    ) {
    }

    init(): void {
        this.templateRequestDataArray = [];
    }
}
