import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IProject } from 'app/shared/model/project.model';
import { LabourRatesModalComponent } from "app/flows/quoter/components/labour-rates-modal/labour-rates-modal.component";

@Injectable({ providedIn: 'root' })
export class LabourRatesModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    open(project: IProject): NgbModalRef {
        this.ngbModalRef = this.modalService.open(LabourRatesModalComponent as Component, {
            windowClass: 'bp-modal fit-content labour-rates blue-modal',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
