<div class="container-fluid">
    <div class="sticky-button-container">
        <ng-container *ngIf="isAdmin">
            <button (confirm)="isGlobal = true; submitFormButton.click()"
                    (cancel)="onCancel($event, submitFormButton)"
                    [swal]="{
                        title: 'Would you like to update ALL quoters database or only default quoters?',
                        text: '(This will take time)',
                        icon: 'warning',
                        showCancelButton: true,
                        customClass: {
                           confirmButton: 'btn btn-primary btn-border-radius waves-effect m-r-10',
                           cancelButton: 'btn btn-danger btn-border-radius waves-effect'
                        },
                        confirmButtonText: 'Yes, update all',
                        cancelButtonText: 'No, update default only'
                }"
                    ngbTooltip="Save"
                    class="btn btn-primary btn-border-radius save"
                    type="button">
                Save
            </button>
        </ng-container>

        <ng-container *ngIf="!isAdmin">
            <button (click)="isGlobal = false; submitFormButton.click()"
                    ngbTooltip="Save"
                    class="btn btn-primary btn-border-radius save"
                    type="button">
                Save
            </button>
        </ng-container>
    </div>

    <div class="row">
        <div class="col-lg-8 col-md-6">
            <h1>
                Default labour rates
                <a [swal]="{
                            html: defaultLabourRatesHelpText,
                            showCloseButton: true,
                            customClass: {
                                confirmButton: 'btn btn-primary btn-border-radius waves-effect'
                            },
                            confirmButtonText: 'Close'
                       }">
                    <i class="material-icons">info_outline</i>
                </a>
            </h1>
        </div>
        <div class="col-lg-4 col-md-6">
            <button (click)="setToDefaults()"
                    class="btn btn-secondary btn-border-radius m-r-10"
                    type="button">
                Reset to defaults
            </button>
        </div>
    </div>

    <div class="card materials m-t-20">
        <div class="header">
            <div class="row">
                <div class="col-sm-6">
                    <h4>
                        Margin
                    </h4>
                </div>
                <div class="offset-sm-2 col-sm-2">
                    <div class="form-group percent">
                        <div class="form-line">
                            <div class="flex-line">
                                <input [(ngModel)]="dataModel.margin"
                                       class="af-input text-right"
                                       placeholder="Enter margin"
                                       required
                                       mask="separator.2"
                                       [dropSpecialCharacters]="true"
                                       suffix="%"
                                       thousandSeparator=","
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 text-right">
                    <button (click)="applyMarginToAll()"
                            [disabled]="!dataModel.isMarginValid()"
                            class="btn btn-primary btn-small btn-border-radius"
                            ngbTooltip="Apply margin to all"
                            type="button">
                        Apply to All
                    </button>
                </div>
            </div>
        </div>

        <div class="horizontal-line"></div>

        <div class="header m-t-20">
            <div class="row">
                <div class="col-sm-5">
                    <h4>
                        Labour
                    </h4>
                </div>
            </div>

            <div class="row m-t-20">
                <div class="col-sm-3">
                    <span class="table-title">Type</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Cost/hr</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Cost/day</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Margin</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Rate/day</span>
                </div>
                <div class="col-sm-1 text-right">
                    <span class="table-title"></span>
                </div>
            </div>

            <form #f="ngForm"
                  (ngSubmit)="!f.invalid && saveAndApply()"
                  class="labour-container m-t-20"
                  name="form"
                  novalidate>
                <button #submitFormButton
                        id="submitFormButton"
                        type="submit">
                </button>

                <div *ngFor="let labour of dataModel.labours; trackBy: trackId;"
                     class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <div class="form-line">
                                <input [(ngModel)]="labour.trade"
                                       [disabled]="true"
                                       class="af-input"
                                       name="trade_{{labour.tradeId}}"
                                       type="text">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group">
                            <div class="form-line">
                                <div class="flex-line">
                                    <input #cost="ngModel"
                                           [(ngModel)]="labour.cost"
                                           [ngClass]="{ 'error': (f.submitted || cost.dirty || cost.touched) && cost.invalid }"
                                           class="af-input text-right"
                                           name="cost_{{labour.tradeId}}"
                                           placeholder="Enter cost/hr"
                                           required
                                           mask="separator.2"
                                           thousandSeparator=","
                                           [dropSpecialCharacters]="true"
                                           prefix="&pound;&nbsp;"
                                           type="text">
                                </div>
                                <div *ngIf="(f.submitted || cost.dirty) && cost.invalid">
                                    <label *ngIf="cost.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group">
                            <div class="form-line">
                                <div class="flex-line">
                                    <input #costPerDay="ngModel"
                                           [(ngModel)]="labour.costPerDay"
                                           [ngClass]="{ 'error': (f.submitted || costPerDay.dirty || costPerDay.touched) && costPerDay.invalid }"
                                           class="af-input text-right"
                                           name="costPerDay_{{labour.tradeId}}"
                                           placeholder="Enter cost/day"
                                           required
                                           mask="separator.2"
                                           [dropSpecialCharacters]="true"
                                           thousandSeparator=","
                                           prefix="&pound;&nbsp;"
                                           type="text">
                                </div>
                                <div *ngIf="(f.submitted || costPerDay.dirty) && costPerDay.invalid">
                                    <label *ngIf="costPerDay.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group percent">
                            <div class="form-line">
                                <div class="flex-line">
                                    <input #margin="ngModel"
                                           [(ngModel)]="labour.margin"
                                           [ngClass]="{ 'error': (f.submitted || margin.dirty || margin.touched) && margin.invalid }"
                                           class="af-input text-right"
                                           name="margin_{{labour.tradeId}}"
                                           placeholder="Enter margin"
                                           required
                                           mask="separator.2"
                                           [dropSpecialCharacters]="true"
                                           suffix="%"
                                           thousandSeparator=","
                                           type="text">
                                </div>
                                <div *ngIf="(f.submitted || margin.dirty) && margin.invalid">
                                    <label *ngIf="margin.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group">
                            <div class="form-line">
                                <div class="flex-line">
                                    <input [(ngModel)]="labour.ratePerDay"
                                           class="af-input text-right"
                                           name="rate_{{labour.tradeId}}"
                                           mask="separator.2"
                                           [dropSpecialCharacters]="true"
                                           thousandSeparator=","
                                           prefix="&pound;&nbsp;"
                                           type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1 text-right">
                        <button (click)="saveOneLabour(labour)"
                                [disabled]="!labour.updated"
                                ngbTooltip="Apply"
                                class="btn btn-primary btn-border-radius apply"
                                type="button">
                            Apply
                        </button>
                    </div>
                </div>

                <small *ngIf="dataModel?.labours.length === 0">
                    No items
                </small>
            </form>
        </div>
    </div>
</div>
