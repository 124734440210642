import { Route } from '@angular/router';
import { BpSingUpLightComponent } from "app/account/sign-up-light/sign-up-light.component";

export const signUpLightRoute: Route = {
    path: 'sign-up',
    component: BpSingUpLightComponent,
    data: {
        authorities: [],
        pageTitle: 'Sign Up'
    }
};
