import { Route } from '@angular/router';
import { SignUpTrackingPageComponent } from './sign-up-tracking-page.component';

export const signUpTrackingPageRoute: Route = {
    path: 'sign-up-tracking-page',
    component: SignUpTrackingPageComponent,
    data: {
        authorities: [],
        pageTitle: 'Redirecting...'
    }
};
