import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ProdConfig } from './blocks/config/prod.config';
import { BpFrontendAppModule } from './app.module';
import { ENVIRONMENT, SERVER_API_URL, SERVER_BUILD_VERSION } from './app.constants';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

ProdConfig();

platformBrowserDynamic()
    .bootstrapModule(BpFrontendAppModule, { preserveWhitespaces: true })
    .then(success => console.log(`Application started, backend url: ` + SERVER_API_URL))
    .catch(err => console.error(err));

console.log(`Environment: ${ENVIRONMENT}, SERVER_BUILD_VERSION: ${SERVER_BUILD_VERSION}, Server API URL: ${SERVER_API_URL}`);

let SENTRY_TRACES_SAMPLE_RATE = 1.0;

switch (ENVIRONMENT) {
    case 'stage':
        SENTRY_TRACES_SAMPLE_RATE = 1.0;
        initSentry();
        break;
    case 'prod':
        SENTRY_TRACES_SAMPLE_RATE = 0.2;
        addTrackingScriptForMautic();
        initSentry();
        break;
    default:
        break;
}

function initSentry() {
    Sentry.init({
        dsn: "https://825fcfc65a8b4d948e47f9d6780f5522@o257679.ingest.sentry.io/4505398900293632",

        // To set a uniform sample rate
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        integrations: [new Integrations.BrowserTracing()]
    });
}

function addTrackingScriptForMautic() {
    const scriptHeadCode = `(function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)})(window,document,'script','https://mautic.buildpartner.com/mtc.js','mt'); window.mt = mt`;
    const scriptHead = document.createElement('script');
    scriptHead.type = 'text/javascript';
    scriptHead.appendChild(document.createTextNode(scriptHeadCode));
    document.getElementsByTagName('head')[0].insertAdjacentElement('afterbegin', scriptHead);
}
