import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bp-estimators-working-on-project-modal',
    templateUrl: './thanks-for-submitting-your-project-modal.component.html',
    styleUrls: ['thanks-for-submitting-your-project-modal.scss']
})
export class ThanksForSubmittingYourProjectModalComponent {

    constructor(
        public activeModal: NgbActiveModal,
    ) {
    }

    onOkClick(): void {
        this.activeModal.close(true);
    }

    clear(): void {
        this.activeModal.close(true);
    }
}
