<form (ngSubmit)="addMaterial()"
      name="addMaterialForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Add Material
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body m-b-20">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-line">
                    <input [(ngModel)]="materialName"
                           class="af-input"
                           id="material-name"
                           name="material-name"
                           placeholder="Enter material name"
                           type="text"/>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="flex-line has-units unit-price-container">
                    <span class="m-l-10 m-r-10">
                        {{ unit }}
                    </span>
                    <input [(ngModel)]="defaultPrice"
                           autocomplete="off"
                           class="af-input"
                           id="default-price"
                           name="default-price"
                           allowNegativeNumbers="true"
                           mask="separator.2"
                           thousandSeparator=","
                           placeholder="Enter default price"
                           type="text"/>
                    <span class="pound-symbol">&pound;</span>
                </div>
            </div>
            <div class="col-lg-2 text-left">
                <div class="form-check">
                    <label class="form-check-label builder-supplied"
                           for="builder-supplied">
                        Builder<br>supplied
                        <input [(ngModel)]="builderSupplied"
                               class="form-check-input"
                               id="builder-supplied"
                               name="builder-supplied"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-line">
                    <input [(ngModel)]="referenceUrl"
                           class="af-input"
                           id="material-referenceUrl"
                           name="material-referenceUrl"
                           placeholder="Enter reference url"
                           type="text"/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="clear()"
                class="btn btn-secondary btn-border-radius"
                data-dismiss="modal"
                type="button">
            Cancel
        </button>
        <button [disabled]="isFormValid()"
                class="btn btn-primary btn-border-radius waves-effect add-material"
                type="submit">
            Add Material
        </button>
    </div>
</form>
