import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IProject } from 'app/shared/model/project.model';
import {
    SelectPaymentProviderQuoterModalComponent
} from 'app/shared/components/common/select-payment-provider-quoter-modal/select-payment-provider-quoter-modal.component';

@Injectable({ providedIn: 'root' })
export class SelectPaymentProviderQuoterModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    open(project: IProject, total: number): NgbModalRef {
        this.ngbModalRef = this.modalService.open(SelectPaymentProviderQuoterModalComponent as Component, {
            windowClass: 'bp-modal',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.project = project;
        this.ngbModalRef.componentInstance.total = total;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
