import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import * as _ from 'lodash';
import {
    AddGroupOfScheduleTasksModalService
} from 'app/flows/scheduler/schedule/components/add-group-of-schedule-tasks-modal/add-group-of-schedule-tasks-modal.service';
import { IMainViewFilterState } from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
    SHOW_SUB_STAGES_MODES,
    ShowSubStagesMode,
    ShowSubStagesModeInfo
} from 'app/shared/constants/show-substages-modes';
import {
    ShowSubstagesModeSelectorComponent
} from 'app/shared/components/common/show-substages-mode-selector/show-substages-mode-selector.component';
import { PopperContent } from 'ngx-popper';
import { EXPORT_TYPES, ExportType } from 'app/shared/constants/export-types';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { AccountHelperService } from 'app/shared/services/account-helper.service';
import { SpecificMarginActionInfo } from 'app/shared/constants/specific-margin-actions';
import { LabourRatesModalService } from 'app/flows/quoter/components/labour-rates-modal/labour-rates-modal.service';
import {
    MaterialRatesModalService
} from 'app/flows/quoter/components/material-rates-modal/material-rates-modal.service';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IAddScheduleTaskRequest } from 'app/shared/constants/events.constants';
import { INewTaskRequestCreationDTO } from 'app/shared/model/new-task-request.model';
import { HttpResponse } from '@angular/common/http';
import { ITask } from 'app/shared/model/task.model';
import {
    TaskRequestModalService
} from 'app/flows/scheduler/schedule/components/task-request-modal/task-request-modal.service';
import { NewTaskRequestApi } from 'app/shared/dataservices/new-task-request.api';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { QuoterService } from 'app/shared/dataservices/quoter.service';

const PLEASE_WAIT_MESSAGE = 'Please wait...';

/**
 * Main Schedule Component
 */
@Component({
    selector: 'bp-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['schedule.scss']
})
export class ScheduleComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    @ViewChild('bpShowSubStagesModeSelector') bpShowSubStagesModeSelector: PopperContent;
    @ViewChild(ShowSubstagesModeSelectorComponent) showSubstagesModeSelectorComponent: ShowSubstagesModeSelectorComponent;
    @ViewChild(PopperContent) bpExportSelector: PopperContent;

    protected MENU_TOOLTIP_OPEN_DELAY = 100;

    protected routeDataSub = Subscription.EMPTY;

    protected searchControl = new FormControl();

    get showSubstageModeInfo(): ShowSubStagesModeInfo {
        return SHOW_SUB_STAGES_MODES.find((ssm: ShowSubStagesModeInfo) => ssm.id === this.scheduleService.showSubStagesMode);
    }

    get showRatesSelector(): boolean {
        return this.accountHelper.isIndividualQuoterTheSameAsScheduler(this.scheduleService.project);
    }

    constructor(
        public scheduleService: ScheduleService,
        private scheduleEventsService: ScheduleEventsService,
        public accountHelper: AccountHelperService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private alertService: BpAlertService,
        private quoterService: QuoterService,
        private addGroupOfScheduleTasksModalService: AddGroupOfScheduleTasksModalService,
        private labourRatesModalService: LabourRatesModalService,
        private materialRatesModalService: MaterialRatesModalService,
        private freemiumModalService: FreemiumModalService,
        private taskRequestModalService: TaskRequestModalService,
        private newTaskRequestApi: NewTaskRequestApi
    ) {
        this.routeDataSub = this.activatedRoute.data.subscribe(data => {
            this.scheduleService.project = data.project;

            if (!this.scheduleService.isAdmin && this.scheduleService.project.defaultQuoter.isSelfQuoter && !this.scheduleService.project.viewedRates) {
                this.quoterService.setViewedRates(this.scheduleService.project.id).subscribe();
                this.labourRatesModalService.open(this.scheduleService.project).result.then(() => {
                    this.scheduleService.reloadAllSavingCurrentScrollPosition();
                });
            }
        });
    }

    ngOnInit(): void {
        this.scheduleService.init();
        this.searchControl.setValue(this.scheduleService.searchKey);
    }

    ngOnDestroy(): void {
        this.routeDataSub.unsubscribe();
        this.scheduleService.destroy();
    }

    protected search(): void {
        this.scheduleService.searchKey = this.searchControl.value;
    }

    protected clearSearch(): void {
        this.searchControl.setValue(null);
        this.scheduleService.searchKey = null;
    }

    protected onMainViewFilterChangedFunc(newFilterState: IMainViewFilterState): void {
        this.scheduleService.filterState = newFilterState;
    }

    protected onSaveButtonClick(): void {
        this.scheduleService.globalSave(PLEASE_WAIT_MESSAGE);
    }

    protected onAddGroupOfScheduleTasksClick(): void {
        const areas = _.clone(this.scheduleService.nativeScheduleAreas);
        this.addGroupOfScheduleTasksModalService.open(this.scheduleService.project, areas, null).result.then(res => {
            if (res.newTaskRequest) {
                this.taskRequestModalService.open(res.newTaskRequest).result.then((updatedData: INewTaskRequestCreationDTO) => {
                    this.newTaskRequestApi.newTask(updatedData).subscribe((res: HttpResponse<ITask>) => {
                        const task = res.body;
                        if (!task) {
                            return;
                        }

                        const stage = this.scheduleService.allStages.find(stage => stage.id === task.stageId);
                        let taskStage = this.scheduleService.stages.find((s) => s.id === stage.id);

                        if (!taskStage) {
                            this.scheduleService.stages.push(Object.assign({}, stage));
                            taskStage = this.scheduleService.stages.find((s) => s.id === stage.id);
                        }

                        this.scheduleService.expandStage(taskStage.id, null, true);

                        const request: IAddScheduleTaskRequest = {
                            area: null,
                            stage: taskStage,
                            element: taskStage.elements.find(el => el.id === task.elementId),
                            task
                        };

                        this.scheduleEventsService.addScheduleTask(request);
                    });
                })
            }
        });
    }

    protected onShowSubStagesModeChanged(newShowSubStagesMode: ShowSubStagesMode): void {
        this.bpShowSubStagesModeSelector.hide();
        this.scheduleService.showSubStagesMode = newShowSubStagesMode;
    }

    protected onProjectSpecificMarginSelector(action: SpecificMarginActionInfo): void {
        switch (action.id) {
            case 'labour-rates':
                this.labourRatesModalService.open(this.scheduleService.project).result.then(() => {
                    this.scheduleService.reloadAllSavingCurrentScrollPosition();
                });
                break;
            case 'material-rates':
                this.materialRatesModalService.open(this.scheduleService.project).result.then(() => {
                    this.scheduleService.reloadAllSavingCurrentScrollPosition();
                });
                break;
            case 'tasks':
                this.router.navigate(['quoter', 'quote', this.scheduleService.project.id]);
                break;
        }
    }

    protected onExportTypeSelected(exportType: ExportType): void {
        this.bpExportSelector.hide();

        this.freemiumModalService.verify('export').then((res) => {
            if (res) {
                this.scheduleService.exportAs(exportType).then((res2) => {
                    if (res2) {
                        this.alertService.success(`Your ${EXPORT_TYPES.find(et => et.id === exportType).label} document will be downloaded shortly. Please wait.`, 10000);
                    }
                });
            }
        })
    }

    protected toggleExpand(): void {
        this.scheduleService.toggleExpandAll();
    }

    protected onBulkUpdateClick(): void {
        this.scheduleService.bulkUpdateMode = !this.scheduleService.bulkUpdateMode;
    }
}
