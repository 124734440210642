import { Component, Input } from '@angular/core';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';

@Component({
    selector: 'bp-individual-quote-element',
    templateUrl: './element.component.html'
})
export class IndividualQuoteElementComponent {
    @Input() element: IComparisonElement;
}
