import { ActivatedRouteSnapshot, Resolve, Route, RouterStateSnapshot } from '@angular/router';
import { ScheduleComponent } from './schedule/schedule.component';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IScheduleArea, ScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    ScheduleTaskDetailsComponent
} from './schedule/components/schedule-task-details/schedule-task-details.component';
import { IScheduleTask, ScheduleTask } from 'app/shared/model/schedule-task.model';
import { CreateProjectComponent } from './create-project/create-project.component';
import { ProjectsComponent } from './projects/projects.component';
import { ScheduleTaskApi } from 'app/shared/dataservices/schedule-task.api';
import { ScheduleAreaApi } from 'app/shared/dataservices/schedule-area.api';
import { IndividualQuoteComponent } from './individual-quote/individual-quote.component';
import { ComparisonComponent } from './comparison/comparison.component';
import { EditScheduleAreasComponent } from './schedule/components/edit-schedule-areas/edit-schedule-areas.component';
import { ScheduleCanDeactivateGuard } from 'app/flows/scheduler/guards/schedule-can-deactivate-quard.service';
import * as _ from 'lodash';
import {
    EditScheduleAreasCanDeactivateGuard
} from 'app/flows/scheduler/guards/edit-schedule-areas-can-deactivate-quard.service';
import { SchedulerQuotesComponent } from 'app/flows/scheduler/quotes/quotes.component';
import { ProjectDetailsComponent } from 'app/flows/scheduler/project-details/project-details.component';
import { SchedulerTeamComponent } from 'app/flows/scheduler/team/team.page';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { ProjectResolve } from 'app/shared/resolvers/project-resolve.service';
import { DashboardComponent } from 'app/flows/scheduler/dashboard/dashboard.component';
import {
    ProjectDetailsCanDeactivateGuard
} from 'app/flows/scheduler/guards/project-info-can-deactivate-quard.service';
import { ScopesPage } from 'app/flows/scheduler/scopes/scopes.page';
import { ValuationsPage } from 'app/flows/scheduler/valuations/valuations.page';
import { INewTaskRequest } from "app/shared/model/new-task-request.model";
import { NewTaskRequestApi } from "app/shared/dataservices/new-task-request.api";

@Injectable({ providedIn: 'root' })
export class ScheduleTaskResolve implements Resolve<IScheduleTask> {
    constructor(private _scheduleTaskApi: ScheduleTaskApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleTask> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this._scheduleTaskApi.find(id).pipe(
                filter((response: HttpResponse<ScheduleTask>) => response.ok),
                map((scheduleArea: HttpResponse<ScheduleTask>) => scheduleArea.body)
            );
        }
        return of(new ScheduleTask());
    }
}

@Injectable({ providedIn: 'root' })
export class NewTaskRequestResolve implements Resolve<INewTaskRequest> {
    constructor(private _newTaskRequestApi: NewTaskRequestApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<INewTaskRequest | null> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this._newTaskRequestApi.get(id).pipe(
                filter((response: HttpResponse<INewTaskRequest>) => response.ok),
                map((scheduleArea: HttpResponse<INewTaskRequest>) => scheduleArea.body)
            );
        }
        return of(null);
    }
}

@Injectable({ providedIn: 'root' })
export class ScheduleAreaResolve implements Resolve<IScheduleArea> {
    constructor(private service: ScheduleAreaApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleArea> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<ScheduleArea>) => response.ok),
                map((scheduleArea: HttpResponse<ScheduleArea>) => scheduleArea.body)
            );
        }
        return of(new ScheduleArea());
    }
}

@Injectable({ providedIn: 'root' })
export class QuoterIdResolve implements Resolve<number> {
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
        return route.params['quoter_id'] ? route.params['quoter_id'] : null;
    }
}

@Injectable({ providedIn: 'root' })
export class QuoterIdsResolve implements Resolve<number[]> {
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number[]> {
        const quoterIds = route.params['quoter_ids'] ? route.params['quoter_ids'] : null;
        if (quoterIds) {
            return _.map(quoterIds.split(','), item => +item);
        } else {
            return null;
        }
    }
}

export const SCHEDULER_ROUTE: Route = {
    path: 'scheduler',
    data: {
        authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
        pageTitle: 'Scheduler'
    },
    canActivate: [UserRouteAccessService],
    children: [
        {
            path: 'projects',
            component: ProjectsComponent,
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Projects'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'new-project',
            component: CreateProjectComponent,
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'New Project'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'project-details/:project_id',
            component: ProjectDetailsComponent,
            canDeactivate: [ProjectDetailsCanDeactivateGuard],
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Project Details'
            },
            resolve: {
                project: ProjectResolve
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'scope/:project_id',
            component: ScopesPage,
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Scopes'
            },
            resolve: {
                project: ProjectResolve
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'valuations/:project_id',
            component: ValuationsPage,
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Valuations'
            },
            resolve: {
                project: ProjectResolve
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'dashboard/:project_id',
            component: DashboardComponent,
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Dashboard'
            },
            resolve: {
                project: ProjectResolve
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'quotes/:project_id',
            component: SchedulerQuotesComponent,
            resolve: {
                project: ProjectResolve
            },
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Quotes'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'team/:project_id',
            component: SchedulerTeamComponent,
            resolve: {
                project: ProjectResolve
            },
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Project Team'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'schedule/:project_id',
            component: ScheduleComponent,
            resolve: {
                project: ProjectResolve
            },
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Schedule'
            },
            canActivate: [UserRouteAccessService],
            canDeactivate: [ScheduleCanDeactivateGuard]
        },
        {
            path: 'schedule-task-details/:project_id/:id',
            component: ScheduleTaskDetailsComponent,
            resolve: {
                project: ProjectResolve,
                scheduleTask: ScheduleTaskResolve
            },
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Schedule Task details'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'edit-schedule-areas/:project_id',
            component: EditScheduleAreasComponent,
            resolve: {
                project: ProjectResolve
            },
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Edit Areas'
            },
            canDeactivate: [EditScheduleAreasCanDeactivateGuard],
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'individual-quote/:project_id/:quoter_id',
            component: IndividualQuoteComponent,
            resolve: {
                project: ProjectResolve,
                quoterId: QuoterIdResolve
            },
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Individual Quote'
            },
            canActivate: [UserRouteAccessService]
        },
        {
            path: 'comparison/:project_id/:quoter_ids',
            component: ComparisonComponent,
            resolve: {
                project: ProjectResolve,
                quoterIds: QuoterIdsResolve
            },
            data: {
                authorities: ['ROLE_SCHEDULER', 'ROLE_HOMEOWNER'],
                pageTitle: 'Quote Comparison'
            },
            canActivate: [UserRouteAccessService]
        }
    ]
};
