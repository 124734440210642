import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAccount } from 'app/shared/model/account.model';
import { PaymentProvider } from 'app/shared/model/payment-provider.model';
import { SetupGocardlessService } from 'app/shared/services/payment/setup-gocardless.service';
import { SetupStripeService } from 'app/shared/services/payment/setup-stripe.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { PAYMENT_TERMS_URL_QUOTER } from 'app/shared/constants/links.constants';
import { IProject } from 'app/shared/model/project.model';
import { from, Subject, timer } from 'rxjs';
import { concatMap, filter, take, takeUntil } from 'rxjs/operators';
import { PaymentService, PaymentStatus } from 'app/shared/dataservices/payment.service';
import { AccountService } from 'app/core/auth/account.service';

export interface SelectPaymentProviderQuoterModalResult {
    paymentProvider: PaymentProvider;
    error?: string;
}

@Component({
    selector: 'select-payment-provider-quoter-modal',
    templateUrl: './select-payment-provider-quoter-modal.component.html',
    styleUrls: ['select-payment-provider-quoter-modal.scss']
})
export class SelectPaymentProviderQuoterModalComponent implements OnInit, OnDestroy {
    project: IProject;
    total: number;
    account: IAccount;
    selectedPaymentProvider: PaymentProvider;
    isSelectedPaymentProviderSetup = false;
    isWaitingForStripePaymentInProcess = false;

    PAYMENT_TERMS_URL = PAYMENT_TERMS_URL_QUOTER;

    private onStripeStatusPollingFinished$ = new Subject<PaymentStatus>();
    private onDestroy$ = new Subject<void>();

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    constructor(
        public activeModal: NgbActiveModal,
        private setupGocardlessService: SetupGocardlessService,
        private setupStripeService: SetupStripeService,
        private accountService: AccountService,
        private paymentService: PaymentService
    ) {}

    ngOnInit(): void {
        this.refreshAccount();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    inProcess(): boolean {
        return this.isWaitingForStripePaymentInProcess;
    }

    onChangeSelectedPaymentProvider(): void {
        this.isSelectedPaymentProviderSetup = this.isPaymentProviderSetup(this.selectedPaymentProvider);
    }

    onOkClick(): void {
        const result: SelectPaymentProviderQuoterModalResult = {
            paymentProvider: this.selectedPaymentProvider
        };

        switch (this.selectedPaymentProvider) {
            case 'GOCARDLESS':
                this.activeModal.close(result);
                break;
            case 'STRIPE':
                this.setupStripeService.setup(this.project.id, this.total).subscribe((sessionId: string) => {
                    this.onStripeStatusPollingFinished$.subscribe((stripePaymentStatus: PaymentStatus) => {
                        switch (stripePaymentStatus) {
                            case 'PENDING':
                            case 'CREATED':
                            case 'SUCCESS':
                                this.activeModal.close(result);
                                break;
                            case 'FAILED':
                                result.error = 'Error during processing the stripe payment. Please contact Administrator';
                                this.activeModal.close(result);
                                break;
                            default: {
                                console.log(`Weird payment status: ${stripePaymentStatus}.`);
                            }
                        }
                    });

                    this.startStripeStatusLongPolling(sessionId);
                });
                break;
        }
    }

    onSetupButtonClick(): void {
        switch (this.selectedPaymentProvider) {
            case 'GOCARDLESS':
                this.setupGocardlessService.setup().subscribe(() => {
                    this.refreshAccount();
                });
                break;
            case 'STRIPE':
                /**
                 nothing to do as we do not show this button for stripe as stripe in already setup
                 */
                break;
            default:
                console.log(`Unknown payment provider: ${this.selectedPaymentProvider}`);
                break;
        }
    }

    clear(): void {
        this.activeModal.dismiss('close');
    }

    isPaymentProviderSetup(paymentProvider: PaymentProvider): boolean {
        return (this.account.paymentProviders || []).includes(paymentProvider);
    }

    okButtonLabel(): string {
        switch (this.selectedPaymentProvider) {
            case 'GOCARDLESS':
                return 'Ok';
            case 'STRIPE':
                return this.isWaitingForStripePaymentInProcess ? 'Waiting for payment..' : 'Make Payment';
            default:
                return 'Ok';
        }
    }

    private refreshAccount(): void {
        this.accountService.identity(true).then(account => {
            this.account = account;

            if (this.selectedPaymentProvider != null) {
                this.isSelectedPaymentProviderSetup = this.isPaymentProviderSetup(this.selectedPaymentProvider);
            }

            if (this.ngSelectComponent) {
                this.ngSelectComponent.detectChanges();
            }
        });
    }

    private startStripeStatusLongPolling(sessionId: string): void {
        this.isWaitingForStripePaymentInProcess = true;

        timer(0, 1000)
            .pipe(concatMap(() => from(this.paymentService.retrieveStripeStatus(sessionId))))
            .pipe(
                filter((res: PaymentStatus) => {
                    const pollingShouldBeFinished = ['SUCCESS', 'FAILED', 'PENDING'].indexOf(res) !== -1;
                    if (pollingShouldBeFinished) {
                        this.onStripeStatusPollingFinished$.next(res);
                        this.onStripeStatusPollingFinished$.complete();
                    }
                    return pollingShouldBeFinished;
                })
            )
            .pipe(take(1))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
    }
}
