import { AfterContentInit, ContentChild, Directive, Host, HostListener, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { BpSortDirective } from 'app/shared/directives/bp-sort-directive.directive';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

@Directive({
    selector: '[bpSortBy]',
})
export class BpSortByDirective implements AfterContentInit {
    @Input() bpSortBy: string;
    @ContentChild(FaIconComponent, { static: true }) iconComponent: FaIconComponent;

    sortIcon: IconDefinition;
    sortAscIcon: IconDefinition;
    sortDescIcon: IconDefinition;

    constructor(@Host() private bpSort: BpSortDirective) {
        this.bpSort = bpSort;
        this.sortIcon = faSort;
        this.sortAscIcon = faSortUp;
        this.sortDescIcon = faSortDown;
    }

    ngAfterContentInit(): void {
        if (this.bpSort.predicate && this.bpSort.predicate !== '_score' && this.bpSort.predicate === this.bpSortBy) {
            this.updateIconDefinition(this.iconComponent, this.bpSort.ascending ? this.sortAscIcon : this.sortDescIcon);
            this.bpSort.activeIconComponent = this.iconComponent;
        }
    }

    @HostListener('click')
    onClick() {
        if (this.bpSort.predicate && this.bpSort.predicate !== '_score') {
            this.bpSort.sort(this.bpSortBy);
            this.updateIconDefinition(this.bpSort.activeIconComponent, this.sortIcon);
            this.updateIconDefinition(this.iconComponent, this.bpSort.ascending ? this.sortAscIcon : this.sortDescIcon);
            this.bpSort.activeIconComponent = this.iconComponent;
        }
    }

    private updateIconDefinition(iconComponent: FaIconComponent, icon: IconDefinition) {
        if (iconComponent) {
            iconComponent.icon = icon.iconName;
            iconComponent.render();
        }
    }
}
