<div class="select-container">
    <div class="select-container__values-container">
        <div class="select-container__values-container__option cursor-pointer"
             *ngFor="let exportType of exportTypes;">
            <div (click)="select(exportType.id)">
                {{ exportType.label }}
            </div>
        </div>
    </div>
</div>
