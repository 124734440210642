import { Injectable } from '@angular/core';
import {
    HowToCreateProjectType
} from 'app/shared/components/projects/how-to-create-project-modal/how-to-create-project-modal.component';
import { IEstimatingAddon } from 'app/shared/model/bp.model';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import {
    HowToCreateProjectModalService
} from 'app/shared/components/projects/how-to-create-project-modal/how-to-create-project-modal.service';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EstimatingAddonService } from 'app/shared/dataservices/estimating-addon.service';
import {
    ServiceSelectorModalService
} from 'app/shared/components/projects/service-selector-modal/service-selector-modal.service';

@Injectable()
export class AddNewProjectService {

    constructor(
        private howToCreateProjectModalService: HowToCreateProjectModalService,
        private freemiumModalService: FreemiumModalService,
        private topMenuStateService: TopMenuStateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private estimatingAddonService: EstimatingAddonService,
        private serviceSelectorModalService: ServiceSelectorModalService) {
    }

    public addNew(): void {
        this.freemiumModalService.verify('create_project').then((allowed) => {
            if (allowed) {
                this.howToCreateProjectModalService.open().result.then((res: {
                    type: HowToCreateProjectType
                }) => {
                    switch (res.type) {
                        case 'cost-plan-wizard':
                            this.topMenuStateService.resetProject();
                            this.router.navigate(['../new-project'], { relativeTo: this.activatedRoute });
                            break;
                        case 'bp-estimating-service':
                            this.estimatingAddonService.queryAllServices().then(addons => {
                                this.serviceSelectorModalService.open(addons).result.then(
                                    (res: { addon: IEstimatingAddon }) => {
                                        this.topMenuStateService.resetProject();
                                        this.router.navigate(
                                            ['../new-project'],
                                            { queryParams: { addonId: res.addon.id }, relativeTo: this.activatedRoute }
                                        )
                                    })
                            })
                            break;
                    }
                })
            }
        })
    }
}
