import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from 'app/flows/scheduler/dashboard/dashboard.component';
import { DashboardTileComponent } from 'app/flows/scheduler/dashboard/components/tile/tile.component';
import { DashboardMenuComponent } from 'app/flows/scheduler/dashboard/components/menu/menu.component';
import {
    DashboardProjectCostComponent
} from 'app/flows/scheduler/dashboard/components/project-cost/project-cost.component';
import { DashboardComparisonComponent } from 'app/flows/scheduler/dashboard/components/comparison/comparison.component';
import { DashboardTimelineComponent } from 'app/flows/scheduler/dashboard/components/timeline/timeline.component';
import {
    DashboardCostPlanTableDefaultQuoterComponent
} from 'app/flows/scheduler/dashboard/components/cost-plan-table-default-quoter/cost-plan-table-default-quoter.component';
import {
    DashboardCostPlanTableComparisonComponent
} from 'app/flows/scheduler/dashboard/components/cost-plan-table-comparison/cost-plan-table-comparison.component';
import {
    DashboardDisplayQuotersSelectorComponent
} from 'app/flows/scheduler/dashboard/components/display-quoters-selector/display-quoters-selector.component';
import {
    DashboardResourceCostBreakdownComponent
} from 'app/flows/scheduler/dashboard/components/resource-cost-breakdown/resource-cost-breakdown.component';

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule
    ],
    declarations: [
        DashboardComponent,
        DashboardMenuComponent,
        DashboardTileComponent,
        DashboardProjectCostComponent,
        DashboardComparisonComponent,
        DashboardTimelineComponent,
        DashboardCostPlanTableDefaultQuoterComponent,
        DashboardCostPlanTableComparisonComponent,
        DashboardDisplayQuotersSelectorComponent,
        DashboardResourceCostBreakdownComponent
    ],
    providers: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class BpDashboardModule {
}
