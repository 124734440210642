<td class="align-left"
    style="width: 35%">
    <div class="w-full flex gap-10 items-center">
        <div class="reference-number">
            {{ scheduleTask.referenceNumber }}
        </div>
        <div class="flex-1">
            <span>{{ !scheduleTask.action?.length ? '' : scheduleTask.action.toLowerCase().startsWith('install') ? 'Install ' : 'Supply and Install ' }}</span><span>{{ scheduleTask.task }}{{ scheduleTask.spec ? ', ' + scheduleTask.spec : '' }}</span>
            <span class="faint"> in {{ scheduleTask.scheduleArea }}</span>
            <span *ngIf="scheduleTask.isProvisional" class="faint">(Provisional)</span>
            <span *ngIf="scheduleTask.isSubcontract" class="faint">(Subcontract)</span>
        </div>
    </div>
</td>

<td class="align-left"
    style="width: 15%">
    <a *ngIf="scheduleTask.primeMaterial && scheduleTask.primeReferenceUrl"
       href="{{scheduleTask.primeReferenceUrl}}"
       rel="noopener"
       target="_blank">
        {{ scheduleTask.primeMaterial }}
    </a>
    <span *ngIf="scheduleTask.primeMaterial && !scheduleTask.primeReferenceUrl">{{ scheduleTask.primeMaterial }}</span>
</td>

<td class="number"
    style="width: 10%">
    <span *ngIf="scheduleTask.unitValue != null">
        {{ scheduleTask.unitValue | number : '1.2-2' }}
        <span *ngIf="scheduleTask.unit == 'm2'">m<sup>2</sup></span>
        <span
            *ngIf="scheduleTask.unit != 'm2'">{{ scheduleTask.unitValue > 1 ? scheduleTask.unitPlural : scheduleTask.unit }}</span>
    </span>
</td>

<td class="number"
    style="width: 8%">
    <span>{{ scheduleTask.totals[0] || 0 | currency:'GBP' }}</span>
</td>

<td class="number"
    style="width: 8%"
    [formGroup]="store.createForm" >
   <div class="m-l-40" [formArrayName]="'valuations'">
        <input [formControl]="service.getValuationFormControl(scheduleTask)"
               class="form-control v2"
               style="width: 70px"
               placeholder="Valuation"
               id="valuation"
               name="valuation"
               required
               type="text">
    </div>
</td>

<td class="number"
    style="width: 8%">
    <span>{{ service.getRemaining(scheduleTask) | currency:'GBP' }}</span>
</td>

<td class="number"
    style="width: 8%">
    <span>{{ service.getToInvoice(scheduleTask) | currency:'GBP' }}</span>
</td>

<td style="width: 8%">
</td>
