import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ComponentAreaUpdateComponent } from './edit/component-area-update.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { IComponentArea } from 'app/shared/model/component-area.model';
import { ComponentAreaListComponent } from 'app/entities/component-area/list/component-area-list.component';
import {
    ComponentAreaDeletePopupComponent
} from 'app/entities/component-area/delete/component-area-delete-dialog.component';

@Injectable({ providedIn: 'root' })
export class ComponentAreaResolve implements Resolve<IComponentArea> {
    constructor(private service: ComponentAreaService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IComponentArea> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<IComponentArea>) => response.ok),
                map((componentArea: HttpResponse<IComponentArea>) => componentArea.body)
            );
        }
        return of({});
    }
}

export const componentAreaRoute: Routes = [
    {
        path: 'component-area',
        component: ComponentAreaListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Component Areas'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'component-area/new',
        component: ComponentAreaUpdateComponent,
        resolve: {
            componentArea: ComponentAreaResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'component-areas'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'component-area/:id/edit',
        component: ComponentAreaUpdateComponent,
        resolve: {
            componentArea: ComponentAreaResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'component-areas'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const componentAreaPopupRoute: Routes = [
    {
        path: 'component-area/:id/delete',
        component: ComponentAreaDeletePopupComponent,
        resolve: {
            componentArea: ComponentAreaResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'component-areas'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
