import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { IAccount } from 'app/shared/model/account.model';
import { IArea } from 'app/shared/model/area.model';
import { AreaService } from 'app/shared/dataservices/area.service';
import { AccountService } from 'app/core/auth/account.service';
import { AreaDeleteDialogComponent } from 'app/entities/area/area-delete-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bp-area',
    templateUrl: './area.component.html'
})
export class AreaComponent implements OnInit {
    areas: IArea[];
    currentAccount: IAccount;

    constructor(
        private areaService: AreaService,
        private accountService: AccountService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });
    }

    protected trackId(index: number, item: IArea): number {
        return item.id;
    }

    protected onDeleteArea(area: IArea): void {
        const ngbModalRef = this.modalService.open(AreaDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });

        ngbModalRef.componentInstance.area = area;

        ngbModalRef.result.then(
            result => {
                this.loadAll();
            },
            reason => {
                //
            }
        );
    }

    protected onUpdateArea(area: IArea): void {
        const ngbModalRef = this.modalService.open(AreaDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });

        ngbModalRef.componentInstance.area = area;

        ngbModalRef.result.then(
            result => {
                this.loadAll();
            },
            reason => {
                //
            }
        );
    }


    private loadAll(): void {
        this.areaService.query().subscribe(
            (res: HttpResponse<IArea[]>) => {
                this.areas = res.body;
            }
        );
    }
}
