<bp-valuations-table>
</bp-valuations-table>

<bp-valuations-submit-valuation>
</bp-valuations-submit-valuation>

<div class="no-valuations"
     *ngIf="!store.valuationResponse; else valuationExists">
    <div>
        Congratulations! Your quote has been accepted! You can now create your first valuation below
    </div>

    <button (click)="onCreateNewValuationClick()"
            class="btn btn-primary btn-border-radius"
            ngbTooltip="Create new valuation"
            type="button">
        Create new valuation
    </button>
</div>

<ng-template #valuationExists>
    <div>
        <button (click)="onCreateNewValuationClick()"
                class="btn btn-primary btn-border-radius"
                ngbTooltip="Submit new valuation"
                type="button">
            Submit new valuation
        </button>
    </div>
</ng-template>

