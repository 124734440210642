<form (ngSubmit)="addMaterialCategory()"
      name="addMaterialCategoryForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Add Material Category
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body m-b-20">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-line">
                    <input [(ngModel)]="materialCategoryName"
                           class="af-input"
                           id="material-category-name"
                           name="material-category-name"
                           placeholder="Enter material category name"
                           type="text"/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="clear()"
                class="btn btn-secondary btn-border-radius"
                data-dismiss="modal"
                type="button">
            Cancel
        </button>
        <button [disabled]="isFormValid()"
                class="btn btn-primary btn-border-radius waves-effect add-material-category"
                type="submit">
            Add Material Category
        </button>
    </div>
</form>
