<img alt=""
     class="login-logo"
     src="../../../content/images/bp_logo.png"/>

<div class="wrap-login">
    <form (ngSubmit)="login()"
          class="wrap-login__form validate-form flex flex-column gap-20"
          role="form">
        <div class="wrap-login__title">
            Sign in
        </div>

        <div *ngIf="incorrectCredentialsError || httpError"
             class="alert alert-danger"
             style="margin-left: 0">
            <div *ngIf="incorrectCredentialsError; else httpErrorBlock">
                Incorrect email/password, feel free to try again or click 'Forgot password' below.
            </div>
            <ng-template #httpErrorBlock>
                <small>
                    {{ httpError }}
                </small>
            </ng-template>
        </div>

        <div class="validate-input"
             data-validate="Enter email">
            <label for="username">
                Email Address
            </label>
            <input [(ngModel)]="username"
                   class="form-control v2"
                   id="username"
                   name="username"
                   type="text"
                   autofocus>
        </div>

        <div class="flex flex-column gap-2">
            <div class="validate-input"
                 data-validate="Enter password">
                <label for="password">
                    Password
                </label>
                <input [(ngModel)]="password"
                       class="form-control v2"
                       id="password"
                       name="password"
                       type="password">
            </div>

            <div class="wrap-login__forgot-password-container">
                <a (click)="requestResetPassword()">
                    Forgot Password?
                </a>
            </div>
        </div>

        <div class="flex flex-column align-items-center gap-20">
            <button class="wrap-login__login btn-primary btn-border-radius w-full"
                    type="submit">
                Sign in
            </button>

            <div class="flex gap-40 align-items-center w-full">
                <div class="flex-1 border-bottom"></div>
                <div>or</div>
                <div class="flex-1 border-bottom"></div>
            </div>

            <button (click)="signInWithGoogle()"
                    class="wrap-login__google google-auth w-full"
                    type="button">
                <img alt="Google" height="24" src="/content/images/google.svg">
                Continue with Google
            </button>

            <div class="m-t-10">
                <span class="link-title m-r-5">Need an account?</span>
                <a (click)="singUp()"
                   class="link underline"
                   href="javascript:void(0);">
                    <span>Sign Up</span>
                </a>
            </div>
        </div>
    </form>
</div>
