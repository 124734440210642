import { Route } from '@angular/router';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { SettingsComponent } from 'app/account/settings/settings.component';

export const settingsRoute: Route = {
    path: 'settings',
    component: SettingsComponent,
    data: {
        authorities: ['ROLE_SCHEDULER', 'ROLE_QUOTER', 'ROLE_ADMIN'],
        pageTitle: 'Settings'
    },
    canActivate: [UserRouteAccessService]
};
