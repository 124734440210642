import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BpDownloadExcelFileService {
    call(data: string, filename: string) {
        const pom = document.createElement('a');
        const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        const url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', filename);
        document.body.appendChild(pom);
        pom.click();
        document.body.removeChild(pom);
    }
}
