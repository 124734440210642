import { Component, Input, OnInit } from '@angular/core';
import { IStage } from 'app/shared/model/stage.model';
import * as _ from 'lodash';
import { IScheduleTaskQuoterTotalModificationEvent } from 'app/shared/constants/events.constants';
import {
    ExpandStageAreaGroupStorageService
} from 'app/flows/scheduler/schedule/services/expand-stage-area-group.storage.service';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    CalculateStageFilteredByAreaTotalService
} from 'app/flows/scheduler/schedule/services/calculate-stage-filtered-by-area-total.service';
import { IElement } from 'app/shared/model/element.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'bp-schedule-stage-for-filter-by-area-grouping',
    templateUrl: './stage.component.html',
    styleUrls: ['../../root-container/stage.scss', 'stage.scss']
})
export class StageForFilterByAreaGroupingComponent implements OnInit {
    @Input() stage: IStage;
    @Input() scheduleArea: IScheduleArea;
    @Input() first: boolean;
    @Input() showGroupNode = true;

    protected expanded = false;

    constructor(
        public scheduleService: ScheduleService,
        private confirmModalService: ConfirmModalService,
        private scheduleEventsService: ScheduleEventsService,
        private expandStageAreaGroupStorageService: ExpandStageAreaGroupStorageService,
        private calculateStageFilteredByAreaTotalService: CalculateStageFilteredByAreaTotalService
    ) {
    }

    protected get stageElements(): IElement[] {
        switch (this.scheduleService.showSubStagesMode) {
            case 'display-all':
            case 'hide-all':
            case 'finishes':
                return this.stage.elements;
            case 'show-in-use':
                return _.filter(this.stage.elements, (element: IElement) => {
                    const scheduleTasks = _.filter(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                        if (this.scheduleArea != null) {
                            return scheduleTask.scheduleAreaId === this.scheduleArea.id;
                        }
                        return true;
                    });

                    return scheduleTasks.length > 0;
                });
        }
    }

    ngOnInit(): void {
        this.stage.elements = this.stage.elements || [];
        this.expanded = this.expandStageAreaGroupStorageService.retrieve(this.scheduleService.project.id, this.stage.id, this.scheduleArea.id);
        this.registerScheduleTaskQuoterTotalModificationEvent();

        this.scheduleService.expandStage$
            .pipe(
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res.expand;
            this.expandStageAreaGroupStorageService.store(this.scheduleService.project.id, this.stage.id, this.scheduleArea.id, res.expand);
        })
    }

    protected onDeleteStageClick(): void {
        this.confirmModalService.open({
            header: `Delete ${this.stage.stage} Stage in Area ${this.scheduleArea.area}?`,
            textHtml: `<div class='strong m-b-10'>
                            This will delete all its associated tasks and costs.
                        </div>
                        <div class='strong m-b-10'>
                            Note: You can re-add tasks to this area by clicking 'On' in the templates section or using the 'add task' function.
                        </div>`
        }).result.then((res) => {
            if (!res) {
                return;
            }

            this.scheduleService.deleteStage(this.scheduleArea, this.stage);
        })
    }

    protected toggleStageExpand(): void {
        if (this.expanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    private expand(): void {
        this.expanded = true;
        this.expandStageAreaGroupStorageService.store(this.scheduleService.project.id, this.stage.id, this.scheduleArea.id, true);
    }

    private collapse(): void {
        this.expanded = false;
        this.expandStageAreaGroupStorageService.store(this.scheduleService.project.id, this.stage.id, this.scheduleArea.id, false);
    }

    private registerScheduleTaskQuoterTotalModificationEvent(): void {
        this.scheduleEventsService.scheduleTaskTotalModified$
            .pipe(
                untilDestroyed(this)
            ).subscribe((event: IScheduleTaskQuoterTotalModificationEvent) => {
            if (!event || event.stageId !== this.stage.id) {
                return;
            }

            this.stage._quoterTotals[this.scheduleArea.id] = this.calculateStageFilteredByAreaTotalService.calculate(
                this.stage,
                this.scheduleArea
            );

            this.scheduleEventsService.stageQuoterTotalModified();
        })
    }
}
