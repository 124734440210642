import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    @Input() showText = false;
    @Input() showBackground = false;
    @Input() loaderText;
    @Input() fullscreen = false;
    @Input() loaderColor = 'rgba(255, 255, 255, 1)';
    @Input() backdropColor = 'rgba(0, 0, 63, 0.2)';
    @Input() backgroundImage = 'loading-splash.gif';

    constructor() {}

    ngOnInit() {}
}
