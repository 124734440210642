import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IProject } from 'app/shared/model/project.model';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class ShowHistoryViewStorageService {

    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(project: IProject, value: boolean): void {
        this.webStorage.store(this.getSessionStorageKey(project), value);
    }

    retrieve(project: IProject): boolean {
        const result = this.webStorage.retrieve(this.getSessionStorageKey(project));
        return result != null ? result : false;
    }

    private getSessionStorageKey(project: IProject): string {
        return `quoter_${this.accountService.getAccountId()}_showHistoryView_${project.id}`;
    }
}
