import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';
import {
    EditScheduleAreaSwitchMode
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/edit-schedule-area-service.service';

@Injectable()
export class AreaDetailsModeStorageService {
    constructor(private webStorage: LocalStorageService, private accountService: AccountService) {
        this.accountService.identity();
    }

    store(projectId: number, value: EditScheduleAreaSwitchMode): void {
        this.webStorage.store(this.getLocalStorageKey(projectId), value);
    }

    retrieve(projectId: number): EditScheduleAreaSwitchMode {
        const value: EditScheduleAreaSwitchMode = this.webStorage.retrieve(this.getLocalStorageKey(projectId));
        return value != null ? value : 'Width_Depth_Height_Scope';
    }

    private getLocalStorageKey(projectId: number): string {
        return `scheduler_${this.accountService.getAccountId()}_areaDetailsModal_${projectId}`;
    }
}
