import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bp-how-your-trial-works',
    templateUrl: './how-your-trial-works.component.html',
    styleUrls: ['how-your-trial-works.scss']
})
export class HowYourTrialWorksComponent implements OnInit {

    day14: Date = HowYourTrialWorksComponent.getDay14();

    constructor() {}

    ngOnInit() {
    }

    private static getDay14(): Date {
        const date = new Date();
        date.setDate(date.getDate() + 14);
        return date;
    }

}
