import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
    providedIn: 'root'
})
export class HighlightTasksStorageService {

    constructor(private _webStorage: SessionStorageService,
                private _accountService: AccountService) {
        this._accountService.identity();
    }

    store(projectId: number, value: number[]): void {
        this._webStorage.store(this.getLocalStorageKey(projectId), value);
    }

    clear(projectId: number): void {
        this._webStorage.store(this.getLocalStorageKey(projectId), []);
    }

    retrieve(projectId: number): number[] {
        return this._webStorage.retrieve(this.getLocalStorageKey(projectId)) || [];
    }

    private getLocalStorageKey(projectId: number): string {
        return `scheduler_${this._accountService.getAccountId()}_highlight_tasks_${projectId}`;
    }
}
