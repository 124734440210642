import { Component, OnInit } from '@angular/core';
import {
    IMainViewFilterItemItem
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import {
    CostPlanService,
    ICostPlanValueColumn
} from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IComparisonQoter } from 'app/shared/model/comparison.model';

@Component({
    selector: 'bp-cost-plan-table',
    templateUrl: './cost-plan-table.component.html',
    styleUrls: ['cost-plan-table.scss']
})
export class CostPlanTableComponent implements OnInit {
    VIEW_MORE_LIMIT = 8;
    viewMoreMode = true;

    get quoters(): IComparisonQoter[] {
        switch (this.costPlanService.costPlanMode) {
            case 'comparison-view':
                return this.costPlanService.quoters;
            default:
                return [this.costPlanService.quoters.find(q => q.default)];
        }
    }

    get valueColumns(): ICostPlanValueColumn[] {
        switch (this.costPlanService.costPlanMode) {
            case 'comparison-view':
                return this.costPlanService.valueColumns;
            default:
                if (!this.costPlanService.valueColumns?.length) {
                    return [];
                } else {
                    return [this.costPlanService.valueColumns.find(q => q.quoter.id === this.costPlanService.project.defaultQuoter.id) || this.costPlanService.valueColumns[0]];
                }
        }
    }

    constructor(public costPlanService: CostPlanService) {
    }

    ngOnInit(): void {
    }

    itemChecked(item: IMainViewFilterItemItem): boolean {
        return this.costPlanService.filterStateIds == null || this.costPlanService.filterStateIds.indexOf(item.id) !== -1;
    }

    onCheckboxClick(item: IMainViewFilterItemItem): void {
        this.costPlanService.toggleItem(item);
    }

    getValue(column: ICostPlanValueColumn, item: IMainViewFilterItemItem): number {
        return column.data.find(d => d.item.id === item.id)?.total || 0;
    }

    selectDefaultQuoter(quoter: IComparisonQoter): void {
        if (this.costPlanService.isQuoterDefault(quoter)) {
            return;
        }
        this.costPlanService.updateDefaultQuoter(quoter);
    }

    getTooltip(quoter: IComparisonQoter): string {
        let additionalInfo = null;

        switch (quoter.company?.toLowerCase()) {
            case 'low benchmark': {
                additionalInfo = 'Small company, 1 - 3 team members';
                break;
            }
            case 'medium benchmark': {
                additionalInfo = 'Medium company, 3-10 team members';
                break;
            }
            case 'high benchmark': {
                additionalInfo = 'Large company, 20+ team members';
                break;
            }
        }

        let result = `Select ${quoter.company || quoter.email}`;
        if (additionalInfo) {
            result = `${result} (${additionalInfo})`;
        }

        return result;
    }
}
