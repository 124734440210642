import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IProject } from 'app/shared/model/project.model';

/**
 * View Project Component
 */
@Component({
    selector: 'bp-view-quoter-project',
    styleUrls: ['view-project.scss'],
    templateUrl: './view-project.component.html'
})
export class ViewProjectComponent implements OnInit {
    routeData: any;

    project: IProject;

    constructor(
        private activatedRoute: ActivatedRoute
    ) {
        this.routeData = this.activatedRoute.data.subscribe(data => {
            this.project = data.project;
        });
    }

    ngOnInit(): void {}

    private getJSONForSaveRequest(): IProject {
        const project = Object.assign({}, this.project);
        project.defaultQuoter = project.defaultQuoter == null ? null : { id: project.defaultQuoter.id };
        return project;
    }
}
