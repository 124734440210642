<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-tag-heading">
                    Create or edit a Tag
                </h1>
                <div>
                    <div [hidden]="!tag.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="tag.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>

                    <div class="form-group">
                        <bp-image-uploader (onUploaded)="tag.icon = $event"
                                           [initialImageUrl]="tag.icon">
                        </bp-image-uploader>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_name">
                            Name
                        </label>
                        <input [(ngModel)]="tag.name"
                               class="form-control"
                               id="field_name"
                               name="name"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.name?.dirty && editForm.controls.name?.invalid)">
                            <small [hidden]="!editForm.controls.name?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_title">
                            Title
                        </label>
                        <input [(ngModel)]="tag.title"
                               class="form-control"
                               id="field_title"
                               name="title"
                               type="text"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_description">
                            Description
                        </label>
                        <input [(ngModel)]="tag.description"
                               class="form-control"
                               id="field_description"
                               name="description"
                               type="text"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_tagOrder">
                            Order
                        </label>
                        <input [(ngModel)]="tag.tagOrder"
                               class="form-control"
                               id="field_tagOrder"
                               name="tagOrder"
                               type="number"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_parentTag">
                            Parent Tag
                        </label>
                        <select [(ngModel)]="tag.parentTag"
                                class="form-control"
                                id="field_parentTag"
                                name="field_parentTag">
                            <option [ngValue]="null"></option>
                            <option
                                *ngFor="let tagOption of tags; trackBy: trackById"
                                [ngValue]="tagOption.id === tag.parentTag?.id ? tag.parentTag : tagOption">
                                {{ tagOption.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
