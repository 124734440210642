import { Component, OnInit } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ActivatedRoute } from '@angular/router';
import {
    RequestProjectQuoterModalService
} from 'app/flows/scheduler/components/request-project-quoter-modal/request-project-quoter-modal.service';
import {
    getPrivilegeText,
    IInvitationProjectTeamMember,
    IProjectTeamMember,
    TEAM_MEMBER_PERMISSIONS
} from 'app/shared/model/project-team-member.model';
import { ProjectTeamService } from 'app/shared/dataservices/project-team.service';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'app/core/auth/account.service';


/**
 * Project Team
 */
@Component({
    selector: 'bp-scheduler-team',
    templateUrl: './team.page.html',
    styleUrls: ['team.scss']
})
export class SchedulerTeamComponent implements OnInit {
    protected project: IProject;
    protected projectTeamMembers: IProjectTeamMember[];
    protected inProcessLoadingProjectTeamMembers = true;
    protected inProcessRemoveProjectTeamMember = false;
    protected inProcessSentInvitation = false;
    protected permissions = _.filter(TEAM_MEMBER_PERMISSIONS, { readonly: false });

    protected form: FormGroup | null;

    constructor(
        private accountService: AccountService,
        private activatedRoute: ActivatedRoute,
        private alertService: BpAlertService,
        private projectTeamService: ProjectTeamService,
        private requestProjectQuoterModalService: RequestProjectQuoterModalService
    ) {
        this.activatedRoute.data.subscribe(data => {
            this.project = data.project;
            this.resetForm();
            this.loadProjectTeamMembers();
        });
    }

    ngOnInit(): void {
    }

    protected onRequestProfessionalClick(): void {
        this.requestProjectQuoterModalService.open(this.project.id);
    }

    protected onRemoveProjectTeamMemberClick(projectTeamMember: IProjectTeamMember): void {
        this.inProcessRemoveProjectTeamMember = true;

        this.projectTeamService
            .delete(projectTeamMember.id)
            .pipe(
                finalize(() => {
                    this.inProcessRemoveProjectTeamMember = false;
                })
            )
            .subscribe(() => {
                this.alertService.success('Project team member successfully removed');
                this.loadProjectTeamMembers();
            });
    }

    protected onSendInviteClick(): void {
        this.inProcessSentInvitation = true;

        const newInvitation: IInvitationProjectTeamMember = {
            email: this.form.controls.email.value,
            phone: this.form.controls.phone.value,
            privilege: this.form.controls.permission.value.id,
        }
        this.projectTeamService
            .sendInvitation(this.project.id, newInvitation)
            .pipe(
                finalize(() => {
                    this.inProcessSentInvitation = false;
                })
            )
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.alertService.success('Invitation successfully sent');
                    this.resetForm();
                    this.projectTeamMembers.push(res.body);
                },
                (erRes: any) => {
                    this.alertService.error(`Cannot invite team member: ${erRes?.error?.detail || 'Unknown reason'}`);
                }
            );
    }

    protected getPrivilege(projectTeamMember: IProjectTeamMember): string {
        return getPrivilegeText(projectTeamMember.privilege);
    }

    protected isRemoveTeamMemberDisabled(projectTeamMember: IProjectTeamMember): boolean {
        return !this.project?.isOwner || this.inProcessRemoveProjectTeamMember || projectTeamMember.email === this.accountService.getEmail();
    }

    private loadProjectTeamMembers(): void {
        this.inProcessLoadingProjectTeamMembers = true;

        this.projectTeamService
            .query(this.project.id)
            .pipe(
                finalize(() => {
                    this.inProcessLoadingProjectTeamMembers = false;
                })
            )
            .subscribe((res: HttpResponse<IProjectTeamMember[]>) => {
                this.projectTeamMembers = res.body;
            });
    }

    private resetForm(): void {
        this.form = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
            permission: new FormControl(this.permissions.find(p => p.id === 'READ'), [Validators.required]),
            phone: new FormControl(null),
        });

    }

}
