<div class="root">
    <table class="af-table m-b-20">
        <tr class="tr-head">
            <th style="width: 35%">
                {{ element.element }}
            </th>
            <th style="width: 25%">
                Specification
            </th>
            <th style="width: 10%">
                Qty
            </th>
            <th style="width: 10%">
                Labour
            </th>
            <th style="width: 10%">
                Materials
            </th>
            <th style="width: 10%">
            </th>
        </tr>

        <tr *ngFor="let scheduleTask of element.taskDTOs"
            [scheduleTask]="scheduleTask"
            bpIndividualQuoteScheduleTask>
        </tr>
    </table>
</div>
