import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QUOTER_ROUTE } from './quoter.route';
import { ProjectsComponent } from './projects/projects.component';
import { QuoteComponent } from './quote/quote.component';
import { QuoteStagesComponent } from './quote/components/stages/stages.component';
import { QuoteScheduleTaskComponent } from './quote/components/schedule-task/schedule-task.component';
import { QuoteStageComponent } from './quote/components/stage/stage.component';
import { QuoteElementComponent } from './quote/components/element/element.component';
import { LabourRatesComponent } from './labour-rates/labour-rates.component';
import { MaterialRatesComponent } from './material-rates/material-rates.component';
import { TaskLabourRatesComponent } from './task-labour-rates/task-labour-rates.component';
import { QuoteTaskDetailsComponent } from './quote-task-details/quote-task-details.component';
import { QuoteReportService } from './quote/services/quote-report.service';
import { QuoterExpandStagesStorageService } from './quote/services/expand-stages-storage.service';
import { ShowSubStagesStorageService } from 'app/flows/quoter/quote/services/show-sub-stages-storage.service';
import { ShowHistoryViewStorageService } from 'app/flows/quoter/quote/services/show-history-view-storage.service';
import { ViewProjectComponent } from 'app/flows/quoter/view-project/view-project.component';
import { CostPlanComponent } from 'app/flows/quoter/cost-plan/cost-plan.component';
import { OptInPayModalComponent } from 'app/flows/quoter/components/opt-in-pay-modal/opt-in-pay-modal.component';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import {
    MaterialRatesModalComponent
} from 'app/flows/quoter/components/material-rates-modal/material-rates-modal.component';
import { LabourRatesModalComponent } from 'app/flows/quoter/components/labour-rates-modal/labour-rates-modal.component';

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule.forRoot([QUOTER_ROUTE])
    ],
    declarations: [
        ProjectsComponent,
        CostPlanComponent,
        QuoteComponent,
        QuoteStagesComponent,
        QuoteStageComponent,
        QuoteElementComponent,
        QuoteScheduleTaskComponent,
        LabourRatesComponent,
        TaskLabourRatesComponent,
        MaterialRatesComponent,
        QuoteTaskDetailsComponent,
        ViewProjectComponent,
        OptInPayModalComponent,
        MaterialRatesModalComponent,
        LabourRatesModalComponent
    ],
    providers: [
        QuoteReportService,
        QuoterExpandStagesStorageService,
        ShowSubStagesStorageService,
        ShowHistoryViewStorageService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpFrontendQuoterModule {
}
