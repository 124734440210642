import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MaterialCategoryListComponent } from 'app/entities/material-category/list/material-category-list.component';
import { MaterialCategoryUpdateComponent } from 'app/entities/material-category/edit/material-category-update.component';
import { MaterialCategoryDeletePopupComponent } from 'app/entities/material-category/delete/material-category-delete-dialog.component';
import { IMaterialCategory, MaterialCategory } from 'app/shared/model/material-category.model';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

const MATERIAL_CATEGORIES = 'Material Categories';

@Injectable({ providedIn: 'root' })
export class MaterialCategoryResolve implements Resolve<IMaterialCategory> {
    constructor(private service: MaterialCategoryService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MaterialCategory> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<MaterialCategory>) => response.ok),
                map((materialCategory: HttpResponse<MaterialCategory>) => materialCategory.body)
            );
        }
        return of(new MaterialCategory());
    }
}

export const materialCategoryRoute: Routes = [
    {
        path: 'material-category',
        component: MaterialCategoryListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: MATERIAL_CATEGORIES
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'material-category/new',
        component: MaterialCategoryUpdateComponent,
        resolve: {
            materialCategory: MaterialCategoryResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: MATERIAL_CATEGORIES
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'material-category/:id/edit',
        component: MaterialCategoryUpdateComponent,
        resolve: {
            materialCategory: MaterialCategoryResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: MATERIAL_CATEGORIES
        },
        canActivate: [UserRouteAccessService]
    }
];

export const materialCategoryPopupRoute: Routes = [
    {
        path: 'material-category/:id/delete',
        component: MaterialCategoryDeletePopupComponent,
        resolve: {
            materialCategory: MaterialCategoryResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: MATERIAL_CATEGORIES
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
