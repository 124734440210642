import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { ScopesPage } from 'app/flows/scheduler/scopes/scopes.page';
import { ScopeComponent } from 'app/flows/scheduler/scopes/components/scope/scope.component';
import { ScopeHeaderComponent } from 'app/flows/scheduler/scopes/components/scope-header/scope-header.component';
import {
    ScopesContainerComponent
} from 'app/flows/scheduler/scopes/components/scopes-container/scopes-container.component';

@NgModule({
    imports: [
        BpFrontendSharedModule,
        RouterModule
    ],
    declarations: [
        ScopesPage,
        ScopesContainerComponent,
        ScopeComponent,
        ScopeHeaderComponent
    ],
    providers: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class BpScopeModule {
}
