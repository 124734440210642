import { Component, Input } from '@angular/core';
import { ProjectStatus } from 'app/shared/model/project.model';

@Component({
    selector: 'bp-project-status',
    templateUrl: './project-status.component.html',
    styleUrls: ['project-status.scss']
})
export class ProjectStatusComponent {
    @Input() status: ProjectStatus;
}
