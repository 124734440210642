import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse } from '@angular/common/http';
import { CssElementService } from 'app/shared/dataservices/css-element.service';
import { ICssElement } from 'app/shared/model/bp.model';

@Component({
    selector: 'bp-add-css-element-modal',
    templateUrl: './add-css-element-modal.component.html',
    styleUrls: ['add-css-element-modal.scss']
})
export class AddCssElementModalComponent {
    protected cssElementName: string;

    constructor(private activeModal: NgbActiveModal,
                private cssElementService: CssElementService) {}

    protected clear(): void {
        this.activeModal.dismiss('cancel');
    }

    protected addCssElement(): void {
        const cssElement: ICssElement = {
            name: this.cssElementName
        };

        this.cssElementService.create(cssElement).subscribe((res: HttpResponse<ICssElement>) => {
            this.activeModal.close(res.body);
        });
    }

    protected isFormInvalid(): boolean {
        return this.cssElementName == null || this.cssElementName.length === 0;
    }
}
