export interface ISpecification {
    id?: number;
    name?: string;
    title?: string;
    description?: string;
    icon?: string;
}

export class Specification implements ISpecification {
    constructor(public id?: number, public name?: string, public title?: string, public description?: string, public icon?: string) {}
}
