import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MainBpBackgroundService } from 'app/shared/services/main-bp-background.service';
import { CreateSampleProjectService } from 'app/shared/services/create-sample-project.service';
import { IProject } from 'app/shared/model/project.model';
import { LoaderService } from 'app/shared/services/loader-service.service';
import { AddNewProjectService } from 'app/shared/components/projects/services/add-new-project.service';

@Component({
    selector: 'bp-empty-projects',
    templateUrl: './empty-projects.component.html',
    styleUrls: ['empty-projects.scss'],
    providers: [
        AddNewProjectService
    ]
})
export class EmptyProjectsComponent implements OnInit, OnDestroy {
    @Input() canCreateProject = true;
    @Output() onExampleProjectCreation = new EventEmitter<IProject>();

    constructor(private mainBpBackgroundService: MainBpBackgroundService,
                private loaderService: LoaderService,
                private addNewProjectService: AddNewProjectService,
                private createSampleProjectService: CreateSampleProjectService) {
    }

    ngOnInit(): void {
        this.mainBpBackgroundService.activate();
    }

    ngOnDestroy(): void {
        this.mainBpBackgroundService.deactivate();
    }

    protected createNewProject(): void {
        this.addNewProjectService.addNew();
    }

    protected createSampleProject(): void {
        this.loaderService.start({ backgroundImage: 'create-account-splash.gif' });

        this.createSampleProjectService.create().then((createdProject: IProject) => {
            this.onExampleProjectCreation.emit(createdProject);
        }).finally(() => {
            this.loaderService.stop();
        });
    }
}
