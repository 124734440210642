import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sort' })
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string, direction: 'asc' | 'desc' = 'desc'): any[] {
        if (!Array.isArray(array)) {
            return null;
        }
        array.sort((a: any, b: any) => {
            if (+(a[field] || 0) < + +(b[field] || 0)) {
                return direction === 'asc' ? -1 : 1;
            } else if (+(a[field] || 0) > +(b[field] || 0)) {
                return direction === 'asc' ? 1 : -1;
            } else {
                return 0;
            }
        });
        return array;
    }
}
